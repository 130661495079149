/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../common/currency';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      bills: {
        nested: {
          Cart: {
            nested: {
              FeatureDetails: {
                nested: {
                  OpenTicket: {
                    nested: {
                      ReservationInformation: {
                        fields: {
                          guestFirstName: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          guestLastName: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                          partySize: {
                            type: 'uint32',
                            id: 3,
                            rule: 'optional',
                          },
                          occasions: {
                            type: 'string',
                            id: 4,
                            rule: 'repeated',
                          },
                          guestRelationships: {
                            type: 'string',
                            id: 5,
                            rule: 'repeated',
                          },
                          providerName: {
                            type: 'string',
                            id: 6,
                            rule: 'optional',
                          },
                          guestDiningPreferences: {
                            type: 'string',
                            id: 7,
                            rule: 'repeated',
                          },
                          guestSeatingPreferences: {
                            type: 'string',
                            id: 8,
                            rule: 'repeated',
                          },
                          reservationNotes: {
                            type: 'squareup.bills.Cart.FeatureDetails.OpenTicket.ReservationInformation.ReservationNotes',
                            id: 9,
                            rule: 'optional',
                          },
                          reservationTime: {
                            type: 'string',
                            id: 10,
                            rule: 'optional',
                          },
                          reservationId: {
                            type: 'string',
                            id: 11,
                            rule: 'optional',
                          },
                          tags: {
                            type: 'string',
                            id: 12,
                            rule: 'repeated',
                          },
                          providerReservationId: {
                            type: 'string',
                            id: 13,
                            rule: 'optional',
                          },
                          deposit: {
                            type: 'squareup.bills.Cart.FeatureDetails.OpenTicket.ReservationInformation.Deposit',
                            id: 14,
                            rule: 'optional',
                          },
                        },
                        nested: {
                          ReservationNotes: {
                            fields: {
                              guestGeneralNote: {
                                type: 'string',
                                id: 1,
                                rule: 'optional',
                              },
                              guestDiningPreferencesNote: {
                                type: 'string',
                                id: 2,
                                rule: 'optional',
                              },
                              guestSpecialRelationshipNote: {
                                type: 'string',
                                id: 3,
                                rule: 'optional',
                              },
                              venueNote: {
                                type: 'string',
                                id: 4,
                                rule: 'optional',
                              },
                              guestRequest: {
                                type: 'string',
                                id: 5,
                                rule: 'optional',
                              },
                              guestSeatingPreferencesNote: {
                                type: 'string',
                                id: 6,
                                rule: 'optional',
                              },
                            },
                          },
                          Deposit: {
                            fields: {
                              depositAmount: {
                                type: 'squareup.common.Money',
                                id: 1,
                                rule: 'optional',
                              },
                              paymentId: {
                                type: 'string',
                                id: 2,
                                rule: 'optional',
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.bills?.nested?.Cart?.nested?.FeatureDetails
    ?.nested?.OpenTicket?.nested?.ReservationInformation &&
  !$root.nested?.squareup?.nested?.bills?.nested?.Cart?.nested?.FeatureDetails
    ?.nested?.OpenTicket?.nested?.ReservationInformation?.nested
    ?.ReservationNotes &&
  !$root.nested?.squareup?.nested?.bills?.nested?.Cart?.nested?.FeatureDetails
    ?.nested?.OpenTicket?.nested?.ReservationInformation?.nested?.Deposit
) {
  $root.addJSON(jsonData);
}

/**
 * Container for all line items on a bill.
 *
 * The cart should contain all information necessary to provide accurate reporting.
 * NEXT TAG: 10
 *
 * SOURCE Cart @ squareup/bills/cart.proto at 29:1
 */
export const Cart = {};
/**
 * SOURCE FeatureDetails @ squareup/bills/cart.proto at 184:3
 */
Cart.FeatureDetails = {};
/**
 * SOURCE OpenTicket @ squareup/bills/cart.proto at 202:5
 */
Cart.FeatureDetails.OpenTicket = {};
/**
 * Contains details on the reservation the OpenTicket is tied to. This is only intended to be used for Restaurants Reservations.
 *
 * SOURCE ReservationInformation @ squareup/bills/cart.proto at 302:7
 */
Cart.FeatureDetails.OpenTicket.ReservationInformation = $root.lookupType(
  'squareup.bills.Cart.FeatureDetails.OpenTicket.ReservationInformation'
);
fixType(Cart.FeatureDetails.OpenTicket.ReservationInformation);
Builder.createAndAttachToType(
  Cart.FeatureDetails.OpenTicket.ReservationInformation
);
/**
 * SOURCE ReservationNotes @ squareup/bills/cart.proto at 327:9
 */
Cart.FeatureDetails.OpenTicket.ReservationInformation.ReservationNotes =
  $root.lookupType(
    'squareup.bills.Cart.FeatureDetails.OpenTicket.ReservationInformation.ReservationNotes'
  );
fixType(Cart.FeatureDetails.OpenTicket.ReservationInformation.ReservationNotes);
Builder.createAndAttachToType(
  Cart.FeatureDetails.OpenTicket.ReservationInformation.ReservationNotes
);
/**
 * SOURCE Deposit @ squareup/bills/cart.proto at 362:9
 */
Cart.FeatureDetails.OpenTicket.ReservationInformation.Deposit =
  $root.lookupType(
    'squareup.bills.Cart.FeatureDetails.OpenTicket.ReservationInformation.Deposit'
  );
fixType(Cart.FeatureDetails.OpenTicket.ReservationInformation.Deposit, {
  depositAmount: { required: true },
});
Builder.createAndAttachToType(
  Cart.FeatureDetails.OpenTicket.ReservationInformation.Deposit
);
