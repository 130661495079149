import {
  MarketButton,
  MarketList,
  MarketRadio,
  MarketRow,
} from '@market/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import nonTranslatableStrings from 'utils/nonTranslatableStrings';

interface OptionsProps {
  doUnsubscribeMerchant: Function;
  doUnsubscribeGlobal: Function;
  obfuscatedEmail: string;
  merchantName: string;
  isUnsubscribing: boolean;
}

enum OPTION_VALUES {
  MERCHANT = 'merchant',
  GLOBAL = 'global',
}

const Options: React.FC<OptionsProps> = (props) => {
  const { t } = useTranslation();

  const [selectedValue, setSelectedValue] = useState<OPTION_VALUES>(
    OPTION_VALUES.MERCHANT
  );

  /* Content */

  return (
    <>
      <h1 className="heading-30">
        {t('preference.components.unsubscribe.marketing.title')}
      </h1>

      <p className="paragraph-30 my-0">
        {t('preference.components.unsubscribe.marketing.subtitle', {
          obfuscatedEmail: props.obfuscatedEmail,
        })}
      </p>

      <MarketList
        aria-label={t('preference.routes.marketing.optionsList.ariaLabel')}
        value={selectedValue}
        onMarketListSelectionsDidChange={(event) => {
          if (!event.detail.newSelectionValue) {
            return;
          }
          setSelectedValue(
            event.detail.newSelectionValue === OPTION_VALUES.MERCHANT
              ? OPTION_VALUES.MERCHANT
              : OPTION_VALUES.GLOBAL
          );
        }}
      >
        <MarketRow value={OPTION_VALUES.MERCHANT}>
          <label
            id="merchant-marketing-unsubscribe-option-label"
            slot={'label'}
          >
            {t(
              'preference.components.unsubscribe.marketing.content.unsubscribeMerchant',
              { merchantName: props.merchantName }
            )}
          </label>
          <MarketRadio
            aria-labelledby="merchant-marketing-unsubscribe-option-label"
            slot={'control'}
          />
        </MarketRow>

        <MarketRow value={OPTION_VALUES.GLOBAL}>
          <label id="global-marketing-unsubscribe-option-label" slot={'label'}>
            {t(
              'preference.components.unsubscribe.marketing.content.unsubscribeGlobal',
              {
                SQUARE: nonTranslatableStrings.square,
              }
            )}
          </label>
          <MarketRadio
            aria-labelledby="global-marketing-unsubscribe-option-label"
            slot={'control'}
          />
        </MarketRow>
      </MarketList>

      <MarketButton
        className={'mt-2'}
        onClick={() => {
          if (selectedValue === OPTION_VALUES.MERCHANT) {
            props.doUnsubscribeMerchant();
          } else if (selectedValue === OPTION_VALUES.GLOBAL) {
            props.doUnsubscribeGlobal();
          }
        }}
        rank={'primary'}
        {...(props.isUnsubscribing && { disabled: true })}
      >
        {t('preference.components.unsubscribe.marketing.unsubscribeButton')}
      </MarketButton>
    </>
  );
};

export default Options;
