import BaseModal from 'components/modals/BaseModal';
import React, { useContext, useEffect } from 'react';
import { MarketButton, MarketHeader } from '@market/react';
import { useTranslation } from 'react-i18next';
import { MOCK_LOCATIONS } from '../__mocks__/locationMockData';
import { MerchantPortalContext } from 'routes/merchant-scoped-portal';
import { useGetMerchantLocationsQuery } from 'store/query/api-extensions/merchantPortalBaseRpcs';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import ModuleLoading from 'routes/profile/common/loading/ModuleLoading';
import ModuleLoadFailed from 'routes/profile/common/errors/ModuleLoadFailed';
import LocationList, {
  LocationListProps,
} from '../merchant-location/LocationList';
import { formatMerchantLocations } from '../merchant-location/view-models/FormattedMerchantLocation';
import { closeModal } from 'store/modalSlice';
import { useDispatch } from 'react-redux';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import {
  ClickFeatureEvent,
  EventName,
  FeatureFormat,
  FeatureID,
  FeatureName,
  ViewFeatureEvent,
} from 'services/tracking/cdp/events/types';

const MOCKED_MERCHANT_LOCATION_DATA = [
  MOCK_LOCATIONS.SF,
  MOCK_LOCATIONS.NYC,
  MOCK_LOCATIONS.CA,
  MOCK_LOCATIONS.JP,
];

// If an onSave prop is passed, the modal will have a save button and the location list will rendered interactively
// Additionally, you can pass a custom onSelectLocation function to handle location selection prior to saving. Only used if onSave is passed
export type LocationListModalProps = {
  onSave?: (locationId: string) => void;
} & Pick<LocationListProps, 'onSelectLocation'>;

const LocationListModal: React.FC<LocationListModalProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const merchantPortalContext = useContext(MerchantPortalContext);
  const { merchantOverview } = merchantPortalContext.merchantPortalBaseData!;
  const { merchantId, merchantName } = merchantOverview;
  const { buyer } = merchantPortalContext.merchantPortalBaseData!;

  // TODO: Remove mock option before launch
  const finalMerchantIdArg = merchantId === 'mock' ? skipToken : merchantId;
  const {
    data: rawMerchantLocations,
    isLoading,
    isError,
  } = useGetMerchantLocationsQuery(finalMerchantIdArg);

  const [selectedLocationId, setSelectedLocationId] = React.useState<
    string | null
  >(null);

  const [hasEditedFilter, setHasEditedFilter] = React.useState<boolean>(false);

  useEffect(() => {
    if (!rawMerchantLocations) {
      return;
    }
    // track a view event for the modal
    const viewLocationModalEvent: ViewFeatureEvent = {
      feature_name: FeatureName.LocationListModal,
      feature_id: FeatureID.LocationListModal,
      feature_parent_id: FeatureID.MerchantOverviewPage,
      feature_format: FeatureFormat.MODAL,
      is_default_view: true,
      event_description: 'view merchant location list modal',
      feature_layout: {
        seller_location_count: rawMerchantLocations.length,
      },
      additional_parameters: {
        merchant_id: merchantId,
        merchant_name: merchantName,
        buyer_authenticated: Boolean(buyer),
      },
    };
    buyerportalCdpClient.track(EventName.VIEW_FEATURE, viewLocationModalEvent);
    // This should only run after the locations have loaded. Everything else is static b/c it comes from context.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawMerchantLocations]);

  const handleSave = () => {
    if (selectedLocationId && props.onSave) {
      props.onSave(selectedLocationId);
    }
    dispatch(closeModal());
  };

  const handleLocationSelection = (locationId: string) => {
    // Default behavior - update local state
    setSelectedLocationId(locationId);
    // Anything custom
    if (props.onSelectLocation) {
      props.onSelectLocation(locationId);
    }
  };

  // The analysis this facilitates is to simply answer the question:
  // "Did the user interact with the filter text input at all while viewing the modal?"
  // Thus, we only need to track the event once, and we can do so by setting a flag when the filter text is edited.
  const handleFilterTextChange = () => {
    if (hasEditedFilter) {
      return;
    }
    setHasEditedFilter(true);

    const interactWithFilterEvent: ClickFeatureEvent = {
      feature_name: FeatureName.LocationListModal,
      feature_id: FeatureID.LocationListModal,
      feature_parent_id: FeatureID.MerchantOverviewPage,
      feature_format: FeatureFormat.MODAL,
      action_item: 'filter_locations',
      event_description: 'filters locations with input',
      additional_parameters: {
        merchant_id: merchantId,
        merchant_name: merchantName,
        buyer_authenticated: Boolean(buyer),
      },
    };
    buyerportalCdpClient.track(
      EventName.CLICK_FEATURE,
      interactWithFilterEvent
    );
  };

  const renderForState = () => {
    if (isLoading) {
      return <ModuleLoading embedded />;
    }
    if (isError) {
      return <ModuleLoadFailed embedded />;
    }
    const finalRawMerchantLocations =
      merchantId === 'mock'
        ? MOCKED_MERCHANT_LOCATION_DATA
        : rawMerchantLocations;
    return (
      <LocationList
        merchantLocations={formatMerchantLocations(
          finalRawMerchantLocations || []
        )}
        shouldShowContactInfo={true}
        onSelectLocation={props.onSave ? handleLocationSelection : undefined}
        onFilterTextChange={handleFilterTextChange}
      />
    );
  };

  return (
    <BaseModal title={t('merchantPortal.merchantLocation.modal.header')}>
      <MarketHeader data-testid="location-modal-header" className="mb-4">
        <h2>{t('merchantPortal.merchantLocation.modal.header')}</h2>
        {props.onSave && (
          <MarketButton rank="primary" slot="actions" onClick={handleSave}>
            {t('common.save')}
          </MarketButton>
        )}
      </MarketHeader>
      {renderForState()}
    </BaseModal>
  );
};

export default LocationListModal;
