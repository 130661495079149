/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../connect/v2/common/money';
import '../../fees/external/payment_price';
import '../../riskevaluation/external/risk_evaluation';
import '../../connect/v2/resources/address';
import './external_api_common';
import '../../connect/v2/resources/card';
import '../../connect/v2/resources/error';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      connect: {
        nested: {
          v2: {
            nested: {
              ReadOnlyExcept: {
                fields: {
                  subfields: {
                    type: 'string',
                    id: 1,
                    rule: 'repeated',
                  },
                },
              },
              Payment: {
                fields: {
                  id: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  createdAt: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  updatedAt: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  amountMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 4,
                    rule: 'optional',
                  },
                  tipMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 5,
                    rule: 'optional',
                  },
                  taxMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 26,
                    rule: 'optional',
                  },
                  totalMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 32,
                    rule: 'optional',
                  },
                  appFeeMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 6,
                    rule: 'optional',
                  },
                  approvedMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 35,
                    rule: 'optional',
                  },
                  paymentFeesSpec: {
                    type: 'squareup.connect.v2.PaymentFeesSpec',
                    id: 33,
                    rule: 'optional',
                  },
                  processingFee: {
                    type: 'squareup.connect.v2.ProcessingFee',
                    id: 25,
                    rule: 'repeated',
                  },
                  appProcessingFee: {
                    type: 'squareup.connect.v2.AppProcessingFee',
                    id: 36,
                    rule: 'repeated',
                  },
                  refundedMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 7,
                    rule: 'optional',
                  },
                  status: {
                    type: 'string',
                    id: 8,
                    rule: 'optional',
                  },
                  delayDuration: {
                    type: 'string',
                    id: 9,
                    rule: 'optional',
                  },
                  delayAction: {
                    type: 'string',
                    id: 43,
                    rule: 'optional',
                  },
                  delayedUntil: {
                    type: 'string',
                    id: 44,
                    rule: 'optional',
                  },
                  sourceType: {
                    type: 'string',
                    id: 11,
                    rule: 'optional',
                  },
                  cardDetails: {
                    type: 'squareup.connect.v2.CardPaymentDetails',
                    id: 12,
                    rule: 'optional',
                  },
                  balanceDetails: {
                    type: 'squareup.connect.v2.BalancePaymentDetails',
                    id: 14,
                    rule: 'optional',
                  },
                  cashDetails: {
                    type: 'squareup.connect.v2.CashPaymentDetails',
                    id: 39,
                    rule: 'optional',
                  },
                  bankAccountDetails: {
                    type: 'squareup.connect.v2.BankAccountPaymentDetails',
                    id: 49,
                    rule: 'optional',
                  },
                  electronicMoneyDetails: {
                    type: 'squareup.connect.v2.ElectronicMoneyDetails',
                    id: 65,
                    rule: 'optional',
                  },
                  externalDetails: {
                    type: 'squareup.connect.v2.ExternalPaymentDetails',
                    id: 40,
                    rule: 'optional',
                  },
                  walletDetails: {
                    type: 'squareup.connect.v2.DigitalWalletDetails',
                    id: 48,
                    rule: 'optional',
                  },
                  buyNowPayLaterDetails: {
                    type: 'squareup.connect.v2.BuyNowPayLaterDetails',
                    id: 54,
                    rule: 'optional',
                  },
                  squareAccountDetails: {
                    type: 'squareup.connect.v2.SquareAccountDetails',
                    id: 56,
                    rule: 'optional',
                  },
                  healthcareDetails: {
                    type: 'squareup.connect.v2.HealthcareDetails',
                    id: 60,
                    rule: 'optional',
                  },
                  locationId: {
                    type: 'string',
                    id: 15,
                    rule: 'optional',
                  },
                  orderId: {
                    type: 'string',
                    id: 16,
                    rule: 'optional',
                  },
                  referenceId: {
                    type: 'string',
                    id: 17,
                    rule: 'optional',
                  },
                  customerId: {
                    type: 'string',
                    id: 31,
                    rule: 'optional',
                  },
                  employeeId: {
                    type: 'string',
                    id: 37,
                    rule: 'optional',
                  },
                  teamMemberId: {
                    type: 'string',
                    id: 52,
                    rule: 'optional',
                  },
                  refundIds: {
                    type: 'string',
                    id: 18,
                    rule: 'repeated',
                  },
                  disputeIds: {
                    type: 'string',
                    id: 19,
                    rule: 'repeated',
                  },
                  riskEvaluation: {
                    type: 'squareup.connect.v2.RiskEvaluation',
                    id: 21,
                    rule: 'optional',
                  },
                  terminalCheckoutId: {
                    type: 'string',
                    id: 23,
                    rule: 'optional',
                  },
                  accountId: {
                    type: 'string',
                    id: 24,
                    rule: 'optional',
                  },
                  buyerEmailAddress: {
                    type: 'string',
                    id: 27,
                    rule: 'optional',
                  },
                  billingAddress: {
                    type: 'squareup.connect.v2.resources.Address',
                    id: 28,
                    rule: 'optional',
                  },
                  shippingAddress: {
                    type: 'squareup.connect.v2.resources.Address',
                    id: 29,
                    rule: 'optional',
                  },
                  note: {
                    type: 'string',
                    id: 30,
                    rule: 'optional',
                  },
                  statementDescriptionIdentifier: {
                    type: 'string',
                    id: 34,
                    rule: 'optional',
                  },
                  capabilities: {
                    type: 'string',
                    id: 38,
                    rule: 'repeated',
                  },
                  disabledCapabilities: {
                    type: 'squareup.connect.v2.CapabilitiesList',
                    id: 58,
                    rule: 'optional',
                  },
                  receiptNumber: {
                    type: 'string',
                    id: 41,
                    rule: 'optional',
                  },
                  receiptUrl: {
                    type: 'string',
                    id: 42,
                    rule: 'optional',
                  },
                  refundPolicy: {
                    type: 'squareup.connect.v2.RefundPolicy',
                    id: 45,
                    rule: 'optional',
                  },
                  recurringDetails: {
                    type: 'squareup.connect.v2.RecurringPaymentDetails',
                    id: 50,
                    rule: 'optional',
                  },
                  issuerInstallmentDetails: {
                    type: 'squareup.connect.v2.IssuerInstallmentDetails',
                    id: 63,
                    rule: 'optional',
                  },
                  deviceDetails: {
                    type: 'squareup.connect.v2.DeviceDetails',
                    id: 51,
                    rule: 'optional',
                  },
                  applicationDetails: {
                    type: 'squareup.connect.v2.ApplicationDetails',
                    id: 53,
                    rule: 'optional',
                  },
                  foreignExchange: {
                    type: 'squareup.connect.v2.ForeignExchange',
                    id: 55,
                    rule: 'optional',
                  },
                  proposalId: {
                    type: 'string',
                    id: 59,
                    rule: 'optional',
                  },
                  isOfflinePayment: {
                    type: 'bool',
                    id: 64,
                    rule: 'optional',
                  },
                  offlinePaymentDetails: {
                    type: 'squareup.connect.v2.OfflinePaymentDetails',
                    id: 67,
                    rule: 'optional',
                  },
                  isTestPayment: {
                    type: 'bool',
                    id: 62,
                    rule: 'optional',
                  },
                  superPosMetadata: {
                    type: 'squareup.connect.v2.SuperPosMetadata',
                    id: 68,
                    rule: 'optional',
                  },
                  versionToken: {
                    type: 'string',
                    id: 1000,
                    rule: 'optional',
                  },
                  version: {
                    type: 'int64',
                    id: 1010,
                    rule: 'optional',
                  },
                },
              },
              ForeignExchange: {
                fields: {
                  amountMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 1,
                    rule: 'optional',
                  },
                  exchangeRate: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              CardPaymentDetails: {
                fields: {
                  status: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  card: {
                    type: 'squareup.connect.v2.resources.Card',
                    id: 2,
                    rule: 'optional',
                  },
                  entryMethod: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  cvvStatus: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  avsStatus: {
                    type: 'string',
                    id: 5,
                    rule: 'optional',
                  },
                  authResultCode: {
                    type: 'string',
                    id: 6,
                    rule: 'optional',
                  },
                  applicationIdentifier: {
                    type: 'string',
                    id: 8,
                    rule: 'optional',
                  },
                  applicationName: {
                    type: 'string',
                    id: 9,
                    rule: 'optional',
                  },
                  applicationCryptogram: {
                    type: 'string',
                    id: 10,
                    rule: 'optional',
                  },
                  verificationMethod: {
                    type: 'string',
                    id: 11,
                    rule: 'optional',
                  },
                  verificationResults: {
                    type: 'string',
                    id: 12,
                    rule: 'optional',
                  },
                  statementDescription: {
                    type: 'string',
                    id: 13,
                    rule: 'optional',
                  },
                  deviceDetails: {
                    type: 'squareup.connect.v2.DeviceDetails',
                    id: 14,
                    rule: 'optional',
                  },
                  felicaDetails: {
                    type: 'squareup.connect.v2.FelicaDetails',
                    id: 23,
                    rule: 'optional',
                  },
                  panFideliusToken: {
                    type: 'string',
                    id: 15,
                    rule: 'optional',
                  },
                  cardPaymentTimeline: {
                    type: 'squareup.connect.v2.CardPaymentTimeline',
                    id: 16,
                    rule: 'optional',
                  },
                  refundRequiresCardPresence: {
                    type: 'bool',
                    id: 17,
                    rule: 'optional',
                  },
                  accountType: {
                    type: 'string',
                    id: 18,
                    rule: 'optional',
                  },
                  signatureBehavior: {
                    type: 'string',
                    id: 19,
                    rule: 'optional',
                  },
                  applicationTransactionCounter: {
                    type: 'string',
                    id: 20,
                    rule: 'optional',
                  },
                  remainingBalanceAmountMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 21,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.connect.v2.resources.Error',
                    id: 7,
                    rule: 'repeated',
                  },
                  cardSurchargeMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 22,
                    rule: 'optional',
                  },
                  emvDetails: {
                    type: 'squareup.connect.v2.EmvDetails',
                    id: 24,
                    rule: 'optional',
                  },
                },
              },
              EmvDetails: {
                fields: {
                  ptagIssuerPinRequest: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  authorizationResponseCode: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              BalancePaymentDetails: {
                fields: {
                  accountId: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  status: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.connect.v2.resources.Error',
                    id: 3,
                    rule: 'repeated',
                  },
                },
              },
              CashPaymentDetails: {
                fields: {
                  buyerSuppliedMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 1,
                    rule: 'optional',
                  },
                  changeBackMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 2,
                    rule: 'optional',
                  },
                  cashDrawerDetails: {
                    type: 'squareup.connect.v2.CashDrawerDetails',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              ExternalPaymentDetails: {
                fields: {
                  type: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  source: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  sourceId: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  sourceFeeMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 4,
                    rule: 'optional',
                  },
                },
              },
              RecurringPaymentDetails: {
                fields: {
                  seriesPosition: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  previousPaymentId: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              IssuerInstallmentDetails: {
                fields: {
                  type: {
                    type: 'squareup.connect.v2.IssuerInstallmentDetails.IssuerInstallmentType',
                    id: 1,
                    rule: 'optional',
                  },
                  installmentCount: {
                    type: 'int32',
                    id: 2,
                    rule: 'optional',
                  },
                },
                nested: {
                  IssuerInstallmentType: {
                    values: {
                      NO_INSTALLMENTS: 0,
                      FIXED: 1,
                      REVOLVING: 2,
                    },
                  },
                },
              },
              BankAccountPaymentDetails: {
                fields: {
                  bankName: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  transferType: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  accountOwnershipType: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  fingerprint: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  country: {
                    type: 'string',
                    id: 5,
                    rule: 'optional',
                  },
                  statementDescription: {
                    type: 'string',
                    id: 6,
                    rule: 'optional',
                  },
                  achDetails: {
                    type: 'squareup.connect.v2.ACHDetails',
                    id: 7,
                    rule: 'optional',
                  },
                  openBankingDetails: {
                    type: 'squareup.connect.v2.OpenBankingDetails',
                    id: 11,
                    rule: 'optional',
                  },
                  eftDetails: {
                    type: 'squareup.connect.v2.EFTDetails',
                    id: 12,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.connect.v2.resources.Error',
                    id: 8,
                    rule: 'repeated',
                  },
                },
              },
              ACHDetails: {
                fields: {
                  routingNumber: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  accountNumberSuffix: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  accountType: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              OpenBankingDetails: {
                fields: {
                  transactionId: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  resourceToken: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  buyerAuthorizedAt: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              EFTDetails: {
                fields: {
                  branch: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  institution: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  accountNumberSuffix: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  accountType: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                },
              },
              DigitalWalletDetails: {
                fields: {
                  status: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  brand: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  statementDescription: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  deviceDetails: {
                    type: 'squareup.connect.v2.DeviceDetails',
                    id: 4,
                    rule: 'optional',
                  },
                  cashAppDetails: {
                    type: 'squareup.connect.v2.CashAppDetails',
                    id: 5,
                    rule: 'optional',
                  },
                  paypayDetails: {
                    type: 'squareup.connect.v2.PaypayDetails',
                    id: 8,
                    rule: 'optional',
                  },
                },
              },
              CashAppDetails: {
                fields: {
                  buyerFullName: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  buyerCountryCode: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  buyerCashtag: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              PaypayDetails: {
                fields: {
                  buyerId: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  transactionId: {
                    type: 'string',
                    id: 5,
                    rule: 'optional',
                  },
                },
              },
              DeviceDetails: {
                fields: {
                  deviceId: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  deviceInstallationId: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  deviceName: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  deviceCredential: {
                    type: 'squareup.connect.v2.DeviceCredential',
                    id: 4,
                    rule: 'optional',
                  },
                },
              },
              DeviceCredential: {
                fields: {
                  token: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  name: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
              FelicaDetails: {
                fields: {
                  terminalId: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  felicaMaskedCardNumber: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  felicaBrand: {
                    type: 'squareup.connect.v2.FelicaDetails.FelicaBrand',
                    id: 4,
                    rule: 'optional',
                  },
                },
                nested: {
                  FelicaBrand: {
                    values: {
                      UNKNOWN: 0,
                      FELICA_ID: 1,
                      FELICA_TRANSPORTATION: 2,
                      FELICA_QP: 3,
                    },
                  },
                },
              },
              ApplicationDetails: {
                fields: {
                  squareProduct: {
                    type: 'squareup.connect.v2.ApplicationDetails.ExternalSquareProduct',
                    id: 2,
                    rule: 'optional',
                  },
                  applicationId: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                },
                nested: {
                  ExternalSquareProduct: {
                    values: {
                      APPOINTMENTS: 1,
                      ECOMMERCE_API: 2,
                      INVOICES: 3,
                      ONLINE_STORE: 4,
                      OTHER: 5,
                      RESTAURANTS: 6,
                      RETAIL: 7,
                      SQUARE_POS: 8,
                      TERMINAL_API: 9,
                      VIRTUAL_TERMINAL: 10,
                    },
                  },
                },
              },
              BuyNowPayLaterDetails: {
                fields: {
                  brand: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  afterpayDetails: {
                    type: 'squareup.connect.v2.AfterpayDetails',
                    id: 2,
                    rule: 'optional',
                  },
                  clearpayDetails: {
                    type: 'squareup.connect.v2.ClearpayDetails',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              AfterpayDetails: {
                fields: {
                  emailAddress: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              ClearpayDetails: {
                fields: {
                  emailAddress: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              SquareAccountDetails: {
                fields: {
                  paymentSourceToken: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  errors: {
                    type: 'squareup.connect.v2.resources.Error',
                    id: 2,
                    rule: 'repeated',
                  },
                },
              },
              CardPaymentTimeline: {
                fields: {
                  authorizedAt: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  capturedAt: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  voidedAt: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              ProcessingFee: {
                fields: {
                  effectiveAt: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  type: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  amountMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              AppProcessingFee: {
                fields: {
                  priceSelector: {
                    type: 'string',
                    id: 1,
                    rule: 'repeated',
                  },
                  type: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  amountMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 3,
                    rule: 'optional',
                  },
                  partyAccountId: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  effectiveAt: {
                    type: 'string',
                    id: 5,
                    rule: 'optional',
                  },
                },
              },
              RefundPolicy: {
                fields: {
                  defaultRefundSplit: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              CapabilitiesList: {
                fields: {
                  capabilities: {
                    type: 'string',
                    id: 1,
                    rule: 'repeated',
                  },
                },
              },
              HealthcareDetails: {
                fields: {
                  prescriptionAmountMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 1,
                    rule: 'optional',
                  },
                  visionAmountMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 2,
                    rule: 'optional',
                  },
                  dentalAmountMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 3,
                    rule: 'optional',
                  },
                  otherHealthcareAmountMoney: {
                    type: 'squareup.connect.v2.common.Money',
                    id: 4,
                    rule: 'optional',
                  },
                },
              },
              ElectronicMoneyDetails: {
                fields: {
                  felicaDetails: {
                    type: 'squareup.connect.v2.FelicaDetails',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
              OfflinePaymentDetails: {
                fields: {
                  clientCreatedAt: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.ReadOnlyExcept &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.Payment &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.ForeignExchange &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.CardPaymentDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.EmvDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.BalancePaymentDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.CashPaymentDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.ExternalPaymentDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.RecurringPaymentDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.IssuerInstallmentDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.IssuerInstallmentDetails?.nested?.IssuerInstallmentType &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.BankAccountPaymentDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.ACHDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.OpenBankingDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.EFTDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.DigitalWalletDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.CashAppDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.PaypayDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.DeviceDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.DeviceCredential &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.FelicaDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.FelicaDetails
    ?.nested?.FelicaBrand &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.ApplicationDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.ApplicationDetails?.nested?.ExternalSquareProduct &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.BuyNowPayLaterDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.AfterpayDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.ClearpayDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.SquareAccountDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.CardPaymentTimeline &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.ProcessingFee &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.AppProcessingFee &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.RefundPolicy &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.CapabilitiesList &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.HealthcareDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.ElectronicMoneyDetails &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested
    ?.OfflinePaymentDetails
) {
  $root.addJSON(jsonData);
}

/**
 * --
 * @desc A custom option for marking fields that are read-only except for
 * one or more subfields.
 * --
 *
 * SOURCE ReadOnlyExcept @ squareup/pago/external/payments_model.proto at 32:1
 */
export const ReadOnlyExcept = $root.lookupType(
  'squareup.connect.v2.ReadOnlyExcept'
);
fixType(ReadOnlyExcept);
Builder.createAndAttachToType(ReadOnlyExcept);
/**
 * --
 * @desc Represents a payment processed by the Square API.
 * --
 *
 * SOURCE Payment @ squareup/pago/external/payments_model.proto at 41:1
 */
export const Payment = $root.lookupType('squareup.connect.v2.Payment');
fixType(Payment);
Builder.createAndAttachToType(Payment);
/**
 * --
 * @desc Details about a foreign exchange.
 * --
 *
 * SOURCE ForeignExchange @ squareup/pago/external/payments_model.proto at 571:1
 */
export const ForeignExchange = $root.lookupType(
  'squareup.connect.v2.ForeignExchange'
);
fixType(ForeignExchange);
Builder.createAndAttachToType(ForeignExchange);
/**
 * --
 * @desc Reflects the current status of a card payment. Contains only non-confidential information.
 * --
 *
 * SOURCE CardPaymentDetails @ squareup/pago/external/payments_model.proto at 597:1
 */
export const CardPaymentDetails = $root.lookupType(
  'squareup.connect.v2.CardPaymentDetails'
);
fixType(CardPaymentDetails);
Builder.createAndAttachToType(CardPaymentDetails);
/**
 * --
 * @desc Holds a subset of EmvData that we propagate through v2/payments
 * --
 *
 * SOURCE EmvDetails @ squareup/pago/external/payments_model.proto at 765:1
 */
export const EmvDetails = $root.lookupType('squareup.connect.v2.EmvDetails');
fixType(EmvDetails);
Builder.createAndAttachToType(EmvDetails);
/**
 * --
 * @desc Reflects the current status of a balance payment. Contains only non-confidential information.
 * --
 *
 * SOURCE BalancePaymentDetails @ squareup/pago/external/payments_model.proto at 787:1
 */
export const BalancePaymentDetails = $root.lookupType(
  'squareup.connect.v2.BalancePaymentDetails'
);
fixType(BalancePaymentDetails);
Builder.createAndAttachToType(BalancePaymentDetails);
/**
 * --
 * @desc Stores details about a cash payment. Contains only non-confidential information. For more information, see
 * [Take Cash Payments](https://developer.squareup.com/docs/payments-api/take-payments/cash-payments).
 * --
 *
 * SOURCE CashPaymentDetails @ squareup/pago/external/payments_model.proto at 807:1
 */
export const CashPaymentDetails = $root.lookupType(
  'squareup.connect.v2.CashPaymentDetails'
);
fixType(CashPaymentDetails, {
  buyerSuppliedMoney: { required: true },
});
Builder.createAndAttachToType(CashPaymentDetails);
/**
 * --
 * @desc Stores details about an external payment. Contains only non-confidential information.
 * For more information, see
 * [Take External Payments](https://developer.squareup.com/docs/payments-api/take-payments/external-payments).
 * --
 *
 * SOURCE ExternalPaymentDetails @ squareup/pago/external/payments_model.proto at 836:1
 */
export const ExternalPaymentDetails = $root.lookupType(
  'squareup.connect.v2.ExternalPaymentDetails'
);
fixType(ExternalPaymentDetails, {
  type: { required: true },
  source: { required: true },
});
Builder.createAndAttachToType(ExternalPaymentDetails);
/**
 * --
 * @desc Additional details indicating that this payment is part of a recurring series.
 * --
 *
 * SOURCE RecurringPaymentDetails @ squareup/pago/external/payments_model.proto at 879:1
 */
export const RecurringPaymentDetails = $root.lookupType(
  'squareup.connect.v2.RecurringPaymentDetails'
);
fixType(RecurringPaymentDetails, {
  seriesPosition: { required: true },
});
Builder.createAndAttachToType(RecurringPaymentDetails);
/**
 * --
 * @desc Details for payments that the issuer will bill to the cardholder in installments over time, as
 * requested by the cardholder. When applied, settlement still occurs to Square and the merchant
 * in full as a single payment. This option is only available in certain markets (Japan, as of
 * Jan. 2024).
 * --
 *
 * SOURCE IssuerInstallmentDetails @ squareup/pago/external/payments_model.proto at 903:1
 */
export const IssuerInstallmentDetails = $root.lookupType(
  'squareup.connect.v2.IssuerInstallmentDetails'
);
fixType(IssuerInstallmentDetails);
Builder.createAndAttachToType(IssuerInstallmentDetails);
/**
 * SOURCE IssuerInstallmentType @ squareup/pago/external/payments_model.proto at 906:3
 */
IssuerInstallmentDetails.IssuerInstallmentType = $root.lookupEnum(
  'squareup.connect.v2.IssuerInstallmentDetails.IssuerInstallmentType'
).values;
/**
 * --
 * @desc Additional details about BANK_ACCOUNT type payments.
 * --
 *
 * SOURCE BankAccountPaymentDetails @ squareup/pago/external/payments_model.proto at 940:1
 */
export const BankAccountPaymentDetails = $root.lookupType(
  'squareup.connect.v2.BankAccountPaymentDetails'
);
fixType(BankAccountPaymentDetails);
Builder.createAndAttachToType(BankAccountPaymentDetails);
/**
 * --
 * @desc ACH-specific details about `BANK_ACCOUNT` type payments with the `transfer_type` of `ACH`.
 * --
 *
 * SOURCE ACHDetails @ squareup/pago/external/payments_model.proto at 1009:1
 */
export const ACHDetails = $root.lookupType('squareup.connect.v2.ACHDetails');
fixType(ACHDetails);
Builder.createAndAttachToType(ACHDetails);
/**
 * --
 * @desc Open Banking-specific details about `BANK_ACCOUNT` type payments.
 * --
 *
 * SOURCE OpenBankingDetails @ squareup/pago/external/payments_model.proto at 1033:1
 */
export const OpenBankingDetails = $root.lookupType(
  'squareup.connect.v2.OpenBankingDetails'
);
fixType(OpenBankingDetails);
Builder.createAndAttachToType(OpenBankingDetails);
/**
 * --
 * @desc EFT-specific details about `BANK_ACCOUNT` type payments with the `transfer_type` of `EFT`.
 * --
 *
 * SOURCE EFTDetails @ squareup/pago/external/payments_model.proto at 1057:1
 */
export const EFTDetails = $root.lookupType('squareup.connect.v2.EFTDetails');
fixType(EFTDetails);
Builder.createAndAttachToType(EFTDetails);
/**
 * --
 * @desc Additional details about `WALLET` type payments. Contains only non-confidential information.
 * --
 *
 * SOURCE DigitalWalletDetails @ squareup/pago/external/payments_model.proto at 1089:1
 */
export const DigitalWalletDetails = $root.lookupType(
  'squareup.connect.v2.DigitalWalletDetails'
);
fixType(DigitalWalletDetails);
Builder.createAndAttachToType(DigitalWalletDetails);
/**
 * --
 * @desc Additional details about `WALLET` type payments with the `brand` of `CASH_APP`.
 * --
 *
 * SOURCE CashAppDetails @ squareup/pago/external/payments_model.proto at 1134:1
 */
export const CashAppDetails = $root.lookupType(
  'squareup.connect.v2.CashAppDetails'
);
fixType(CashAppDetails);
Builder.createAndAttachToType(CashAppDetails);
/**
 * --
 * @desc Additional details about `WALLET` type payments with the `brand` of `PAYPAY`.
 * --
 *
 * SOURCE PaypayDetails @ squareup/pago/external/payments_model.proto at 1178:1
 */
export const PaypayDetails = $root.lookupType(
  'squareup.connect.v2.PaypayDetails'
);
fixType(PaypayDetails);
Builder.createAndAttachToType(PaypayDetails);
/**
 * --
 * @desc Details about the device that took the payment.
 * --
 *
 * SOURCE DeviceDetails @ squareup/pago/external/payments_model.proto at 1205:1
 */
export const DeviceDetails = $root.lookupType(
  'squareup.connect.v2.DeviceDetails'
);
fixType(DeviceDetails);
Builder.createAndAttachToType(DeviceDetails);
/**
 * --
 * @desc The device credential of the device that took the payment.
 * --
 *
 * SOURCE DeviceCredential @ squareup/pago/external/payments_model.proto at 1235:1
 */
export const DeviceCredential = $root.lookupType(
  'squareup.connect.v2.DeviceCredential'
);
fixType(DeviceCredential);
Builder.createAndAttachToType(DeviceCredential);
/**
 * --
 * @desc Details for Felica payments.
 * --
 *
 * SOURCE FelicaDetails @ squareup/pago/external/payments_model.proto at 1254:1
 */
export const FelicaDetails = $root.lookupType(
  'squareup.connect.v2.FelicaDetails'
);
fixType(FelicaDetails);
Builder.createAndAttachToType(FelicaDetails);
/**
 * --
 * @desc A Felica card's "sub-brand"--the actual brand on the Felica card
 * --
 *
 * SOURCE FelicaBrand @ squareup/pago/external/payments_model.proto at 1272:3
 */
FelicaDetails.FelicaBrand = $root.lookupEnum(
  'squareup.connect.v2.FelicaDetails.FelicaBrand'
).values;
/**
 * --
 * @desc Details about the application that took the payment.
 * --
 *
 * SOURCE ApplicationDetails @ squareup/pago/external/payments_model.proto at 1291:1
 */
export const ApplicationDetails = $root.lookupType(
  'squareup.connect.v2.ApplicationDetails'
);
fixType(ApplicationDetails);
Builder.createAndAttachToType(ApplicationDetails);
/**
 * --
 * @desc A list of products to return to external callers.
 * --
 *
 * SOURCE ExternalSquareProduct @ squareup/pago/external/payments_model.proto at 1295:3
 */
ApplicationDetails.ExternalSquareProduct = $root.lookupEnum(
  'squareup.connect.v2.ApplicationDetails.ExternalSquareProduct'
).values;
/**
 * --
 * @desc Additional details about a Buy Now Pay Later payment type.
 * --
 *
 * SOURCE BuyNowPayLaterDetails @ squareup/pago/external/payments_model.proto at 1331:1
 */
export const BuyNowPayLaterDetails = $root.lookupType(
  'squareup.connect.v2.BuyNowPayLaterDetails'
);
fixType(BuyNowPayLaterDetails);
Builder.createAndAttachToType(BuyNowPayLaterDetails);
/**
 * --
 * @desc Additional details about Afterpay payments.
 * --
 *
 * SOURCE AfterpayDetails @ squareup/pago/external/payments_model.proto at 1355:1
 */
export const AfterpayDetails = $root.lookupType(
  'squareup.connect.v2.AfterpayDetails'
);
fixType(AfterpayDetails);
Builder.createAndAttachToType(AfterpayDetails);
/**
 * --
 * @desc Additional details about Clearpay payments.
 * --
 *
 * SOURCE ClearpayDetails @ squareup/pago/external/payments_model.proto at 1369:1
 */
export const ClearpayDetails = $root.lookupType(
  'squareup.connect.v2.ClearpayDetails'
);
fixType(ClearpayDetails);
Builder.createAndAttachToType(ClearpayDetails);
/**
 * --
 * @desc Additional details about Square Account payments.
 * --
 *
 * SOURCE SquareAccountDetails @ squareup/pago/external/payments_model.proto at 1382:1
 */
export const SquareAccountDetails = $root.lookupType(
  'squareup.connect.v2.SquareAccountDetails'
);
fixType(SquareAccountDetails);
Builder.createAndAttachToType(SquareAccountDetails);
/**
 * --
 * @desc The timeline for card payments.
 * --
 *
 * SOURCE CardPaymentTimeline @ squareup/pago/external/payments_model.proto at 1397:1
 */
export const CardPaymentTimeline = $root.lookupType(
  'squareup.connect.v2.CardPaymentTimeline'
);
fixType(CardPaymentTimeline);
Builder.createAndAttachToType(CardPaymentTimeline);
/**
 * --
 * @desc Represents the Square processing fee.
 * --
 *
 * SOURCE ProcessingFee @ squareup/pago/external/payments_model.proto at 1418:1
 */
export const ProcessingFee = $root.lookupType(
  'squareup.connect.v2.ProcessingFee'
);
fixType(ProcessingFee);
Builder.createAndAttachToType(ProcessingFee);
/**
 * --
 * @desc Represents an application processing fee.
 * --
 *
 * SOURCE AppProcessingFee @ squareup/pago/external/payments_model.proto at 1441:1
 */
export const AppProcessingFee = $root.lookupType(
  'squareup.connect.v2.AppProcessingFee'
);
fixType(AppProcessingFee);
Builder.createAndAttachToType(AppProcessingFee);
/**
 * --
 * @desc Options for controlling how a payment is refunded by default.
 * --
 *
 * SOURCE RefundPolicy @ squareup/pago/external/payments_model.proto at 1475:1
 */
export const RefundPolicy = $root.lookupType(
  'squareup.connect.v2.RefundPolicy'
);
fixType(RefundPolicy);
Builder.createAndAttachToType(RefundPolicy);
/**
 * --
 * @desc Represents a list of actions that can be performed.
 * --
 *
 * SOURCE CapabilitiesList @ squareup/pago/external/payments_model.proto at 1494:1
 */
export const CapabilitiesList = $root.lookupType(
  'squareup.connect.v2.CapabilitiesList'
);
fixType(CapabilitiesList);
Builder.createAndAttachToType(CapabilitiesList);
/**
 * --
 * @desc Healthcare-related payment details.
 * --
 *
 * SOURCE HealthcareDetails @ squareup/pago/external/payments_model.proto at 1506:1
 */
export const HealthcareDetails = $root.lookupType(
  'squareup.connect.v2.HealthcareDetails'
);
fixType(HealthcareDetails);
Builder.createAndAttachToType(HealthcareDetails);
/**
 * --
 * @desc Details specific to electronic money payments.
 * --
 *
 * SOURCE ElectronicMoneyDetails @ squareup/pago/external/payments_model.proto at 1533:1
 */
export const ElectronicMoneyDetails = $root.lookupType(
  'squareup.connect.v2.ElectronicMoneyDetails'
);
fixType(ElectronicMoneyDetails);
Builder.createAndAttachToType(ElectronicMoneyDetails);
/**
 * --
 * @desc Details specific to offline payments.
 * --
 *
 * SOURCE OfflinePaymentDetails @ squareup/pago/external/payments_model.proto at 1546:1
 */
export const OfflinePaymentDetails = $root.lookupType(
  'squareup.connect.v2.OfflinePaymentDetails'
);
fixType(OfflinePaymentDetails);
Builder.createAndAttachToType(OfflinePaymentDetails);
