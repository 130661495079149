/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../../Fixes';
import Builder from '../../../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      card: {
        nested: {
          balance: {
            nested: {
              loyalty_api: {
                nested: {
                  model: {
                    nested: {
                      LoyaltyAccountMapping: {
                        fields: {
                          id: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          type: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyAccountMapping.Type',
                            id: 2,
                            rule: 'optional',
                          },
                          value: {
                            type: 'string',
                            id: 3,
                            rule: 'optional',
                          },
                          createdAt: {
                            type: 'string',
                            id: 4,
                            rule: 'optional',
                          },
                          phoneNumber: {
                            type: 'string',
                            id: 5,
                            rule: 'optional',
                          },
                          phoneToken: {
                            type: 'string',
                            id: 6,
                            rule: 'optional',
                          },
                        },
                        nested: {
                          Type: {
                            values: {
                              TYPE_DO_NOT_USE: 0,
                              PHONE: 1,
                            },
                          },
                        },
                      },
                      LoyaltyAccountExpiringPointDeadline: {
                        fields: {
                          points: {
                            type: 'uint32',
                            id: 1,
                            rule: 'optional',
                          },
                          expiresAt: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                        },
                      },
                      LoyaltyAccountEnrollmentType: {
                        values: {
                          ENROLLMENT_TYPE_UNKNOWN: 0,
                          FIRST_PARTY_POS: 1,
                          BUYER_STATUS_PAGE_CLAIM_POINTS: 2,
                          BACKFILLED: 3,
                          BUYER_STATUS_PAGE_LOGIN: 4,
                          APPLE_WALLET: 5,
                          PREVIEW_API: 6,
                          THIRD_PARTY_API: 7,
                          INVOICE: 8,
                          BUYER_PORTAL_LOGIN: 9,
                          BUYER_PORTAL_CLAIM_POINTS: 10,
                        },
                      },
                      LoyaltyAccount: {
                        fields: {
                          id: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          mappings: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyAccountMapping',
                            id: 2,
                            rule: 'repeated',
                          },
                          programId: {
                            type: 'string',
                            id: 3,
                            rule: 'optional',
                          },
                          balance: {
                            type: 'int32',
                            id: 4,
                            rule: 'optional',
                          },
                          lifetimePoints: {
                            type: 'uint32',
                            id: 5,
                            rule: 'optional',
                          },
                          customerId: {
                            type: 'string',
                            id: 6,
                            rule: 'optional',
                          },
                          enrolledAt: {
                            type: 'string',
                            id: 7,
                            rule: 'optional',
                          },
                          createdAt: {
                            type: 'string',
                            id: 8,
                            rule: 'optional',
                          },
                          updatedAt: {
                            type: 'string',
                            id: 9,
                            rule: 'optional',
                          },
                          mapping: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyAccountMapping',
                            id: 10,
                            rule: 'optional',
                          },
                          expiringPointDeadlines: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyAccountExpiringPointDeadline',
                            id: 11,
                            rule: 'repeated',
                          },
                          statusPageUrl: {
                            type: 'string',
                            id: 12,
                            rule: 'optional',
                          },
                          deletedAt: {
                            type: 'string',
                            id: 13,
                            rule: 'optional',
                          },
                          loyaltyAccountLookupToken: {
                            type: 'string',
                            id: 14,
                            rule: 'optional',
                          },
                          cashAppDownloadUrl: {
                            type: 'string',
                            id: 15,
                            rule: 'optional',
                          },
                          loyaltyAccountEnrollmentType: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyAccountEnrollmentType',
                            id: 16,
                            rule: 'optional',
                          },
                          version: {
                            type: 'uint32',
                            id: 17,
                            rule: 'optional',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyAccountMapping &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyAccountMapping?.nested?.Type &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyAccountExpiringPointDeadline &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyAccountEnrollmentType &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyAccount
) {
  $root.addJSON(jsonData);
}

/**
 * TODO (@romil) add rfc validation to all time related fields
 * --
 * @desc Represents the mapping that associates a loyalty account with a buyer.
 *
 * Currently, a loyalty account can only be mapped to a buyer by phone number. For more information, see
 * [Loyalty Overview](https://developer.squareup.com/docs/loyalty/overview).
 * --
 *
 * SOURCE LoyaltyAccountMapping @ squareup/card/balance/loyalty_api/model/loyalty-account.proto at 24:1
 */
export const LoyaltyAccountMapping = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyAccountMapping'
);
fixType(LoyaltyAccountMapping);
Builder.createAndAttachToType(LoyaltyAccountMapping);
/**
 * --
 * @desc The type of mapping.
 * --
 *
 * SOURCE Type @ squareup/card/balance/loyalty_api/model/loyalty-account.proto at 28:3
 */
LoyaltyAccountMapping.Type = $root.lookupEnum(
  'squareup.card.balance.loyalty_api.model.LoyaltyAccountMapping.Type'
).values;
/**
 * --
 * @desc Represents a set of points for a loyalty account that are scheduled to expire on a specific date.
 * --
 *
 * SOURCE LoyaltyAccountExpiringPointDeadline @ squareup/card/balance/loyalty_api/model/loyalty-account.proto at 82:1
 */
export const LoyaltyAccountExpiringPointDeadline = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyAccountExpiringPointDeadline'
);
fixType(LoyaltyAccountExpiringPointDeadline, {
  points: { required: true },
  expiresAt: { notEmpty: true },
});
Builder.createAndAttachToType(LoyaltyAccountExpiringPointDeadline);
/**
 * SOURCE LoyaltyAccountEnrollmentType @ squareup/card/balance/loyalty_api/model/loyalty-account.proto at 96:1
 */
export const LoyaltyAccountEnrollmentType = $root.lookupEnum(
  'squareup.card.balance.loyalty_api.model.LoyaltyAccountEnrollmentType'
).values;
/**
 * --
 * @desc Describes a loyalty account in a [loyalty program](entity:LoyaltyProgram). For more information, see
 * [Create and Retrieve Loyalty Accounts](https://developer.squareup.com/docs/loyalty-api/loyalty-accounts).
 * --
 *
 * SOURCE LoyaltyAccount @ squareup/card/balance/loyalty_api/model/loyalty-account.proto at 156:1
 */
export const LoyaltyAccount = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyAccount'
);
fixType(LoyaltyAccount, {
  programId: { notEmpty: true },
});
Builder.createAndAttachToType(LoyaltyAccount);
