import React, { useState } from 'react';
import Chevron from 'svgs/market-icons/Chevron';
import { theme } from 'tailwind.config';
import { MarketAccessory, MarketRow } from '@market/react';
import { GiftCard as GiftCardModel } from 'routes/profile/models/GiftCard';
import { useHistory } from 'react-router-dom';
import GiftCard from 'svgs/market-icons/GiftCard';

type GiftCardRowProps = {
  giftCard: GiftCardModel;
};

const GiftCardRow: React.FC<GiftCardRowProps> = ({ giftCard }) => {
  const [imageFailedToLoad, setImageFailedToLoad] = useState<boolean>(false);
  const history = useHistory();

  return (
    <MarketRow
      role={'option'}
      onMarketRowSelected={() => {
        history.push(`/paymentmethods/giftcard/${giftCard.token}`);
      }}
    >
      <div className={'flex items-center'}>
        <MarketAccessory size={'image'} slot={'icon'}>
          {giftCard.merchantInfo.imageUrl || imageFailedToLoad ? (
            <img
              onError={() => {
                setImageFailedToLoad(true);
              }}
              alt={giftCard.merchantInfo.displayName}
              src={giftCard.merchantInfo.imageUrl}
            />
          ) : (
            <GiftCard width={24} height={20} />
          )}
        </MarketAccessory>
        <div className={'ml-4'}>
          <div className={'font-medium'}>
            {giftCard.merchantInfo.displayName}
          </div>
          <div className={'paragraph-20 text-grey'}>
            {giftCard.formattedBalance()}
          </div>
        </div>
        <div className={'ml-auto flex items-center'}>
          <Chevron fill={theme.extend.colors.lightgrey} direction={'right'} />
        </div>
      </div>
    </MarketRow>
  );
};

export default GiftCardRow;
