import { MarketDivider, MarketRow } from '@market/react';
import React, { useEffect } from 'react';
import DownloadCashAppSection from '../components/DownloadCashAppSection';
import { useSelector } from 'react-redux';
import {
  selectEmailCollectionIncentiveTermsAndStatus,
  selectPromotions,
  selectLoyaltyProgram,
  selectAccount,
} from '../loyaltySlice';
import { AppState } from 'store';
import {
  useRetrieveEmailCollectionIncentiveQuery,
  useSearchPromotionsQuery,
} from 'store/query/api-extensions/loyalty';
import LoyaltyOffers from '../components/LoyaltyOffers';
import { useTranslation } from 'react-i18next';
import { getProgramDescription } from 'routes/merchant-scoped-portal/integrations/loyalty/utils/program';
import ModuleLoadFailed from 'routes/profile/common/errors/ModuleLoadFailed';
import { Route, useParams } from 'react-router-dom';
import EmailCollectionIncentivePage from './EmailCollectionIncentivePage';
import {
  AuthenticatedLoyaltyRouteParams,
  mailingListRoutePattern,
} from '../routeUtils';
import { useTrackLoyaltyEvent } from 'utils/custom-react-hooks/loyalty/useTrackLoyaltyEvent';
import { EventName, FeatureFormat } from 'services/tracking/cdp/events/types';

const EarningPage = () => {
  const { merchantId, loyaltyAccountLookupToken } =
    useParams<AuthenticatedLoyaltyRouteParams>();
  const program = useSelector((state: AppState) =>
    selectLoyaltyProgram(state.loyaltyMerchantPortal)
  );
  const programId = program?.id ?? '';
  const { t } = useTranslation();

  useRetrieveEmailCollectionIncentiveQuery(loyaltyAccountLookupToken, {
    skip: !loyaltyAccountLookupToken,
  });
  useSearchPromotionsQuery(
    { merchantId, programId },
    {
      skip: !(merchantId && programId),
    }
  );

  const emailCollectionIncentiveTermsAndStatus = useSelector(
    (state: AppState) =>
      selectEmailCollectionIncentiveTermsAndStatus(state.loyaltyMerchantPortal)
  );
  const promotions = useSelector((state: AppState) =>
    selectPromotions(state.loyaltyMerchantPortal)
  );
  const showOffers =
    Boolean(promotions?.length) ||
    emailCollectionIncentiveTermsAndStatus?.eligible;

  const trackLoyaltyEvent = useTrackLoyaltyEvent();
  useEffect(() => {
    trackLoyaltyEvent(EventName.VIEW_FEATURE, {
      event_description: 'View earning tab',
      feature_format: FeatureFormat.PAGE,
    });
  }, [trackLoyaltyEvent]);

  const accountInfo = useSelector((state: AppState) =>
    selectAccount(state.loyaltyMerchantPortal)
  );

  if (!program) {
    return <ModuleLoadFailed embedded={true} />;
  }

  return (
    <div>
      <MarketRow>
        <label slot="label">
          {t('loyalty.resourcePage.howItWorks.header')}
        </label>
        <label slot="subtext">
          {getProgramDescription(program)}{' '}
          {t('loyalty.program.earningRuleDescription.helperText')}
        </label>
      </MarketRow>
      {showOffers && (
        <div className="mb-8">
          <MarketDivider />
          <LoyaltyOffers
            emailCollectionIncentiveTermsAndStatus={
              emailCollectionIncentiveTermsAndStatus
            }
            merchantId={merchantId}
            loyaltyAccountLookupToken={loyaltyAccountLookupToken}
            promotions={promotions}
          />
        </div>
      )}
      {accountInfo?.loyaltyAccount?.cashAppDownloadUrl && (
        <>
          <MarketDivider />
          <DownloadCashAppSection
            cashAppDownloadUrl={accountInfo?.loyaltyAccount?.cashAppDownloadUrl}
          />
        </>
      )}
      <Route
        path={mailingListRoutePattern}
        component={EmailCollectionIncentivePage}
      />
    </div>
  );
};
export default EarningPage;
