/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      client: {
        nested: {
          orders: {
            nested: {
              OrderLoyaltyDetails: {
                fields: {
                  loyaltyDetails: {
                    type: 'squareup.client.orders.OrderLoyaltyDetails.LoyaltyDetails',
                    id: 1,
                    rule: 'optional',
                  },
                },
                nested: {
                  LoyaltyDetails: {
                    fields: {
                      starsEarned: {
                        type: 'int32',
                        id: 1,
                        rule: 'optional',
                      },
                      newEnrollment: {
                        type: 'bool',
                        id: 2,
                        rule: 'optional',
                      },
                      reason: {
                        type: 'squareup.client.orders.OrderLoyaltyDetails.LoyaltyDetails.ReasonForNoStars',
                        id: 3,
                        rule: 'optional',
                      },
                      accountContact: {
                        type: 'squareup.client.orders.OrderLoyaltyDetails.LoyaltyDetails.AccountContact',
                        id: 4,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      ReasonForNoStars: {
                        values: {
                          UNKNOWN: 0,
                          NOT_YET_SUBSCRIBED: 1,
                          CLIENT_DISABLED_LOYALTY: 2,
                          RETURNED_FROM_RECEIPT_SCREEN: 3,
                          RETURNED_FROM_LOYALTY_SCREEN: 4,
                          BUYER_DECLINED: 5,
                          OFFLINE_MODE: 6,
                          NETWORK_TIMEOUT: 7,
                          MISSING_LOYALTY_INFO: 8,
                          RETURNED_DURING_LOADING: 9,
                          PURCHASE_DID_NOT_QUALIFY: 10,
                        },
                      },
                      AccountContact: {
                        fields: {
                          displayName: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          contactToken: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested
    ?.OrderLoyaltyDetails &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested
    ?.OrderLoyaltyDetails?.nested?.LoyaltyDetails &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested
    ?.OrderLoyaltyDetails?.nested?.LoyaltyDetails?.nested?.ReasonForNoStars &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested
    ?.OrderLoyaltyDetails?.nested?.LoyaltyDetails?.nested?.AccountContact
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE OrderLoyaltyDetails @ squareup/client/orders/order-loyalty.proto at 8:1
 */
export const OrderLoyaltyDetails = $root.lookupType(
  'squareup.client.orders.OrderLoyaltyDetails'
);
fixType(OrderLoyaltyDetails);
Builder.createAndAttachToType(OrderLoyaltyDetails);
/**
 * A subset of data from
 * {@link https://prototype.sqprod.co/#/docs/squareup.client.bills.Tender.LoyaltyDetails}
 * necessary for Order Manager to display. This data is fetched via the
 * {@link https://prototype.sqprod.co/#/docs/squareup.card.balance.api.TransactionsService#GetPaymentInformation}
 * endpoint.
 *
 * SOURCE LoyaltyDetails @ squareup/client/orders/order-loyalty.proto at 19:3
 */
OrderLoyaltyDetails.LoyaltyDetails = $root.lookupType(
  'squareup.client.orders.OrderLoyaltyDetails.LoyaltyDetails'
);
fixType(OrderLoyaltyDetails.LoyaltyDetails);
Builder.createAndAttachToType(OrderLoyaltyDetails.LoyaltyDetails);
/**
 * SOURCE ReasonForNoStars @ squareup/client/orders/order-loyalty.proto at 29:5
 */
OrderLoyaltyDetails.LoyaltyDetails.ReasonForNoStars = $root.lookupEnum(
  'squareup.client.orders.OrderLoyaltyDetails.LoyaltyDetails.ReasonForNoStars'
).values;
/**
 * The contact attached to the Loyalty Account that accrued the points for this transaction
 *
 * SOURCE AccountContact @ squareup/client/orders/order-loyalty.proto at 60:5
 */
OrderLoyaltyDetails.LoyaltyDetails.AccountContact = $root.lookupType(
  'squareup.client.orders.OrderLoyaltyDetails.LoyaltyDetails.AccountContact'
);
fixType(OrderLoyaltyDetails.LoyaltyDetails.AccountContact);
Builder.createAndAttachToType(
  OrderLoyaltyDetails.LoyaltyDetails.AccountContact
);
