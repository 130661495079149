import React from 'react';
import {
  MarketRow,
  MarketAccessory,
  MarketList,
  MarketButton,
} from '@market/react';
import { TrashIcon, BankIcon } from 'svgs';
import { OnFileBankAccount } from 'rpc/model/squareup/buyerportal/onfile/data';

export type PaymentMethod = {
  accountNumberSuffix: string;
  bankName: string;
  bankId: string;
};

type PaymentMethodOnFileProps = {
  onSelectPaymentMethod: (paymentMethod: PaymentMethod) => void;
  paymentMethods: readonly OnFileBankAccount[];
};

const PaymentMethodOnFile: React.FC<PaymentMethodOnFileProps> = ({
  onSelectPaymentMethod,
  paymentMethods,
}) => {
  return (
    <div>
      <div>
        <h2 className="heading-20">Payment methods on file</h2>
        <p id="bank-account-list-description" className={'text-base'}>
          {paymentMethods.length > 0
            ? 'The following payments have been saved on file with all locations:'
            : 'There are no payment methods saved on file.'}
        </p>
      </div>
      <MarketList aria-labelledby="bank-account-list-description">
        {paymentMethods.map((payment) => {
          return (
            <MarketRow
              key={payment.bankId}
              controlPosition="leading"
              data-testid="payment-method-row"
            >
              <label slot="label">
                {payment.bankName} **** {payment.accountNumberSuffix}
              </label>
              <MarketAccessory size="icon" slot="leading-accessory">
                <BankIcon />
              </MarketAccessory>

              <MarketButton
                slot="trailing-accessory"
                aria-label="Remove bank account"
                onClick={() => {
                  onSelectPaymentMethod(payment);
                }}
              >
                <TrashIcon fill="rgba(0, 0, 0, 0.55)" />
              </MarketButton>
            </MarketRow>
          );
        })}
      </MarketList>

      <p className={'text-sm text-black/[.55]'}>
        Note: If you set up recurring or pre-scheduled payment, your
        authorization is valid until payment method on file is removed.
      </p>
    </div>
  );
};

export default PaymentMethodOnFile;
