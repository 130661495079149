/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../../google/protobuf/descriptor';
import './model';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      privacyvault: {
        nested: {
          service: {
            nested: {
              MessageWithDescriptors: {
                fields: {
                  protoFiles: {
                    type: 'google.protobuf.FileDescriptorSet',
                    id: 1,
                    rule: 'optional',
                  },
                  typeName: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  messageData: {
                    type: 'bytes',
                    id: 3,
                    rule: 'repeated',
                  },
                  scope: {
                    type: 'squareup.privacyvault.model.PrivacyScope',
                    id: 4,
                    rule: 'optional',
                    options: {
                      default: 'UNKNOWN',
                    },
                  },
                },
              },
              Protected: {
                fields: {
                  stringData: {
                    type: 'string',
                    id: 1,
                  },
                  binaryData: {
                    type: 'bytes',
                    id: 2,
                  },
                  fideliusToken: {
                    type: 'squareup.privacyvault.service.Protected.ProtectedFideliusTokenWithCategory',
                    id: 3,
                  },
                  trunkToken: {
                    type: 'string',
                    id: 4,
                  },
                  protectedMessage: {
                    type: 'squareup.privacyvault.service.MessageWithDescriptors',
                    id: 5,
                  },
                  s3ObjectKey: {
                    type: 'string',
                    id: 6,
                  },
                },
                nested: {
                  ProtectedFideliusTokenWithCategory: {
                    fields: {
                      category: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      token: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                    },
                  },
                  content: {
                    oneof: [
                      'stringData',
                      'binaryData',
                      'fideliusToken',
                      'trunkToken',
                      'protectedMessage',
                      's3ObjectKey',
                    ],
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.privacyvault?.nested?.service?.nested
    ?.MessageWithDescriptors &&
  !$root.nested?.squareup?.nested?.privacyvault?.nested?.service?.nested
    ?.Protected &&
  !$root.nested?.squareup?.nested?.privacyvault?.nested?.service?.nested
    ?.Protected?.nested?.ProtectedFideliusTokenWithCategory
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE MessageWithDescriptors @ squareup/privacyvault/service.proto at 190:1
 */
export const MessageWithDescriptors = $root.lookupType(
  'squareup.privacyvault.service.MessageWithDescriptors'
);
fixType(MessageWithDescriptors, {
  typeName: { required: true },
  messageData: { required: true },
});
Builder.createAndAttachToType(MessageWithDescriptors);
/**
 * SOURCE Protected @ squareup/privacyvault/service.proto at 294:1
 */
export const Protected = $root.lookupType(
  'squareup.privacyvault.service.Protected'
);
fixType(Protected);
Builder.createAndAttachToType(Protected);
/**
 * SOURCE ProtectedFideliusTokenWithCategory @ squareup/privacyvault/service.proto at 295:3
 */
Protected.ProtectedFideliusTokenWithCategory = $root.lookupType(
  'squareup.privacyvault.service.Protected.ProtectedFideliusTokenWithCategory'
);
fixType(Protected.ProtectedFideliusTokenWithCategory, {
  category: { required: true },
  token: { required: true },
});
Builder.createAndAttachToType(Protected.ProtectedFideliusTokenWithCategory);
