import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MarketButton, MarketField, MarketInputText } from '@market/react';
import Es2Tracker from 'services/tracking/tracker';
import BackButton from 'components/BackButton';
import ErrorToastSlot from 'components/ErrorToastSlot';
import PrivacyRequestType from '../enums/PrivacyRequestType';
import {
  createAccountlessPrivacyRequest,
  executeAccountlessPrivacyRequest,
} from 'services/buyerportal';
import { IdentifierType } from 'routes/profile/models/Identifier';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import {
  privacyRequestsVerifyExportActionEvent,
  privacyRequestsVerifyExportInvalidActionEvent,
  privacyRequestsVerifyExportViewEvent,
} from 'services/tracking/events/privacyRequests';

type VerifyExportDataProps = {
  identifierType: IdentifierType;
  identifierValue: string;
  destinationEmail: string;
  onClickBack: () => void;
  onClickNext: () => void;
};

const VerifyExportData: React.FC<VerifyExportDataProps> = (props) => {
  const CODE_LENGTH = 6;

  const { t } = useTranslation(undefined, { useSuspense: false });

  const [code, setCode] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isUnexpectedError, setIsUnexpectedError] = useState<boolean>(false);
  const [isInvalidCode, setIsInvalidCode] = useState<boolean>(false);

  useEffect(() => {
    Es2Tracker.track(
      privacyRequestsVerifyExportViewEvent(props.identifierType)
    );
  }, [props.identifierType]);

  const triggerCodeVerification = async () => {
    try {
      setIsProcessing(true);
      setCode('');

      await createAccountlessPrivacyRequest(
        props.identifierType,
        props.identifierValue,
        PrivacyRequestType.Export
      );
    } catch {
      setIsUnexpectedError(true);
    } finally {
      setIsProcessing(false);
    }
  };

  const executeRequest = async () => {
    try {
      setIsProcessing(true);
      Es2Tracker.track(
        privacyRequestsVerifyExportActionEvent(props.identifierType)
      );

      await executeAccountlessPrivacyRequest(
        props.identifierType,
        props.identifierValue,
        PrivacyRequestType.Export,
        code,
        props.destinationEmail
      );

      props.onClickNext();
    } catch (ex) {
      if (ex.status === RequestStatus.STATUS_BAD_REQUEST) {
        Es2Tracker.track(
          privacyRequestsVerifyExportInvalidActionEvent(props.identifierType)
        );
        setIsInvalidCode(true);
        return;
      }

      setIsUnexpectedError(true);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      <BackButton onClick={props.onClickBack} />
      <h1 className={'mb-4 heading-30'}>
        {t('common.verifyIdentifier.title', {
          identifierValue: props.identifierValue,
        })}
      </h1>
      <p className={'mt-0 mb-6 paragraph-30'}>
        {t('privacyManagement.verifyExportData.subtitle')}
      </p>
      <MarketField invalid={isInvalidCode} className={'mb-6'}>
        <MarketInputText>
          <label>{t('common.code')}</label>
          <input
            slot={'input'}
            data-testid={'code-input'}
            autoComplete={'one-time-code'}
            autoCorrect={'off'}
            autoCapitalize={'off'}
            inputMode={'numeric'}
            maxLength={CODE_LENGTH}
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
              setIsInvalidCode(false);
            }}
            onKeyUpCapture={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                executeRequest();
              }
            }}
          />
        </MarketInputText>
        <small slot={'error'}>{t('common.code.invalid')}</small>
      </MarketField>
      <MarketButton
        rank={'primary'}
        {...((isInvalidCode || isProcessing) && { disabled: true })}
        onClick={executeRequest}
      >
        {t('common.requestReport')}
      </MarketButton>
      <MarketButton
        rank={'tertiary'}
        className={'mt-2'}
        {...(isProcessing && { disabled: true })}
        onClick={triggerCodeVerification}
      >
        {t('common.requestNewCode')}
      </MarketButton>
      <ErrorToastSlot
        errored={isUnexpectedError}
        clearError={() => setIsUnexpectedError(false)}
      />
    </>
  );
};

export default VerifyExportData;
