import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLoyaltyProgram, selectMembershipProgram } from '../loyaltySlice';
import { getProgramDescription } from '../utils/program';
import { MarketDivider } from '@market/react';
import RewardTierList from './RewardTierList';
import TierProgramDetails from './TierProgramDetails';
import { AppState } from 'store';

const LoyaltyProgramOverview = () => {
  const { t } = useTranslation();
  const loyaltyProgram = useSelector((state: AppState) =>
    selectLoyaltyProgram(state.loyaltyMerchantPortal)
  );
  const membershipProgram = useSelector((state: AppState) =>
    selectMembershipProgram(state.loyaltyMerchantPortal)
  );

  return (
    <>
      <h2 className="mt-0">{t('loyalty.resourcePage.howItWorks.header')}</h2>
      <p className="m-0">
        {getProgramDescription(loyaltyProgram!)}{' '}
        {t('loyalty.resourcePage.howItWorks.description')}
      </p>
      <MarketDivider margin="large" />
      <h2 className="mt-0">{t('loyalty.resourcePage.rewards.header')}</h2>
      <RewardTierList rewardTiers={loyaltyProgram?.rewardTiers!} />
      {membershipProgram && (
        <>
          <MarketDivider margin="large" />
          <h2 className="mt-0">
            {t('loyalty.resourcePage.tiersAndBenefits.header')}
          </h2>
          <TierProgramDetails
            loyaltyProgram={loyaltyProgram!}
            membershipProgram={membershipProgram}
            isCollapsible={false}
          />
        </>
      )}
    </>
  );
};

export default LoyaltyProgramOverview;
