import React from 'react';
import { MarketSvgProps } from '.';

const Home: React.FC<MarketSvgProps> = (props) => {
  return (
    <svg
      aria-hidden
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 2.58984L4 10.5898V17.9998C4 19.6498 5.35 20.9998 7 20.9998H17C18.65 20.9998 20 19.6498 20 17.9998V10.5898L12 2.58984ZM13 18.9998H11V14.9998H13V18.9998ZM18 17.9998C18 18.5498 17.55 18.9998 17 18.9998H15V12.9998H9V18.9998H7C6.45 18.9998 6 18.5498 6 17.9998V11.4098L12 5.40984L18 11.4098V17.9998Z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
};

export default Home;
