// eslint-disable-next-line filenames/match-exported
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Es2Tracker from 'services/tracking/tracker';
import { LogoIcon } from 'svgs';
import {
  MarketButton,
  MarketList,
  MarketRadio,
  MarketRow,
} from '@market/react';
import nonTranslatableStrings from 'utils/nonTranslatableStrings';
import PrivacyRequestType from './enums/PrivacyRequestType';
import DeleteData from './delete';
import ExportData from './export';
import { privacyRequestsViewEvent } from 'services/tracking/events/privacyRequests';
import Footer from 'components/SqPrivacyFooter';

const PrivacyManagement: React.FC = () => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  const [unconfirmedRequestType, setUnconfirmedRequestType] =
    useState<PrivacyRequestType>(PrivacyRequestType.Export);
  const [confirmedRequestType, setConfirmedRequestType] =
    useState<PrivacyRequestType | null>(null);

  useEffect(() => {
    Es2Tracker.track(privacyRequestsViewEvent());
  }, []);

  const unselectConfirmedRequestType = () => {
    setConfirmedRequestType(null);
  };

  /*
   * Renders the component to select a request type.
   */
  const renderSelectRequestType = () => {
    return (
      <>
        <LogoIcon className={'text-[32px]'} />
        <h1 className={'mb-4 heading-30'}>
          {t('privacyManagement.title', {
            appName: nonTranslatableStrings.square,
          })}
        </h1>
        <p className={'mt-0 mb-4 paragraph-30'}>
          {t('privacyManagement.subtitle', {
            appName: nonTranslatableStrings.square,
          })}
        </p>
        <MarketList
          aria-label={t('privacyManagement.ariaLabels.selectRequestType')}
          value={unconfirmedRequestType.toString()}
          onMarketListSelectionsDidChange={(event) => {
            // MARKET ODDITY: 2 events being emitted for each selection change
            // First one contains the deselected value, second contains the newly selected value
            // We ignore the former
            // Slack: https://square.slack.com/archives/CTU91JFU0/p1693946123163909
            if (!event.detail.newSelectionValue) {
              return;
            }
            setUnconfirmedRequestType(Number(event.detail.newSelectionValue));
          }}
        >
          <MarketRow value={PrivacyRequestType.Export.toString()}>
            <label id="export-data-option-label" slot={'label'}>
              {t('privacyManagement.requestType.exportData')}
            </label>
            <MarketRadio
              aria-labelledby="export-data-option-label"
              slot={'control'}
            />
          </MarketRow>
          <MarketRow value={PrivacyRequestType.Delete.toString()}>
            <label id="delete-data-option-label" slot={'label'}>
              {t('privacyManagement.requestType.deleteData')}
            </label>
            <MarketRadio
              aria-labelledby="delete-data-option-label"
              slot={'control'}
            />
          </MarketRow>
        </MarketList>
        <MarketButton
          className={'mt-6 '}
          onClick={() =>
            setConfirmedRequestType(Number(unconfirmedRequestType))
          }
          rank={'primary'}
        >
          {t('common.next')}
        </MarketButton>
      </>
    );
  };

  /*
   * Renders the component for the current step.
   */
  const renderSelectedRequestFlow = () => {
    switch (confirmedRequestType) {
      case PrivacyRequestType.Delete: {
        return <DeleteData onCancel={unselectConfirmedRequestType} />;
      }
      case PrivacyRequestType.Export: {
        return <ExportData onCancel={unselectConfirmedRequestType} />;
      }
      default: {
        return renderSelectRequestType();
      }
    }
  };

  return (
    <>
      <div className={'flex grow justify-center min-h-full'}>
        <main className={'flex flex-col py-[47px] px-[24px] xs:max-w-[440px]'}>
          {renderSelectedRequestFlow()}
        </main>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyManagement;
