/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      privacyvault: {
        nested: {
          model: {
            nested: {
              Entity: {
                fields: {
                  tokenType: {
                    type: 'squareup.privacyvault.model.Entity.TokenType',
                    id: 1,
                    rule: 'optional',
                  },
                  token: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                },
                nested: {
                  TokenType: {
                    values: {
                      UNKNOWN: 0,
                      MERCHANT_MASTER: 1,
                      MERCHANT_EMPLOYEE: 2,
                      MERCHANT_CONTACT: 3,
                      LOYALTY_CONTACT: 4,
                      CASH_CUSTOMER: 5,
                      SQUARE_EMPLOYEE: 6,
                      SQUARE_JOB_CANDIDATE: 15,
                      PERSON: 11,
                      PAYMENT: 12,
                      CAPITAL_CUSTOMER: 7,
                      APPOINTMENTS_CUSTOMER: 8,
                      BUYER: 14,
                      WEEBLY_SITE_OWNER: 16,
                      LOGGED_OUT_USER: 23,
                      AFTERPAY_CONSUMER: 24,
                      AFTERPAY_CONSUMER_ANZ: 27,
                      AFTERPAY_CONSUMER_EU: 28,
                      AFTERPAY_CONSUMER_NA: 29,
                      AFTERPAY_MERCHANT: 25,
                      TIDAL_CUSTOMER: 26,
                      BITKEY_CUSTOMER: 30,
                    },
                  },
                },
              },
              PrivacyScope: {
                values: {
                  UNKNOWN: 0,
                  REGISTER_MERCHANT: 1,
                  REGISTER_BUYER: 2,
                  CASH_SENDER: 3,
                  CASH_RECIPIENT: 4,
                  CASH_CUSTOMER: 5,
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.privacyvault?.nested?.model?.nested
    ?.Entity &&
  !$root.nested?.squareup?.nested?.privacyvault?.nested?.model?.nested?.Entity
    ?.nested?.TokenType &&
  !$root.nested?.squareup?.nested?.privacyvault?.nested?.model?.nested
    ?.PrivacyScope
) {
  $root.addJSON(jsonData);
}

/**
 * An identifier for a thing that can be protected relative to a PrivacyRequest.
 *
 * SOURCE Entity @ squareup/privacyvault/model.proto at 19:1
 */
export const Entity = $root.lookupType('squareup.privacyvault.model.Entity');
fixType(Entity, {
  tokenType: { required: true },
  token: { required: true },
});
Builder.createAndAttachToType(Entity);
/**
 * SOURCE TokenType @ squareup/privacyvault/model.proto at 20:5
 */
Entity.TokenType = $root.lookupEnum(
  'squareup.privacyvault.model.Entity.TokenType'
).values;
/**
 * A scope in which a thing (usually a message) could be protected, which overlaps with
 * Entity.TokenType, but not exactly.
 *
 * SOURCE PrivacyScope @ squareup/privacyvault/model.proto at 104:1
 */
export const PrivacyScope = $root.lookupEnum(
  'squareup.privacyvault.model.PrivacyScope'
).values;
