import React from 'react';
import {
  MarketActivityIndicator,
  MarketContentCard,
  MarketLink,
  MarketRow,
} from '@market/react';
import { RetrieveOnFileMerchantResponse } from 'rpc/model/squareup/buyerportal/onfile/data';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import { useTranslation } from 'react-i18next';

type DashboardHeaderProps = {
  merchantData: RetrieveOnFileMerchantResponse | null;
};

const DEFAULT_MERCHANT_LOGO =
  'https://s3-eu-west-1.amazonaws.com/tpd/logos/5aeadef0f840a40001b866ac/0x0.png';

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ merchantData }) => {
  const { t } = useTranslation();

  /*
    Currently adding a BoF is a US only so the country code will always be +1
   */
  const formatPhoneNumber = (phoneNumberString?: string) => {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  };

  return (
    <header>
      {merchantData === null ||
      merchantData.status !== RequestStatus.STATUS_SUCCESS ? (
        <MarketActivityIndicator
          size="small"
          className={
            'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
          }
        />
      ) : (
        <div className={'flex flex-col w-full h-[300px]'}>
          <div className={'w-full h-[40%] bg-[#F5F6F7]'} />
          <MarketRow>
            <img
              src={merchantData.profileImageUrl ?? DEFAULT_MERCHANT_LOGO}
              alt="Merchant Logo"
              className={
                'absolute w-16 h-16 top-0 left-1/2 translate-x-[-50%] translate-y-[-50%] rounded-[10%]'
              }
            />
            <h1 className={'--core-type-heading-30-size text-center pt-[20px]'}>
              {merchantData.merchantName}
            </h1>
          </MarketRow>
          {(merchantData.phoneNumber || merchantData.emailAddress) && (
            <MarketContentCard
              className={'sm:w-[600px] text-black/[.55] self-center space-y-1'}
            >
              {merchantData.phoneNumber && (
                <div>
                  <label>
                    {t('common.tel')}:&nbsp;
                    <MarketLink href={`tel:${merchantData.phoneNumber}`}>
                      {formatPhoneNumber(merchantData.phoneNumber)}
                    </MarketLink>
                  </label>
                </div>
              )}
              {merchantData.emailAddress && (
                <div>
                  <label>
                    {t('common.email')}:&nbsp;
                    <MarketLink href={`mailto:${merchantData.emailAddress}`}>
                      {merchantData.emailAddress}
                    </MarketLink>
                  </label>
                </div>
              )}
            </MarketContentCard>
          )}
        </div>
      )}
    </header>
  );
};

export default DashboardHeader;
