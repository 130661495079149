import React from 'react';
import { MarketActivityIndicatorBar } from '@market/react';
import { MemberStatus } from 'routes/merchant-scoped-portal/integrations/loyalty/models/MemberStatus';
import { useTranslation } from 'react-i18next';
import { LoyaltyProgramTerminology } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-program';

interface TierProgressProps {
  memberStatus: MemberStatus;
  terminology: LoyaltyProgramTerminology;
}

const TierProgress: React.FC<TierProgressProps> = ({
  memberStatus,
  terminology,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mt-8 mb-4" data-testid="loyalty__tier-progress__container">
      <h3 className="mb-0">
        {t('loyalty.tiers.progress.header', {
          nextTierName: memberStatus.nextTierName,
        })}
      </h3>
      <p className="mt-1">
        {t('loyalty.tiers.progress.description', {
          numPoints: memberStatus.pointsTowardNextTier,
          pointsTerminology:
            memberStatus.pointsTowardNextTier === 1
              ? terminology.one
              : terminology.other,
        })}
      </p>
      <MarketActivityIndicatorBar
        data-testid="loyalty__tier-progress__bar"
        value={memberStatus.nextTierProgressPercentage}
        aria-label={t('loyalty.tiers.progress.barLabel')}
      />
    </div>
  );
};

export default TierProgress;
