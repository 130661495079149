import { MarketList, MarketRow } from '@market/react';
import React from 'react';
import { EmailCollectionIncentiveTermsAndStatus } from 'rpc/model/squareup/card/balance/loyalty_api/model/email-collection-incentive-terms-and-status';
import EmailCollectionIncentiveCard from './EmailCollectionIncentiveCard';
import { useTranslation } from 'react-i18next';
import { LoyaltyPromotion } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-promotion';
import LoyaltyPromotionCard from './LoyaltyPromotionCard';

interface LoyaltyOffersProps {
  emailCollectionIncentiveTermsAndStatus?: EmailCollectionIncentiveTermsAndStatus;
  merchantId: string;
  loyaltyAccountLookupToken: string;
  promotions?: LoyaltyPromotion[];
}

// This component assumes that the customer is eligible for at least 1 loyalty offer, including
// email collection incentive and loyalty promotions.
const LoyaltyOffers: React.FC<LoyaltyOffersProps> = ({
  emailCollectionIncentiveTermsAndStatus,
  merchantId,
  loyaltyAccountLookupToken,
  promotions,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <MarketRow>
        <label slot="label">{t('loyalty.loyaltyOffers.label')}</label>
        <label slot="subtext">{t('loyalty.loyaltyOffers.subtext')}</label>
      </MarketRow>
      <a className="global-skip-link" href="#after-loyalty-promotion-offers">
        {t('loyalty.loyaltyOffers.skipLink.origin')}
      </a>
      <MarketList>
        {emailCollectionIncentiveTermsAndStatus?.eligible && (
          <EmailCollectionIncentiveCard
            incentiveTermsAndStatus={emailCollectionIncentiveTermsAndStatus}
            merchantId={merchantId}
            loyaltyAccountLookupToken={loyaltyAccountLookupToken}
          />
        )}
        {promotions?.map((promotion) => (
          <LoyaltyPromotionCard
            key={promotion.id}
            loyaltyPromotion={promotion}
          />
        ))}
      </MarketList>
      <div
        className="global-skip-link-destination"
        id="after-loyalty-promotion-offers"
        tabIndex={-1}
      >
        {t('loyalty.loyaltyOffers.skipLink.destination')}
      </div>
    </>
  );
};

export default LoyaltyOffers;
