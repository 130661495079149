/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../../Fixes';
import Builder from '../../../../../Builder';
import '../../../../connect/v2/common/source_application';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      card: {
        nested: {
          balance: {
            nested: {
              loyalty_api: {
                nested: {
                  model: {
                    nested: {
                      LoyaltyEvent: {
                        fields: {
                          id: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          type: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyEvent.Type',
                            id: 2,
                            rule: 'optional',
                          },
                          createdAt: {
                            type: 'string',
                            id: 3,
                            rule: 'optional',
                          },
                          createAccount: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyEvent.CreateAccount',
                            id: 4,
                            rule: 'optional',
                          },
                          accumulatePoints: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyEvent.AccumulatePoints',
                            id: 5,
                            rule: 'optional',
                          },
                          createReward: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyEvent.CreateReward',
                            id: 6,
                            rule: 'optional',
                          },
                          redeemReward: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyEvent.RedeemReward',
                            id: 7,
                            rule: 'optional',
                          },
                          deleteReward: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyEvent.DeleteReward',
                            id: 8,
                            rule: 'optional',
                          },
                          adjustPoints: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyEvent.AdjustPoints',
                            id: 9,
                            rule: 'optional',
                          },
                          loyaltyAccountId: {
                            type: 'string',
                            id: 10,
                            rule: 'optional',
                          },
                          locationId: {
                            type: 'string',
                            id: 11,
                            rule: 'optional',
                          },
                          source: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyEvent.Source',
                            id: 12,
                            rule: 'optional',
                          },
                          expirePoints: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyEvent.ExpirePoints',
                            id: 13,
                            rule: 'optional',
                          },
                          otherEvent: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyEvent.Other',
                            id: 14,
                            rule: 'optional',
                          },
                          sourceApplication: {
                            type: 'squareup.connect.v2.common.SourceApplication',
                            id: 15,
                            rule: 'optional',
                          },
                          accumulatePromotionPoints: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyEvent.AccumulatePromotionPoints',
                            id: 16,
                            rule: 'optional',
                          },
                          copiedFromEventId: {
                            type: 'string',
                            id: 17,
                            rule: 'optional',
                          },
                          newAccountBalance: {
                            type: 'int64',
                            id: 18,
                            rule: 'optional',
                          },
                          accumulateTierPoints: {
                            type: 'squareup.card.balance.loyalty_api.model.LoyaltyEvent.AccumulateTierPoints',
                            id: 19,
                            rule: 'optional',
                          },
                        },
                        nested: {
                          Type: {
                            values: {
                              TYPE_DO_NOT_USE: 0,
                              CREATE_ACCOUNT: 1,
                              ACCUMULATE_POINTS: 2,
                              CREATE_REWARD: 3,
                              REDEEM_REWARD: 4,
                              DELETE_REWARD: 5,
                              ADJUST_POINTS: 6,
                              EXPIRE_POINTS: 7,
                              OTHER: 8,
                              ACCUMULATE_PROMOTION_POINTS: 9,
                              ACCUMULATE_TIER_POINTS: 10,
                            },
                          },
                          Source: {
                            values: {
                              SOURCE_DO_NOT_USE: 0,
                              SQUARE: 1,
                              LOYALTY_API: 2,
                            },
                          },
                          CreateAccount: {
                            fields: {
                              loyaltyProgramId: {
                                type: 'string',
                                id: 1,
                                rule: 'optional',
                              },
                            },
                          },
                          AccumulatePoints: {
                            fields: {
                              loyaltyProgramId: {
                                type: 'string',
                                id: 1,
                                rule: 'optional',
                              },
                              points: {
                                type: 'uint32',
                                id: 2,
                                rule: 'optional',
                              },
                              orderId: {
                                type: 'string',
                                id: 3,
                                rule: 'optional',
                              },
                              reason: {
                                type: 'squareup.card.balance.loyalty_api.model.LoyaltyEvent.AccumulatePoints.Reason',
                                id: 4,
                                rule: 'optional',
                              },
                            },
                            nested: {
                              Reason: {
                                values: {
                                  REASON_DO_NOT_USE: 0,
                                  PHONE_KEYED_IN: 1,
                                  CUSTOMER_IN_CART: 2,
                                  CARD_LINKED_TO_PHONE: 3,
                                  RECEIPT_PREFERENCE: 4,
                                  APPLE_VAS_PASS: 5,
                                },
                              },
                            },
                          },
                          CreateReward: {
                            fields: {
                              loyaltyProgramId: {
                                type: 'string',
                                id: 1,
                                rule: 'optional',
                              },
                              rewardId: {
                                type: 'string',
                                id: 2,
                                rule: 'optional',
                              },
                              points: {
                                type: 'int32',
                                id: 3,
                                rule: 'optional',
                              },
                            },
                          },
                          RedeemReward: {
                            fields: {
                              loyaltyProgramId: {
                                type: 'string',
                                id: 1,
                                rule: 'optional',
                              },
                              rewardId: {
                                type: 'string',
                                id: 2,
                                rule: 'optional',
                              },
                              rewardIdWithCoupon: {
                                type: 'string',
                                id: 4,
                                rule: 'optional',
                              },
                              orderId: {
                                type: 'string',
                                id: 3,
                                rule: 'optional',
                              },
                            },
                          },
                          DeleteReward: {
                            fields: {
                              loyaltyProgramId: {
                                type: 'string',
                                id: 1,
                                rule: 'optional',
                              },
                              rewardId: {
                                type: 'string',
                                id: 2,
                                rule: 'optional',
                              },
                              points: {
                                type: 'uint32',
                                id: 3,
                                rule: 'optional',
                              },
                            },
                          },
                          AdjustPoints: {
                            fields: {
                              loyaltyProgramId: {
                                type: 'string',
                                id: 1,
                                rule: 'optional',
                              },
                              points: {
                                type: 'int32',
                                id: 2,
                                rule: 'optional',
                              },
                              reason: {
                                type: 'string',
                                id: 3,
                                rule: 'optional',
                              },
                            },
                          },
                          ExpirePoints: {
                            fields: {
                              loyaltyProgramId: {
                                type: 'string',
                                id: 1,
                                rule: 'optional',
                              },
                              points: {
                                type: 'int32',
                                id: 2,
                                rule: 'optional',
                              },
                            },
                          },
                          Other: {
                            fields: {
                              loyaltyProgramId: {
                                type: 'string',
                                id: 1,
                                rule: 'optional',
                              },
                              points: {
                                type: 'int32',
                                id: 2,
                                rule: 'optional',
                              },
                            },
                          },
                          AccumulatePromotionPoints: {
                            fields: {
                              loyaltyProgramId: {
                                type: 'string',
                                id: 1,
                                rule: 'optional',
                              },
                              loyaltyPromotionId: {
                                type: 'string',
                                id: 2,
                                rule: 'optional',
                              },
                              points: {
                                type: 'uint32',
                                id: 3,
                                rule: 'optional',
                              },
                              orderId: {
                                type: 'string',
                                id: 4,
                                rule: 'optional',
                              },
                            },
                          },
                          AccumulateTierPoints: {
                            fields: {
                              benefitPackageId: {
                                type: 'string',
                                id: 1,
                                rule: 'optional',
                              },
                              benefitId: {
                                type: 'string',
                                id: 2,
                                rule: 'optional',
                              },
                              points: {
                                type: 'uint32',
                                id: 3,
                                rule: 'optional',
                              },
                              orderId: {
                                type: 'string',
                                id: 4,
                                rule: 'optional',
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyEvent &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyEvent?.nested?.Type &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyEvent?.nested?.Source &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyEvent?.nested?.CreateAccount &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyEvent?.nested?.AccumulatePoints &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyEvent?.nested?.AccumulatePoints?.nested
    ?.Reason &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyEvent?.nested?.CreateReward &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyEvent?.nested?.RedeemReward &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyEvent?.nested?.DeleteReward &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyEvent?.nested?.AdjustPoints &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyEvent?.nested?.ExpirePoints &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyEvent?.nested?.Other &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyEvent?.nested?.AccumulatePromotionPoints &&
  !$root.nested?.squareup?.nested?.card?.nested?.balance?.nested?.loyalty_api
    ?.nested?.model?.nested?.LoyaltyEvent?.nested?.AccumulateTierPoints
) {
  $root.addJSON(jsonData);
}

/**
 * TODO (@romil) add rfc validation to all time related fields
 * --
 * @desc Provides information about a loyalty event.
 * For more information, see [Search for Balance-Changing Loyalty Events](https://developer.squareup.com/docs/loyalty-api/loyalty-events).
 * --
 *
 * SOURCE LoyaltyEvent @ squareup/card/balance/loyalty_api/model/loyalty-event.proto at 22:1
 */
export const LoyaltyEvent = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyEvent'
);
fixType(LoyaltyEvent, {
  id: { notEmpty: true },
  type: { required: true },
  createdAt: { notEmpty: true },
  loyaltyAccountId: { notEmpty: true },
  source: { required: true },
});
Builder.createAndAttachToType(LoyaltyEvent);
/**
 * --
 * @desc The type of the loyalty event.
 * --
 *
 * SOURCE Type @ squareup/card/balance/loyalty_api/model/loyalty-event.proto at 26:3
 */
LoyaltyEvent.Type = $root.lookupEnum(
  'squareup.card.balance.loyalty_api.model.LoyaltyEvent.Type'
).values;
/**
 * --
 * @desc Defines whether the event was generated by the Square Point of Sale.
 * --
 *
 * SOURCE Source @ squareup/card/balance/loyalty_api/model/loyalty-event.proto at 88:3
 */
LoyaltyEvent.Source = $root.lookupEnum(
  'squareup.card.balance.loyalty_api.model.LoyaltyEvent.Source'
).values;
/**
 * --
 * @desc Provides metadata when the event `type` is `CREATE_ACCOUNT`.
 * --
 *
 * SOURCE CreateAccount @ squareup/card/balance/loyalty_api/model/loyalty-event.proto at 103:3
 */
LoyaltyEvent.CreateAccount = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyEvent.CreateAccount'
);
fixType(LoyaltyEvent.CreateAccount, {
  loyaltyProgramId: { notEmpty: true },
});
Builder.createAndAttachToType(LoyaltyEvent.CreateAccount);
/**
 * --
 * @desc Provides metadata when the event `type` is `ACCUMULATE_POINTS`.
 * --
 *
 * SOURCE AccumulatePoints @ squareup/card/balance/loyalty_api/model/loyalty-event.proto at 116:3
 */
LoyaltyEvent.AccumulatePoints = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyEvent.AccumulatePoints'
);
fixType(LoyaltyEvent.AccumulatePoints);
Builder.createAndAttachToType(LoyaltyEvent.AccumulatePoints);
/**
 * --
 * @desc The first-party reason for point accrual
 * --
 *
 * SOURCE Reason @ squareup/card/balance/loyalty_api/model/loyalty-event.proto at 120:5
 */
LoyaltyEvent.AccumulatePoints.Reason = $root.lookupEnum(
  'squareup.card.balance.loyalty_api.model.LoyaltyEvent.AccumulatePoints.Reason'
).values;
/**
 * --
 * @desc Provides metadata when the event `type` is `CREATE_REWARD`.
 * --
 *
 * SOURCE CreateReward @ squareup/card/balance/loyalty_api/model/loyalty-event.proto at 180:3
 */
LoyaltyEvent.CreateReward = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyEvent.CreateReward'
);
fixType(LoyaltyEvent.CreateReward, {
  loyaltyProgramId: { notEmpty: true },
  points: { required: true },
});
Builder.createAndAttachToType(LoyaltyEvent.CreateReward);
/**
 * --
 * @desc Provides metadata when the event `type` is `REDEEM_REWARD`.
 * --
 *
 * SOURCE RedeemReward @ squareup/card/balance/loyalty_api/model/loyalty-event.proto at 201:3
 */
LoyaltyEvent.RedeemReward = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyEvent.RedeemReward'
);
fixType(LoyaltyEvent.RedeemReward, {
  loyaltyProgramId: { notEmpty: true },
});
Builder.createAndAttachToType(LoyaltyEvent.RedeemReward);
/**
 * --
 * @desc Provides metadata when the event `type` is `DELETE_REWARD`.
 * --
 *
 * SOURCE DeleteReward @ squareup/card/balance/loyalty_api/model/loyalty-event.proto at 230:3
 */
LoyaltyEvent.DeleteReward = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyEvent.DeleteReward'
);
fixType(LoyaltyEvent.DeleteReward, {
  loyaltyProgramId: { notEmpty: true },
  points: { required: true },
});
Builder.createAndAttachToType(LoyaltyEvent.DeleteReward);
/**
 * --
 * @desc Provides metadata when the event `type` is `ADJUST_POINTS`.
 * --
 *
 * SOURCE AdjustPoints @ squareup/card/balance/loyalty_api/model/loyalty-event.proto at 251:3
 */
LoyaltyEvent.AdjustPoints = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyEvent.AdjustPoints'
);
fixType(LoyaltyEvent.AdjustPoints, {
  points: { required: true },
});
Builder.createAndAttachToType(LoyaltyEvent.AdjustPoints);
/**
 * --
 * @desc Provides metadata when the event `type` is `EXPIRE_POINTS`.
 * --
 *
 * SOURCE ExpirePoints @ squareup/card/balance/loyalty_api/model/loyalty-event.proto at 271:3
 */
LoyaltyEvent.ExpirePoints = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyEvent.ExpirePoints'
);
fixType(LoyaltyEvent.ExpirePoints, {
  loyaltyProgramId: { notEmpty: true },
  points: { required: true },
});
Builder.createAndAttachToType(LoyaltyEvent.ExpirePoints);
/**
 * --
 * @desc Provides metadata when the event `type` is `OTHER`.
 * --
 *
 * SOURCE Other @ squareup/card/balance/loyalty_api/model/loyalty-event.proto at 285:3
 */
LoyaltyEvent.Other = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyEvent.Other'
);
fixType(LoyaltyEvent.Other, {
  loyaltyProgramId: { notEmpty: true },
  points: { required: true },
});
Builder.createAndAttachToType(LoyaltyEvent.Other);
/**
 * --
 * @desc Provides metadata when the event `type` is `ACCUMULATE_PROMOTION_POINTS`.
 * --
 *
 * SOURCE AccumulatePromotionPoints @ squareup/card/balance/loyalty_api/model/loyalty-event.proto at 300:3
 */
LoyaltyEvent.AccumulatePromotionPoints = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyEvent.AccumulatePromotionPoints'
);
fixType(LoyaltyEvent.AccumulatePromotionPoints, {
  points: { required: true },
  orderId: { notEmpty: true },
});
Builder.createAndAttachToType(LoyaltyEvent.AccumulatePromotionPoints);
/**
 * --
 * @desc Provides metadata when the event `type` is `ACCUMULATE_TIER_POINTS`.
 * --
 *
 * SOURCE AccumulateTierPoints @ squareup/card/balance/loyalty_api/model/loyalty-event.proto at 327:3
 */
LoyaltyEvent.AccumulateTierPoints = $root.lookupType(
  'squareup.card.balance.loyalty_api.model.LoyaltyEvent.AccumulateTierPoints'
);
fixType(LoyaltyEvent.AccumulateTierPoints, {
  points: { required: true },
  orderId: { notEmpty: true },
});
Builder.createAndAttachToType(LoyaltyEvent.AccumulateTierPoints);
