import React from 'react';

const PlusIconV2: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { className } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <g>
        <path
          d="M13 19V13H19V11H13V5H11V11H5V13H11V19H13Z"
          fill="black"
          fillOpacity="0.9"
        />
      </g>
    </svg>
  );
};

export default PlusIconV2;
