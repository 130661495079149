/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../pago/external/payments_model';
import '../../connect/v2/common/money';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      client: {
        nested: {
          orders: {
            nested: {
              OrderPayment: {
                fields: {
                  paymentDetails: {
                    type: 'squareup.client.orders.OrderPayment.PaymentDetails',
                    id: 2,
                    rule: 'repeated',
                  },
                },
                nested: {
                  PaymentDetails: {
                    fields: {
                      payment: {
                        type: 'squareup.connect.v2.Payment',
                        id: 1,
                        rule: 'optional',
                      },
                      feePlanClientInfo: {
                        type: 'squareup.client.orders.OrderPayment.PaymentDetails.FeePlanClientInfo',
                        id: 4,
                        rule: 'repeated',
                      },
                    },
                    nested: {
                      FeePlanClientInfo: {
                        fields: {
                          uid: {
                            type: 'string',
                            id: 4,
                            rule: 'optional',
                          },
                          discountBasisPoints: {
                            type: 'int64',
                            id: 1,
                            rule: 'optional',
                          },
                          interchangeCents: {
                            type: 'int64',
                            id: 2,
                            rule: 'optional',
                          },
                          taxDetails: {
                            type: 'squareup.client.orders.OrderPayment.PaymentDetails.FeePlanClientInfo.TaxDetailClientInfo',
                            id: 3,
                            rule: 'repeated',
                          },
                        },
                        nested: {
                          TaxDetailClientInfo: {
                            fields: {
                              uid: {
                                type: 'string',
                                id: 4,
                                rule: 'optional',
                              },
                              taxAmount: {
                                type: 'squareup.connect.v2.common.Money',
                                id: 1,
                                rule: 'optional',
                              },
                              taxRateType: {
                                type: 'squareup.client.orders.OrderPayment.PaymentDetails.FeePlanClientInfo.TaxDetailClientInfo.TaxRateTypeClientInfo',
                                id: 2,
                                rule: 'optional',
                              },
                              taxRateCentiBps: {
                                type: 'uint32',
                                id: 3,
                                rule: 'optional',
                              },
                            },
                            nested: {
                              TaxRateTypeClientInfo: {
                                values: {
                                  INCLUSIVE: 0,
                                  EXCLUSIVE: 1,
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested
    ?.OrderPayment &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested?.OrderPayment
    ?.nested?.PaymentDetails &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested?.OrderPayment
    ?.nested?.PaymentDetails?.nested?.FeePlanClientInfo &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested?.OrderPayment
    ?.nested?.PaymentDetails?.nested?.FeePlanClientInfo?.nested
    ?.TaxDetailClientInfo &&
  !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested?.OrderPayment
    ?.nested?.PaymentDetails?.nested?.FeePlanClientInfo?.nested
    ?.TaxDetailClientInfo?.nested?.TaxRateTypeClientInfo
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE OrderPayment @ squareup/client/orders/order-payment.proto at 10:1
 */
export const OrderPayment = $root.lookupType(
  'squareup.client.orders.OrderPayment'
);
fixType(OrderPayment);
Builder.createAndAttachToType(OrderPayment);
/**
 * SOURCE PaymentDetails @ squareup/client/orders/order-payment.proto at 16:2
 */
OrderPayment.PaymentDetails = $root.lookupType(
  'squareup.client.orders.OrderPayment.PaymentDetails'
);
fixType(OrderPayment.PaymentDetails);
Builder.createAndAttachToType(OrderPayment.PaymentDetails);
/**
 * deleted fields
 *
 * SOURCE FeePlanClientInfo @ squareup/client/orders/order-payment.proto at 22:3
 */
OrderPayment.PaymentDetails.FeePlanClientInfo = $root.lookupType(
  'squareup.client.orders.OrderPayment.PaymentDetails.FeePlanClientInfo'
);
fixType(OrderPayment.PaymentDetails.FeePlanClientInfo);
Builder.createAndAttachToType(OrderPayment.PaymentDetails.FeePlanClientInfo);
/**
 * SOURCE TaxDetailClientInfo @ squareup/client/orders/order-payment.proto at 28:4
 */
OrderPayment.PaymentDetails.FeePlanClientInfo.TaxDetailClientInfo =
  $root.lookupType(
    'squareup.client.orders.OrderPayment.PaymentDetails.FeePlanClientInfo.TaxDetailClientInfo'
  );
fixType(OrderPayment.PaymentDetails.FeePlanClientInfo.TaxDetailClientInfo);
Builder.createAndAttachToType(
  OrderPayment.PaymentDetails.FeePlanClientInfo.TaxDetailClientInfo
);
/**
 * SOURCE TaxRateTypeClientInfo @ squareup/client/orders/order-payment.proto at 41:5
 */
OrderPayment.PaymentDetails.FeePlanClientInfo.TaxDetailClientInfo.TaxRateTypeClientInfo =
  $root.lookupEnum(
    'squareup.client.orders.OrderPayment.PaymentDetails.FeePlanClientInfo.TaxDetailClientInfo.TaxRateTypeClientInfo'
  ).values;
