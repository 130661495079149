import React from 'react';
import BaseModal from 'components/modals/BaseModal';
import { MarketButton, MarketButtonGroup, MarketFooter } from '@market/react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/modalSlice';
import { useDeleteProfileMutation } from 'store/query';
import { openToast, ToastVariant } from 'store/toastSlice';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import { confirmDeleteAccountProperties } from 'services/tracking/cdp/events/profile';
import { useTranslation } from 'react-i18next';

const ConfirmDeleteModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [deleteProfile, deleteProfileResult] = useDeleteProfileMutation();

  const deleteAccount = async () => {
    await deleteProfile()
      .unwrap()
      .then(() => {
        buyerportalCdpClient.track(
          'Confirm Delete Account',
          confirmDeleteAccountProperties()
        );

        dispatch(closeModal());
      })
      .catch(() => {
        dispatch(
          openToast({
            message: t('common.somethingWentWrong.retryable.direct'),
            variant: ToastVariant.Critical,
          })
        );
      });
  };

  return (
    <BaseModal>
      <div className={'mx-auto'}>
        <main>
          <h2>
            {t('profile.account.deleteAccount.confirmDeleteModal.title')}?
          </h2>
          <p>{t('profile.account.deleteAccount.confirmDeleteModal.noUndo')}</p>
          <p className={'mb-8'}>
            {t(
              'profile.account.deleteAccount.confirmDeleteModal.contactDirectly'
            )}
          </p>
        </main>
        <MarketFooter className={'p-0'}>
          <MarketButtonGroup alignment={'fill'}>
            <MarketButton
              rank={'primary'}
              variant={'destructive'}
              onClick={deleteAccount}
              data-testid={'confirm-delete-account'}
              {...(deleteProfileResult.isLoading && { disabled: true })}
            >
              {t(
                'profile.account.deleteAccount.confirmDeleteModal.deleteAccount'
              )}
            </MarketButton>
            <MarketButton
              rank={'secondary'}
              onClick={() => dispatch(closeModal())}
              {...(deleteProfileResult.isLoading && { disabled: true })}
            >
              {t('common.cancel')}
            </MarketButton>
          </MarketButtonGroup>
        </MarketFooter>
      </div>
    </BaseModal>
  );
};

export default ConfirmDeleteModal;
