import React from 'react';
import { useSelector } from 'react-redux';
import ConfirmDeleteAuthorizedApplicationModal from 'routes/profile/account/AuthorizedApplications/modals/ConfirmDeleteModal';
import AddContactInfoModal from 'routes/profile/account/ContactInfo/modals/AddContactInfoModal';
import RequestDownloadModal from 'routes/profile/account/DownloadData/modals/RequestDownloadModal';
import EditProfileDetailsModal from 'routes/profile/account/ProfileDetails/modals/EditProfileDetailsModal';
import MobileNavModal from 'routes/profile/nav/modals/MobileNavModal';
import ConfirmDeleteAccountModal from 'routes/profile/account/DeleteAccount/modals/ConfirmDeleteModal';
import { ModalType, selectActiveModal } from 'store/modalSlice';
import AddPaymentCardModal from 'routes/profile/payment-methods/PaymentCards/modals/AddPaymentCardModal';
import ConfirmRemoveGiftCardModal from 'routes/profile/payment-methods/GiftCards/modals/ConfirmRemoveGiftCardModal';
import TransferGiftCardBalanceModal from 'routes/profile/payment-methods/GiftCards/modals/TransferGiftCardBalanceModal';
import AddGiftCardModal from 'routes/profile/payment-methods/GiftCards/modals/AddGiftCardModal';
import ConfirmRemovePaymentCardModal from 'routes/profile/payment-methods/PaymentCards/modals/ConfirmRemovePaymentCardModal';
import LocationListModal from 'routes/merchant-scoped-portal/components/modals/LocationListModal';
import PointsExpirationModal from 'routes/merchant-scoped-portal/integrations/loyalty/components/PointsExpirationModal';
import TierProgramDetailsModal from 'routes/merchant-scoped-portal/integrations/loyalty/components/modals/TierProgramDetailsModal';
import LoyaltyPromotionDetailModal from 'routes/merchant-scoped-portal/integrations/loyalty/components/modals/LoyaltyPromotionDetailModal';
import EmailCollectionIncentiveModal from 'routes/merchant-scoped-portal/integrations/loyalty/components/EmailCollectionIncentiveModal';
import BasicDialog from './BasicDialog';
import RedemptionOptionsModal from 'routes/merchant-scoped-portal/integrations/loyalty/components/modals/RedemptionOptionsModal';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ModalComponents: { [key in ModalType]: React.FC<any> } = {
  [ModalType.EditProfileDetails]: EditProfileDetailsModal,
  [ModalType.AddContactInfo]: AddContactInfoModal,
  [ModalType.ConfirmDeleteAuthorizedApplication]:
    ConfirmDeleteAuthorizedApplicationModal,
  [ModalType.MobileNav]: MobileNavModal,
  [ModalType.ConfirmDeleteAccount]: ConfirmDeleteAccountModal,
  [ModalType.DownloadData]: RequestDownloadModal,
  [ModalType.AddPaymentCard]: AddPaymentCardModal,
  [ModalType.ConfirmRemoveGiftCard]: ConfirmRemoveGiftCardModal,
  [ModalType.TransferGiftCardBalance]: TransferGiftCardBalanceModal,
  [ModalType.AddGiftCard]: AddGiftCardModal,
  [ModalType.ConfirmRemovePaymentCard]: ConfirmRemovePaymentCardModal,
  [ModalType.LocationList]: LocationListModal,
  [ModalType.PointsExpiration]: PointsExpirationModal,
  [ModalType.LoyaltyTierProgramDetailsModal]: TierProgramDetailsModal,
  [ModalType.LoyaltyPromotionDetailModal]: LoyaltyPromotionDetailModal,
  [ModalType.LoyaltyEmailIncentiveModal]: EmailCollectionIncentiveModal,
  [ModalType.BasicDialog]: BasicDialog,
  [ModalType.LoyaltyRedemptionOptionsModal]: RedemptionOptionsModal,
};

const GlobalModal: React.FC = () => {
  const activeModal = useSelector(selectActiveModal);

  if (activeModal === null) {
    return null;
  }

  const Modal = ModalComponents[activeModal.type];
  return <Modal {...activeModal.props} />;
};

export default GlobalModal;
