/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../../Fixes';
import Builder from '../../../../../Builder';
import './launch_darkly_project';
import '../../common/token';
import '../../common/attribute';
import '../../common/data_type';
import './context';
import './status';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      feature: {
        nested: {
          relay: {
            nested: {
              flags: {
                nested: {
                  message: {
                    nested: {
                      FlagEvaluationDetailsRequest: {
                        fields: {
                          project: {
                            type: 'squareup.feature.relay.flags.message.LaunchDarklyProject',
                            id: 1,
                            rule: 'optional',
                          },
                          flagKey: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                          userToken: {
                            type: 'squareup.feature.relay.common.Token',
                            id: 3,
                            rule: 'optional',
                          },
                          userAttributes: {
                            type: 'squareup.feature.relay.common.Attribute',
                            id: 4,
                            rule: 'repeated',
                          },
                          flagType: {
                            type: 'squareup.feature.relay.common.DataType',
                            id: 5,
                            rule: 'optional',
                          },
                          userAgentOverride: {
                            type: 'string',
                            id: 6,
                            rule: 'optional',
                          },
                          singleUserContext: {
                            type: 'squareup.feature.relay.flags.message.SingleUserContext',
                            id: 7,
                          },
                          multiUserContext: {
                            type: 'squareup.feature.relay.flags.message.MultiUserContext',
                            id: 8,
                          },
                        },
                        nested: {
                          composite_user_token: {
                            oneof: ['singleUserContext', 'multiUserContext'],
                          },
                        },
                      },
                      UserContextAttributes: {
                        fields: {
                          contextName: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          usedAttributes: {
                            type: 'squareup.feature.relay.common.Attribute',
                            id: 2,
                            rule: 'repeated',
                          },
                        },
                      },
                      FlagEvaluationDetailsResponse: {
                        fields: {
                          status: {
                            type: 'squareup.feature.relay.flags.message.Status',
                            id: 1,
                            rule: 'optional',
                          },
                          humanReadableEvaluationReason: {
                            type: 'string',
                            id: 6,
                            rule: 'optional',
                          },
                          userAttributes: {
                            type: 'squareup.feature.relay.common.Attribute',
                            id: 7,
                            rule: 'repeated',
                          },
                          userContextAttributes: {
                            type: 'squareup.feature.relay.flags.message.UserContextAttributes',
                            id: 8,
                            rule: 'repeated',
                          },
                          stringValue: {
                            type: 'string',
                            id: 2,
                          },
                          intValue: {
                            type: 'int32',
                            id: 3,
                          },
                          boolValue: {
                            type: 'bool',
                            id: 4,
                          },
                          doubleValue: {
                            type: 'double',
                            id: 5,
                          },
                        },
                        nested: {
                          value: {
                            oneof: [
                              'stringValue',
                              'intValue',
                              'boolValue',
                              'doubleValue',
                            ],
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.FlagEvaluationDetailsRequest &&
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.UserContextAttributes &&
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.FlagEvaluationDetailsResponse
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE FlagEvaluationDetailsRequest @ squareup/feature/relay/flags/message/flag_evaluation_details.proto at 16:1
 */
export const FlagEvaluationDetailsRequest = $root.lookupType(
  'squareup.feature.relay.flags.message.FlagEvaluationDetailsRequest'
);
fixType(FlagEvaluationDetailsRequest, {
  flagKey: { required: true, notEmpty: true },
});
Builder.createAndAttachToType(FlagEvaluationDetailsRequest);
/**
 * SOURCE UserContextAttributes @ squareup/feature/relay/flags/message/flag_evaluation_details.proto at 44:1
 */
export const UserContextAttributes = $root.lookupType(
  'squareup.feature.relay.flags.message.UserContextAttributes'
);
fixType(UserContextAttributes);
Builder.createAndAttachToType(UserContextAttributes);
/**
 * SOURCE FlagEvaluationDetailsResponse @ squareup/feature/relay/flags/message/flag_evaluation_details.proto at 49:1
 */
export const FlagEvaluationDetailsResponse = $root.lookupType(
  'squareup.feature.relay.flags.message.FlagEvaluationDetailsResponse'
);
fixType(FlagEvaluationDetailsResponse);
Builder.createAndAttachToType(FlagEvaluationDetailsResponse);
