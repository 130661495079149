import { datadogRum } from '@datadog/browser-rum';
import { applyPolyfills } from '@market/web-components/loader';
import { defineCustomElements } from '@market/web-components/dist/custom-elements';
import * as Sentry from '@sentry/react';
import { environment } from 'utils/environment';
import wildcardPathParameters from './wildcardPaths';

function isXhr(event: Sentry.Event, hint: Sentry.EventHint) {
  const exception = event.exception?.values?.[0] || {};
  const originalExceptions = (hint?.originalException || {}) as any;

  return (
    exception.type === 'Error' &&
    exception.value === '[object Object]' &&
    Boolean(originalExceptions.status) &&
    Boolean(originalExceptions.statusText)
  );
}

export const initSentry = (savt: string) => {
  Sentry.init({
    beforeSend(event, hint) {
      // Ignore rejected Promises caused by XHR request failures - they're noisy and we have backend logs for that.
      // If we want to enable this at some point, we should filter out 401s (session timeout) as they are very common
      if (isXhr(event, hint)) {
        return null;
      }

      return event;
    },
    dsn: 'https://6e6eafe489ba45899c666ddd5fb05629@o160250.ingest.sentry.io/1730086',
    environment: environment().name,
    // ignore errors caused by client stopping loading
    // https://adithya.dev/sentry-unhandled-failed-to-fetch/
    ignoreErrors: [
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: cancelled',
      'Object Not Found Matching Id',
    ],
  });

  // This will be overwritten by the actual user id if/when we render an AuthenticatedRoute
  Sentry.setUser({ id: savt });
};

export async function setupMarketPolyfills() {
  await Promise.all([applyPolyfills(), defineCustomElements(window)]);
}

/* DataDog RUM initialization */
// Exclusion logic borrowed from dashboard: https://github.com/squareup/dashboard/pull/77346/

// Returns a list of regexes that match known requests made in the background, to be excluded from activity detection.
const getBackgroundRequestsRegexes = (): RegExp[] => {
  return [
    /\/v1\/batch$/,
    /\/v1\/cdp\/batch$/, // CDP V1
    /\/1\.0\/events\/sendBatch$/, // CDP V2
  ];
};

// Checks if a URL is external to Square and thus should be excluded from activity detection
const isSquareExternalHost = (url: string): boolean => {
  try {
    const urlObject = new URL(url);
    const host = urlObject.hostname;
    return !host.includes('square') && !host.includes('localhost');
  } catch {
    return false;
  }
};

export const initDataDogRum = (manuallyTrackPageViews: boolean) => {
  datadogRum.init({
    allowedTracingUrls: [
      /squareup\.buyerportal\.BuyerPortalService/,
      /squareup\.buyerportal\.orders.OrderService/,
      /squareup\.buyerportal\.merchantportal\.MerchantPortalService/,
      /squareup\.buyerportal\.merchantportal\.loyalty\.LoyaltyService/,
      /squareup\.receipts\.SubscriptionService/,
      /squareup\.multipass\.external\.MultipassExternalService/,
    ],
    // found within DD at rum/list
    applicationId: '14c86734-2433-4833-bcb2-44da951d5d69',
    beforeSend(event) {
      if (!manuallyTrackPageViews) {
        return true;
      }
      if (event.type !== 'view') {
        return true;
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const wildcardedViewName = wildcardPathParameters(event.view.name!);
      // We may not have a view name if the user navigates to a page that doesn't match a route
      // In that case, we'll use the raw path as the view name
      // eslint-disable-next-line no-param-reassign
      event.view.name = wildcardedViewName || event.view.name;
      return true;
    },
    // found within DD at organization-settings/client-tokens
    clientToken: 'pub82c6eed1b33deb6dca085b69f961e130',
    defaultPrivacyLevel: 'mask',
    env: environment().name,
    excludedActivityUrls: [
      // Exclude background requests
      ...getBackgroundRequestsRegexes(),
      // Exclude external URLs
      isSquareExternalHost,
    ],
    service: 'square-profile',
    sessionReplaySampleRate: 100,
    sessionSampleRate: 100,
    site: 'datadoghq.com',
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    trackViewsManually: manuallyTrackPageViews,
    version: process.env.GIT_COMMIT,
  });
};
