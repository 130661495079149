import React, { useContext, useState } from 'react';
import BaseModal from 'components/modals/BaseModal';
import {
  MarketButton,
  MarketField,
  MarketHeader,
  MarketInputText,
} from '@market/react';
import { useTranslation } from 'react-i18next';
import { EmailCollectionIncentiveTermsAndStatus } from 'rpc/model/squareup/card/balance/loyalty_api/model/email-collection-incentive-terms-and-status';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'store/modalSlice';
import { selectLoyaltyProgram } from '../loyaltySlice';
import { AppState } from 'store';
import { MerchantPortalContext } from 'routes/merchant-scoped-portal';
import useValidation from 'utils/custom-react-hooks/useValidation';
import { isValidEmail } from 'utils/identifiers';
import { useCollectEmailIncentiveMutation } from 'store/query/api-extensions/loyalty';
import { ToastVariant, openToast } from 'store/toastSlice';
import { RequestStatus } from 'rpc/model/squareup/customers/request';

export interface EmailCollectionIncentiveModalProps {
  incentiveTermsAndStatus: EmailCollectionIncentiveTermsAndStatus;
  loyaltyAccountLookupToken: string;
  onClose?: () => void | (() => Promise<void>);
}

const EmailCollectionIncentiveModal: React.FC<
  EmailCollectionIncentiveModalProps
> = ({ incentiveTermsAndStatus, loyaltyAccountLookupToken, onClose }) => {
  const { t } = useTranslation();
  const mpContext = useContext(MerchantPortalContext);
  const merchantName =
    mpContext.merchantPortalBaseData?.merchantOverview?.merchantName;
  const program = useSelector((state: AppState) =>
    selectLoyaltyProgram(state.loyaltyMerchantPortal)
  );
  const pointsTerminology =
    incentiveTermsAndStatus.pointAmount === 1
      ? program?.terminology?.one
      : program?.terminology?.other;
  const subtext = t('loyalty.emailCollectionIncentiveModal.subtext', {
    merchantName,
    points: incentiveTermsAndStatus.pointAmount,
    pointsTerminology,
  });
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const buyer = mpContext.merchantPortalBaseData?.buyer;
  const [firstName, setFirstName] = useState(buyer?.name?.first ?? '');
  const [lastName, setLastName] = useState(buyer?.name?.last ?? '');
  const verifiedEmails = buyer?.emails;
  let prefilledEmail = null;
  if (verifiedEmails?.length === 1) {
    prefilledEmail = verifiedEmails[0].displayValue;
  }
  const [email, setEmail] = useState(prefilledEmail ?? '');
  const [isEmailInvalid, resetEmailValidation, validateEmail] = useValidation(
    (email) => isValidEmail(email)
  );
  const dispatch = useDispatch();
  const [collectEmailIncentive, collectEmailIncentiveResult] =
    useCollectEmailIncentiveMutation();
  const submit = async () => {
    setHasSubmitted(true);

    const valid = validateEmail(email);
    if (!valid) {
      return;
    }

    try {
      await collectEmailIncentive({
        agreedToCopyToken:
          incentiveTermsAndStatus.emailMarketingConsentCopy?.copyToken,
        email,
        firstName,
        lastName,
        loyaltyAccountLookupToken,
      }).unwrap();
      dispatch(
        openToast({
          // TODO: finalize success message
          message: 'Thanks for subscribing!',
          variant: ToastVariant.Success,
        })
      );
      onClose?.();
      dispatch(closeModal());
    } catch (error) {
      let message = t('common.somethingWentWrong.blocked');
      if (error.status === RequestStatus.STATUS_CONFLICT) {
        message = t(
          'loyalty.emailCollectionIncentiveModal.error.emailAlreadySubscribed'
        );
      }
      dispatch(
        openToast({
          message,
          variant: ToastVariant.Critical,
        })
      );
    }
  };

  return (
    <BaseModal
      title={t('loyalty.emailCollectionIncentiveModal.header')}
      onClose={onClose}
    >
      <MarketHeader>
        <MarketButton
          disabled={hasSubmitted && isEmailInvalid}
          isLoading={collectEmailIncentiveResult.isLoading}
          onClick={submit}
          rank="primary"
          slot="actions"
        >
          {t('common.join')}
        </MarketButton>
        <h2>{t('loyalty.emailCollectionIncentiveModal.header')}</h2>
      </MarketHeader>
      <p>{subtext}</p>
      <div className="market-grid-container">
        <MarketField className="market-grid-item-full">
          <MarketInputText
            onMarketInputValueChange={(e) => {
              const firstName = e.detail.value;
              setFirstName(firstName);
            }}
            value={firstName}
          >
            <label>
              {t('common.field.optional', { fieldName: t('common.firstName') })}
            </label>
          </MarketInputText>
        </MarketField>
        <MarketField className="market-grid-item-full">
          <MarketInputText
            onMarketInputValueChange={(e) => {
              const lastName = e.detail.value;
              setLastName(lastName);
            }}
            value={lastName}
          >
            <label>
              {t('common.field.optional', { fieldName: t('common.lastName') })}
            </label>
          </MarketInputText>
        </MarketField>
        <MarketField className="market-grid-item-full" invalid={isEmailInvalid}>
          <MarketInputText
            data-testid="email-collection-incentive-modal__email-input"
            onMarketInputValueChange={(e) => {
              const email = e.detail.value;
              setEmail(email);
              resetEmailValidation();
            }}
            type="email"
            value={email}
            required
          >
            <label>{t('common.email')}</label>
          </MarketInputText>
          <small slot="error">{t('common.message.validation.email')}</small>
        </MarketField>
      </div>
      <p data-testid="email-collection-incentive-modal__consent-text">
        {incentiveTermsAndStatus.emailMarketingConsentCopy?.content?.text}
      </p>
      <p
        data-testid="email-collection-incentive-modal__privacy-disclosure"
        // Consent terms may contain links as anchor tags, which should be safe
        // to render directly as it comes from a trusted backend.
        dangerouslySetInnerHTML={
          incentiveTermsAndStatus.emailMarketingConsentCopy?.content
            ?.privacyDisclosure
            ? {
                __html:
                  incentiveTermsAndStatus.emailMarketingConsentCopy?.content
                    ?.privacyDisclosure,
              }
            : undefined
        }
      ></p>
    </BaseModal>
  );
};

export default EmailCollectionIncentiveModal;
