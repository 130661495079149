/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import './common';
import '../common/time';
import '../common/merchant_type';
import './gateway';
import '../common/gateways';
import '../bank-accounts/bank_accounts';
import '../payments/attributes/model/model';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      roster: {
        nested: {
          mds: {
            nested: {
              BusinessUnit: {
                values: {
                  DEFAULT_BUSINESS_UNIT_DO_NOT_USE: 0,
                  SQUARE: 1,
                  CASH_APP: 2,
                  AFTERPAY: 3,
                },
              },
              MerchantBillingData: {
                fields: {
                  subscriptionFeatureStatus: {
                    type: 'squareup.roster.common.SubscriptionFeatureStatus',
                    id: 1,
                    rule: 'repeated',
                  },
                },
              },
              Hours: {
                fields: {
                  dayOfWeek: {
                    type: 'int32',
                    id: 1,
                    rule: 'optional',
                  },
                  openIntervals: {
                    type: 'squareup.roster.mds.Hours.Interval',
                    id: 2,
                    rule: 'repeated',
                  },
                },
                nested: {
                  Interval: {
                    fields: {
                      start: {
                        type: 'squareup.common.time.LocalTime',
                        id: 1,
                        rule: 'optional',
                      },
                      durationMinutes: {
                        type: 'int32',
                        id: 2,
                        rule: 'optional',
                      },
                    },
                  },
                },
              },
              UnitPaymentData: {
                fields: {
                  transactionFundName: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  merchantType: {
                    type: 'squareup.common.MerchantType',
                    id: 7,
                    rule: 'optional',
                  },
                  gatewayMerchantData: {
                    type: 'squareup.roster.gateway.GatewayMerchantData',
                    id: 8,
                    rule: 'repeated',
                  },
                  gatewayHints: {
                    type: 'squareup.common.gateways.GatewayName',
                    id: 9,
                    rule: 'repeated',
                  },
                  transactionMinCents: {
                    type: 'int64',
                    id: 15,
                    rule: 'optional',
                  },
                  lowRisk: {
                    type: 'bool',
                    id: 16,
                    rule: 'optional',
                  },
                  attributes: {
                    type: 'squareup.roster.mds.UnitPaymentData.AttributeTimeSeries',
                    id: 17,
                    rule: 'repeated',
                  },
                  protoSource: {
                    type: 'squareup.roster.mds.UnitPaymentData.Source',
                    id: 18,
                    rule: 'optional',
                  },
                  receiptMessage: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  linkedBankAccountToken: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  readerState: {
                    type: 'string',
                    id: 5,
                    rule: 'optional',
                  },
                  linkedBankAccountType: {
                    type: 'squareup.bank_accounts.BankAccountType',
                    id: 14,
                    rule: 'optional',
                  },
                  cpInterchangeCents: {
                    type: 'int32',
                    id: 10,
                    rule: 'optional',
                  },
                  cnpInterchangeCents: {
                    type: 'int32',
                    id: 11,
                    rule: 'optional',
                  },
                  cpDiscountBasisPoints: {
                    type: 'int32',
                    id: 12,
                    rule: 'optional',
                  },
                  cnpDiscountBasisPoints: {
                    type: 'int32',
                    id: 13,
                    rule: 'optional',
                  },
                },
                nested: {
                  TimedValue: {
                    fields: {
                      activeAtMillis: {
                        type: 'int64',
                        id: 1,
                        rule: 'optional',
                      },
                      expireAtMillis: {
                        type: 'int64',
                        id: 2,
                        rule: 'optional',
                      },
                      value: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                  },
                  AttributeTimeSeries: {
                    fields: {
                      type: {
                        type: 'squareup.payments.attributes.model.AttributeTypes.AttributeType',
                        id: 1,
                        rule: 'optional',
                      },
                      values: {
                        type: 'squareup.roster.mds.UnitPaymentData.TimedValue',
                        id: 2,
                        rule: 'repeated',
                      },
                    },
                  },
                  Source: {
                    values: {
                      ROSTER: 1,
                      PAYATTR: 2,
                    },
                  },
                },
              },
              BusinessIds: {
                fields: {
                  ieVatNumber: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  euVatNumber: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  frSiret: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  frNaf: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  frRcs: {
                    type: 'string',
                    id: 5,
                    rule: 'optional',
                  },
                  frRcsPlace: {
                    type: 'string',
                    id: 6,
                    rule: 'optional',
                  },
                  gbVatNumber: {
                    type: 'string',
                    id: 7,
                    rule: 'optional',
                  },
                  esNif: {
                    type: 'string',
                    id: 8,
                    rule: 'optional',
                  },
                  jpQiiNumber: {
                    type: 'string',
                    id: 9,
                    rule: 'optional',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.roster?.nested?.mds?.nested?.BusinessUnit &&
  !$root.nested?.squareup?.nested?.roster?.nested?.mds?.nested
    ?.MerchantBillingData &&
  !$root.nested?.squareup?.nested?.roster?.nested?.mds?.nested?.Hours &&
  !$root.nested?.squareup?.nested?.roster?.nested?.mds?.nested?.Hours?.nested
    ?.Interval &&
  !$root.nested?.squareup?.nested?.roster?.nested?.mds?.nested
    ?.UnitPaymentData &&
  !$root.nested?.squareup?.nested?.roster?.nested?.mds?.nested?.UnitPaymentData
    ?.nested?.TimedValue &&
  !$root.nested?.squareup?.nested?.roster?.nested?.mds?.nested?.UnitPaymentData
    ?.nested?.AttributeTimeSeries &&
  !$root.nested?.squareup?.nested?.roster?.nested?.mds?.nested?.UnitPaymentData
    ?.nested?.Source &&
  !$root.nested?.squareup?.nested?.roster?.nested?.mds?.nested?.BusinessIds
) {
  $root.addJSON(jsonData);
}

/**
 * BusinessUnit correspond to business units within Block. This can be used to create a different
 * product experience for different BUs, but ideally even when another business unit wants to enable
 * Square processing, we give them a full SQUARE account with their same email address.
 *
 * For now, we have the following restrictions (which might be changed later):
 * * Units have the same BU as their merchant
 * * BUs are immutable
 *
 * SOURCE BusinessUnit @ squareup/roster/mds.proto at 288:1
 */
export const BusinessUnit = $root.lookupEnum(
  'squareup.roster.mds.BusinessUnit'
).values;
/**
 * Merchant app data for application=BILLING
 *
 * SOURCE MerchantBillingData @ squareup/roster/mds.proto at 659:1
 */
export const MerchantBillingData = $root.lookupType(
  'squareup.roster.mds.MerchantBillingData'
);
fixType(MerchantBillingData);
Builder.createAndAttachToType(MerchantBillingData);
/**
 * Deprecated along with its parent, UnitHoursData. Use top-level "NormalBusinessHours" field on Unit instead
 *
 * SOURCE Hours @ squareup/roster/mds.proto at 757:1
 */
export const Hours = $root.lookupType('squareup.roster.mds.Hours');
fixType(Hours);
Builder.createAndAttachToType(Hours);
/**
 * SOURCE Interval @ squareup/roster/mds.proto at 763:3
 */
Hours.Interval = $root.lookupType('squareup.roster.mds.Hours.Interval');
fixType(Hours.Interval);
Builder.createAndAttachToType(Hours.Interval);
/**
 * Unit app data for application=PAYMENTS.
 *
 * SOURCE UnitPaymentData @ squareup/roster/mds.proto at 794:1
 */
export const UnitPaymentData = $root.lookupType(
  'squareup.roster.mds.UnitPaymentData'
);
fixType(UnitPaymentData);
Builder.createAndAttachToType(UnitPaymentData);
/**
 * Represents the value of an attribute during a particular interval in time.
 *
 * SOURCE TimedValue @ squareup/roster/mds.proto at 797:3
 */
UnitPaymentData.TimedValue = $root.lookupType(
  'squareup.roster.mds.UnitPaymentData.TimedValue'
);
fixType(UnitPaymentData.TimedValue);
Builder.createAndAttachToType(UnitPaymentData.TimedValue);
/**
 * SOURCE AttributeTimeSeries @ squareup/roster/mds.proto at 803:3
 */
UnitPaymentData.AttributeTimeSeries = $root.lookupType(
  'squareup.roster.mds.UnitPaymentData.AttributeTimeSeries'
);
fixType(UnitPaymentData.AttributeTimeSeries);
Builder.createAndAttachToType(UnitPaymentData.AttributeTimeSeries);
/**
 * SOURCE Source @ squareup/roster/mds.proto at 808:3
 */
UnitPaymentData.Source = $root.lookupEnum(
  'squareup.roster.mds.UnitPaymentData.Source'
).values;
/**
 * SOURCE BusinessIds @ squareup/roster/mds.proto at 2158:1
 */
export const BusinessIds = $root.lookupType('squareup.roster.mds.BusinessIds');
fixType(BusinessIds);
Builder.createAndAttachToType(BusinessIds);
