/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      simple_instrument_store: {
        nested: {
          ExpirationDate: {
            fields: {
              year: {
                type: 'uint32',
                id: 1,
                rule: 'optional',
              },
              month: {
                type: 'uint32',
                id: 2,
                rule: 'optional',
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.simple_instrument_store?.nested
    ?.ExpirationDate
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE ExpirationDate @ squareup/simple_instrument_store/model.proto at 315:1
 */
export const ExpirationDate = $root.lookupType(
  'squareup.simple_instrument_store.ExpirationDate'
);
fixType(ExpirationDate, {
  year: { required: true },
  month: { required: true },
});
Builder.createAndAttachToType(ExpirationDate);
