import React from 'react';
import { MarketContentCard, MarketList, MarketRow } from '@market/react';
import { Tier } from 'routes/merchant-scoped-portal/integrations/loyalty/models/Tier';
import { LoyaltyProgram } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-program';
import { MembershipProgram } from 'rpc/model/squareup/memberships/model/membership-program';
import TierDetailsCard, {
  TierDetailsWrapper,
} from 'routes/merchant-scoped-portal/integrations/loyalty/components/TierDetailsCard';
import { useTranslation } from 'react-i18next';
import { getProgramDescription } from 'routes/merchant-scoped-portal/integrations/loyalty/utils/program';
import { MemberStatus } from 'routes/merchant-scoped-portal/integrations/loyalty/models/MemberStatus';

interface TierProgramDetailsProps {
  loyaltyProgram: LoyaltyProgram;
  membershipProgram: MembershipProgram;
  memberStatus?: MemberStatus;
  isCollapsible?: boolean;
}

const TierProgramDetails: React.FC<TierProgramDetailsProps> = ({
  loyaltyProgram,
  membershipProgram,
  memberStatus,
  isCollapsible = true,
}) => {
  const { t } = useTranslation();
  const baseTierName = t('loyalty.common.baseTierName');

  return (
    <MarketList
      aria-label={t('loyalty.tiers.list.label')}
      data-testid="loyalty__tier-program-details__container"
    >
      {/* Base tier (Loyalty Program) card */}
      <MarketContentCard role="option" className="py-0 mb-4">
        {TierDetailsWrapper(
          isCollapsible,
          baseTierName,
          baseTierName,
          memberStatus?.isBaseTier ?? false,
          <MarketRow>
            <label slot="label">{t('loyalty.tiers.benefits.label')}</label>
            <div slot="subtext">{getProgramDescription(loyaltyProgram)}</div>
          </MarketRow>
        )}
      </MarketContentCard>
      {membershipProgram.tieredData?.tiers.map((tier) => (
        <TierDetailsCard
          key={tier.uid}
          tier={Tier.fromProto(tier, loyaltyProgram.terminology)}
          isCollapsible={isCollapsible}
          isCurrentTier={memberStatus?.currentTierUid === tier.uid}
        />
      ))}
    </MarketList>
  );
};

export default TierProgramDetails;
