import { MarketRow, MarketSelect, MarketList } from '@market/react';
import React, { useState, useEffect } from 'react';
import {
  CardPreference as ModelCardPreference,
  Product,
  Type,
  Action,
} from 'routes/profile/models/CardPreference';
import { Identifier, IdentifierType } from 'routes/profile/models/Identifier';
import { useUpdateCardPreferenceMutation } from 'store/query/api-extensions/receiptAndMarketingPreferences';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { selectBuyer } from 'store/buyerSlice';
import { openToast, ToastVariant } from 'store/toastSlice';
import { PlusIconV2 } from 'svgs';
import { ModalType, openModal } from 'store/modalSlice';
import { useTranslation } from 'react-i18next';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import {
  actionAddEmailProperties,
  actionUpdateLinkedEmailProperties,
  actionUpdateLinkedPhoneProperties,
} from 'services/tracking/cdp/events/receiptSettings';

const NONE = 'None';
const NEW_EMAIL_TOKEN = 'NEW_EMAIL_TOKEN';

type ReceiptDeliveryOptionsProps = {
  isRetrievePrefsLoading: boolean;
  cardPreferences: ModelCardPreference[];
  preferenceCardId: string;
};

const ReceiptDeliveryOptions: React.FC<ReceiptDeliveryOptionsProps> = ({
  isRetrievePrefsLoading,
  cardPreferences,
  preferenceCardId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector((appState: AppState) => selectBuyer(appState));
  const emailIdentifiers = profile.verifiedIdentifiers.filter(
    (identifier) => identifier.identifierType === IdentifierType.Email
  );
  const phoneIdentifiers = profile.verifiedIdentifiers.filter(
    (identifier) => identifier.identifierType === IdentifierType.Phone
  );

  const emailPreference = cardPreferences.find(
    (pref) => pref.identifier.identifierType === IdentifierType.Email
  );
  const phonePreference = cardPreferences.find(
    (pref) => pref.identifier.identifierType === IdentifierType.Phone
  );
  const [selectedEmailIdentifierToken, setSelectedEmailIdentifierToken] =
    useState(emailPreference?.identifier.token || NONE);

  useEffect(() => {
    setSelectedEmailIdentifierToken(emailPreference?.identifier.token || NONE);
  }, [emailPreference]);

  const [updateCardPreference, updateCardPreferenceResult] =
    useUpdateCardPreferenceMutation();

  // NOTE: We deliberately execute the unlink and link email callbacks at different points in the flow.
  // The unlink callback is executed before we update the card pref because that callback (updateEmailPref)
  // requires that the card and email still be linked.
  // The "change email from one non-NONE to another" callback is executed after the BE link
  // because we want to wait to update the FE until after the backend state has updated.
  const onIdentifierChange = async (
    newSelectedOption: HTMLMarketRowElement,
    newIdentifierToken: string,
    identifierType: IdentifierType
  ) => {
    // MARKET ODDITY: 2 events being emitted for each selection change
    // First one contains the deselected value, second contains the newly selected value
    // We ignore the former
    // Slack: https://square.slack.com/archives/CTU91JFU0/p1693946123163909
    if (!newSelectedOption) {
      return;
    }

    let newIdentifierValue: Identifier;

    if (newIdentifierToken === NEW_EMAIL_TOKEN) {
      buyerportalCdpClient.track(
        'Add Email',
        actionAddEmailProperties(preferenceCardId)
      );

      dispatch(
        openModal({
          type: ModalType.AddContactInfo,
        })
      );
      // NOTE: this is a hack to not make the current value of the select
      // as "add new email"
      const prevSelectedEmailIdentifierToken = selectedEmailIdentifierToken;
      setSelectedEmailIdentifierToken('');
      setSelectedEmailIdentifierToken(prevSelectedEmailIdentifierToken);
      return;
    } else if (newIdentifierToken === NONE) {
      // newIdentifierToken === NONE i.e. unlinking
      // we need to pass the current identifier if we're unlinking
      newIdentifierValue =
        identifierType === IdentifierType.Phone
          ? phonePreference!.identifier
          : emailPreference!.identifier;
    } else {
      // we need to pass the new identifier if we're linking
      newIdentifierValue = [...emailIdentifiers, ...phoneIdentifiers].find(
        (identifier) => identifier.token === newIdentifierToken
      )!;
    }

    const cardPreference = new ModelCardPreference({
      product: Product.Receipt,
      type: Type.Identifier,
      identifier: newIdentifierValue,
    });

    // We're using 'None' instead of undefined because we're checking for undefined to get around a Market firing twice onChange bug.
    const action = newIdentifierToken === NONE ? Action.Unlink : Action.Link;

    await updateCardPreference({
      action,
      cardPreference,
      preferenceCardId,
    })
      .unwrap()
      .then(() => {
        if (identifierType === IdentifierType.Email) {
          buyerportalCdpClient.track(
            'Update Linked Email',
            actionUpdateLinkedEmailProperties(
              newIdentifierToken,
              preferenceCardId
            )
          );
        } else if (identifierType === IdentifierType.Phone) {
          buyerportalCdpClient.track(
            'Update Linked Phone',
            actionUpdateLinkedPhoneProperties(
              newIdentifierToken,
              preferenceCardId
            )
          );
        }

        dispatch(
          openToast({
            message: t('common.changeSuccess'),
            variant: ToastVariant.Success,
          })
        );
      })
      .catch(() => {
        dispatch(
          openToast({
            message: t('common.somethingWentWrong.retryable.direct'),
            variant: ToastVariant.Critical,
          })
        );
      });
  };

  return (
    <>
      <h2 className={'heading-20 mt-8 mb-0'}>
        {t('profile.receiptSettings.receiptDeliveryOptions.title')}
      </h2>
      <div className="my-4">
        <p>{t('profile.receiptSettings.receiptDeliveryOptions.subtitle')}</p>
      </div>
      <MarketSelect
        onMarketSelectValueDidChange={(event) => {
          onIdentifierChange(
            event.detail.newSelectedOption,
            event.detail.value as string,
            IdentifierType.Email
          );
        }}
        value={selectedEmailIdentifierToken}
        {...((updateCardPreferenceResult.isLoading ||
          isRetrievePrefsLoading) && { disabled: true })}
      >
        <label>{t('common.emailAddress')}</label>
        <MarketList slot="list">
          {emailIdentifiers.map((identifier) => {
            const { displayValue, token } = identifier;
            return (
              <MarketRow key={token} value={token}>
                <div data-testid="receipt-delivery-option-email-row">
                  {displayValue}
                </div>
              </MarketRow>
            );
          })}
          <MarketRow key={'no-email'} value={NONE}>
            {t('common.none')}
          </MarketRow>
          {emailIdentifiers.length === 0 && (
            <MarketRow key={'add-email'} value={NEW_EMAIL_TOKEN}>
              <div className="flex items-center">
                <div className="flex items-center">
                  <PlusIconV2 fontSize="large" color="secondary" />
                </div>
                <div className="ml-4">
                  {t('profile.receiptSettings.receiptDeliveryOptions.addEmail')}
                </div>
              </div>
            </MarketRow>
          )}
        </MarketList>
      </MarketSelect>
      <MarketSelect
        className="mt-4"
        onMarketSelectValueDidChange={(event) => {
          onIdentifierChange(
            event.detail.newSelectedOption,
            event.detail.value as string,
            IdentifierType.Phone
          );
        }}
        value={phonePreference?.identifier.token || NONE}
        {...((updateCardPreferenceResult.isLoading ||
          isRetrievePrefsLoading ||
          phoneIdentifiers.length === 0) && { disabled: true })}
      >
        <label>{t('common.phoneNumber')}</label>
        <MarketList slot="list" defaultValue={'607'}>
          {phoneIdentifiers.map((identifier) => {
            const { displayValue, token } = identifier;
            return (
              <MarketRow key={token} value={token}>
                <div data-testid="receipt-delivery-option-phone-row">
                  {displayValue}
                </div>
              </MarketRow>
            );
          })}
          <MarketRow key={'no-phone'} value={NONE}>
            {t('common.none')}
          </MarketRow>
        </MarketList>
      </MarketSelect>
    </>
  );
};

export default ReceiptDeliveryOptions;
