import { MarketEmptyState, MarketLink, MarketList } from '@market/react';
import React from 'react';
import { GiftCard as GiftCardModel } from 'routes/profile/models/GiftCard';
import GiftCardRow from './GiftCardRow';
import { useTranslation } from 'react-i18next';

type GiftCardsProps = {
  giftCards: GiftCardModel[];
};

const GiftCards: React.FC<GiftCardsProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <h3 className={'heading-20 my-0'}>
        {t('profile.paymentMethods.giftCards.title')}
      </h3>
      <p className={'paragraph-30'}>
        {t('profile.paymentMethods.giftCards.explain')}
      </p>
      {props.giftCards.length === 0 && (
        <MarketEmptyState>
          <h4 slot={'primary-text'} className={'medium-20'}>
            {t('profile.paymentMethods.giftCards.noCards')}
          </h4>
          <p slot={'secondary-text'}>
            {t('profile.paymentMethods.giftCards.supportLocalBusiness')}.{' '}
            <MarketLink
              href={'https://giveandgetlocal.com/'}
              target={'_blank'}
              role="link"
            >
              {t('profile.paymentMethods.giftCards.browse')}.
            </MarketLink>
          </p>
        </MarketEmptyState>
      )}
      <MarketList
        aria-label={t('profile.paymentMethods.giftCards.ariaLabel')}
        interactive
        role={'list'}
      >
        {props.giftCards!.map((giftCard) => {
          return <GiftCardRow key={giftCard.token} giftCard={giftCard} />;
        })}
      </MarketList>
    </>
  );
};

export default GiftCards;
