import Cookies from 'js-cookie';
import {
  ConsoleLoggerFactory,
  RpcLoggerFactory,
  SilentRequestLogger,
} from './logger';
import { RpcError } from './model/Error';
import { environment } from 'utils/environment';
import { generateEnvoyConsentHeader } from 'utils/userConsent';

function identityPromise(a) {
  return new Promise((resolve) => resolve(a));
}

function getRpcPath(method) {
  return `/services/${method.parent.fullName.slice(1)}/${method.name}`;
}

/**
 * @typedef {function(?Error, ?Uint8Array): void} RpcCallback
 */

/**
 * @template Req, Res
 * @param {function(*, string): string=} urlForMethod
 * @param {RpcLoggerFactory} loggerFactory
 * @param {function(*): Promise<*>=} errorDeserializer
 * @param {Map=} additionalHeaders
 * @returns {function(*, Uint8Array, RpcCallback): void}
 */
function baseRpc(
  urlForMethod = (method, derivedUrl) => derivedUrl,
  loggerFactory = new RpcLoggerFactory(SilentRequestLogger),
  errorDeserializer = identityPromise,
  additionalHeaders = new Map()
) {
  /**
   * @template Req, Res
   * @param {*} method
   * @param {Uint8Array} requestData
   * @param {function(?Error, ?Uint8Array): void} callback
   * @returns {Promise<Response>}
   */
  return function rpcImpl(method, requestData, callback) {
    const logger = loggerFactory.build();
    const url = urlForMethod(method, getRpcPath(method));
    const headers = new Headers();

    headers.append('Accept', 'application/x-protobuf');
    headers.append('Content-Type', 'application/x-protobuf');
    headers.append('X-CSRF-Token', Cookies.get('_js_csrf[square-buyer]'));
    additionalHeaders.forEach((value, key) => headers.append(key, value));

    const fetchRequest = new Request(url, {
      body: requestData,
      credentials: 'include',
      headers,
      method: 'POST',
    });

    logger.logRequest(method, url, requestData);

    return Promise.resolve(fetch(fetchRequest))
      .then(errorDeserializer)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => new Uint8Array(arrayBuffer))
      .then((responseData) => {
        logger.logResponse(method, url, responseData);
        callback(null, responseData);
      })
      .catch((error) => {
        const { sakeError } = error;
        // catch top level 401 unauthorized error
        if (sakeError && sakeError.code === 401) {
          window.location.assign(
            `/signin?return_to=${encodeURIComponent(window.location.href)}`
          );
        } else {
          logger.logError(method, url, error);
          callback(error, null);
        }
      });
  };
}

export default function rpc(urlForMethod) {
  return baseRpc(
    urlForMethod,
    new ConsoleLoggerFactory(!environment().isProduction),
    RpcError.fromResponse,
    new Map(Object.entries(generateEnvoyConsentHeader()))
  );
}
