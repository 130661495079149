import React, { useEffect, useState } from 'react';
import { MarketButton } from '@market/react';
import { v4 as uuidv4 } from 'uuid';
import Es2Tracker from 'services/tracking/tracker';
import { useTranslation } from 'react-i18next';
import {
  signInAddIdentifierViewEvent,
  signInRequestAddIdentifierActionEvent,
  signInCreateProfileActionEvent,
} from 'services/tracking/events/signIn';
import { IdentifierType } from 'routes/profile/models/Identifier';
import IdentifierValueInput from 'components/IdentifierValueInput';
import { isValidEmail } from 'utils/identifiers';
import BackButton from 'components/BackButton';
import {
  CreateLoginOrSignupVerificationRequest,
  ICreateLoginOrSignupVerificationRequest,
} from 'rpc/model/squareup/buyerportal/onboarding/data';
import buyerPortalClient from 'rpc/client';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import useThrottle from '../../utils/custom-react-hooks/useThrottle';
import {
  AddProfileIdentifierRequest,
  IAddProfileIdentifierRequest,
} from 'rpc/model/squareup/buyerportal/profile/verified';
import { Identifier as RpcIdentifier } from 'rpc/model/squareup/buyerportal/profile/common';
import {
  AddIdentifierNextAction,
  Name,
  SignInIdentifier,
  SignInIdentifierType,
} from './types';
import { IdentifierClaimedError } from 'models/Error';
import { createBuyerFromDraft } from 'services/buyerportal';
import { useSelector } from 'react-redux';
import { selectBoolFlag } from 'store/featureSlice';
import { AppState } from 'store';
import { BoolFlag } from 'routes/profile/models/Flags';
import { SEND_CODE_THROTTLE_DURATION } from './util/constants';
import PhoneInput from './components/PhoneInput';
import SquareBuyerPrivacyPolicy from './components/SquarePrivacyPolicy';
import nonTranslatableStrings from 'utils/nonTranslatableStrings';

interface AddIdentifierProps {
  nextAction: AddIdentifierNextAction;
  personToken?: string;
  identifier: SignInIdentifier;
  newBuyerName?: Name;
  onBack: () => void;
  onForward:
    | ((input: string) => void)
    | ((input: string, wasEmailClaimed?: boolean) => void);
  onUnexpectedError: (isCatastrophic?: boolean) => void;
}

const AddIdentifierTranslationHelpers = {
  getTitleTranslationKey: (
    isNewUser: boolean,
    identifierType: SignInIdentifierType
  ): string => {
    return `onboarding.addIdentifier.title.${
      isNewUser ? 'newUser' : 'existingUser'
    }.${identifierType === IdentifierType.Email ? 'email' : 'phone'}`;
  },
  getSubtitleTranslationKey: (
    isNewUser: boolean,
    identifierType: SignInIdentifierType
  ): string => {
    return `onboarding.addIdentifier.subtitle.${
      isNewUser ? 'newUser' : 'existingUser'
    }.${identifierType === IdentifierType.Email ? 'email' : 'phone'}`;
  },
  primaryButtonTextKey: (nextAction: AddIdentifierNextAction): string =>
    nextAction === AddIdentifierNextAction.CreateProfile
      ? 'onboarding.common.buttonText.createProfile' // Onboarding doesn't require email verification
      : 'onboarding.common.buttonText.sendCode', // Onboarding + phone, or completing profile all require verification
};

const AddIdentifier: React.FC<AddIdentifierProps> = (props) => {
  const { t } = useTranslation();

  const isIdentifierObfuscatedUnverifiedEmail =
    props.identifier.type === IdentifierType.Email &&
    Boolean(props.identifier.id);
  const [identifierValue, setIdentifierValue] = useState<string>(
    isIdentifierObfuscatedUnverifiedEmail ? '' : props.identifier.value
  );
  const [nationalNumber, setNationalNumber] = useState<string>(''); // TODO: should factor in initial identifier
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false); // This will be updated by the PhoneInput for prefilled phones
  const [isEmailValid, setIsEmailValid] = useState<boolean>(() => {
    if (
      props.identifier.type === IdentifierType.Email &&
      Boolean(props.identifier.value)
    ) {
      return isValidEmail(props.identifier.value);
    }
    return false; // Empty => invalid
  });

  const [justAttemptedInvalidSubmission, setJustAttemptedInvalidSubmission] =
    useState<boolean>(false);

  const isIdentifierValid = () => {
    if (props.identifier.type === IdentifierType.Phone) {
      return isPhoneValid;
    } else {
      return isEmailValid;
    }
  };
  const useAppSubdomain = useSelector((state: AppState) =>
    selectBoolFlag(state, BoolFlag.useAppSubdomain)
  );
  useEffect(() => {
    Es2Tracker.track(signInAddIdentifierViewEvent(props.identifier.type));
  }, [props.identifier.type]);

  const [sendCodeToExistingBuyer, isSendCodeToExistingBuyerThrottled] =
    useThrottle(async () => {
      if (!isIdentifierValid()) {
        setJustAttemptedInvalidSubmission(true);
        return;
      }

      setIsProcessing(true);

      const parameters: IAddProfileIdentifierRequest = {
        idempotencyKey: uuidv4(),
        identifierType:
          props.identifier.type === IdentifierType.Email
            ? RpcIdentifier.Type.TYPE_EMAIL
            : RpcIdentifier.Type.TYPE_PHONE,
        identifierRawValue: identifierValue,
      };

      try {
        Es2Tracker.track(
          signInRequestAddIdentifierActionEvent(props.identifier.type)
        );
        const response = await buyerPortalClient.requestAddProfileIdentifier(
          AddProfileIdentifierRequest.create(parameters)
        );
        if (response.status !== RequestStatus.STATUS_SUCCESS) {
          throw new Error();
        }
        props.onForward(identifierValue);
      } catch {
        props.onUnexpectedError();
      } finally {
        setIsProcessing(false);
      }
    }, SEND_CODE_THROTTLE_DURATION);

  const [sendCodeToNewBuyer, isSendCodeToNewBuyerThrottled] = useThrottle(
    async () => {
      if (!isIdentifierValid()) {
        setJustAttemptedInvalidSubmission(true);
        return;
      }

      setIsProcessing(true);

      const parameters: ICreateLoginOrSignupVerificationRequest = {
        idempotencyKey: uuidv4(),
        verificationCredential:
          props.identifier.type === IdentifierType.Email
            ? { email: identifierValue }
            : { phoneNumber: identifierValue },
      };

      try {
        Es2Tracker.track(
          signInRequestAddIdentifierActionEvent(props.identifier.type)
        );

        const response =
          await buyerPortalClient.createLoginOrSignupVerification(
            CreateLoginOrSignupVerificationRequest.create(parameters)
          );
        if (response.status !== RequestStatus.STATUS_SUCCESS) {
          throw new Error();
        }
        props.onForward(identifierValue);
      } catch {
        props.onUnexpectedError();
      } finally {
        setIsProcessing(false);
      }
    },
    SEND_CODE_THROTTLE_DURATION
  );

  const createBuyer = async () => {
    if (!isIdentifierValid()) {
      setJustAttemptedInvalidSubmission(true);
      return;
    }

    setIsProcessing(true);

    try {
      Es2Tracker.track(signInCreateProfileActionEvent());
      await createBuyerFromDraft(
        // NB: Using the _state_ value of identifierValue. The prop is essentially an initial suggestion
        { value: identifierValue, type: props.identifier.type },
        false, // Not using identifier ID in this case
        undefined, // No code required for new users entering email
        props.newBuyerName!
      );
      props.onForward(identifierValue, false);
    } catch (e) {
      if (e instanceof IdentifierClaimedError) {
        props.onForward(identifierValue, true);
      } else {
        props.onUnexpectedError(false);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const actionHandler =
    props.nextAction === AddIdentifierNextAction.CreateProfile
      ? createBuyer
      : props.personToken
      ? sendCodeToExistingBuyer
      : sendCodeToNewBuyer;

  let inputComponent;
  if (props.identifier.type === IdentifierType.Email) {
    inputComponent = (
      <IdentifierValueInput
        emailInputDataTestId={'email-input'}
        phoneInputDataTestId={'phone-input'}
        identifierType={IdentifierType.Email}
        identifierValue={identifierValue}
        placeholder={
          isIdentifierObfuscatedUnverifiedEmail
            ? props.identifier.value
            : undefined
        }
        isInvalid={justAttemptedInvalidSubmission}
        onEnterPressed={actionHandler}
        onIdentifierValueChanged={(value) => {
          setIdentifierValue(value);
          setIsEmailValid(isValidEmail(value));
          setJustAttemptedInvalidSubmission(false);
        }}
      />
    );
  } else {
    inputComponent = (
      <PhoneInput
        onChange={(
          isValid: boolean,
          number: string,
          nationalNumber: string
        ) => {
          setIdentifierValue(number);
          setNationalNumber(nationalNumber);
          setIsPhoneValid(isValid);
          setJustAttemptedInvalidSubmission(false);
        }}
        onEnterPressed={actionHandler}
        shouldShowValidationError={justAttemptedInvalidSubmission}
        value={identifierValue}
      />
    );
  }

  return (
    <>
      <BackButton onClick={props.onBack} />
      <h1 className={'mt-7 mb-4 heading-30'}>
        {t(
          AddIdentifierTranslationHelpers.getTitleTranslationKey(
            !props.personToken,
            props.identifier.type
          ),
          { SQUARE: nonTranslatableStrings.square }
        )}
      </h1>
      <p className={'m-0 mb-6 paragraph-30'}>
        {t(
          AddIdentifierTranslationHelpers.getSubtitleTranslationKey(
            !props.personToken,
            props.identifier.type
          ),
          { SQUARE: nonTranslatableStrings.square }
        )}
      </p>
      {inputComponent}
      <MarketButton
        rank={'primary'}
        /* Fun workaround to get disabled working: https://github.com/squareup/market/issues/1570 */
        {...((isProcessing ||
          (props.identifier.type === IdentifierType.Email &&
            !identifierValue) ||
          (props.identifier.type === IdentifierType.Phone && !nationalNumber) ||
          justAttemptedInvalidSubmission ||
          isSendCodeToExistingBuyerThrottled ||
          isSendCodeToNewBuyerThrottled) && { disabled: true })}
        onClick={actionHandler}
      >
        {t(
          AddIdentifierTranslationHelpers.primaryButtonTextKey(props.nextAction)
        )}
      </MarketButton>
      {props.nextAction === AddIdentifierNextAction.CreateProfile && (
        <p className={'text-[#71767b] paragraph-30'}>
          <SquareBuyerPrivacyPolicy useAppSubdomain={useAppSubdomain} />
        </p>
      )}
    </>
  );
};

export default AddIdentifier;
