/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      omg: {
        nested: {
          thirdparty: {
            nested: {
              JurisdictionType: {
                values: {
                  UNKNOWN_JURISDICTION_TYPE: 0,
                  CITY: 1,
                  COUNTY: 2,
                  STATE: 3,
                  DISTRICT: 4,
                  APO: 5,
                  BOROUGH: 6,
                  COUNTRY: 7,
                  FPO: 8,
                  LOCAL_IMPROVEMENT_DISTRICT: 9,
                  PARISH: 10,
                  PROVINCE: 11,
                  SPECIAL_PURPOSE_DISTRICT: 12,
                  TERRITORY: 13,
                  TOWNSHIP: 14,
                  TRADE_BLOCK: 15,
                  TRANSIT_DISTRICT: 16,
                },
              },
              Taxpayer: {
                values: {
                  UNKNOWN_TAXPAYER: 0,
                  MERCHANT: 1,
                  DOORDASH: 2,
                  UBEREATS: 3,
                  GRUBHUB: 4,
                },
              },
              ThirdPartyTaxExtension: {
                fields: {
                  impositionName: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  impositionType: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  jurisdictionName: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  jurisdictionType: {
                    type: 'squareup.omg.thirdparty.JurisdictionType',
                    id: 4,
                    rule: 'optional',
                  },
                  taxpayer: {
                    type: 'squareup.omg.thirdparty.Taxpayer',
                    id: 5,
                    rule: 'optional',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.omg?.nested?.thirdparty?.nested
    ?.JurisdictionType &&
  !$root.nested?.squareup?.nested?.omg?.nested?.thirdparty?.nested?.Taxpayer &&
  !$root.nested?.squareup?.nested?.omg?.nested?.thirdparty?.nested
    ?.ThirdPartyTaxExtension
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE JurisdictionType @ squareup/omg/thirdparty/extensions.proto at 16:1
 */
export const JurisdictionType = $root.lookupEnum(
  'squareup.omg.thirdparty.JurisdictionType'
).values;
/**
 * SOURCE Taxpayer @ squareup/omg/thirdparty/extensions.proto at 36:1
 */
export const Taxpayer = $root.lookupEnum(
  'squareup.omg.thirdparty.Taxpayer'
).values;
/**
 * SOURCE ThirdPartyTaxExtension @ squareup/omg/thirdparty/extensions.proto at 44:1
 */
export const ThirdPartyTaxExtension = $root.lookupType(
  'squareup.omg.thirdparty.ThirdPartyTaxExtension'
);
fixType(ThirdPartyTaxExtension, {
  impositionName: { required: true },
  impositionType: { required: true },
  jurisdictionName: { required: true },
  jurisdictionType: { required: true },
  taxpayer: { required: true },
});
Builder.createAndAttachToType(ThirdPartyTaxExtension);
