import React, { useState } from 'react';
import { MarketList, MarketRow, MarketSelect } from '@market/react';
import { countryCodeToPhoneMetadata } from '../countries';
import { CountryCode } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';

interface CountrySelectorProps {
  className?: string;
  disabled: boolean;
  onChange: (countryCode: CountryCode) => void;
  selectedCountryCode?: CountryCode;
}

const CountrySelector: React.FC<CountrySelectorProps> = (props) => {
  const [hack, setHack] = useState(false);
  const { t } = useTranslation();

  return (
    <MarketSelect
      onMarketSelectValueDidChange={(e) => {
        const countryCode: CountryCode = e.target.value as CountryCode;
        if (!countryCode || !(countryCode in countryCodeToPhoneMetadata)) {
          return;
        }
        props.onChange(countryCode);

        // The pain and suffering of Market!
        // It seems to render stale state (there's some race condition with React <> Web components).
        // To address this, queue another (invis/no-op) state update to trigger another render (with the latest state)
        // Slack ref: https://square.slack.com/archives/CTU91JFU0/p1661967551020749?thread_ts=1661967477.772299&cid=CTU91JFU0
        // Github issue: https://github.com/squareup/market/issues/5790
        setTimeout(() => {
          setHack(!hack);
        }, 0);
      }}
      size="medium"
      slot="select"
      aria-label={t('onboarding.ariaLabels.countrySelector.selectCountry')}
      value={
        (props.selectedCountryCode as CountryCode) ||
        countryCodeToPhoneMetadata.US
      }
      {...(props.className && { className: props.className })}
      {...(props.disabled && { disabled: true })}
    >
      <MarketList slot="list">
        {Object.entries(countryCodeToPhoneMetadata).map(
          ([abbreviation, info]) => (
            <MarketRow key={abbreviation} value={abbreviation}>
              <label className="flex items-center justify-start" slot="label">
                <div className="flex items-center mr-[8px] w-[40px] h-[40px] text-[40px]">
                  {info.flag}
                </div>
                <div>{info.internationalCallingCode}</div>
              </label>
            </MarketRow>
          )
        )}
      </MarketList>
    </MarketSelect>
  );
};

export default CountrySelector;
