import * as Sentry from '@sentry/browser';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { retrieveCashCouponLinkRedirectUrl } from 'services/buyerportal';
import Es2Tracker from 'services/tracking/tracker';
import { cashCouponLinkingBetaInterstitialViewEvent } from 'services/tracking/events';
import Errored from 'routes/cash-coupon-beta/components/Errored';
import QRCode from 'routes/cash-coupon-beta/components/QrCode';
import CashRedirect from 'routes/cash-coupon-beta/components/CashRedirect';
import SqLoading from 'components/SqLoading';

class InvalidInterstitialStateException extends Error {
  constructor(
    hasUnexpectedError: boolean,
    isLoading: boolean,
    isMobile: boolean,
    qrCodeImageUrl: string | null,
    redirectUrl: string | null
  ) {
    const message =
      'Have reached an invalid interstitial state - ' +
      `hasUnexpectedError = ${hasUnexpectedError}, ` +
      `isLoading = ${isLoading}, ` +
      `isMobile = ${isMobile}, ` +
      `qrCodeImageUrl = ${qrCodeImageUrl}, ` +
      `redirectUrl = ${redirectUrl}, `;
    super(message);

    this.name = this.constructor.name;
    this.message = message;
  }
}

export type CashCouponLinkInterstitialRouteProps = {
  cashLinkToken: string | null;
};

const Interstitial: React.FC = () => {
  const { t } = useTranslation();
  const { cashLinkToken } = useParams<CashCouponLinkInterstitialRouteProps>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState<string | null>(null);
  const [hasUnexpectedError, setHasUnexpectedError] = useState<boolean>(false);

  useEffect(() => {
    if (!cashLinkToken) {
      setHasUnexpectedError(true);
      return;
    }

    const loadUrls = async () => {
      try {
        setIsLoading(true);
        const response = await retrieveCashCouponLinkRedirectUrl(cashLinkToken);

        if (response.redirectUrl) {
          setRedirectUrl(response.redirectUrl);
        }
        if (response.qrCodeImageUrl) {
          setQrCodeImageUrl(response.qrCodeImageUrl);
        }
      } catch {
        setHasUnexpectedError(true);
      } finally {
        setIsLoading(false);
      }
    };

    loadUrls();
  }, [cashLinkToken]);

  Es2Tracker.track(cashCouponLinkingBetaInterstitialViewEvent());

  if (hasUnexpectedError) {
    return <Errored />;
  }

  if (isLoading) {
    return (
      <div
        className={
          'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
        }
      >
        <SqLoading />
      </div>
    );
  }

  if (!isMobile && qrCodeImageUrl) {
    return (
      <>
        <QRCode imageUrl={qrCodeImageUrl} />
        {/* TODO (mraasch): Run the text by design */}
        <div role="alert" className="sr-only">
          Screen reader users: Please re-open this email on a mobile client.
        </div>
      </>
    );
  }

  if (isMobile && redirectUrl) {
    return <CashRedirect url={redirectUrl} />;
  }

  // Unexpected error state
  const ex = new InvalidInterstitialStateException(
    hasUnexpectedError,
    isLoading,
    isMobile,
    qrCodeImageUrl,
    redirectUrl
  );
  Sentry.captureException(ex);

  return <span>{t('cashcouponbeta.interstitial.genericError')}</span>;
};

export default Interstitial;
