export const searchReceipts = (payments: string[]) => {
  return {
    receipts_action_detail: 'search',
    receipts_action_payments: payments,
    receipts_action_type: 'action',
  };
};

export const showReceipt = (payment: string) => {
  return {
    receipts_action_detail: 'show',
    receipts_action_payment: payment,
    receipts_action_type: 'action',
  };
};
