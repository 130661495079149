/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../../Fixes';
import Builder from '../../../../../Builder';
import '../../common/token';
import '../../common/user_attributes';
import './launch_darkly_project';
import '../../common/data_type';
import './flag_config';
import './context';
import './status';
import './success_evaluation_reason';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      feature: {
        nested: {
          relay: {
            nested: {
              flags: {
                nested: {
                  message: {
                    nested: {
                      GetFlagsRequest: {
                        fields: {
                          flagRequests: {
                            type: 'squareup.feature.relay.flags.message.GetFlagRequest',
                            id: 1,
                            rule: 'repeated',
                          },
                          userToken: {
                            type: 'squareup.feature.relay.common.Token',
                            id: 3,
                            rule: 'optional',
                          },
                          userAttributes: {
                            type: 'squareup.feature.relay.common.UserAttributes',
                            id: 4,
                            rule: 'optional',
                          },
                          projectId: {
                            type: 'string',
                            id: 2,
                          },
                          project: {
                            type: 'squareup.feature.relay.flags.message.LaunchDarklyProject',
                            id: 5,
                          },
                        },
                        nested: {
                          flag_project: {
                            oneof: ['projectId', 'project'],
                          },
                        },
                      },
                      GetFlagRequest: {
                        fields: {
                          flagName: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                          flagType: {
                            type: 'squareup.feature.relay.common.DataType',
                            id: 3,
                            rule: 'optional',
                          },
                          userToken: {
                            type: 'squareup.feature.relay.common.Token',
                            id: 4,
                            rule: 'optional',
                          },
                          userAttributes: {
                            type: 'squareup.feature.relay.common.UserAttributes',
                            id: 5,
                            rule: 'optional',
                          },
                          includeValueExpiration: {
                            type: 'bool',
                            id: 7,
                            rule: 'optional',
                          },
                          flagConfig: {
                            type: 'squareup.feature.relay.flags.message.FlagConfig',
                            id: 11,
                            rule: 'optional',
                          },
                          projectId: {
                            type: 'string',
                            id: 1,
                          },
                          project: {
                            type: 'squareup.feature.relay.flags.message.LaunchDarklyProject',
                            id: 6,
                          },
                          singleUserContext: {
                            type: 'squareup.feature.relay.flags.message.SingleUserContext',
                            id: 8,
                          },
                          multiUserContext: {
                            type: 'squareup.feature.relay.flags.message.MultiUserContext',
                            id: 9,
                          },
                          multipassSessionUserContext: {
                            type: 'squareup.feature.relay.flags.message.MultipassSessionUserContext',
                            id: 10,
                          },
                        },
                        nested: {
                          flag_project: {
                            oneof: ['projectId', 'project'],
                          },
                          composite_user_token: {
                            oneof: [
                              'singleUserContext',
                              'multiUserContext',
                              'multipassSessionUserContext',
                            ],
                          },
                        },
                      },
                      GetFlagsResponse: {
                        fields: {
                          status: {
                            type: 'squareup.feature.relay.flags.message.Status',
                            id: 1,
                            rule: 'optional',
                          },
                          flagResponses: {
                            type: 'squareup.feature.relay.flags.message.GetFlagResponse',
                            id: 2,
                            rule: 'repeated',
                          },
                        },
                      },
                      GetFlagResponse: {
                        fields: {
                          status: {
                            type: 'squareup.feature.relay.flags.message.Status',
                            id: 1,
                            rule: 'optional',
                          },
                          flagName: {
                            type: 'string',
                            id: 3,
                            rule: 'optional',
                          },
                          flagType: {
                            type: 'squareup.feature.relay.common.DataType',
                            id: 4,
                            rule: 'optional',
                          },
                          userToken: {
                            type: 'squareup.feature.relay.common.Token',
                            id: 5,
                            rule: 'optional',
                          },
                          userAttributes: {
                            type: 'squareup.feature.relay.common.UserAttributes',
                            id: 6,
                            rule: 'optional',
                          },
                          flagValueExpirationHours: {
                            type: 'int32',
                            id: 12,
                            rule: 'optional',
                          },
                          flagValueCooldownHours: {
                            type: 'int32',
                            id: 15,
                            rule: 'optional',
                          },
                          successEvaluationReason: {
                            type: 'squareup.feature.relay.flags.message.SuccessEvaluationReason',
                            id: 16,
                            rule: 'optional',
                          },
                          projectId: {
                            type: 'string',
                            id: 2,
                          },
                          project: {
                            type: 'squareup.feature.relay.flags.message.LaunchDarklyProject',
                            id: 11,
                          },
                          stringValue: {
                            type: 'string',
                            id: 7,
                          },
                          intValue: {
                            type: 'int32',
                            id: 8,
                          },
                          boolValue: {
                            type: 'bool',
                            id: 9,
                          },
                          doubleValue: {
                            type: 'double',
                            id: 10,
                          },
                          singleUserContext: {
                            type: 'squareup.feature.relay.flags.message.SingleUserContext',
                            id: 13,
                          },
                          multiUserContext: {
                            type: 'squareup.feature.relay.flags.message.MultiUserContext',
                            id: 14,
                          },
                        },
                        nested: {
                          flag_project: {
                            oneof: ['projectId', 'project'],
                          },
                          value: {
                            oneof: [
                              'stringValue',
                              'intValue',
                              'boolValue',
                              'doubleValue',
                            ],
                          },
                          user_context: {
                            oneof: ['singleUserContext', 'multiUserContext'],
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.GetFlagsRequest &&
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.GetFlagRequest &&
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.GetFlagsResponse &&
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.GetFlagResponse
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE GetFlagsRequest @ squareup/feature/relay/flags/message/get_flags.proto at 18:1
 */
export const GetFlagsRequest = $root.lookupType(
  'squareup.feature.relay.flags.message.GetFlagsRequest'
);
fixType(GetFlagsRequest, {
  flagRequests: { required: true },
});
Builder.createAndAttachToType(GetFlagsRequest);
/**
 * SOURCE GetFlagRequest @ squareup/feature/relay/flags/message/get_flags.proto at 41:1
 */
export const GetFlagRequest = $root.lookupType(
  'squareup.feature.relay.flags.message.GetFlagRequest'
);
fixType(GetFlagRequest, {
  flagName: { required: true, notEmpty: true },
});
Builder.createAndAttachToType(GetFlagRequest);
/**
 * SOURCE GetFlagsResponse @ squareup/feature/relay/flags/message/get_flags.proto at 86:1
 */
export const GetFlagsResponse = $root.lookupType(
  'squareup.feature.relay.flags.message.GetFlagsResponse'
);
fixType(GetFlagsResponse);
Builder.createAndAttachToType(GetFlagsResponse);
/**
 * SOURCE GetFlagResponse @ squareup/feature/relay/flags/message/get_flags.proto at 94:1
 */
export const GetFlagResponse = $root.lookupType(
  'squareup.feature.relay.flags.message.GetFlagResponse'
);
fixType(GetFlagResponse, {
  flagName: { required: true, notEmpty: true },
});
Builder.createAndAttachToType(GetFlagResponse);
