import { MarketAccessory, MarketRow } from '@market/react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import Call from 'svgs/market-icons/Call';
import { selectLoyaltyPhoneIdentifier } from '../loyaltySlice';
import { MerchantPortalContext } from 'routes/merchant-scoped-portal';

const LinkedPhoneNumber = () => {
  const { t } = useTranslation();
  const mpContext = useContext(MerchantPortalContext);

  const loyaltyPhoneIdentifier = useSelector((state: AppState) =>
    selectLoyaltyPhoneIdentifier(
      state,
      mpContext.merchantPortalBaseData?.buyer?.phones
    )
  );

  const renderPhoneNumber = () => {
    const phoneNumber = loyaltyPhoneIdentifier?.displayValue;
    const isPhoneNumberKnown = Boolean(phoneNumber);
    if (isPhoneNumberKnown) {
      return (
        <a href={`tel:${phoneNumber}`} className="text-black">
          {phoneNumber}
        </a>
      );
    }

    return <label slot="subtext">{t('common.unknown')}</label>;
  };

  return (
    <>
      <section>
        <MarketRow>
          <label slot="label">{t('loyalty.linkedPhoneNumber.label')}</label>
          <label slot="subtext">{t('loyalty.linkedPhoneNumber.subtext')}</label>
        </MarketRow>
      </section>
      <section>
        <MarketRow>
          <MarketAccessory slot="leading-accessory">
            <Call />
          </MarketAccessory>
          {renderPhoneNumber()}
        </MarketRow>
      </section>
    </>
  );
};

export default LinkedPhoneNumber;
