// eslint-disable-next-line filenames/match-exported
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import BackButton from 'components/BackButton';
import { MarketButton, MarketDivider, MarketHeader } from '@market/react';
import CardInfo from './CardInfo';
import Preferences from './Preferences';
import { useListCardsQuery } from 'store/query';
import ModuleLoading from 'routes/profile/common/loading/ModuleLoading';
import ModuleLoadFailed from 'routes/profile/common/errors/ModuleLoadFailed';
import PaymentCardPreview, {
  DEFAULT_PAYMENT_CARD_PREVIEW_HEIGHT,
} from 'components/profile/PaymentCardPreview';
import { openToast, ToastVariant } from 'store/toastSlice';
import Page from 'routes/profile/layout/Page';
import useCdpPageTracking from 'utils/custom-react-hooks/useCdpPageTracking';
import { AppState } from 'store';
import { selectPrimaryId } from 'store/buyerSlice';
import { IdentifierType } from 'routes/profile/models/Identifier';
import { ModalType, openModal } from 'store/modalSlice';
import { viewPaymentCardDetailsProperties } from 'services/tracking/cdp/events/paymentMethods';
import { useTranslation } from 'react-i18next';

type PaymentCardDetailsParams = {
  paymentCardId: string;
};

const SMALL_PAYMENT_CARD_PREVIEW_HEIGHT = Math.floor(
  DEFAULT_PAYMENT_CARD_PREVIEW_HEIGHT * 0.75
);

const PaymentCardDetails: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [paymentCardPreviewHeight, setPaymentCardPreviewHeight] =
    useState<number>(DEFAULT_PAYMENT_CARD_PREVIEW_HEIGHT);

  const primaryCardId = useSelector((state: AppState) =>
    selectPrimaryId(state, IdentifierType.Card)
  );

  const {
    data: cards,
    isLoading: isPaymentCardsLoading,
    isError: didPaymentCardsLoadingFail,
  } = useListCardsQuery();

  const { paymentCardId } = useParams<PaymentCardDetailsParams>();

  const handleResize = () => {
    if (window.innerWidth <= 600) {
      setPaymentCardPreviewHeight(SMALL_PAYMENT_CARD_PREVIEW_HEIGHT);
    } else {
      setPaymentCardPreviewHeight(DEFAULT_PAYMENT_CARD_PREVIEW_HEIGHT);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useCdpPageTracking({
    didLoadingFail: didPaymentCardsLoadingFail,
    isLoading: isPaymentCardsLoading,
    properties: viewPaymentCardDetailsProperties(
      paymentCardId,
      false,
      primaryCardId === paymentCardId
    ),
  });

  if (isPaymentCardsLoading) {
    return <ModuleLoading />;
  }

  if (didPaymentCardsLoadingFail) {
    return <ModuleLoadFailed />;
  }

  const paymentCard = cards?.find((card) => card.cardsApiId === paymentCardId);

  if (!paymentCard) {
    dispatch(
      openToast({
        message: t(
          'profile.paymentMethods.paymentCards.details.noLongerOnYourAccount'
        ),
        persistent: false,
        variant: ToastVariant.Info,
      })
    );

    return <Redirect to={'/paymentmethods'} />;
  }

  return (
    <Page type={'backdrop'}>
      <div className={'flex flex-col'}>
        <MarketHeader compact showNavigation className={'mb-6'}>
          <BackButton
            slot={'navigation'}
            onClick={() => history.push('/paymentmethods')}
          />
          <h1>{paymentCard.displayName}</h1>
        </MarketHeader>
        <PaymentCardPreview
          className={'self-center'}
          lastFour={paymentCard.lastFour}
          brand={paymentCard.brand}
          height={paymentCardPreviewHeight}
        />
        <CardInfo paymentCard={paymentCard} />
        <MarketDivider className={'my-8'} />
        <Preferences paymentCard={paymentCard} />
        <MarketButton
          className={'w-fit'}
          variant={'destructive'}
          onClick={() => {
            dispatch(
              openModal({
                props: {
                  paymentCard,
                },
                type: ModalType.ConfirmRemovePaymentCard,
              })
            );
          }}
        >
          {t(
            'profile.paymentMethods.paymentCards.addPaymentCardModal.removeCard'
          )}
        </MarketButton>
      </div>
    </Page>
  );
};

export default PaymentCardDetails;
