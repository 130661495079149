import dayjs from 'dayjs';
import i18n from 'i18n';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';

// Import supported locales
// https://shuttle.squareup.com/projects/buyerportal-fe/edit
import 'dayjs/locale/ca';
import 'dayjs/locale/en-au';
import 'dayjs/locale/en-ca';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/en-ie';
import 'dayjs/locale/es';
import 'dayjs/locale/es-us';
import 'dayjs/locale/fr';
import 'dayjs/locale/fr-ca';
import 'dayjs/locale/ja';

dayjs.extend(duration);
dayjs.extend(localizedFormat);

// Export for parsing
export { Dayjs, default as dayjs } from 'dayjs';

export enum DateFormat {
  // Localized formats
  // https://day.js.org/docs/en/display/format#list-of-localized-formats
  LT = 'LT', // h:mm A
  LTS = 'LTS', // h:mm:ss A
  L = 'L', // MM/DD/YYYY
  LL = 'LL', // MMMM D, YYYY
  LLL = 'LLL', // MMMM D, YYYY h:mm A
  LLLL = 'LLLL', // dddd, MMMM D, YYYY h:mm A
  l = 'l', // M/D/YYYY
  ll = 'll', // MMM D, YYYY
  lll = 'lll', // MMM D, YYYY h:mm A
  llll = 'llll', // ddd, MMM D, YYYY h:mm A

  MonthDayCompact = 'MM/DD',
  MonthDayShort = 'MMM D',
  MonthDayFull = 'MMMM D',
}

export function formatDate(date: Date, format: string) {
  return dayjs(date).format(format);
}

function setLocaleGlobal(locale: string) {
  dayjs.locale(locale);
}

(function bindToI18nLanguage() {
  i18n.on('initialized', () => {
    setLocaleGlobal(i18n.language);
  });

  i18n.on('languageChanged', () => {
    setLocaleGlobal(i18n.language);
  });
})();

/**
 * Detects the date format used in a given locale.
 * Determines if the format is MM/DD/YYYY (US) or DD/MM/YYYY (most other regions).
 *
 * @param {string} locale - The user's locale (e.g., "en-US", "en-GB", "en-AU").
 * @returns {'MM/DD/YYYY' | 'DD/MM/YYYY'} - The detected date format.
 */
export function detectDateFormat(locale: string): 'MM/DD/YYYY' | 'DD/MM/YYYY' {
  // Use a fixed known date (15th May 2000) to determine the format
  const testDate = new Date(2000, 4, 15); // May 15, 2000 (Month is 0-based)

  // Format the test date
  const formattedDate = new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(testDate);

  // Extract positions of day (15) and month (05)
  const dayIndex = formattedDate.indexOf('15');
  const monthIndex = formattedDate.indexOf('05');

  return monthIndex < dayIndex ? 'MM/DD/YYYY' : 'DD/MM/YYYY';
}

/**
 * Parses a user-provided date string into a JavaScript Date object.
 * Uses the user's locale to determine whether the input is MM/DD/YYYY or DD/MM/YYYY.
 *
 * @param {string} input - The date string entered by the user.
 * @returns {Date} - A JavaScript Date object representing the parsed date.
 * @throws {Error} - Throws an error if the input is not in a valid format.
 */
export function parseUserDate(input: string): Date {
  const locale = navigator.language;
  const format = detectDateFormat(locale);

  // Match common date formats (supports "/", ".", "-")
  const datePattern = /^(\d{1,2})[./-](\d{1,2})[./-](\d{4})$/;
  const match = input.trim().match(datePattern);

  if (!match) {
    throw new Error('Invalid date format');
  }

  const [, part1, part2, year] = match;
  let day: number, month: number;

  if (format === 'MM/DD/YYYY') {
    month = Number.parseInt(part1, 10) - 1;
    day = Number.parseInt(part2, 10);
  } else {
    day = Number.parseInt(part1, 10);
    month = Number.parseInt(part2, 10) - 1;
  }

  return new Date(Number.parseInt(year, 10), month, day);
}

/**
 * Formats a JavaScript Date object into a localized date string.
 * Uses the user's locale to determine the appropriate date format.
 *
 * @param {Date} date - The JavaScript Date object to format.
 * @returns {string} - A localized date string (e.g., "12/31/2024" in en-US, "31/12/2024" in en-GB).
 */
export function formatLocalDate(date: Date): string {
  const locale = navigator.language || 'en-US'; // Get user's locale
  return new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(date);
}
