import {
  MarketAccessory,
  MarketDropdown,
  MarketList,
  MarketPill,
  MarketPopover,
  MarketRow,
} from '@market/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAddresses, selectPrimaryAddressId } from 'store/buyerSlice';
import { Address, AddressFormatters } from 'routes/profile/models/Address';
import {
  useDeleteAddressMutation,
  useUpdatePrimaryAddressIdMutation,
} from 'store/query';
import { openToast, ToastVariant } from 'store/toastSlice';
import EllipsisHorizontal from 'svgs/market-icons/EllipsisHorizontal';
import Home from 'svgs/market-icons/Home';
import { theme } from 'tailwind.config';
import { useTranslation } from 'react-i18next';

type ShippingAddressProps = {
  address: Address;
  isDefault: boolean;
  hasMultiple: boolean;
};

const ShippingAddress: React.FC<ShippingAddressProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [updatePrimaryAddressId] = useUpdatePrimaryAddressIdMutation();
  const [deleteAddress] = useDeleteAddressMutation();

  const setAsPrimary = async (id: string) => {
    await updatePrimaryAddressId(id)
      .unwrap()
      .then(() => {
        dispatch(
          openToast({
            message: t('common.changeSuccess'),
            variant: ToastVariant.Success,
          })
        );
      })
      .catch(() => {
        dispatch(
          openToast({
            message: t('common.somethingWentWrong.retryable.direct'),
            variant: ToastVariant.Critical,
          })
        );
      });
  };

  const removeAddress = async (id: string) => {
    await deleteAddress(id)
      .unwrap()
      .then(() => {
        dispatch(
          openToast({
            message: t('profile.account.shippingAddresses.addressRemoved'),
            variant: ToastVariant.Success,
          })
        );
      })
      .catch(() => {
        dispatch(
          openToast({
            message: t('common.somethingWentWrong.retryable.direct'),
            variant: ToastVariant.Critical,
          })
        );
      });
  };

  const formattedAddress = AddressFormatters.formatSingleLineAddress(
    props.address
  );

  return (
    <div className={'flex grow items-center'} data-testid={'address'}>
      <MarketAccessory size={'icon'} slot={'icon'}>
        <Home />
      </MarketAccessory>
      <span className={'font-medium ml-4'}>{formattedAddress}</span>
      <div className={'ml-auto flex items-center'}>
        {props.isDefault && props.hasMultiple && (
          <MarketPill className={'mr-4'}>{t('common.default')}</MarketPill>
        )}
        <MarketDropdown popoverPlacement={'bottom-start'} tabIndex={0}>
          <MarketAccessory
            aria-label={t('profile.account.addresses.moreActions.ariaLabel', {
              address: formattedAddress,
            })}
            size={'icon'}
            slot={'trigger'}
            role={'button'}
          >
            <EllipsisHorizontal fill={theme.extend.colors.grey} />
          </MarketAccessory>
          <MarketPopover slot={'popover'}>
            <MarketList interactive transient>
              {!props.isDefault && props.hasMultiple && (
                <MarketRow
                  className={'medium-30'}
                  onClick={() => setAsPrimary(props.address.id)}
                >
                  {t('common.setAsDefault')}
                </MarketRow>
              )}
              <MarketRow
                className={'medium-30 text-criticalRed'}
                onClick={() => removeAddress(props.address.id)}
              >
                {t('common.remove')}
              </MarketRow>
            </MarketList>
          </MarketPopover>
        </MarketDropdown>
      </div>
    </div>
  );
};

const ShippingAddresses: React.FC = () => {
  const addresses = useSelector(selectAddresses);
  const primaryAddressId = useSelector(selectPrimaryAddressId);
  const { t } = useTranslation();

  return (
    <>
      <div className={'flex grow items-center justify-between mb-4'}>
        <h3 className={'heading-20 my-0'}>
          {t('profile.account.shippingAddresses.title')}
        </h3>
      </div>
      <MarketList data-testid={'address-list'}>
        {addresses.map((address) => {
          return (
            <MarketRow key={`address-${address.id}`}>
              <ShippingAddress
                address={address}
                isDefault={address.id === primaryAddressId}
                hasMultiple={addresses.length > 1}
              />
            </MarketRow>
          );
        })}
      </MarketList>
    </>
  );
};

export default ShippingAddresses;
