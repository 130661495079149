import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MarketButton, MarketLink } from '@market/react';
import Globe from 'svgs/market-icons/Globe';
import Store from 'svgs/market-icons/Store';
import classNames from 'classnames';
import { ModalType, openModal } from 'store/modalSlice';
import { useTranslation } from 'react-i18next';
import { normalizeUrl } from 'utils/url';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import {
  ClickLinkEvent,
  EventName,
  FeatureFormat,
  FeatureID,
  FeatureName,
} from 'services/tracking/cdp/events/types';

export type AboutUsProps = {
  businessDescription?: string;
  websiteUrl?: string;
};

const AboutUs: React.FC<AboutUsProps> = ({
  businessDescription,
  websiteUrl,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // If the business bio is longer than 3 lines, show a "view more" / "view less" button.
  // Measure the total height of the div, and compare it to the height of 3 lines of text.
  const [hasLongBio, setHasLongBio] = useState(false);
  const businessDescriptionRef = useRef<HTMLParagraphElement>(null);
  useEffect(() => {
    const element = businessDescriptionRef.current!;
    const divHeight = element.clientHeight;
    const lineHeight = Number.parseInt(
      window.getComputedStyle(element).getPropertyValue('line-height'),
      10
    );
    setHasLongBio(divHeight > lineHeight * 3);
  }, []);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const paragraphClass = classNames('mt-0 mb-6 paragraph-30 text-black/60', {
    'line-clamp-3': hasLongBio && !isExpanded,
  });

  const openLocationModal = () => {
    dispatch(
      openModal({
        type: ModalType.LocationList,
      })
    );
  };

  const normalizedWebsiteUrl = normalizeUrl(websiteUrl);

  const handleWebsiteClick = (
    translatedButtonText: string,
    shopUrl: string
  ) => {
    const clickMerchantWebsiteLinkEvent: ClickLinkEvent = {
      feature_name: FeatureName.MerchantOverviewPage,
      feature_id: FeatureID.MerchantOverviewPage,
      feature_format: FeatureFormat.BUTTON,
      event_description: 'click visit online shop',
      link_text: translatedButtonText,
      link_title: 'Visit shop',
      link_url: shopUrl,
    };
    buyerportalCdpClient.track(
      EventName.CLICK_LINK,
      clickMerchantWebsiteLinkEvent
    );
  };

  return (
    <div>
      <h2 className="heading-20 mt-0 mb-2">{t('merchantPortal.aboutUs')}</h2>
      <p
        className={paragraphClass}
        data-testid={isExpanded ? 'expanded-text' : 'collapsed-text'}
        ref={businessDescriptionRef}
      >
        {businessDescription || t('merchantPortal.defaultBusinessDescription')}
      </p>
      {hasLongBio && (
        <div className="mb-10" data-testid="bio-expand-button">
          <MarketLink onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded
              ? t('merchantPortal.viewLess')
              : t('merchantPortal.viewMore')}
          </MarketLink>
        </div>
      )}
      <div className="flex justify-between md:justify-start gap-4">
        {normalizedWebsiteUrl && (
          <MarketButton
            className="grow md:grow-0"
            rank="primary"
            onClick={() =>
              handleWebsiteClick(
                t('merchantPortal.visitShop'),
                normalizedWebsiteUrl
              )
            }
            href={normalizedWebsiteUrl}
            target="_blank"
          >
            <Globe />
            {t('merchantPortal.visitShop')}
          </MarketButton>
        )}
        <MarketButton
          className={websiteUrl ? 'grow md:grow-0' : ''}
          rank="secondary"
          onClick={openLocationModal}
        >
          <Store width={20} height={16} />
          {t('merchantPortal.viewLocations')}
        </MarketButton>
      </div>
    </div>
  );
};

export default AboutUs;
