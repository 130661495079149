import SqOneTrust, {
  ALERT_BOX_CLOSED_COOKIE_KEY,
  COOKIE_GROUP_KEYS,
  CookieGroupKeyValue,
  X_ALLOW_COOKIES,
  X_BLOCK_COOKIES,
  X_BLOCK_COOKIES_DEFAULT_VALUE,
} from '@square/one-trust';
import Cookies from 'js-cookie';

export {
  COOKIE_GROUP_KEYS,
  type CookieGroupKeyValue,
  CONSENT_GROUP_COOKIE_SUBSTRING,
  CONSENT_COOKIE_KEY,
  ALERT_BOX_CLOSED_COOKIE_KEY,
} from '@square/one-trust';

declare global {
  /**
   * The global instance of SqOneTrust, Square's utility
   * for determining user consent for various cookie types.
   * @see {@link https://github.com/squareup/sq-one-trust}
   */
  let SqOneTrust: SqOneTrust | undefined;

  /**
   * Callback function invoked by OneTrust to signal when user consent settings
   * have been updated.
   */
  function OptanonWrapper(): void;

  /**
   * The global OneTrust instance set up by the OneTrust
   * script.
   */
  interface OneTrust {
    ToggleInfoDisplay: () => null;
  }
}

export const DEFAULT_CONSENT_UPDATE_EVENT_NAME =
  'userConsentPreferencesChanged';

/**
 * This value should be set by the initializeUserConsent function.
 * If this value is `""`, someone forgot to call that function.
 */
let consentEventName = '';

export interface UserConsentConfiguration {
  /**
   * Determines whether this application is in charge
   * of emitting events when user consent preferences
   * change, or if it listens for events coming from
   * somewhere else (e.g. a micro-frontend's host application).
   *
   * If set to 'emitter', will set up global event emitters and listeners
   * for user preference change events.
   *
   * @default 'emitter'
   */
  mode?: 'emitter' | 'listener';

  /**
   * The name of the event that is dispatched if user consent preferences change.
   *
   * If creating a micro-frontend application, set this value to the name
   * of events emitted by your application's host.
   * Dashboard's is 'dashboardSqOneTrustInstanceChangedEvent'.
   *
   * @default 'userConsentPreferencesChanged'
   */
  consentEventName?: string;
}

/**
 * Initialize user consent management.
 */
export function initializeUserConsent(options: UserConsentConfiguration = {}) {
  const {
    mode = 'emitter',
    consentEventName:
      userDefinedConsentEventName = DEFAULT_CONSENT_UPDATE_EVENT_NAME,
  } = options;

  consentEventName = userDefinedConsentEventName;
  const shouldEmitEvents = mode === 'emitter';

  if (shouldEmitEvents) {
    globalThis.OptanonWrapper = function () {
      updateUserConsentPreferences({ shouldEmitEvents });
    };

    // Initialize things in the event that OneTrust either failed to load
    // or has already fired the OptanonWrapper.
    updateUserConsentPreferences({ isInitialUpdate: true, shouldEmitEvents });
  }
}

/**
 * Displays a modal that allows users to update their cookie preferences
 */
export function toggleShowConsentSelectionModal(): void {
  globalThis.OneTrust?.ToggleInfoDisplay();
}

/**
 * Determine whether a user has consented to a particular category of
 * functionality.
 *
 * In the event that OneTrust has failed to load, only strictly necessary
 * functionality is allowed.
 */
export function hasConsent(consentCategory: CookieGroupKeyValue): boolean {
  if (consentCategory === COOKIE_GROUP_KEYS.strictlyNecessary) {
    // We always allow strictly necessary behavior.
    return true;
  }

  if (!hasConsentBeenSet()) {
    return false;
  }

  // a value of '1' represents that consent has been given.
  return globalThis.SqOneTrust?.groupConsentHash[consentCategory] === '1';
}

/**
 * Executes the provided callback on the first event where a user
 * has consented to the configured consent type.
 *
 * @example
 * ```js
 * onFirstConsent(someConsentType, () => {
 *   initializeUserTrackingLibrary();
 * })
 * ```
 */
export function onFirstConsent(
  consentCategory: CookieGroupKeyValue,
  callback: () => void
) {
  if (hasConsent(consentCategory)) {
    callback();
    return;
  }

  function executeCallbackOnConsent() {
    if (hasConsent(consentCategory)) {
      callback();
      document.removeEventListener(consentEventName, executeCallbackOnConsent);
    }
  }

  document.addEventListener(consentEventName, executeCallbackOnConsent);
}

/**
 * Executes the provided callback once the user has rejected the provided
 * consent level.
 */
export function onFirstRejection(
  consentLevel: CookieGroupKeyValue,
  callback: () => void
): void {
  if (!hasConsent(consentLevel) && hasConsentBeenSet()) {
    callback();
    return;
  }

  function executeCallbackIfConsentRejected() {
    if (!hasConsent(consentLevel) && hasConsentBeenSet()) {
      callback();

      document.removeEventListener(
        consentEventName,
        executeCallbackIfConsentRejected
      );
    }
  }

  document.addEventListener(consentEventName, executeCallbackIfConsentRejected);
}

function updateUserConsentPreferences({
  shouldEmitEvents,
  isInitialUpdate,
}: {
  shouldEmitEvents: boolean;
  isInitialUpdate?: boolean;
}) {
  if (!consentEventName) {
    throw new Error(
      'A user consent event name was not specified. Ensure you have called `initializeUserConsent`.'
    );
  }

  // Only manage the global SqOneTrust instance if this application is
  // responsible for handling global consent preferences.
  if (shouldEmitEvents) {
    // SqOneTrust is initialized outside of the OptanonWrapper to allow
    // us to configure the SDK in the event that OneTrust loading fails and
    // OptanonWrapper is never called.
    globalThis.SqOneTrust = new SqOneTrust();

    if (isInitialUpdate && !isOneTrustLoaded() && !isConsentCookieValid()) {
      globalThis.SqOneTrust.groupConsentHash = {
        [COOKIE_GROUP_KEYS.strictlyNecessary]: '1',
        [COOKIE_GROUP_KEYS.performanceAndAnalytics]: '0',
        [COOKIE_GROUP_KEYS.functionality]: '0',
        [COOKIE_GROUP_KEYS.retargetingOrAdvertising]: '0',
      };
    }

    ensureAlertBoxCookieExists();

    if (globalThis.document) {
      document.dispatchEvent(new Event(consentEventName));
    }
  }
}

export type EnvoyConsentHeader = {
  [X_BLOCK_COOKIES]?: string;
  [X_ALLOW_COOKIES]?: string;
};

/**
 * Builds a request headers object that informs downstream services
 * of consents given by a user. This allows services to set
 * cookies appropriately on responses.
 */
export function generateEnvoyConsentHeader(): EnvoyConsentHeader {
  const envoyConsentDefaultHeader = {
    [X_BLOCK_COOKIES]: X_BLOCK_COOKIES_DEFAULT_VALUE,
  };

  // If the onetrust script ran, oneTrustActivityGroups will always be populated with C0001
  const activeGroups = getOneTrustActiveGroups();
  if (hasConsentBeenSet() && activeGroups) {
    // X-Block-Cookies is always included to inform server that we need to filter cookies based
    // on X-Allow-Cookies categories.
    return {
      ...envoyConsentDefaultHeader,
      [X_ALLOW_COOKIES]: activeGroups,
    };
  }

  return envoyConsentDefaultHeader;
}

function hasConsentBeenSet(): boolean {
  const noConsentFound = !isOneTrustLoaded();
  // Check to see if the user was auto consented (US/CA)
  const hasAutoConsented = isUserAutoConsented();
  // Finally, if neither of those are true, and there is an `OptanonAlertBoxClosed` cookie, the merchant has consented through the modal.
  const hasValidManualConsent = isConsentCookieValid();

  return Boolean(noConsentFound || hasAutoConsented || hasValidManualConsent);
}

/**
 * Get the current active groups. Will only be unset if the OneTrust script has not run.
 */
export function getOneTrustActiveGroups(): string {
  return globalThis.OnetrustActiveGroups;
}

function isUserAutoConsented() {
  // If the merchant has anything other than strictly necessary
  // cookies, and have no existing OptanonAlertBoxClosed cookie,
  // they have been auto-consented (US/CA).
  return (
    /C000[^1]/.test(getOneTrustActiveGroups()) &&
    !document.cookie.includes(ALERT_BOX_CLOSED_COOKIE_KEY)
  );
}

/**
 * If the value of window.OnetrustActiveGroups is undefined,
 * we know that the OneTrust script failed to load.
 */
function isOneTrustLoaded(): boolean {
  return Boolean(getOneTrustActiveGroups());
}

/**
 * Check if the consent cookie exists and is set to a valid date of less than a year ago.
 */
function isConsentCookieValid(): boolean {
  const alertCookieDate = Cookies.get(ALERT_BOX_CLOSED_COOKIE_KEY);
  if (!alertCookieDate) {
    return false;
  }

  const alertInteractionDate = new Date(alertCookieDate);

  if (Number.isNaN(alertInteractionDate.getDate())) {
    return false;
  }

  const currentDate = new Date();
  const diff = currentDate.getTime() - alertInteractionDate.getTime();
  const yearDiff = diff / (1000 * 60 * 60 * 24 * 365);

  return yearDiff < 1;
}

/**
 * Certain browser extensions such as "I don't care about cookies" try to circumvent OneTrust.
 * They will force the OneTrust modal to hide and set the cookie manually.
 * This causes issues as the alert cookie is how we know a
 * merchant has interacted with the modal.
 *
 * This will set that cookie manually to avoid this issue.
 */
function ensureAlertBoxCookieExists() {
  const existingCookie = Cookies.get(ALERT_BOX_CLOSED_COOKIE_KEY);
  if (existingCookie) {
    return;
  }

  const oneTrustSdkElement = document.getElementById('onetrust-consent-sdk');
  const isHidden = oneTrustSdkElement
    ? window.getComputedStyle(oneTrustSdkElement).visibility === 'hidden'
    : false;

  if (isHidden) {
    Cookies.set(ALERT_BOX_CLOSED_COOKIE_KEY, new Date().toISOString());
  }
}
