import '@market/web-components/dist/market/market.css';
import App from 'App';
import 'i18n';
import 'index.scss';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from 'serviceWorker';
import { configureStoreWithState } from 'store';
import 'services/tracking/tracker';
import {
  initDataDogRum,
  initSentry,
  setupMarketPolyfills,
} from 'utils/initTasks';
import { getOrSetSavt } from 'utils/cookies';
import {
  CreateDefaultBoolFlagConfig,
  PartialFlags,
} from 'routes/profile/models/Flags';
import {
  FeatureState,
  fetchAnonymousFeaturesNoStore,
} from 'store/featureSlice';
import * as Sentry from '@sentry/react';
import GlobalLoader from 'routes/profile/common/loading/GlobalLoader';
import { initializeUserConsent } from 'utils/userConsent';

initializeUserConsent();

// init savt
const savt = getOrSetSavt();
// init tasks that don't require FF input
setupMarketPolyfills();
initSentry(savt);

const Root = () => {
  const [loading, setLoading] = React.useState(true);
  const [anonymousFeatureFlags, setAnonymousFeatureFlags] = React.useState<
    PartialFlags | undefined
  >(undefined);

  useEffect(() => {
    const initialize = async () => {
      let flags = undefined; // holds ref to feature flags outside of react state
      try {
        flags = await fetchAnonymousFeaturesNoStore();
        setAnonymousFeatureFlags(flags);
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        initDataDogRum(
          flags?.boolean['crm-track-square-profile-views-manually'] || false
        );
        setLoading(false);
      }
    };
    initialize();
  }, []);

  if (loading) {
    return <GlobalLoader />;
  }

  const anonFlagsLoadedFeatureState: FeatureState = {
    areAnonymousFlagsLoaded: true,
    flags: {
      boolean: {
        ...CreateDefaultBoolFlagConfig(),
        ...anonymousFeatureFlags?.boolean,
      },
    },
  };

  const store = configureStoreWithState({
    feature: anonFlagsLoadedFeatureState,
  });
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
