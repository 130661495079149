import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MarketButton,
  MarketField,
  MarketInputText,
  MarketToast,
} from '@market/react';
import BackButton from './components/common/BackButton';
import SecuredBySquare from './components/common/SecuredBySquare';
import { useParams } from 'react-router-dom';
import { CashLinkingProcessRouteProps } from 'routes/cash-linking';
import { getIdentifier } from './utils/CashLinkInfoHelper';
import { CashLinkInfo } from 'rpc/model/squareup/buyerportal/cashlink/data';
import { Identifier } from 'rpc/model/squareup/buyerportal/profile/common';
import {
  requestPhoneVerification,
  checkCashVerification,
} from 'services/buyerportal';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import Es2Tracker from 'services/tracking/tracker';
import {
  cashVerifyPhoneActionEvent,
  cashVerifyPhoneViewEvent,
} from 'services/tracking/events';

type VerifyPhoneProps = {
  cashLinkInfo: CashLinkInfo;
  backStep: Function;
  nextStep: (url: string) => void;
  phoneNumber: string | null;
  phoneId: string | null;
};

const VerifyPhone: React.FC<VerifyPhoneProps> = (props) => {
  const CODE_LENGTH = 6;
  const { t } = useTranslation();

  const [code, setCode] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isInvalidTotp, setIsInvalidTotp] = useState<boolean>(false);
  const [isUnexpectedError, setIsUnexpectedError] = useState<boolean>(false);

  const { cashLinkToken } = useParams<CashLinkingProcessRouteProps>();

  useEffect(() => {
    Es2Tracker.track(
      cashVerifyPhoneViewEvent(
        props.cashLinkInfo.deliveryIdentifier!,
        props.phoneId === null,
        props.cashLinkInfo.merchantInfo!.id
      )
    );
  }, [props.cashLinkInfo, props.phoneId]);

  /*
   * Link the buyer to Cash App.
   */
  const checkCashAppVerification = async () => {
    try {
      setIsProcessing(true);

      Es2Tracker.track(
        cashVerifyPhoneActionEvent(
          props.cashLinkInfo.deliveryIdentifier!,
          props.phoneId === null,
          props.cashLinkInfo.merchantInfo!.id
        )
      );

      const phoneId =
        getIdentifier(props.cashLinkInfo, Identifier.Type.TYPE_PHONE)?.id ||
        null;

      const personToken = await checkCashVerification(
        cashLinkToken,
        phoneId,
        phoneId ? null : props.phoneNumber,
        code,
        null
      );

      props.nextStep(
        `https://cashapp.onelink.me/ui6m?af_dp=cashme%3A%2F%2F&customer_reference_id=${personToken}`
      );
    } catch (ex) {
      if (ex.status === RequestStatus.STATUS_BAD_REQUEST) {
        setIsInvalidTotp(true);
        return;
      }

      setIsUnexpectedError(true);
    } finally {
      setIsProcessing(false);
    }
  };

  /*
   * Sends a new TOTP code to the inputted phone number or phone number associated with the phone id in the token.
   */
  const requestNewCode = async () => {
    await requestPhoneVerification(props.phoneNumber, props.phoneId);
  };

  return (
    <div className={'flex flex-col m-w-full sm:m-w-[600px] sm:p-0'}>
      <BackButton className={'mb-7'} onClick={() => props.backStep()} />
      <h1 className={'m-0 mb-4 heading-30'}>
        {t('common.verifyIdentifier.title', {
          identifierValue: props.phoneNumber?.replace('+', ''),
        })}
      </h1>
      <p className={'m-0 mb-8 paragraph-30'}>
        {t('cashlinking.verifyPhone.subtitle')}
      </p>
      <MarketField invalid={isInvalidTotp} className={'mb-7 w-full'}>
        <MarketInputText>
          <label>{t('common.code')}</label>
          <input
            slot={'input'}
            data-testid={'totp-input'}
            autoComplete={'one-time-code'}
            autoCorrect={'off'}
            autoCapitalize={'off'}
            inputMode={'numeric'}
            maxLength={CODE_LENGTH}
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
              setIsInvalidTotp(false);
            }}
          />
        </MarketInputText>
        <small slot={'error'}>{t('common.code.invalid')}</small>
      </MarketField>
      <MarketButton
        rank={'primary'}
        className={'mb-2'}
        {...(isProcessing && { disabled: true })}
        /* Fun workaround to get disabled working: https://github.com/squareup/market/issues/1570 */
        {...((code.length < CODE_LENGTH || isProcessing) && { disabled: true })}
        onClick={() => checkCashAppVerification()}
      >
        {t('cashlinking.verifyPhone.continue')}
      </MarketButton>
      <MarketButton
        rank={'tertiary'}
        {...(isProcessing && { disabled: true })}
        onClick={() => requestNewCode()}
      >
        {t('common.requestNewCode')}
      </MarketButton>
      <SecuredBySquare className={'mt-7 self-center'} />
      {isUnexpectedError && (
        <div className={'absolute bottom-[28px] p-6 m-auto'}>
          <MarketToast
            variant={'critical'}
            onMarketToastManuallyDismissed={() => setIsUnexpectedError(false)}
            onMarketToastAutoDismissed={() => setIsUnexpectedError(false)}
          >
            {t('common.somethingWentWrong.retryable.apologetic')}
          </MarketToast>
        </div>
      )}
    </div>
  );
};

export default VerifyPhone;
