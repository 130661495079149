import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PaymentRecord } from 'rpc/model/squareup/payments/model/payment_record';
import { environment } from 'utils/environment';
import camelize from 'camelize';

export const URL_RECEIPT_SEARCH_SERVICE =
  '/services/squareup.receipts.SearchService/';

// This allows us to use a staging captcha token in development mode.
const DEV_CAPTCHA_TOKEN = 'receipts_staging_test_token';

type RequestType = {
  nonce: string;
  recaptchaToken: string;
  amount?: string;
  date: Date;
};

export type ResponseType = {
  payments: PaymentRecord[];
};

export const receiptLookupApi = createApi({
  baseQuery: async (args, api, extraOptions) => {
    const rawResponse = await fetchBaseQuery({
      baseUrl: '/services/squareup.receipts.SearchService/',
      credentials: 'include',
      prepareHeaders: (headers) => {
        headers.set('Content-Type', 'application/json');
        return headers;
      },
    })(args, api, extraOptions);

    // Transform response to camelCase
    if (rawResponse.data) {
      rawResponse.data = camelize(rawResponse.data as {});
    }

    return rawResponse;
  },
  endpoints: (builder) => ({
    searchReceipts: builder.mutation<ResponseType, RequestType>({
      query: ({ nonce, amount, date, recaptchaToken }) => ({
        body: {
          amount,
          date_submit: date.toISOString().split('T')[0],
          nonce,
          'g-recaptcha-response':
            environment().name === 'development'
              ? DEV_CAPTCHA_TOKEN
              : recaptchaToken,
        },
        method: 'POST',
        url: 'search',
      }),
    }),
  }),
  reducerPath: 'receiptLookupApi',
});

export const { useSearchReceiptsMutation } = receiptLookupApi;
