import React from 'react';
import { LoadingIcon, SmallLoadingIcon } from 'svgs';

interface SqLoadingProps {
  variant?: 'inline' | 'primary';
}

const SqLoading: React.FC<SqLoadingProps> = (props) => {
  let loadingIcon;

  if (props.variant === 'inline') {
    loadingIcon = (
      <SmallLoadingIcon fontSize="small" className={'block w-7 h-7'} />
    );
  } else {
    loadingIcon = (
      <LoadingIcon fontSize="large" className={'block w-[80px] h-[80px]'} />
    );
  }

  return (
    <div className={'animate-spin'} data-testid={'loading'}>
      {loadingIcon}
    </div>
  );
};

export default SqLoading;
