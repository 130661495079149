import {
  MarketButton,
  MarketField,
  MarketHeader,
  MarketInputText,
} from '@market/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBuyer } from 'store/buyerSlice';
import BaseModal from 'components/modals/BaseModal';
import { AppState } from 'store';
import useValidation from 'utils/custom-react-hooks/useValidation';
import { useUpdateNameMutation } from 'store/query';
import { closeModal } from 'store/modalSlice';
import { openToast, ToastVariant } from 'store/toastSlice';
import { useTranslation } from 'react-i18next';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import { saveUpdateProfileProperties } from 'services/tracking/cdp/events/profile';

const isNameValid = (name: string) => {
  return name.length > 0;
};

const MAX_NAME_LENGTH = 32;

const EditProfileDetailsModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const buyer = useSelector((state: AppState) => selectBuyer(state));

  const [firstName, setFirstName] = useState<string | undefined>(
    buyer.firstName
  );
  const [lastName, setLastName] = useState<string | undefined>(buyer.lastName);

  const [isInvalidFirstName, resetFirstNameValidation, validateFirstName] =
    useValidation((firstName) => isNameValid(firstName));
  const [isInvalidLastName, resetLastNameValidation, validateLastName] =
    useValidation((lastName) => isNameValid(lastName));

  const [updateName, updateNameResult] = useUpdateNameMutation();

  const isDirty = firstName !== buyer.firstName || lastName !== buyer.lastName;

  const saveName = async () => {
    const isValidFirstName = validateFirstName(firstName);
    const isValidLastName = validateLastName(lastName);

    if (isValidFirstName && isValidLastName) {
      if (!isDirty) {
        dispatch(closeModal());
        return;
      }

      await updateName({
        firstName,
        lastName,
      })
        .unwrap()
        .then(() => {
          buyerportalCdpClient.track(
            'Save Update Details',
            saveUpdateProfileProperties('edit', 'name')
          );

          dispatch(
            openToast({
              message: t('common.changeSuccess'),
              variant: ToastVariant.Success,
            })
          );

          dispatch(closeModal());
        })
        .catch(() => {
          dispatch(
            openToast({
              message: t('common.somethingWentWrong.retryable.direct'),
              variant: ToastVariant.Critical,
            })
          );
        });
    }
  };

  const isSaveButtonDisabled = updateNameResult.isLoading;

  return (
    <BaseModal>
      <MarketHeader>
        <MarketButton
          rank={'primary'}
          slot={'actions'}
          {...(isSaveButtonDisabled && { disabled: true })}
          onClick={saveName}
        >
          {t('common.save')}
        </MarketButton>
      </MarketHeader>
      <h2 className={'heading-30 my-8'}>
        {t('profile.account.profileDetails.editDetails')}
      </h2>
      <MarketField invalid={isInvalidFirstName} className={'mb-4'}>
        <MarketInputText
          aria-required
          value={firstName}
          data-testid={'firstName'}
          type={'text'}
          inputMode={'text'}
          maxlength={MAX_NAME_LENGTH}
          autoCapitalize={'off'}
          onMarketInputValueChange={(e) => {
            const newFirstName = e.detail.value;
            setFirstName(newFirstName);
            resetFirstNameValidation();
          }}
        >
          <label>{t('common.firstName')}</label>
        </MarketInputText>
        <small slot={'error'}>{t('common.message.validation.firstName')}</small>
      </MarketField>
      <MarketField invalid={isInvalidLastName} className={'mb-4'}>
        <MarketInputText
          aria-required
          value={lastName}
          data-testid={'lastName'}
          type={'text'}
          inputMode={'text'}
          maxlength={MAX_NAME_LENGTH}
          autoCapitalize={'off'}
          onMarketInputValueChange={(e) => {
            const newLastName = e.detail.value;
            setLastName(newLastName);
            resetLastNameValidation();
          }}
        >
          <label>{t('common.lastName')}</label>
        </MarketInputText>
        <small slot={'error'}>{t('common.message.validation.lastName')}</small>
      </MarketField>
    </BaseModal>
  );
};

export default EditProfileDetailsModal;
