/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/no-cycle */
// eslint-disable-next-line filenames/match-exported
import SqLoading from 'components/SqLoading';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ReceiptFAQ from 'routes/not-your-receipt/ReceiptFaq';
import Options from './Options';
import Unsubscribed from './Unsubscribed';
import GlobalToast from 'components/GlobalToast';
import { LogoIcon } from 'svgs';
import PrivacyFooter from 'components/SqPrivacyFooter';
import { MarketDivider } from '@market/react';
import {
  useFetchReceiptInfoQuery,
  useAutomaticReceiptOptInMerchantMutation,
  useAutomaticReceiptOptOutMerchantMutation,
  useAutomaticReceiptOptInGlobalMutation,
  useAutomaticReceiptOptOutGlobalMutation,
} from 'store/query/receipts';
import { openToast, ToastVariant } from 'store/toastSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useCdpPageTracking, {
  Client_Namespace,
} from 'utils/custom-react-hooks/useCdpPageTracking';
import {
  actionTurnOffReceipts,
  viewAutoReceiptUnsubscribe,
} from 'services/tracking/cdp/events/singleClickAutomaticReceiptPreferences';
import { buyerportalUnsubscribeCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import Resubscribed from './Resubscribed';

interface ManageAutomaticReceiptsParams {
  id: string;
}

export enum UNSUBSCRIBE_VALUES {
  NONE,
  MERCHANT,
  GLOBAL,
}

const ManageAutomaticReceipts: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams<ManageAutomaticReceiptsParams>();

  const showErrorToast = () => {
    dispatch(
      openToast({
        message: t('common.somethingWentWrong.retryable.direct'),
        variant: ToastVariant.Critical,
      })
    );
  };
  /* Query state + functions */

  const {
    data: fetchData,
    isLoading: isFetchQueryLoading,
    isError: hasFetchQueryFailed,
  } = useFetchReceiptInfoQuery(id);

  /* Mutation state + functions */
  const [
    optInMerchant,
    { isLoading: isOptingInMerchant, isSuccess: isOptedInMerchant },
  ] = useAutomaticReceiptOptInMerchantMutation();
  const [optOutMerchant, { isLoading: isOptingOutMerchant }] =
    useAutomaticReceiptOptOutMerchantMutation();
  const [
    optInGlobal,
    { isLoading: isOptingInGlobal, isSuccess: isOptedInGlobal },
  ] = useAutomaticReceiptOptInGlobalMutation();
  const [optOutGlobal, { isLoading: isOptingOutGlobal }] =
    useAutomaticReceiptOptOutGlobalMutation();

  /* CDP page view tracking */

  useCdpPageTracking({
    client: Client_Namespace.UNSUBSCRIBE,
    didLoadingFail: hasFetchQueryFailed,
    isLoading: isFetchQueryLoading,
    properties: viewAutoReceiptUnsubscribe(id),
  });

  /* Subscribe state + functions */

  const [unsubscribedFrom, setUnsubscribedFrom] = useState<UNSUBSCRIBE_VALUES>(
    UNSUBSCRIBE_VALUES.NONE
  );

  const unsubscribeMerchant = () => {
    buyerportalUnsubscribeCdpClient.track(
      'Turn Off Receipts',
      actionTurnOffReceipts(id, 'merchant')
    );
    optOutMerchant(id)
      .unwrap()
      .then(() => {
        setUnsubscribedFrom(UNSUBSCRIBE_VALUES.MERCHANT);
      })
      .catch(() => {
        showErrorToast();
      });
  };

  const unsubscribeGlobal = () => {
    buyerportalUnsubscribeCdpClient.track(
      'Turn Off Receipts',
      actionTurnOffReceipts(id, 'all')
    );
    optOutGlobal(id)
      .unwrap()
      .then(() => {
        setUnsubscribedFrom(UNSUBSCRIBE_VALUES.GLOBAL);
      })
      .catch(() => {
        showErrorToast();
      });
  };

  const resubscribe = () => {
    if (unsubscribedFrom === UNSUBSCRIBE_VALUES.MERCHANT) {
      optInMerchant(id)
        .unwrap()
        .catch(() => {
          showErrorToast();
        });
    } else if (unsubscribedFrom === UNSUBSCRIBE_VALUES.GLOBAL) {
      optInGlobal(id)
        .unwrap()
        .catch(() => {
          showErrorToast();
        });
    }
  };

  /* Content */

  let content;
  if (isFetchQueryLoading) {
    content = (
      <div
        className={
          'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
        }
      >
        <SqLoading />
      </div>
    );
  } else if (hasFetchQueryFailed) {
    content = <ReceiptFAQ />;
    // if the email is undefined, it means the buyer tried to unsubscribe but was already unsubscribed
  } else if (!fetchData?.obfuscatedEmail) {
    content = (
      <Unsubscribed
        receiptToken={id}
        unsubscribedFrom={UNSUBSCRIBE_VALUES.MERCHANT}
        obfuscatedEmail={fetchData?.obfuscatedEmail}
        obfuscatedCard={fetchData?.obfuscatedCard || ''}
        merchantName={fetchData?.merchant.name || ''}
        doResubscribe={resubscribe}
        isResubscribing={false}
      />
    );
  } else if (unsubscribedFrom === UNSUBSCRIBE_VALUES.NONE) {
    content = (
      <>
        <Options
          doUnsubscribeMerchant={unsubscribeMerchant}
          doUnsubscribeGlobal={unsubscribeGlobal}
          obfuscatedEmail={fetchData.obfuscatedEmail || ''}
          obfuscatedCard={fetchData.obfuscatedCard || ''}
          merchantName={fetchData?.merchant.name || ''}
          isUnsubscribing={isOptingOutMerchant || isOptingOutGlobal}
        />
        <MarketDivider />
        <ReceiptFAQ />
      </>
    );
  } else if (isOptedInMerchant || isOptedInGlobal) {
    content = (
      <Resubscribed
        receiptToken={id}
        unsubscribedFrom={unsubscribedFrom}
        obfuscatedCard={fetchData?.obfuscatedCard || ''}
        obfuscatedEmail={fetchData?.obfuscatedEmail || ''}
        merchantName={fetchData?.merchant.name || ''}
      />
    );
  } else {
    content = (
      <Unsubscribed
        receiptToken={id}
        unsubscribedFrom={unsubscribedFrom}
        obfuscatedEmail={fetchData?.obfuscatedEmail || ''}
        obfuscatedCard={fetchData?.obfuscatedCard || ''}
        merchantName={fetchData?.merchant.name || ''}
        doResubscribe={resubscribe}
        isResubscribing={isOptingInMerchant || isOptingInGlobal}
      />
    );
  }

  return (
    <>
      <div className={'flex justify-center min-h-full grow'}>
        <GlobalToast />
        <main
          className={
            'flex flex-col justify-center py-[47px] px-[24px] w-[calc(100vw-48px)] sm:w-[440px]'
          }
        >
          <LogoIcon className={'text-[32px] mb-[12px]'} />
          {content}
        </main>
      </div>
      <PrivacyFooter />
    </>
  );
};

export default ManageAutomaticReceipts;
