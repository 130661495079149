/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';
import '../../../authz_engines/obligations/obligations';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      connect: {
        nested: {
          v2: {
            nested: {
              resources: {
                nested: {
                  Error: {
                    fields: {
                      category: {
                        type: 'squareup.connect.v2.resources.Error.Category',
                        id: 4,
                        rule: 'optional',
                      },
                      code: {
                        type: 'squareup.connect.v2.resources.Error.Code',
                        id: 1,
                        rule: 'optional',
                      },
                      detail: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      field: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                      obligations: {
                        type: 'squareup.authz_engines.obligations.Obligation',
                        id: 5,
                        rule: 'repeated',
                      },
                    },
                    nested: {
                      Category: {
                        values: {
                          API_ERROR: 1,
                          AUTHENTICATION_ERROR: 2,
                          INVALID_REQUEST_ERROR: 3,
                          RATE_LIMIT_ERROR: 4,
                          PAYMENT_METHOD_ERROR: 5,
                          REFUND_ERROR: 6,
                          MERCHANT_SUBSCRIPTION_ERROR: 7,
                          EXTERNAL_VENDOR_ERROR: 8,
                        },
                      },
                      Code: {
                        values: {
                          INTERNAL_SERVER_ERROR: 5000000,
                          UNAUTHORIZED: 4010000,
                          ACCESS_TOKEN_EXPIRED: 4010001,
                          ACCESS_TOKEN_REVOKED: 4010002,
                          CLIENT_DISABLED: 4010003,
                          FORBIDDEN: 4030000,
                          INSUFFICIENT_SCOPES: 4030001,
                          APPLICATION_DISABLED: 4030002,
                          V1_APPLICATION: 4030003,
                          V1_ACCESS_TOKEN: 4030004,
                          CARD_PROCESSING_NOT_ENABLED: 4030005,
                          MERCHANT_SUBSCRIPTION_NOT_FOUND: 4030006,
                          BAD_REQUEST: 4000000,
                          MISSING_REQUIRED_PARAMETER: 4000001,
                          INCORRECT_TYPE: 4000002,
                          INVALID_TIME: 4000003,
                          INVALID_TIME_RANGE: 4000004,
                          INVALID_VALUE: 4000005,
                          INVALID_CURSOR: 4000006,
                          UNKNOWN_QUERY_PARAMETER: 4000007,
                          CONFLICTING_PARAMETERS: 4000008,
                          EXPECTED_JSON_BODY: 4000009,
                          INVALID_SORT_ORDER: 4000010,
                          VALUE_REGEX_MISMATCH: 4000061,
                          VALUE_TOO_SHORT: 4000057,
                          VALUE_TOO_LONG: 4000011,
                          VALUE_TOO_LOW: 4000012,
                          VALUE_TOO_HIGH: 4000058,
                          VALUE_EMPTY: 4000059,
                          ARRAY_LENGTH_TOO_LONG: 4000073,
                          ARRAY_LENGTH_TOO_SHORT: 4000074,
                          ARRAY_EMPTY: 4000060,
                          EXPECTED_BOOLEAN: 4000013,
                          EXPECTED_INTEGER: 4000014,
                          EXPECTED_FLOAT: 4000015,
                          EXPECTED_STRING: 4000016,
                          EXPECTED_OBJECT: 4000017,
                          EXPECTED_ARRAY: 4000018,
                          EXPECTED_MAP: 4000079,
                          EXPECTED_BASE64_ENCODED_BYTE_ARRAY: 4000056,
                          INVALID_ARRAY_VALUE: 4000019,
                          INVALID_ENUM_VALUE: 4000020,
                          INVALID_CONTENT_TYPE: 4000021,
                          INVALID_FORM_VALUE: 4000022,
                          CUSTOMER_NOT_FOUND: 4000023,
                          BUYER_NOT_FOUND: 4000121,
                          ONE_INSTRUMENT_EXPECTED: 4000052,
                          NO_FIELDS_SET: 4000053,
                          DEPRECATED_FIELD_SET: 4000078,
                          RETIRED_FIELD_SET: 4000103,
                          TOO_MANY_MAP_ENTRIES: 4000108,
                          MAP_KEY_LENGTH_TOO_SHORT: 4000109,
                          MAP_KEY_LENGTH_TOO_LONG: 4000110,
                          CUSTOMER_MISSING_NAME: 4000122,
                          CUSTOMER_MISSING_EMAIL: 4000123,
                          INVALID_PAUSE_LENGTH: 4000124,
                          INVALID_DATE: 4000125,
                          JOB_TEMPLATE_NAME_TAKEN: 4000138,
                          CLIENT_NOT_SUPPORTED: 4000140,
                          UNSUPPORTED_COUNTRY: 4000149,
                          UNSUPPORTED_CURRENCY: 4000150,
                          APPLE_TTP_PIN_TOKEN: 4000151,
                          INELIGIBLE_FOR_PREAUTHORIZATION: 4000162,
                          CARD_EXPIRED: 4000024,
                          INVALID_EXPIRATION: 4000027,
                          INVALID_EXPIRATION_YEAR: 4000028,
                          INVALID_EXPIRATION_DATE: 4000039,
                          UNSUPPORTED_CARD_BRAND: 4000055,
                          UNSUPPORTED_ENTRY_METHOD: 4000076,
                          INVALID_ENCRYPTED_CARD: 4000081,
                          INVALID_CARD: 4000040,
                          PAYMENT_AMOUNT_MISMATCH: 4000141,
                          GENERIC_DECLINE: 4000082,
                          CVV_FAILURE: 4000083,
                          ADDRESS_VERIFICATION_FAILURE: 4000084,
                          INVALID_ACCOUNT: 4000085,
                          CURRENCY_MISMATCH: 4000086,
                          INSUFFICIENT_FUNDS: 4000087,
                          INSUFFICIENT_PERMISSIONS: 4000088,
                          CARDHOLDER_INSUFFICIENT_PERMISSIONS: 4000089,
                          INVALID_LOCATION: 4000090,
                          TRANSACTION_LIMIT: 4000091,
                          VOICE_FAILURE: 4000092,
                          PAN_FAILURE: 4000093,
                          EXPIRATION_FAILURE: 4000094,
                          CARD_NOT_SUPPORTED: 4000095,
                          READER_NOT_SUPPORTED_FOR_COUNTRY: 4000160,
                          READER_NOT_SUPPORTED_DUE_TO_NO_EMV_READER: 4000161,
                          READER_NOT_SUPPORTED_END_OF_LIFE: 4000163,
                          READER_DECLINED: 4000168,
                          ISSUER_INSTALLMENT_ERROR: 4000152,
                          INVALID_PIN: 4000096,
                          MISSING_PIN: 4000119,
                          MISSING_ACCOUNT_TYPE: 4000120,
                          INVALID_POSTAL_CODE: 4000097,
                          INVALID_FEES: 4000098,
                          MANUALLY_ENTERED_PAYMENT_NOT_SUPPORTED: 4000099,
                          PAYMENT_LIMIT_EXCEEDED: 4000100,
                          GIFT_CARD_AVAILABLE_AMOUNT: 4000106,
                          GIFT_CARD_BUYER_DAILY_LIMIT_REACHED: 4000133,
                          GIFT_CARD_INVALID_AMOUNT: 4000134,
                          GIFT_CARD_MAX_VALUE_REACHED: 4000135,
                          GIFT_CARD_MERCHANT_MAX_OUTSTANDING_BALANCE_REACHED: 4000136,
                          GIFT_CARD_VALUE_ADDITION_LIMIT_REACHED: 4000137,
                          ACCOUNT_UNUSABLE: 4000117,
                          BUYER_REFUSED_PAYMENT: 4000118,
                          DELAYED_TRANSACTION_EXPIRED: 4000041,
                          DELAYED_TRANSACTION_CANCELED: 4000042,
                          DELAYED_TRANSACTION_CAPTURED: 4000043,
                          DELAYED_TRANSACTION_FAILED: 4000044,
                          CARD_TOKEN_EXPIRED: 4000045,
                          CARD_TOKEN_USED: 4000046,
                          AMOUNT_TOO_HIGH: 4000047,
                          UNSUPPORTED_INSTRUMENT_TYPE: 4000054,
                          REFUND_AMOUNT_INVALID: 4000048,
                          REFUND_ALREADY_PENDING: 4000049,
                          PAYMENT_NOT_REFUNDABLE: 4000050,
                          PAYMENT_NOT_REFUNDABLE_DUE_TO_DISPUTE: 4000165,
                          REFUND_ERROR_PAYMENT_NEEDS_COMPLETION: 4000169,
                          REFUND_DECLINED: 4000112,
                          INSUFFICIENT_PERMISSIONS_FOR_REFUND: 4000145,
                          INVALID_CARD_DATA: 4000051,
                          SOURCE_USED: 4000113,
                          SOURCE_EXPIRED: 4000114,
                          UNSUPPORTED_LOYALTY_REWARD_TIER: 4000115,
                          LOCATION_MISMATCH: 4000077,
                          ORDER_EXPIRED: 4000062,
                          ORDER_ALREADY_USED: 4000067,
                          ORDER_TOO_MANY_CATALOG_OBJECTS: 4000072,
                          ORDER_UNPAID_NOT_RETURNABLE: 4000170,
                          INSUFFICIENT_INVENTORY: 4000063,
                          PRICE_MISMATCH: 4000064,
                          VERSION_MISMATCH: 4000065,
                          IDEMPOTENCY_KEY_REUSED: 4000066,
                          UNEXPECTED_VALUE: 4000068,
                          SANDBOX_NOT_SUPPORTED: 4000069,
                          INVALID_EMAIL_ADDRESS: 4000070,
                          INVALID_PHONE_NUMBER: 4000075,
                          CHECKOUT_EXPIRED: 4000071,
                          BAD_CERTIFICATE: 4000080,
                          INVALID_SQUARE_VERSION_FORMAT: 4000101,
                          API_VERSION_INCOMPATIBLE: 4000102,
                          INVALID_URL: 4000104,
                          HTTPS_ONLY: 4000105,
                          UNREACHABLE_URL: 4000107,
                          SESSION_EXPIRED: 4000111,
                          INVALID_VERIFICATION_CODE: 4000142,
                          CARD_PRESENCE_REQUIRED: 4000146,
                          UNSUPPORTED_SOURCE_TYPE: 4000147,
                          CARD_MISMATCH: 4000148,
                          PLAID_ERROR: 4000300,
                          PLAID_ERROR_ITEM_LOGIN_REQUIRED: 4000301,
                          PLAID_ERROR_RATE_LIMIT: 4000302,
                          CALCULATE_FULFILLMENT_RATES_NO_PROFILES_CONFIGURED: 4000400,
                          CALCULATE_FULFILLMENT_RATES_SHIPMENT_DESTINATION_NOT_CONFIGURED: 4000401,
                          CALCULATE_FULFILLMENT_RATES_INVALID_RECIPIENT_ADDRESS: 4000402,
                          CALCULATE_FULFILLMENT_RATES_FULFILLMENT_TYPE_NOT_SUPPORTED: 4000403,
                          FULFILLMENT_RATE_PROFILE_DUPLICATE_ITEM: 4000404,
                          FULFILLMENT_RATE_PROFILE_INVALID_ITEM: 4000405,
                          CUSTOMS_DECLARATION_NO_VALUE: 4000500,
                          CUSTOMS_DECLARATION_NO_WEIGHT: 4000501,
                          CARD_DECLINED: 4020001,
                          VERIFY_CVV_FAILURE: 4020002,
                          VERIFY_AVS_FAILURE: 4020003,
                          CARD_DECLINED_CALL_ISSUER: 4020004,
                          CARD_DECLINED_VERIFICATION_REQUIRED: 4020005,
                          BAD_EXPIRATION: 4020006,
                          CHIP_INSERTION_REQUIRED: 4020007,
                          ALLOWABLE_PIN_TRIES_EXCEEDED: 4020008,
                          RESERVATION_DECLINED: 4020009,
                          BLOCKED_BY_BLOCKLIST: 4020010,
                          FULFILLMENT_PREFERENCES_RESTRICTED_DATE_NOT_UNIQUE: 4000126,
                          FULFILLMENT_PREFERENCES_INVALID_SCHEDULING_DATETIME: 4000127,
                          FULFILLMENT_PREFERENCES_INVALID_FULFILLMENT_AVAILABILITY_WINDOW: 4000128,
                          FULFILLMENT_PREFERENCES_FULFILLMENT_SCHEDULE_NOT_ALLOWED: 4000129,
                          FULFILLMENT_PREFERENCES_ASSIGNMENT_IS_IMMUTABLE: 4000130,
                          INVALID_TIMEZONE: 4000131,
                          UNKNOWN_BODY_PARAMETER: 4000132,
                          FULFILLMENT_PREFERENCES_CONFLICTING_ASSIGNMENT_TYPE: 4000139,
                          SCHEDULE_UPDATES_DUPLICATE_EXECUTE_AT: 4000153,
                          SCHEDULE_UPDATES_PAST_EXECUTE_AT: 4000154,
                          FULFILLMENT_PREFERENCES_ASSIGNMENT_REQUIRED: 4000155,
                          ITEMS_BULK_BULK_ACTIONS_INVALID_ITEM: 4000156,
                          ITEMS_BULK_BULK_ACTIONS_SALE_PRICE_INVALID_PERCENTAGE: 4000157,
                          ITEMS_BULK_BULK_ACTIONS_SALE_PRICE_INVALID_AMOUNT: 4000158,
                          ITEMS_BULK_BULK_ACTIONS_NO_ELIGIBLE_ITEMS_FOUND: 4000159,
                          ITEMS_INVALID_CANADIAN_FREE_TEXT_DONATION_CATEGORY: 4000164,
                          ITEMS_CUSTOM_ATTRIBUTE_LIMIT_EXCEEDED: 4000166,
                          ITEMS_CUSTOM_ATTRIBUTE_NOT_UNIQUE: 4000167,
                          CARRIER_INTEGRATION_ERROR: 4000171,
                          ITEMS_BULK_BULK_ACTIONS_INVALID_PAYLOAD: 4000172,
                          NOT_FOUND: 4040000,
                          APPLE_PAYMENT_PROCESSING_CERTIFICATE_HASH_NOT_FOUND: 4040001,
                          METHOD_NOT_ALLOWED: 4050000,
                          NOT_ACCEPTABLE: 4060000,
                          REQUEST_TIMEOUT: 4080000,
                          CONFLICT: 4090000,
                          GONE: 4100000,
                          REQUEST_ENTITY_TOO_LARGE: 4130000,
                          UNSUPPORTED_MEDIA_TYPE: 4150000,
                          UNPROCESSABLE_ENTITY: 4220000,
                          RATE_LIMITED: 4290000,
                          CLIENT_CLOSED_REQUEST: 4990000,
                          NOT_IMPLEMENTED: 5010000,
                          BAD_GATEWAY: 5020000,
                          SERVICE_UNAVAILABLE: 5030000,
                          TEMPORARY_ERROR: 5030001,
                          GATEWAY_TIMEOUT: 5040000,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Error &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Error?.nested?.Category &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources
    ?.nested?.Error?.nested?.Code
) {
  $root.addJSON(jsonData);
}

/**
 * Errors are always a repeated field. This allows clients to easily group
 * errors by code or by field as they please. The contents of the human-readable
 * detail property may be subject to change.
 * --
 * @desc Represents an error encountered during a request to the Connect API.
 *
 * See [Handling errors](https://developer.squareup.com/docs/build-basics/handling-errors) for more information.
 * --
 *
 * SOURCE Error @ squareup/connect/v2/resources/error.proto at 43:1
 */
export const Error = $root.lookupType('squareup.connect.v2.resources.Error');
fixType(Error, {
  category: { required: true },
  code: { required: true },
});
Builder.createAndAttachToType(Error);
/**
 * --
 * @desc Indicates which high-level category of error has occurred during a
 * request to the Connect API.
 * --
 *
 * SOURCE Category @ squareup/connect/v2/resources/error.proto at 50:3
 */
Error.Category = $root.lookupEnum(
  'squareup.connect.v2.resources.Error.Category'
).values;
/**
 * Error types are (http status code * 10000 + increment), except for
 * internal_server_error, which is the enum default.
 * --
 * @desc Indicates the specific error that occurred during a request to a
 * Square API.
 * --
 *
 * SOURCE Code @ squareup/connect/v2/resources/error.proto at 103:3
 */
Error.Code = $root.lookupEnum(
  'squareup.connect.v2.resources.Error.Code'
).values;
