import React, { useCallback, useContext, useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import NavigationBar from '../components/NavigationBar';
import AccountPage from './AccountPage';
import EarningPage from './EarningPage';
import RewardsPage from './RewardsPage';
import {
  AuthenticatedLoyaltyRouteParams,
  authenticatedLoyaltyRoutePattern,
} from 'routes/merchant-scoped-portal/integrations/loyalty/routeUtils';

import PointExpirationPreview from '../components/PointsExpirationPreview';
import { ExpiringPointsDeadline } from '../models/ExpiringPointsDeadline';
import LoyaltyAccountStatus from '../components/LoyaltyAccountStatus';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import {
  selectAccount,
  selectAvailableNewTos,
  selectPassDownloadUrl,
  setAvailableNewTos,
} from 'routes/merchant-scoped-portal/integrations/loyalty/loyaltySlice';
import {
  useGetTermsOfServiceQuery,
  useRetrieveAccountInfoQuery,
  useRetrievePersonalizedPassInfoQuery,
  useUpdateTermsOfServiceConsentMutation,
} from 'store/query/api-extensions/loyalty';
import ModuleLoadFailed from 'routes/profile/common/errors/ModuleLoadFailed';
import ModuleLoading from 'routes/profile/common/loading/ModuleLoading';
import { MerchantPortalContext } from 'routes/merchant-scoped-portal';
import { ModalType, openModal } from 'store/modalSlice';
import { useTranslation } from 'react-i18next';
import { chooseConsentCopy } from 'routes/merchant-scoped-portal/integrations/loyalty/utils/consentCopy';
import { useTrackLoyaltyEvent } from 'utils/custom-react-hooks/loyalty/useTrackLoyaltyEvent';
import {
  ActionItem,
  EventName,
  FeatureFormat,
} from 'services/tracking/cdp/events/types';
import { logUnexpectedError } from 'routes/merchant-scoped-portal/utils/error';
import { Team } from 'routes/merchant-scoped-portal/teamRegistry';
import { isIphone } from 'utils/device';
import AddToAppleWalletSection from 'routes/merchant-scoped-portal/integrations/loyalty/components/AddToAppleWalletSection';
import { MarketList } from '@market/react';

// Custom hook that fetches the loyalty terms of service for the loyalty account
// and shows the terms of service dialog if there are new terms to accept
const useShowTermsOfServiceModal = (
  loyaltyAccountLookupToken: string,
  loyaltyAccountToken: string,
  merchantId: string
) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading, error } = useGetTermsOfServiceQuery(
    {
      loyaltyAccountLookupToken,
      merchantId,
    },
    { skip: !loyaltyAccountLookupToken }
  );

  const errorMessage = error?.message || error?.data?.message;
  if (errorMessage) {
    logUnexpectedError(
      new Error('Error fetching terms of service'),
      [Team.CeWeb],
      {
        error,
        errorMessage,
        loyaltyAccountToken,
        merchantId,
      }
    );
  }

  const availableNewTos = useSelector((state: AppState) =>
    selectAvailableNewTos(state.loyaltyMerchantPortal)
  );

  const [updateTermsOfService] = useUpdateTermsOfServiceConsentMutation();
  const trackLoyaltyEvent = useTrackLoyaltyEvent();
  const trackOpenLoyaltyTOSModal = useCallback(() => {
    trackLoyaltyEvent(EventName.VIEW_FEATURE, {
      event_description: 'View ToS consent modal',
      feature_format: FeatureFormat.MODAL,
    });
  }, [trackLoyaltyEvent]);

  useEffect(() => {
    if (availableNewTos && loyaltyAccountLookupToken) {
      const handleAgreeToTermsOfServiceConsent = async () => {
        try {
          trackLoyaltyEvent(EventName.CLICK_FEATURE, {
            action_item: ActionItem.CLICK_BUTTON,
            event_description: 'Accept ToS from consent modal',
            feature_format: FeatureFormat.BUTTON,
          });
          await updateTermsOfService({
            loyaltyAccountLookupToken,
            newlyAcceptedTos: availableNewTos,
          }).unwrap();
          dispatch(setAvailableNewTos(undefined));
        } catch (error) {
          logUnexpectedError(
            new Error('Unable to agree to terms of service'),
            [Team.CeWeb],
            {
              availableNewTos,
              error,
              loyaltyAccountToken,
            }
          );
          // If the update fails for some reason, we will still dismiss the modal.
          // Since the consent hasn't been updated, the next visit to the page
          // will show the modal again.
          dispatch(setAvailableNewTos(undefined));
        }
      };

      const handleDeclineToTermsOfServiceConsent = () => {
        history.push(`/merchantportal/${merchantId}/loyalty`);
      };

      const tosCopy = chooseConsentCopy(i18n.language, availableNewTos.copies);

      if (tosCopy) {
        dispatch(
          openModal({
            props: {
              header: t('loyalty.termsOfService.header'),
              onRender: trackOpenLoyaltyTOSModal,
              persistent: true,
              primaryButtonAction: handleAgreeToTermsOfServiceConsent,
              primaryButtonLabel: t('common.agree'),
              secondaryButtonAction: handleDeclineToTermsOfServiceConsent,
              secondaryButtonLabel: t('common.decline'),
              subtext: tosCopy.content,
            },
            type: ModalType.BasicDialog,
          })
        );
      } else {
        logUnexpectedError(
          new Error('Consent copy not found for available new TOS'),
          [Team.CeWeb],
          {
            availableNewTos,
            loyaltyAccountToken,
          }
        );
      }
    }
  }, [
    availableNewTos,
    dispatch,
    history,
    i18n.language,
    loyaltyAccountLookupToken,
    loyaltyAccountToken,
    merchantId,
    t,
    trackLoyaltyEvent,
    trackOpenLoyaltyTOSModal,
    updateTermsOfService,
  ]);

  return {
    error,
    isLoading,
  };
};

const AuthenticatedLoyaltyResourcePage = () => {
  const match = useRouteMatch<Partial<AuthenticatedLoyaltyRouteParams>>(
    authenticatedLoyaltyRoutePattern
  );
  const loyaltyAccountLookupToken =
    match?.params?.loyaltyAccountLookupToken ?? '';
  const mpContext = useContext(MerchantPortalContext);
  const merchantId =
    mpContext.merchantPortalBaseData?.merchantOverview.merchantId ?? '';

  const {
    isLoading: isLoadingAccountInfo,
    isUninitialized,
    isError,
  } = useRetrieveAccountInfoQuery(loyaltyAccountLookupToken, {
    skip: !loyaltyAccountLookupToken,
  });

  const account = useSelector((state: AppState) =>
    selectAccount(state.loyaltyMerchantPortal)
  );

  const loyaltyAccountToken = account?.loyaltyAccount?.id ?? '';

  const { isLoading: isLoadingTos } = useShowTermsOfServiceModal(
    loyaltyAccountLookupToken,
    loyaltyAccountToken,
    merchantId
  );

  const { isLoading: isLoadingPassInfo } = useRetrievePersonalizedPassInfoQuery(
    loyaltyAccountToken,
    {
      skip: !(loyaltyAccountToken && isIphone()),
    }
  );

  const passDownloadUrl = useSelector((state: AppState) =>
    selectPassDownloadUrl(state.loyaltyMerchantPortal)
  );

  if (
    isLoadingAccountInfo ||
    isLoadingTos ||
    isUninitialized ||
    isLoadingPassInfo
  ) {
    return <ModuleLoading embedded={true} />;
  }

  if (isError || !account?.loyaltyAccount) {
    return <ModuleLoadFailed embedded={true} />;
  }

  const expiringPointDeadlines =
    account.loyaltyAccount.expiringPointDeadlines?.map((deadline) =>
      ExpiringPointsDeadline.fromProto(deadline)
    );

  return (
    <main>
      <div className="mt-[-140px]">
        <LoyaltyAccountStatus
          loyaltyAccount={account.loyaltyAccount}
          member={account.member}
        />
      </div>
      <div className="grid gap-y-[16px]">
        {isIphone() && passDownloadUrl && (
          <AddToAppleWalletSection loyaltyPassDownloadUrl={passDownloadUrl} />
        )}
        {expiringPointDeadlines?.length ? (
          <MarketList>
            <PointExpirationPreview
              data-testid="point-expiration-preview"
              deadlines={expiringPointDeadlines}
            />
          </MarketList>
        ) : null}
        <NavigationBar />
        <Switch>
          <Route
            exact
            path={`${authenticatedLoyaltyRoutePattern}/account`}
            component={AccountPage}
          />
          <Route
            path={`${authenticatedLoyaltyRoutePattern}/earning`}
            component={EarningPage}
          />
          <Route
            exact
            path={`${authenticatedLoyaltyRoutePattern}/rewards`}
            component={RewardsPage}
          />
          <Redirect
            from={authenticatedLoyaltyRoutePattern}
            to={`${authenticatedLoyaltyRoutePattern}/rewards`}
          />
        </Switch>
      </div>
    </main>
  );
};

export default AuthenticatedLoyaltyResourcePage;
