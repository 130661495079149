import React, { PropsWithChildren } from 'react';

type PageProps = {
  type?: 'default' | 'backdrop';
};

/*
 * This component provides standard page layouts to be used for all pages within the authenticated Square Profile UI.
 */
const Page: React.FC<PropsWithChildren<PageProps>> = ({ type, children }) => {
  return (
    <main className={'relative w-full'}>
      {type === 'backdrop' && (
        <div
          className={
            'absolute w-full h-[223px] md:h-[276px] bg-black opacity-[0.03]'
          }
        />
      )}
      <div
        className={
          'box-border h-full px-8 md:px-[64px] py-9 md:w-[800px] max-w-[800px] mx-auto my-0'
        }
      >
        {children}
      </div>
    </main>
  );
};

export default Page;
