import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useValidation from 'utils/custom-react-hooks/useValidation';
import {
  MarketButton,
  MarketField,
  MarketInputText,
  MarketToast,
} from '@market/react';
import { formatPhoneNational, isValidPhone } from 'utils/identifiers';
import SecuredBySquare from './components/common/SecuredBySquare';
import { CashLinkInfo } from 'rpc/model/squareup/buyerportal/cashlink/data';
import { requestPhoneVerification } from 'services/buyerportal';
import Es2Tracker from 'services/tracking/tracker';
import {
  cashRequestPhoneVerificationActionEvent,
  cashRequestPhoneVerificationViewEvent,
} from 'services/tracking/events';

type RequestVerificationProps = {
  cashLinkInfo: CashLinkInfo;
  nextStep: (phoneNumber: string) => void;
};

const RequestPhoneVerification: React.FC<RequestVerificationProps> = (
  props
) => {
  const { t } = useTranslation();

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const [
    isInvalidPhoneNumber,
    resetPhoneNumberValidation,
    validatePhoneNumber,
  ] = useValidation((phone) => isValidPhone(phone));
  const [isUnexpectedError, setIsUnexpectedError] = useState<boolean>(false);

  const phoneAlreadyAutofilled = useRef(false);

  useEffect(() => {
    Es2Tracker.track(
      cashRequestPhoneVerificationViewEvent(
        props.cashLinkInfo.deliveryIdentifier!,
        props.cashLinkInfo.merchantInfo!.id
      )
    );
  }, [props.cashLinkInfo]);

  // If the phone number gets auto-filled by Fillr, make sure that it's
  // formatted (onClick will not trigger from this)
  useEffect(() => {
    if (!phoneAlreadyAutofilled.current && phoneNumber !== '') {
      phoneAlreadyAutofilled.current = true;
      setPhoneNumber(formatPhoneNational(phoneNumber));
    }
  }, [phoneNumber]);

  /*
   * Sends a TOTP code to the inputted number.
   */
  const sendPhoneVerification = async () => {
    if (!validatePhoneNumber(phoneNumber)) {
      return;
    }

    try {
      setIsProcessing(true);

      Es2Tracker.track(
        cashRequestPhoneVerificationActionEvent(
          props.cashLinkInfo.deliveryIdentifier!,
          props.cashLinkInfo.merchantInfo!.id
        )
      );

      await requestPhoneVerification(phoneNumber, null);
      props.nextStep(phoneNumber);
    } catch {
      setIsUnexpectedError(true);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className={'flex flex-col w-full sm:max-w-[600px]'}>
      <h1 className={'mt-[76px] mb-4 heading-30'}>
        {t('cashlinking.requestPhoneVerification.title')}
      </h1>
      <p className={'m-0 mb-4 paragraph-30'}>
        {t('cashlinking.requestPhoneVerification.subtitle')}
      </p>
      <MarketField className={'mb-7'} invalid={isInvalidPhoneNumber}>
        <MarketInputText value={phoneNumber}>
          <label>{t('common.phoneNumber')}</label>
          <input
            data-testid={'phone-input'}
            slot={'input'}
            type={'tel'}
            autoComplete={'tel'}
            inputMode={'tel'}
            name={'phone'}
            autoCorrect={'off'}
            autoCapitalize={'off'}
            value={phoneNumber}
            onChange={(e) => {
              phoneAlreadyAutofilled.current = true;
              setPhoneNumber(formatPhoneNational(e.target.value));
              resetPhoneNumberValidation();
            }}
          />
        </MarketInputText>
        <small slot={'error'}>{t('common.message.validation.phone')}</small>
      </MarketField>
      <MarketButton
        rank={'primary'}
        /* Fun workaround to get disabled working: https://github.com/squareup/market/issues/1570 */
        {...((isInvalidPhoneNumber || isProcessing) && { disabled: true })}
        onClick={() => sendPhoneVerification()}
      >
        {t('common.sendCode')}
      </MarketButton>
      <SecuredBySquare className={'mt-7 self-center'} />
      {isUnexpectedError && (
        <div className={'absolute bottom-[28px] p-6 m-auto'}>
          <MarketToast
            variant={'critical'}
            onMarketToastManuallyDismissed={() => setIsUnexpectedError(false)}
            onMarketToastAutoDismissed={() => {
              setIsUnexpectedError(false);
            }}
          >
            {t('common.somethingWentWrong.retryable.apologetic')}
          </MarketToast>
        </div>
      )}
    </div>
  );
};

export default RequestPhoneVerification;
