/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../../Fixes';
import Builder from '../../../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      feature: {
        nested: {
          relay: {
            nested: {
              flags: {
                nested: {
                  message: {
                    nested: {
                      SuccessEvaluationReason: {
                        fields: {
                          unknownReason: {
                            type: 'squareup.feature.relay.flags.message.ReasonUnknown',
                            id: 1,
                          },
                          flagTargetingOff: {
                            type: 'squareup.feature.relay.flags.message.ReasonFlagTargetingOff',
                            id: 2,
                          },
                          fallthroughToDefaultRule: {
                            type: 'squareup.feature.relay.flags.message.ReasonFallthroughToDefaultRule',
                            id: 3,
                          },
                          matchedTargetOverride: {
                            type: 'squareup.feature.relay.flags.message.ReasonMatchedTargetOverride',
                            id: 4,
                          },
                          matchedRule: {
                            type: 'squareup.feature.relay.flags.message.ReasonMatchedRule',
                            id: 5,
                          },
                          failedPrerequisite: {
                            type: 'squareup.feature.relay.flags.message.ReasonFailedPrerequisite',
                            id: 6,
                          },
                        },
                        nested: {
                          reason: {
                            oneof: [
                              'unknownReason',
                              'flagTargetingOff',
                              'fallthroughToDefaultRule',
                              'matchedTargetOverride',
                              'matchedRule',
                              'failedPrerequisite',
                            ],
                          },
                        },
                      },
                      ReasonUnknown: {
                        fields: {},
                      },
                      ReasonFlagTargetingOff: {
                        fields: {},
                      },
                      ReasonFallthroughToDefaultRule: {
                        fields: {},
                      },
                      ReasonMatchedTargetOverride: {
                        fields: {},
                      },
                      ReasonMatchedRule: {
                        fields: {
                          ruleId: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          prerequisiteName: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                        },
                      },
                      ReasonFailedPrerequisite: {
                        fields: {
                          prerequisiteName: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.SuccessEvaluationReason &&
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.ReasonUnknown &&
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.ReasonFlagTargetingOff &&
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.ReasonFallthroughToDefaultRule &&
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.ReasonMatchedTargetOverride &&
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.ReasonMatchedRule &&
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.ReasonFailedPrerequisite
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE SuccessEvaluationReason @ squareup/feature/relay/flags/message/success_evaluation_reason.proto at 10:1
 */
export const SuccessEvaluationReason = $root.lookupType(
  'squareup.feature.relay.flags.message.SuccessEvaluationReason'
);
fixType(SuccessEvaluationReason);
Builder.createAndAttachToType(SuccessEvaluationReason);
/**
 * Empty messages for simple cases
 *
 * SOURCE ReasonUnknown @ squareup/feature/relay/flags/message/success_evaluation_reason.proto at 22:1
 */
export const ReasonUnknown = $root.lookupType(
  'squareup.feature.relay.flags.message.ReasonUnknown'
);
fixType(ReasonUnknown);
Builder.createAndAttachToType(ReasonUnknown);
/**
 * SOURCE ReasonFlagTargetingOff @ squareup/feature/relay/flags/message/success_evaluation_reason.proto at 23:1
 */
export const ReasonFlagTargetingOff = $root.lookupType(
  'squareup.feature.relay.flags.message.ReasonFlagTargetingOff'
);
fixType(ReasonFlagTargetingOff);
Builder.createAndAttachToType(ReasonFlagTargetingOff);
/**
 * SOURCE ReasonFallthroughToDefaultRule @ squareup/feature/relay/flags/message/success_evaluation_reason.proto at 24:1
 */
export const ReasonFallthroughToDefaultRule = $root.lookupType(
  'squareup.feature.relay.flags.message.ReasonFallthroughToDefaultRule'
);
fixType(ReasonFallthroughToDefaultRule);
Builder.createAndAttachToType(ReasonFallthroughToDefaultRule);
/**
 * SOURCE ReasonMatchedTargetOverride @ squareup/feature/relay/flags/message/success_evaluation_reason.proto at 25:1
 */
export const ReasonMatchedTargetOverride = $root.lookupType(
  'squareup.feature.relay.flags.message.ReasonMatchedTargetOverride'
);
fixType(ReasonMatchedTargetOverride);
Builder.createAndAttachToType(ReasonMatchedTargetOverride);
/**
 * SOURCE ReasonMatchedRule @ squareup/feature/relay/flags/message/success_evaluation_reason.proto at 27:1
 */
export const ReasonMatchedRule = $root.lookupType(
  'squareup.feature.relay.flags.message.ReasonMatchedRule'
);
fixType(ReasonMatchedRule, {
  ruleId: { required: true },
});
Builder.createAndAttachToType(ReasonMatchedRule);
/**
 * SOURCE ReasonFailedPrerequisite @ squareup/feature/relay/flags/message/success_evaluation_reason.proto at 32:1
 */
export const ReasonFailedPrerequisite = $root.lookupType(
  'squareup.feature.relay.flags.message.ReasonFailedPrerequisite'
);
fixType(ReasonFailedPrerequisite, {
  prerequisiteName: { required: true },
});
Builder.createAndAttachToType(ReasonFailedPrerequisite);
