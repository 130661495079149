import React from 'react';
import { useDispatch } from 'react-redux';
import { SmallLoadingIcon } from 'svgs';
import {
  CreditCard,
  PaymentForm,
  PaymentFormProps,
} from 'react-square-web-payments-sdk';
import { MarketButton, MarketContentCard, MarketHeader } from '@market/react';
import * as Sentry from '@sentry/browser';
import BaseModal from 'components/modals/BaseModal';
import config from 'config/config';
import { Identifier } from 'routes/profile/models/Identifier';
import { openToast, ToastVariant } from 'store/toastSlice';
import { useTranslation } from 'react-i18next';
import {
  useCreateCardMutation,
  useUnlinkSuggestedProfileIdentifierMutation,
} from 'store/query';
import { closeModal } from 'store/modalSlice';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import { actionVerifyUnverifiedPaymentCardProperties } from 'services/tracking/cdp/events/paymentMethods';

export type AddPaymentCardModalProps = {
  /**
   * If present, this indicates that the user wants to verify an unverified card. Regardless if present, this modal will attempt to add a card.
   */
  unverifiedCard?: Identifier;
};

const AddPaymentCardModal: React.FC<AddPaymentCardModalProps> = ({
  unverifiedCard,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [createCard, createCardResult] = useCreateCardMutation();
  const [unlinkSuggestedCard, unlinkSuggestedCardResult] =
    useUnlinkSuggestedProfileIdentifierMutation();

  const handleTokenizationResponse: PaymentFormProps['cardTokenizeResponseReceived'] =
    async (tokenResponse) => {
      if (tokenResponse.status !== 'OK') {
        // According to the SDK code:
        // type TokenError = TokenErrorDetails | Error;
        // We don't need to log "expected" TokenErrorDetails, but the latter might be interesting.
        // Using the property 'field' to determine the runtime type of the TokenError
        const nonStandardTokenizationErrors = tokenResponse.errors?.filter(
          (err) => !Object.prototype.hasOwnProperty.call(err, 'field')
        );

        if (
          nonStandardTokenizationErrors &&
          nonStandardTokenizationErrors.length > 0
        ) {
          // Have verified in sentry these are auto-grouped
          nonStandardTokenizationErrors.forEach((err) =>
            Sentry.captureException(err)
          );
        }

        throw new Error('Invalid token');
      }

      await createCard(tokenResponse.token!)
        .unwrap()
        .then((createdCard) => {
          dispatch(closeModal());
          dispatch(
            openToast({
              message: t(
                'profile.paymentMethods.paymentCards.addPaymentCardModal.paymentCardAdded'
              ),
              variant: ToastVariant.Success,
            })
          );

          if (unverifiedCard) {
            buyerportalCdpClient.track(
              'Verify card',
              actionVerifyUnverifiedPaymentCardProperties(
                createdCard.cardsApiId
              )
            );
          }
        })
        .catch((error: any) => {
          dispatch(
            openToast({
              message:
                error.data.message ||
                t('common.somethingWentWrong.retryable.direct'),
              variant: ToastVariant.Critical,
            })
          );
        });
    };

  const unlinkUnverifiedCard = () => {
    unlinkSuggestedCard(unverifiedCard!)
      .unwrap()
      .then(() => {
        dispatch(
          openToast({
            message: t(
              'profile.paymentMethods.paymentCards.addPaymentCardModal.paymentCardRemoved'
            ),
            variant: ToastVariant.Success,
          })
        );
      })
      .catch(() => {
        dispatch(
          openToast({
            message: t('common.somethingWentWrong.retryable.direct'),
            variant: ToastVariant.Critical,
          })
        );
      });

    dispatch(closeModal());
  };

  const copy = {
    title: unverifiedCard
      ? `${t('common.verify')} ${unverifiedCard.displayValue}`
      : t(
          'profile.paymentMethods.paymentCards.addPaymentCardModal.addPaymentCard'
        ),
    subtitle: unverifiedCard
      ? t(
          'profile.paymentMethods.paymentCards.addPaymentCardModal.unverifiedCardSubtitle'
        )
      : t(
          'profile.paymentMethods.paymentCards.addPaymentCardModal.verifiedCardSubtitle'
        ),
    verifyButton: unverifiedCard ? t('common.verify') : t('common.add'),
  };

  return (
    <BaseModal>
      <MarketHeader />
      <h2 className={'heading-30 mt-6 mb-4'}>{copy.title}</h2>
      <p className={'mt-0 mb-8'}>{copy.subtitle}</p>
      <div className={'h-[185px] paymentSdkStackPoint:h-[137px] mb-2'}>
        <PaymentForm
          applicationId={config.squareDeveloper.applicationId}
          cardTokenizeResponseReceived={handleTokenizationResponse}
          locationId={config.squareDeveloper.locationId}
          overrides={{
            scriptSrc: config.squareDeveloper.webPaymentsSdkSrc,
          }}
        >
          <CreditCard
            buttonProps={{
              isLoading: createCardResult.isLoading,
            }}
          >
            {createCardResult.isLoading ? (
              <div className="flex justify-center">
                <SmallLoadingIcon
                  fontSize="small"
                  className={'block w-7 h-7 animate-spin'}
                />
              </div>
            ) : (
              copy.verifyButton
            )}
          </CreditCard>
        </PaymentForm>
      </div>
      {unverifiedCard && (
        <>
          <MarketButton
            className={'w-full'}
            variant={'destructive'}
            {...(unlinkSuggestedCardResult.isLoading && { disabled: true })}
            onClick={unlinkUnverifiedCard}
          >
            {t(
              'profile.paymentMethods.paymentCards.addPaymentCardModal.removeCard'
            )}
          </MarketButton>
          <MarketContentCard className={'mt-4'}>
            <p className={'font-medium my-0'}>
              {t(
                'profile.paymentMethods.paymentCards.addPaymentCardModal.dontRecognizeCard'
              )}
            </p>
            <p className={'mb-0'}>
              {t(
                'profile.paymentMethods.paymentCards.addPaymentCardModal.digitalWalletInfo'
              )}
            </p>
          </MarketContentCard>
        </>
      )}
    </BaseModal>
  );
};

export default AddPaymentCardModal;
