import React from 'react';
import BaseModal from 'components/modals/BaseModal';
import { MarketHeader, MarketList, MarketRow } from '@market/react';
import { ExpiringPointsDeadline } from '../models/ExpiringPointsDeadline';
import { DateFormat, dayjs, formatDate } from 'utils/date';
import { useTranslation } from 'react-i18next';
import { selectLoyaltyProgram } from 'routes/merchant-scoped-portal/integrations/loyalty/loyaltySlice';
import { useSelector } from 'react-redux';
import { AppState } from 'store';

export interface PointsExpirationModalProps {
  deadlines: Array<ExpiringPointsDeadline>;
}

const PointsExpirationModal: React.FC<PointsExpirationModalProps> = (props) => {
  const { t } = useTranslation();
  const program = useSelector((state: AppState) =>
    selectLoyaltyProgram(state.loyaltyMerchantPortal)
  );
  const expirationDuration = dayjs.duration(
    program?.expirationPolicy?.expirationDuration!
  );
  const expirationDurationInMonths = expirationDuration.months();
  const pointsTerminology = program?.terminology!;
  const description =
    expirationDurationInMonths === 1
      ? t('loyalty.pointsExpirationModal.expirationPolicy.one', {
          count: expirationDurationInMonths,
          pointsTerminology: pointsTerminology.one,
        })
      : t('loyalty.pointsExpirationModal.expirationPolicy.other', {
          count: expirationDurationInMonths,
          pointsTerminology: pointsTerminology.other,
        });

  return (
    <BaseModal
      title={t('loyalty.pointsExpirationModal.title', {
        pointsTerminology: pointsTerminology.other,
      })}
    >
      <MarketHeader>
        <h2>
          {t('loyalty.pointsExpirationModal.title', {
            pointsTerminology: pointsTerminology.other,
          })}
        </h2>
      </MarketHeader>
      <p>{description}</p>
      <MarketList>
        {props.deadlines?.map((deadline) => (
          <MarketRow
            data-testid="points-expiration-modal-row"
            key={deadline.expiresAt?.toISOString()}
          >
            <label slot="label">
              {formatDate(deadline.expiresAt!, DateFormat.ll)}
            </label>
            <label slot="side-label">
              {t('loyalty.common.points', {
                count: deadline.points,
                pointsTerminology:
                  deadline.points === 1
                    ? pointsTerminology.one
                    : pointsTerminology.other,
              })}
            </label>
          </MarketRow>
        ))}
      </MarketList>
    </BaseModal>
  );
};

export default PointsExpirationModal;
