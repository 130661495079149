import { MarketButton } from '@market/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Es2Tracker from 'services/tracking/tracker';
import { unauthenticatedNotYourReceiptUnlinkActionEvent } from 'services/tracking/events';
import { useDispatch } from 'react-redux';
import { openToast, ToastVariant } from 'store/toastSlice';
import {
  ReceiptDeliveryType,
  useUnlinkCardFromDeliveryIdentifierMutation,
} from 'store/query/receipts';

interface UnlinkActionProps {
  receiptToken: string;
  deliveryType: ReceiptDeliveryType;
  isLinkedToDeliveryType: boolean;
  obfuscatedEmail?: string;
}

const UnlinkAction: React.FC<UnlinkActionProps> = ({
  receiptToken,
  deliveryType,
  isLinkedToDeliveryType,
}) => {
  const dispatch = useDispatch();

  const [isLinked, setIsLinked] = useState<boolean>(isLinkedToDeliveryType);

  const [unlink, { isLoading: isUnlinking }] =
    useUnlinkCardFromDeliveryIdentifierMutation();

  const { t } = useTranslation();

  const unlinkDeliveryIdentifierFromCard = async () => {
    Es2Tracker.track(
      unauthenticatedNotYourReceiptUnlinkActionEvent(deliveryType)
    );
    await unlink(receiptToken)
      .unwrap()
      .then(() => {
        setIsLinked(false);
      })
      .catch(() => {
        dispatch(
          openToast({
            message: t(
              'preference.components.notYourReceiptUnlink.unlinkError'
            ),
            persistent: true,
            variant: ToastVariant.Critical,
          })
        );
      });
  };

  const unlinkForm = () => (
    <>
      <p className="paragraph-30 mt-0 mb-[30px]">
        {t(
          `preference.components.notYourReceiptUnlink.paragraph.sorry.${deliveryType}`
        )}
      </p>

      <MarketButton
        className={'mb-[24px]'}
        rank={'primary'}
        onClick={unlinkDeliveryIdentifierFromCard}
        {...(isUnlinking && { disabled: true })}
      >
        {t(`preference.components.notYourReceiptUnlink.button.${deliveryType}`)}
      </MarketButton>
    </>
  );

  const unlinkConfirmation = () => (
    <>
      <h2 className={'heading-20'}>
        {t('preference.components.notYourReceiptUnlink.title.turnedOff')}
      </h2>

      <p className={'paragraph-30 mt-0 mb-[30px]'}>
        {t(
          `preference.components.notYourReceiptUnlink.paragraph.turnedOff.${deliveryType}`
        )}
      </p>
    </>
  );

  return isLinked ? unlinkForm() : unlinkConfirmation();
};

export default UnlinkAction;
