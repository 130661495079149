import React, { useRef, useState } from 'react';
import { MarketButton, MarketIcon, MarketHeader } from '@market/react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { BASE_PATH } from './constants/routes';
import { environment } from 'utils/environment';
import { ReceiptLookupRouteProps } from './types/RouteProps';
import Es2Tracker from 'services/tracking/tracker';
import { showReceipt } from 'services/tracking/events/receiptLookupEvents';
import ModuleLoading from 'routes/profile/common/loading/ModuleLoading';
import classNames from 'classnames';

export const BASE_RECEIPT_URL = environment().isProduction
  ? 'https://squareup.com/receipt/preview'
  : 'https://squareupstaging.com/receipt/preview';

const ReceiptDetail: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { paymentToken } = useParams<ReceiptLookupRouteProps>();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    setIsLoading(false);
    Es2Tracker.trackReceiptsCatalog(showReceipt(paymentToken));
  };

  // Ensures the loading spinner is vertically centered but the iframe (content) will be laid out like normal
  const contentFlexboxClasses = classNames({
    flex: true,
    'flex-col': true,
    grow: true,
    'justify-center': isLoading,
    'min-w-[440px]': true,
  });

  const iframeClasses = classNames({
    'border-none': true,
    grow: true,
    // we have to render it to start loading, but we don't want to show it until it's ready
    hidden: isLoading,
  });

  return (
    <>
      <MarketHeader compact show-navigation className="m-4">
        <MarketButton
          rank="secondary"
          slot="navigation"
          aria-label={t('common.back')}
          onClick={() => {
            history.push({ pathname: BASE_PATH });
          }}
        >
          <MarketIcon name="back" slot="icon"></MarketIcon>
        </MarketButton>
        <h2>{t('receiptLookup.detail.title')}</h2>
      </MarketHeader>
      <div className={contentFlexboxClasses}>
        {isLoading && <ModuleLoading />}

        <iframe
          src={`${BASE_RECEIPT_URL}/${paymentToken}?context=web`}
          className={iframeClasses}
          ref={iframeRef}
          title={'receipt'}
          width={'100%'}
          allowFullScreen={true}
          onLoad={handleIframeLoad}
        />
      </div>
    </>
  );
};

export default ReceiptDetail;
