import React, { useContext } from 'react';
import { LogoIcon } from 'svgs';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ClickLinkEvent,
  EventName,
  FeatureFormat,
  FeatureID,
  FeatureName,
} from 'services/tracking/cdp/events/types';
import { MerchantPortalContext } from '..';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import AccountManagementMenu from './AccountManagementMenu';

type SignInLinkProps = {
  returnTo: string;
};
const SignInLink: React.FC<SignInLinkProps> = ({ returnTo }) => {
  const { t } = useTranslation();
  const handleSignInLinkClick = () => {
    const clickSignInLinkEvent: ClickLinkEvent = {
      event_description: 'clicks sign in on top nav',
      feature_format: FeatureFormat.PAGE,
      feature_id: FeatureID.MerchantOverviewPage,
      feature_name: FeatureName.MerchantOverviewPage,
      link_text: t('navBar.signIn'),
      link_title: 'Sign In',
      link_url: `/signin?return_to=${returnTo}`,
    };

    buyerportalCdpClient.track(EventName.CLICK_FEATURE, clickSignInLinkEvent);
  };
  return (
    <a
      href={`/signin?return_to=${returnTo}`}
      className={'text-black no-underline font-medium'}
      onClick={handleSignInLinkClick}
    >
      {t('navBar.signIn')}
    </a>
  );
};

export type TopNavProps = {
  isAuthenticated?: boolean;
  shouldOnlyRenderSquareProfileLink?: boolean;
  buyerName?: string;
};

const TopNav = ({
  isAuthenticated,
  buyerName,
  shouldOnlyRenderSquareProfileLink,
}: TopNavProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  const { merchantPortalBaseData } = useContext(MerchantPortalContext);
  const { buyer, merchantOverview } = merchantPortalBaseData ?? {};
  const { merchantId, merchantName } = merchantOverview ?? {};

  const handleHomeNavLinkClick = () => {
    // Track logo click
    const logoLinkClickEvent: ClickLinkEvent = {
      additional_parameters: {
        buyer_authenticated: Boolean(buyer),
        merchant_id: merchantId,
        merchant_name: merchantName,
      },
      event_description: 'clicks profile home',
      feature_format: FeatureFormat.PAGE,
      feature_id: FeatureID.MerchantOverviewPage,
      feature_name: FeatureName.MerchantOverviewPage,
      // No text/title for logo
      link_text: '',
      link_title: '',
      link_url: '/',
    };
    // actually track
    buyerportalCdpClient.track(EventName.CLICK_LINK, logoLinkClickEvent);
  };

  const mobileStickyClasses = 'sticky top-0 bg-white z-[2]';

  return (
    <nav
      id="top-nav"
      className={`${mobileStickyClasses} md:static shadow-bottomDivider flex justify-between items-center px-2 py-1`}
    >
      <Link
        to="/"
        onClick={handleHomeNavLinkClick}
        className="border-none bg-transparent p-3 cursor-pointer visited:text-inherit"
        aria-label={t('merchantPortal.overview.nav.homeLink.ariaLabel')}
      >
        <LogoIcon />
      </Link>
      {!shouldOnlyRenderSquareProfileLink && (
        <div className="px-2 py-3 cursor-pointer">
          {isAuthenticated ? (
            <AccountManagementMenu buyerName={buyerName} />
          ) : (
            <SignInLink returnTo={location.pathname} />
          )}
        </div>
      )}
    </nav>
  );
};

export default TopNav;
