// eslint-disable-next-line filenames/match-exported
import React, { useEffect } from 'react';
import { MarketDivider } from '@market/react';
import PrivacyFooter from 'components/SqPrivacyFooter';
import { useParams } from 'react-router-dom';
import ReceiptFAQ from './ReceiptFaq';
import UnlinkAction from './UnlinkAction';
import LoadingUnlinkReceipt from './LoadingUnlinkReceipt';
import Es2Tracker from 'services/tracking/tracker';
import { unauthenticatedNotYourReceiptViewAction } from 'services/tracking/events';
import { useFetchReceiptInfoQuery } from 'store/query/receipts';
import { LogoIcon } from 'svgs';
import { useTranslation } from 'react-i18next';
import GlobalToast from 'components/GlobalToast';
import { Country } from 'rpc/model/squareup/common/countries';

interface NotYourReceiptParams {
  receiptToken: string;
}

const NotYourReceipt: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    Es2Tracker.track(unauthenticatedNotYourReceiptViewAction());
  }, []);

  const { receiptToken } = useParams<NotYourReceiptParams>();

  const {
    data: receiptInfo,
    isLoading: isLoadingReceiptInfo,
    isError: isReceiptInfoQueryFailed,
  } = useFetchReceiptInfoQuery(receiptToken);

  let component;
  if (isLoadingReceiptInfo) {
    component = <LoadingUnlinkReceipt />;
  } else if (isReceiptInfoQueryFailed || !receiptInfo) {
    component = <ReceiptFAQ />;
  } else {
    const { deliveryType, isLinkedToDeliveryType, obfuscatedEmail, merchant } =
      receiptInfo;

    component = (
      <>
        <UnlinkAction
          receiptToken={receiptToken}
          obfuscatedEmail={obfuscatedEmail}
          isLinkedToDeliveryType={isLinkedToDeliveryType}
          deliveryType={deliveryType}
        />
        <MarketDivider />
        <ReceiptFAQ
          shouldFilterAutoReceiptFaqs={
            merchant.countryCode.toUpperCase() !== Country[Country.US]
          }
        />
      </>
    );
  }

  return (
    <>
      <div className={'flex justify-center min-h-full grow'}>
        <GlobalToast />
        <main
          className={
            'flex flex-col justify-center py-[47px] px-[24px] w-[calc(100vw-48px)] sm:w-[440px]'
          }
        >
          <LogoIcon className={'text-[32px] mb-[12px]'} />
          <h1 className={'heading-30'}>
            {t('preference.routes.notYourReceipt.header')}
          </h1>
          {component}
        </main>
      </div>
      <PrivacyFooter />
    </>
  );
};

export default NotYourReceipt;
