/** @type {import('tailwindcss').Config} */

export const content = ['./src/**/*.{js,jsx,ts,tsx}'];
export const important = true;

export const theme = {
  extend: {
    maxWidth: {
      desktopContent: '600px',
    },
    // example usage: <div className={'shadow-bottomDivider'} />
    boxShadow: {
      topDivider: '0px 0px 0px -1px rgba(0, 0, 0, 0.05) inset',
      bottomDivider: '0px -1px 0px 0px rgba(0, 0, 0, 0.05) inset',
      rightDivider: '-1px 0px 0px 0px rgba(0, 0, 0, 0.05) inset',
      leftDivider: '0px 0px -1px 0px rgba(0, 0, 0, 0.05) inset',
      selectedBottomDivider: '0px -2px 0px 0px rgba(0, 0, 0, 0.9) inset',
      previewCard:
        '4px 4px 12px rgba(0, 0, 0, 0.3), 2px 2px 4px rgba(0, 0, 0, 0.04)',
    },
    colors: {
      blue: '#006AFF',
      emphasisBlue: '#005AD9',
      criticalRed: '#CC0023',
      lightgrey: '#B3B3B3',
      grey: '#0000008C',
      bluegrey: '#546476', // Default color when no branding is set for merchant (merchant portal)
      lightblue: '#E8F0FE',
      marketBlueHover: '#E5F0FF',
      marketBlueActive: '#CCE1FF',
      footerText: '#6C7075',

      // Market core color tokens https://www.figma.com/file/BKizTk0NavqV0IZS58rPXk/%C2%AE-Market-1.0?type=design&node-id=22201-0&mode=design&t=E82dZdiLElKV1eFY-0
      'fill-10': '#003308',
      'fill-20': '#002D59',
      'fill-30': '#00000026',
      'fill-40': '#0000000D',
      'fill-50': '#00000008',
    },
    screens: {
      xs: '440px',
      marketFullWidthModal: '800px', // largest width at which market full width modal is used
      paymentSdkStackPoint: '517px', // smallest width at which web sdk uses 2 rows for its form instead of 1, increasing the height of the form
    },
  },
};
export const plugins = [];
export const corePlugins = {
  preflight: false,
};
