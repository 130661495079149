/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  google: {
    nested: {
      protobuf: {
        nested: {
          Struct: {
            fields: {
              fields: {
                type: 'google.protobuf.Value',
                id: 1,
                keyType: 'string',
              },
            },
          },
          Value: {
            fields: {
              nullValue: {
                type: 'google.protobuf.NullValue',
                id: 1,
              },
              numberValue: {
                type: 'double',
                id: 2,
              },
              stringValue: {
                type: 'string',
                id: 3,
              },
              boolValue: {
                type: 'bool',
                id: 4,
              },
              structValue: {
                type: 'google.protobuf.Struct',
                id: 5,
              },
              listValue: {
                type: 'google.protobuf.ListValue',
                id: 6,
              },
            },
            nested: {
              kind: {
                oneof: [
                  'nullValue',
                  'numberValue',
                  'stringValue',
                  'boolValue',
                  'structValue',
                  'listValue',
                ],
              },
            },
          },
          NullValue: {
            values: {
              NULL_VALUE: 0,
            },
          },
          ListValue: {
            fields: {
              values: {
                type: 'google.protobuf.Value',
                id: 1,
                rule: 'repeated',
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.google?.nested?.protobuf?.nested?.Struct &&
  !$root.nested?.google?.nested?.protobuf?.nested?.Value &&
  !$root.nested?.google?.nested?.protobuf?.nested?.NullValue &&
  !$root.nested?.google?.nested?.protobuf?.nested?.ListValue
) {
  $root.addJSON(jsonData);
}

/**
 * `Struct` represents a structured data value, consisting of fields
 * which map to dynamically typed values. In some languages, `Struct`
 * might be supported by a native representation. For example, in
 * scripting languages like JS a struct is represented as an
 * object. The details of that representation are described together
 * with the proto support for the language.
 *
 * The JSON representation for `Struct` is JSON object.
 *
 * SOURCE Struct @ google/protobuf/struct.proto at 51:1
 */
export const Struct = $root.lookupType('google.protobuf.Struct');
fixType(Struct);
Builder.createAndAttachToType(Struct);
/**
 * `Value` represents a dynamically typed value which can be either
 * null, a number, a string, a boolean, a recursive struct value, or a
 * list of values. A producer of value is expected to set one of these
 * variants. Absence of any variant indicates an error.
 *
 * The JSON representation for `Value` is JSON value.
 *
 * SOURCE Value @ google/protobuf/struct.proto at 62:1
 */
export const Value = $root.lookupType('google.protobuf.Value');
fixType(Value);
Builder.createAndAttachToType(Value);
/**
 * `NullValue` is a singleton enumeration to represent the null value for the
 * `Value` type union.
 *
 * The JSON representation for `NullValue` is JSON `null`.
 *
 * SOURCE NullValue @ google/protobuf/struct.proto at 84:1
 */
export const NullValue = $root.lookupEnum('google.protobuf.NullValue').values;
/**
 * `ListValue` is a wrapper around a repeated field of values.
 *
 * The JSON representation for `ListValue` is JSON array.
 *
 * SOURCE ListValue @ google/protobuf/struct.proto at 92:1
 */
export const ListValue = $root.lookupType('google.protobuf.ListValue');
fixType(ListValue);
Builder.createAndAttachToType(ListValue);
