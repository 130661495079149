import { MarketBanner, MarketLink, MarketRow } from '@market/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatGan } from 'utils/GiftCardDetailHelper';
import { GiftCardDetail } from 'routes/profile/models/GiftCard';

type GiftCardInfoProps = {
  giftCardDetail: GiftCardDetail;
};

const GiftCardInfo: React.FC<GiftCardInfoProps> = ({ giftCardDetail }) => {
  const { t } = useTranslation();

  return (
    <div className={'py-8'}>
      {giftCardDetail.isAutoLink && (
        <MarketBanner dismissable className={'mb-8'}>
          {t('profile.paymentMethods.giftCards.giftCardInfo.autolinked')}
        </MarketBanner>
      )}
      <h2 className={'heading-20 mt-0'}>
        {t('profile.paymentMethods.giftCards.giftCardInfo.cardInfo')}
      </h2>
      <MarketRow>
        <label slot={'label'}>
          {t('profile.paymentMethods.giftCards.giftCardInfo.cardNumber')}
        </label>
        <p slot={'subtext'}>{formatGan(giftCardDetail.gan)}</p>
      </MarketRow>
      <MarketRow>
        <label slot={'label'}>
          {t('profile.paymentMethods.giftCards.giftCardInfo.business')}
        </label>
        <p slot={'subtext'}>{giftCardDetail.merchantInfo.displayName}</p>
      </MarketRow>
      <MarketRow>
        <label slot={'label'}>
          {t('profile.paymentMethods.giftCards.giftCardInfo.waysToRedeem')}
        </label>
        <p slot={'subtext'}>
          {t(
            'profile.paymentMethods.giftCards.giftCardInfo.waysToRedeemInstructions'
          )}
          <MarketLink
            className={'block'}
            href={giftCardDetail.detailUrl}
            target={'_blank'}
          >
            {t(
              'profile.paymentMethods.giftCards.giftCardInfo.waysToRedeemLearnMore'
            )}
          </MarketLink>
        </p>
      </MarketRow>
    </div>
  );
};

export default GiftCardInfo;
