import React from 'react';
import { useTranslation } from 'react-i18next';
import { LogoIcon } from 'svgs';
import { getDashboardDomain } from 'config/config';
import { AppState } from 'store';
import { BoolFlag } from 'routes/profile/models/Flags';
import { useSelector } from 'react-redux';
import { selectBoolFlag } from 'store/featureSlice';
import { MarketButton } from '@market/react';
import { toggleShowConsentSelectionModal } from 'utils/userConsent';

interface SqPrivacyFooterLink {
  key: string;
  href: string;
  target?: string;
}

const getFooterLinks = (useAppSubdomain: boolean): SqPrivacyFooterLink[] => [
  {
    href: `https://${getDashboardDomain(
      useAppSubdomain
    )}/legal/general/buyer-features`,
    key: 'footer.item.privacy.policy',
    target: '_blank',
  },
  {
    href: '/privacyrequests',
    key: 'footer.item.privacy.requests',
  },
];

export type SqPrivacyFooterProps = {
  isMerchantPortal?: boolean;
};

const SqPrivacyFooter: React.FC<SqPrivacyFooterProps> = ({
  isMerchantPortal,
}) => {
  const useAppSubdomain = useSelector((state: AppState) =>
    selectBoolFlag(state, BoolFlag.useAppSubdomain)
  );
  const showCookieConsent = useSelector((state: AppState) =>
    selectBoolFlag(state, BoolFlag.showCookieConsent)
  );

  const { t } = useTranslation(undefined, { useSuspense: false });
  const footerLinks = getFooterLinks(useAppSubdomain);

  const renderPrivacyLinks = () => {
    return (
      <div className={'flex'}>
        {footerLinks.map((link, index) => (
          <div key={index}>
            <a
              href={link.href}
              className={
                'cursor-pointer text-footerText underline decoration-no-skip-ink font-normal text-[16px]'
              }
              target={link.target}
            >
              <span>{t(link.key)}</span>
            </a>
            <span
              className={
                index === footerLinks.length - 1
                  ? ''
                  : 'after:content-["|"] after:py-0 after:px-2.5'
              }
            ></span>
          </div>
        ))}
      </div>
    );
  };

  const renderMerchantPortalPrivacyDisclaimer = () => {
    return <p>{t('merchantPortal.pageNotFound.footer.privacyPolicy')}</p>;
  };
  return (
    <footer
      className={
        'flex md:justify-center box-border w-full bg-black/[.03] mt-auto pt-8 py-[27px] pb-[56px]'
      }
    >
      <div className={'px-6'}>
        <span className={'flex align-center mb-6'}>
          <LogoIcon color="#6C7075" width={22} height={22} />
        </span>
        <div className={'mb-2.5'}>
          <span className={'font-normal text-footerText text-[16px]'}>
            {t('footer.copyright', { year: new Date().getFullYear() })}
          </span>
        </div>
        {isMerchantPortal
          ? renderMerchantPortalPrivacyDisclaimer()
          : renderPrivacyLinks()}
        {showCookieConsent && (
          <MarketButton
            className={'mt-6'}
            rank={'secondary'}
            onClick={toggleShowConsentSelectionModal}
          >
            {t('footer.item.privacy.manageCookies')}
          </MarketButton>
        )}
      </div>
    </footer>
  );
};

export default SqPrivacyFooter;
