import React, { useEffect, useState } from 'react';
import { PaymentRecord } from 'rpc/model/squareup/payments/model/payment_record';
import PrivacyFooter from 'components/SqPrivacyFooter';
import ReceiptLookupForm from './ReceiptLookupForm';
import ResultList from './ResultList';
import { Step } from './enums/Step';
import { useParams, useHistory } from 'react-router-dom';
import ReceiptDetail from './ReceiptDetail';
import { ReceiptLookupRouteProps } from './types/RouteProps';
import { BASE_PATH } from './constants/routes';

const ReceiptLookup: React.FC = () => {
  const [paymentRecords, setPaymentRecords] = useState<
    PaymentRecord[] | undefined
  >();
  const { paymentToken } = useParams<ReceiptLookupRouteProps>();
  const [currentStep, setCurrentStep] = useState(Step.FORM);
  const history = useHistory();

  useEffect(() => {
    if (paymentRecords?.length && paymentRecords.length > 1) {
      setCurrentStep(Step.LIST);
    } else if (paymentRecords?.length === 1) {
      history.push(`${BASE_PATH}/${paymentRecords[0].paymentToken}`);
    }
  }, [history, paymentRecords]);

  const renderStep = () => {
    if (paymentToken) {
      return <ReceiptDetail />;
    }
    switch (currentStep) {
      case Step.LIST: {
        return (
          <ResultList
            paymentRecords={paymentRecords}
            setCurrentStep={setCurrentStep}
          />
        );
      }
      default: {
        return (
          <ReceiptLookupForm
            paymentRecords={paymentRecords}
            setPaymentRecords={setPaymentRecords}
          />
        );
      }
    }
  };

  return (
    <>
      {renderStep()}
      <PrivacyFooter />
    </>
  );
};

export default ReceiptLookup;
