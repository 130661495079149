import React from 'react';
import { MarketSvgProps } from '.';
import { theme } from 'tailwind.config';
import { useTranslation } from 'react-i18next';

const Store: React.FC<MarketSvgProps> = ({
  width = 24,
  height = 24,
  fill = theme.extend.colors.emphasisBlue,
  title,
}) => {
  const { t } = useTranslation();

  const svgTitle = title || t('common.svgs.storeIconTitle');

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{svgTitle}</title>
      <path
        d="M20 6L18.82 0H1.18L0.02 5.8L0 6C0 7.47 0.81 8.75 2 9.44V16H12V9.44C12.38 9.22 12.71 8.94 13 8.61C13.73 9.45 14.8 9.99 16 9.99V15.99H18V9.43C19.19 8.75 20 7.47 20 6ZM17.18 2L17.58 4H2.42L2.82 2H17.18ZM2 6.09L2.02 6H6C6 7.1 5.1 8 4 8C2.93 8 2.05 7.15 2 6.09ZM10 14H4V10C5.2 10 6.27 9.46 7 8.62C7.73 9.46 8.8 10 10 10V14ZM10 8C8.9 8 8 7.1 8 6H12C12 7.1 11.1 8 10 8ZM14 6H17.98L18 6.09C17.95 7.15 17.07 8 16 8C14.9 8 14 7.1 14 6Z"
        fill={fill}
      />
    </svg>
  );
};

export default Store;
