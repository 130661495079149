import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { MarketButton, MarketHeader } from '@market/react';
import BaseModal from 'components/modals/BaseModal';
import IdentifierValueInput from 'components/IdentifierValueInput';
import { IdentifierType } from 'routes/profile/models/Identifier';
import useValidation from 'utils/custom-react-hooks/useValidation';
import { isValidEmail } from 'utils/identifiers';
import { closeModal } from 'store/modalSlice';
import { useExportProfileMutation } from 'store/query';
import { openToast, ToastVariant } from 'store/toastSlice';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import { confirmDownloadDataProperties } from 'services/tracking/cdp/events/profile';
import { useTranslation } from 'react-i18next';

export type RequestDownloadModalProps = {
  idempotencyKey: string;
  defaultEmail: string;
};

const RequestDownloadModal: React.FC<RequestDownloadModalProps> = ({
  idempotencyKey,
  defaultEmail,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [exportProfile, exportProfileResult] = useExportProfileMutation();

  const [destinationEmail, setDestinationEmail] =
    useState<string>(defaultEmail);

  const [isInvalid, resetValidation, validate] = useValidation(isValidEmail);

  const sendRequest = async () => {
    if (!validate(destinationEmail)) {
      return;
    }

    await exportProfile({
      destinationEmail,
      idempotencyKey,
    })
      .unwrap()
      .then(() => {
        buyerportalCdpClient.track(
          'Confirm Download Data',
          confirmDownloadDataProperties()
        );

        dispatch(
          openToast({
            message: t(
              'profile.account.downloadData.requestDownloadModal.requestSent'
            ),
            variant: ToastVariant.Success,
          })
        );
      })
      .catch(() => {
        dispatch(
          openToast({
            message: t('common.somethingWentWrong.retryable.direct'),
            variant: ToastVariant.Critical,
          })
        );
      });

    dispatch(closeModal());
  };

  const isSaveButtonDisabled = exportProfileResult.isLoading;

  return (
    <BaseModal>
      <MarketHeader>
        <MarketButton
          rank={'primary'}
          slot={'actions'}
          {...(isSaveButtonDisabled && { disabled: true })}
          onClick={sendRequest}
        >
          {t('profile.account.downloadData.requestDownloadModal.sendRequest')}
        </MarketButton>
      </MarketHeader>
      <h2 className={'heading-30 mt-6 mb-4'}>
        {t('profile.account.downloadData.requestDownloadModal.title')}
      </h2>
      <p className={'paragraph-30 mt-0 mb-8'}>
        {t('profile.account.downloadData.requestDownloadModal.subtitle')}
      </p>
      <IdentifierValueInput
        identifierType={IdentifierType.Email}
        identifierValue={destinationEmail}
        onEnterPressed={sendRequest}
        isInvalid={isInvalid}
        onIdentifierValueChanged={(value) => {
          setDestinationEmail(value);
          resetValidation();
        }}
        {...(exportProfileResult.isLoading && { disabled: true })}
        emailInputDataTestId={'email-input'}
        enableTypeSelection={false}
      />
    </BaseModal>
  );
};

export default RequestDownloadModal;
