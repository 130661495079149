/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../../Fixes';
import Builder from '../../../../../Builder';
import './enums';
import '../../common/money';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      connect: {
        nested: {
          v2: {
            nested: {
              merchant_catalog: {
                nested: {
                  resources: {
                    nested: {
                      ItemVariationWeight: {
                        fields: {
                          amount: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          weightUnit: {
                            type: 'squareup.connect.v2.merchant_catalog.resources.CatalogItemVariationWeightUnit',
                            id: 2,
                            rule: 'optional',
                          },
                        },
                      },
                      ServiceCost: {
                        fields: {
                          pricingType: {
                            type: 'squareup.connect.v2.merchant_catalog.resources.ServiceCostType',
                            id: 1,
                            rule: 'optional',
                          },
                          amountFlat: {
                            type: 'squareup.connect.v2.common.Money',
                            id: 2,
                            rule: 'optional',
                          },
                          amountPercent: {
                            type: 'string',
                            id: 3,
                            rule: 'optional',
                          },
                        },
                      },
                      CatalogObjectReference: {
                        fields: {
                          objectId: {
                            type: 'string',
                            id: 1,
                            rule: 'optional',
                          },
                          catalogVersion: {
                            type: 'int64',
                            id: 2,
                            rule: 'optional',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.merchant_catalog
    ?.nested?.resources?.nested?.ItemVariationWeight &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.merchant_catalog
    ?.nested?.resources?.nested?.ServiceCost &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.merchant_catalog
    ?.nested?.resources?.nested?.CatalogObjectReference
) {
  $root.addJSON(jsonData);
}

/**
 * --
 * @desc Represent the weight of an ItemVariation
 * --
 *
 * SOURCE ItemVariationWeight @ squareup/connect/v2/catalog/resources/datatypes.proto at 1285:1
 */
export const ItemVariationWeight = $root.lookupType(
  'squareup.connect.v2.merchant_catalog.resources.ItemVariationWeight'
);
fixType(ItemVariationWeight);
Builder.createAndAttachToType(ItemVariationWeight);
/**
 * --
 * @desc Cost of materials required to perform an appointments service.
 * --
 *
 * SOURCE ServiceCost @ squareup/connect/v2/catalog/resources/datatypes.proto at 1330:1
 */
export const ServiceCost = $root.lookupType(
  'squareup.connect.v2.merchant_catalog.resources.ServiceCost'
);
fixType(ServiceCost);
Builder.createAndAttachToType(ServiceCost);
/**
 * --
 * @desc A reference to a Catalog object at a specific version. In general this is
 * used as an entry point into a graph of catalog objects, where the objects exist
 * at a specific version.
 * --
 *
 * SOURCE CatalogObjectReference @ squareup/connect/v2/catalog/resources/datatypes.proto at 3792:1
 */
export const CatalogObjectReference = $root.lookupType(
  'squareup.connect.v2.merchant_catalog.resources.CatalogObjectReference'
);
fixType(CatalogObjectReference);
Builder.createAndAttachToType(CatalogObjectReference);
