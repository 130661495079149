import React from 'react';
import { MarketDivider, MarketLink } from '@market/react';
import { useTranslation } from 'react-i18next';
import { getDashboardDomain } from 'config/config';
import { useSelector } from 'react-redux';
import { BoolFlag } from 'routes/profile/models/Flags';
import { AppState } from 'store';
import { selectBoolFlag } from 'store/featureSlice';

const SquareInformational: React.FC = () => {
  const { t } = useTranslation();

  const useAppSubdomain = useSelector((state: AppState) =>
    selectBoolFlag(state, BoolFlag.useAppSubdomain)
  );
  const link = `https://${getDashboardDomain(useAppSubdomain)}/`;
  return (
    <>
      <MarketDivider className="my-8" />
      <h2 className="heading-20 mb-0 mt-0">
        {t('receiptLookup.footer.title')}
      </h2>
      <p className="paragraph-30">{t('receiptLookup.footer.text')}</p>
      <MarketLink href={link} data-testid="learn-more-link">
        {t('receiptLookup.footer.learnMore')}
      </MarketLink>
    </>
  );
};

export default SquareInformational;
