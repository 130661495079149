import React from 'react';
import { BuyerCardConverter } from 'routes/profile/models/BuyerCard';
import EllipsisIcon from 'svgs/EllipsisIcon';

type PaymentCardPreviewProps = {
  className?: string;
  lastFour: string;
  brand: string;
  height?: number;
};

export const DEFAULT_PAYMENT_CARD_PREVIEW_HEIGHT = 261;

const PaymentCardPreview: React.FC<PaymentCardPreviewProps> = (props) => {
  const RATIOS = {
    BRAND_HEIGHT_TO_PREVIEW_RATIO: 0.11,
    WIDTH_TO_HEIGHT_RATIO: 1.4,
    INNER_BOTTOM_PADDING_TO_HEIGHT_RATIO: 0.18,
    INNER_X_PADDING_TO_HEIGHT_RATIO: 0.14,
  };

  // calculate dynamic sizing
  const height = props.height || DEFAULT_PAYMENT_CARD_PREVIEW_HEIGHT;
  const width = height * RATIOS.WIDTH_TO_HEIGHT_RATIO;
  const brandHeight = Math.floor(height * RATIOS.BRAND_HEIGHT_TO_PREVIEW_RATIO);
  const innerBottomPadding = Math.floor(
    height * RATIOS.INNER_BOTTOM_PADDING_TO_HEIGHT_RATIO
  );
  const innerXPadding = Math.floor(
    height * RATIOS.INNER_X_PADDING_TO_HEIGHT_RATIO
  );

  const renderBackground = () => {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 384 261"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_dd_2177_570)">
          <rect
            x="16"
            y="14"
            width="352"
            height="229"
            rx="12"
            fill="black"
            fillOpacity="0.9"
          />
        </g>
        <path
          d="M16 110L368 29.5V231C368 237.627 362.627 243 356 243H28C21.3726 243 16 237.627 16 231V110Z"
          fill="white"
          fillOpacity="0.09"
        />
        <defs>
          <filter
            id="filter0_dd_2177_570"
            x="0"
            y="0"
            width="384"
            height="261"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="8" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2177_570"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_2177_570"
              result="effect2_dropShadow_2177_570"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_2177_570"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  };

  const brandImageUrl = BuyerCardConverter.getBrandImageUrl(props.brand);

  return (
    <div
      aria-hidden
      className={`${props.className} relative`}
      style={{ width }}
    >
      {renderBackground()}
      <span
        className={'absolute text-white flex justify-center'}
        style={{ bottom: innerBottomPadding, left: innerXPadding }}
      >
        {Array.from({ length: 4 }, (e, i) => (
          <div className={'pr-1 flex items-center'} key={i}>
            <EllipsisIcon />
          </div>
        ))}
        <span className={'pl-1'}>{props.lastFour}</span>
      </span>
      {brandImageUrl && (
        <img
          className={'absolute'}
          style={{
            height: brandHeight,
            bottom: innerBottomPadding,
            right: innerXPadding,
          }}
          src={brandImageUrl}
          alt={props.brand}
        />
      )}
    </div>
  );
};

export default PaymentCardPreview;
