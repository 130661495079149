// eslint-disable-next-line filenames/match-exported
import { MarketDivider, MarketEmptyState } from '@market/react';
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { selectIdentifierCollection, selectPrimaryId } from 'store/buyerSlice';
import { IdentifierType } from 'routes/profile/models/Identifier';
import {
  useRetrieveCardPreferencesQuery,
  useRetrieveGlobalEmailPreferencesQuery,
  useRetrieveMerchantEmailPreferencesQuery,
} from 'store/query/api-extensions/receiptAndMarketingPreferences';
import { useListCardsQuery } from 'store/query';
import {
  GlobalEmailPreference,
  Value,
} from 'routes/profile/models/EmailPreference';
import { Product as CardPreferenceProduct } from 'routes/profile/models/CardPreference';
import ReceiptDeliveryOptions from './ReceiptDeliveryOptions';
import ModuleLoading from '../common/loading/ModuleLoading';
import ModuleLoadFailed from '../common/errors/ModuleLoadFailed';
import BuyerCard from 'routes/profile/models/BuyerCard';
import ReceiptPreferenceToggles from './ReceiptPreferenceToggles';
import ReceiptCardTabs from './ReceiptCardTabs';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Page from '../layout/Page';
import useCdpPageTracking from 'utils/custom-react-hooks/useCdpPageTracking';
import { viewReceiptSettingsProperties } from 'services/tracking/cdp/events/receiptSettings';
import { useTranslation } from 'react-i18next';

const ReceiptSettings: React.FC = () => {
  const [isInitialLoaded, setIsInitialLoaded] = useState(false);
  const { t } = useTranslation();

  const primaryCardId = useSelector((appState: AppState) =>
    selectPrimaryId(appState, IdentifierType.Card)
  );

  const verifiedEmails = useSelector((state: AppState) =>
    selectIdentifierCollection(state, IdentifierType.Email)
  );

  const {
    data: paymentCards,
    isLoading: isPaymentCardsLoading,
    isError: didPaymentCardsLoadingFail,
  } = useListCardsQuery();

  const [selectedPaymentCard, setSelectedPaymentCard] = useState<
    BuyerCard | undefined
  >(undefined);

  useEffect(() => {
    if (paymentCards && !isPaymentCardsLoading) {
      const initialSelectedCardId =
        primaryCardId || paymentCards?.at(0)?.cardsApiId;
      const initialSelectedCard = paymentCards?.find(
        (card) => card.cardsApiId === initialSelectedCardId
      );
      setSelectedPaymentCard(initialSelectedCard);
    }
  }, [paymentCards, primaryCardId, isPaymentCardsLoading]);

  const retrieveCardPreferencesArg =
    selectedPaymentCard?.preferenceCardId ?? skipToken;

  const {
    data: cardResponse,
    isLoading: isCardPreferencesLoading,
    isFetching: isCardPreferencesFetching,
    isError: didCardPreferencesLoadingFail,
  } = useRetrieveCardPreferencesQuery(retrieveCardPreferencesArg);

  const cardPreferences = cardResponse?.cardPreferences ?? [];

  const onCardSelected = useCallback(
    (cardsApiId: string) => {
      const selectedCard = paymentCards?.find(
        (card) => card.cardsApiId === cardsApiId
      );
      setSelectedPaymentCard(selectedCard);
    },
    [paymentCards]
  );

  const linkedEmailId = cardPreferences?.find(
    (pref) => pref.identifier.identifierType === IdentifierType.Email
  )?.identifier?.token;

  const linkedPhoneId = cardPreferences?.find(
    (pref) => pref.identifier.identifierType === IdentifierType.Phone
  )?.identifier?.token;

  const retrieveReceiptPreferencesArg =
    linkedEmailId !== undefined &&
    verifiedEmails.some(({ token }) => token === linkedEmailId)
      ? linkedEmailId
      : skipToken;

  const isSelectedCardGloballyOptedInToReceipts = (
    globalEmailPreferences: GlobalEmailPreference | undefined,
    selectedPaymentCard: BuyerCard
  ): boolean => {
    // card can't be globally opted in if we don't have a linked email
    if (!linkedEmailId) {
      return false;
    }

    // Card may be new and therefore not have any prefs yet
    if (!globalEmailPreferences) {
      return false;
    }

    const relevantCardPref = globalEmailPreferences.cardReceiptPreferences.find(
      (cardPref) => cardPref.cardId === selectedPaymentCard.preferenceCardId
    );
    // Edge case: There may not be any card prefs for the current card due to a manually registered card (i.e. not added from suggested identifiers). or
    // the email the card was linked to was deleted, severing the link in buyer service.
    if (!relevantCardPref) {
      return false;
    }

    return relevantCardPref.receiptPreference.value === Value.OptIn;
  };

  const {
    data: globalEmailPreferences,
    isLoading: isGlobalEmailPreferencesLoading,
    isFetching: isGlobalEmailPreferencesFetching,
    isError: didGlobalEmailPreferencesLoadingFail,
  } = useRetrieveGlobalEmailPreferencesQuery(retrieveReceiptPreferencesArg);

  // Although we could wait to fetch merchant prefs until we know the user is globally opted in,
  // we fetch them here to avoid a delay when the user is in fact opted in.
  const {
    data: receiptMerchantEmailPreferences,
    isLoading: isReceiptMerchantEmailPreferencesLoading,
  } = useRetrieveMerchantEmailPreferencesQuery(retrieveReceiptPreferencesArg);

  const isLoading =
    !isInitialLoaded &&
    (isPaymentCardsLoading ||
      isCardPreferencesLoading ||
      isGlobalEmailPreferencesLoading ||
      Boolean(paymentCards && paymentCards.length > 0 && !selectedPaymentCard));

  const didLoadingFail =
    didPaymentCardsLoadingFail ||
    didCardPreferencesLoadingFail ||
    didGlobalEmailPreferencesLoadingFail;

  useCdpPageTracking({
    properties: viewReceiptSettingsProperties(
      paymentCards?.map((card) => card.cardsApiId) ?? [],
      linkedEmailId,
      linkedPhoneId,
      isSelectedCardGloballyOptedInToReceipts(
        globalEmailPreferences,
        selectedPaymentCard!
      ),
      selectedPaymentCard?.cardsApiId
    ),
    isLoading,
    didLoadingFail,
    onLoad: () => setIsInitialLoaded(true),
  });

  if (isLoading) {
    return <ModuleLoading />;
  }

  if (didLoadingFail) {
    return <ModuleLoadFailed />;
  }

  const renderEmpty = () => {
    return (
      <MarketEmptyState>
        <p slot={'secondary-text'}>
          {t('profile.receiptSettings.emptyCardText')}
        </p>
      </MarketEmptyState>
    );
  };

  const renderNonEmpty = () => {
    return (
      <div>
        <ReceiptCardTabs
          paymentCards={paymentCards}
          primaryCardId={primaryCardId}
          selectedPaymentCardId={selectedPaymentCard!.cardsApiId}
          onCardSelected={onCardSelected}
        />

        {/* Start of delivery options */}
        <ReceiptDeliveryOptions
          preferenceCardId={selectedPaymentCard!.preferenceCardId}
          cardPreferences={cardPreferences.filter(
            (pref) => pref.product === CardPreferenceProduct.Receipt
          )}
          isRetrievePrefsLoading={isCardPreferencesFetching}
        />
        {/* End of delivery options */}
        <MarketDivider className="my-8"></MarketDivider>
        <ReceiptPreferenceToggles
          deliveryEmailId={linkedEmailId ?? null}
          isGloballyOptedIn={isSelectedCardGloballyOptedInToReceipts(
            globalEmailPreferences,
            selectedPaymentCard!
          )}
          isGlobalToggleDisabled={isGlobalEmailPreferencesFetching}
          selectedPaymentCard={selectedPaymentCard!}
          merchantPrefs={receiptMerchantEmailPreferences ?? []}
          isMerchantEmailPreferencesLoading={
            isReceiptMerchantEmailPreferencesLoading
          }
        />
      </div>
    );
  };

  return (
    <Page>
      <h1 className={'heading-30 mb-4'}>
        {t('profile.receiptSettings.title')}
      </h1>
      <p className={'mb-8'}>{t('profile.receiptSettings.subtitle')}</p>
      {paymentCards && paymentCards.length > 0
        ? renderNonEmpty()
        : renderEmpty()}
    </Page>
  );
};

export default ReceiptSettings;
