/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../../nanopb/nanopb';
import '../../grpc/gateway/protoc_gen_openapiv2/options/openapiv2';
import '../../squareup/agenda/dynamicflags';
import '../../squareup/connect/v2/common/options';
import '../../squareup/client/options';
import '../../squareup/api/sync/sync';
import '../../squareup/privacyvault/model';
import '../../squareup/common/governance';
import '../../squareup/pago/external/payments_model';
import '../../squareup/omg/opt/options';
import '../../squareup/common/validation';
import '../../squareup/common/currency_codes';
import '../../squareup/connect/v2/resources/error';
import '../../squareup/objc/objc-rpc';
import '../../squareup/httprpc/httprpc';
import '../../squareup/tabuloid/tabuloid';
import '../../squareup/multipass/options';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  google: {
    nested: {
      protobuf: {
        nested: {
          FileDescriptorSet: {
            fields: {
              file: {
                type: 'google.protobuf.FileDescriptorProto',
                id: 1,
                rule: 'repeated',
              },
            },
          },
          FileDescriptorProto: {
            fields: {
              name: {
                type: 'string',
                id: 1,
                rule: 'optional',
              },
              package: {
                type: 'string',
                id: 2,
                rule: 'optional',
              },
              dependency: {
                type: 'string',
                id: 3,
                rule: 'repeated',
              },
              publicDependency: {
                type: 'int32',
                id: 10,
                rule: 'repeated',
              },
              weakDependency: {
                type: 'int32',
                id: 11,
                rule: 'repeated',
              },
              messageType: {
                type: 'google.protobuf.DescriptorProto',
                id: 4,
                rule: 'repeated',
              },
              enumType: {
                type: 'google.protobuf.EnumDescriptorProto',
                id: 5,
                rule: 'repeated',
              },
              service: {
                type: 'google.protobuf.ServiceDescriptorProto',
                id: 6,
                rule: 'repeated',
              },
              extension: {
                type: 'google.protobuf.FieldDescriptorProto',
                id: 7,
                rule: 'repeated',
              },
              options: {
                type: 'google.protobuf.FileOptions',
                id: 8,
                rule: 'optional',
              },
              sourceCodeInfo: {
                type: 'google.protobuf.SourceCodeInfo',
                id: 9,
                rule: 'optional',
              },
              syntax: {
                type: 'string',
                id: 12,
                rule: 'optional',
              },
            },
          },
          DescriptorProto: {
            fields: {
              name: {
                type: 'string',
                id: 1,
                rule: 'optional',
              },
              field: {
                type: 'google.protobuf.FieldDescriptorProto',
                id: 2,
                rule: 'repeated',
              },
              extension: {
                type: 'google.protobuf.FieldDescriptorProto',
                id: 6,
                rule: 'repeated',
              },
              nestedType: {
                type: 'google.protobuf.DescriptorProto',
                id: 3,
                rule: 'repeated',
              },
              enumType: {
                type: 'google.protobuf.EnumDescriptorProto',
                id: 4,
                rule: 'repeated',
              },
              extensionRange: {
                type: 'google.protobuf.DescriptorProto.ExtensionRange',
                id: 5,
                rule: 'repeated',
              },
              oneofDecl: {
                type: 'google.protobuf.OneofDescriptorProto',
                id: 8,
                rule: 'repeated',
              },
              options: {
                type: 'google.protobuf.MessageOptions',
                id: 7,
                rule: 'optional',
              },
              reservedRange: {
                type: 'google.protobuf.DescriptorProto.ReservedRange',
                id: 9,
                rule: 'repeated',
              },
              reservedName: {
                type: 'string',
                id: 10,
                rule: 'repeated',
              },
            },
            nested: {
              ExtensionRange: {
                fields: {
                  start: {
                    type: 'int32',
                    id: 1,
                    rule: 'optional',
                  },
                  end: {
                    type: 'int32',
                    id: 2,
                    rule: 'optional',
                  },
                  options: {
                    type: 'google.protobuf.ExtensionRangeOptions',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
              ReservedRange: {
                fields: {
                  start: {
                    type: 'int32',
                    id: 1,
                    rule: 'optional',
                  },
                  end: {
                    type: 'int32',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
            },
          },
          ExtensionRangeOptions: {
            fields: {
              uninterpretedOption: {
                type: 'google.protobuf.UninterpretedOption',
                id: 999,
                rule: 'repeated',
              },
            },
          },
          FieldDescriptorProto: {
            fields: {
              name: {
                type: 'string',
                id: 1,
                rule: 'optional',
              },
              number: {
                type: 'int32',
                id: 3,
                rule: 'optional',
              },
              label: {
                type: 'google.protobuf.FieldDescriptorProto.Label',
                id: 4,
                rule: 'optional',
              },
              type: {
                type: 'google.protobuf.FieldDescriptorProto.Type',
                id: 5,
                rule: 'optional',
              },
              typeName: {
                type: 'string',
                id: 6,
                rule: 'optional',
              },
              extendee: {
                type: 'string',
                id: 2,
                rule: 'optional',
              },
              defaultValue: {
                type: 'string',
                id: 7,
                rule: 'optional',
              },
              oneofIndex: {
                type: 'int32',
                id: 9,
                rule: 'optional',
              },
              jsonName: {
                type: 'string',
                id: 10,
                rule: 'optional',
              },
              options: {
                type: 'google.protobuf.FieldOptions',
                id: 8,
                rule: 'optional',
              },
            },
            nested: {
              Type: {
                values: {
                  TYPE_DOUBLE: 1,
                  TYPE_FLOAT: 2,
                  TYPE_INT64: 3,
                  TYPE_UINT64: 4,
                  TYPE_INT32: 5,
                  TYPE_FIXED64: 6,
                  TYPE_FIXED32: 7,
                  TYPE_BOOL: 8,
                  TYPE_STRING: 9,
                  TYPE_GROUP: 10,
                  TYPE_MESSAGE: 11,
                  TYPE_BYTES: 12,
                  TYPE_UINT32: 13,
                  TYPE_ENUM: 14,
                  TYPE_SFIXED32: 15,
                  TYPE_SFIXED64: 16,
                  TYPE_SINT32: 17,
                  TYPE_SINT64: 18,
                },
              },
              Label: {
                values: {
                  LABEL_OPTIONAL: 1,
                  LABEL_REQUIRED: 2,
                  LABEL_REPEATED: 3,
                },
              },
            },
          },
          OneofDescriptorProto: {
            fields: {
              name: {
                type: 'string',
                id: 1,
                rule: 'optional',
              },
              options: {
                type: 'google.protobuf.OneofOptions',
                id: 2,
                rule: 'optional',
              },
            },
          },
          EnumDescriptorProto: {
            fields: {
              name: {
                type: 'string',
                id: 1,
                rule: 'optional',
              },
              value: {
                type: 'google.protobuf.EnumValueDescriptorProto',
                id: 2,
                rule: 'repeated',
              },
              options: {
                type: 'google.protobuf.EnumOptions',
                id: 3,
                rule: 'optional',
              },
              reservedRange: {
                type: 'google.protobuf.EnumDescriptorProto.EnumReservedRange',
                id: 4,
                rule: 'repeated',
              },
              reservedName: {
                type: 'string',
                id: 5,
                rule: 'repeated',
              },
            },
            nested: {
              EnumReservedRange: {
                fields: {
                  start: {
                    type: 'int32',
                    id: 1,
                    rule: 'optional',
                  },
                  end: {
                    type: 'int32',
                    id: 2,
                    rule: 'optional',
                  },
                },
              },
            },
          },
          EnumValueDescriptorProto: {
            fields: {
              name: {
                type: 'string',
                id: 1,
                rule: 'optional',
              },
              number: {
                type: 'int32',
                id: 2,
                rule: 'optional',
              },
              options: {
                type: 'google.protobuf.EnumValueOptions',
                id: 3,
                rule: 'optional',
              },
            },
          },
          ServiceDescriptorProto: {
            fields: {
              name: {
                type: 'string',
                id: 1,
                rule: 'optional',
              },
              method: {
                type: 'google.protobuf.MethodDescriptorProto',
                id: 2,
                rule: 'repeated',
              },
              options: {
                type: 'google.protobuf.ServiceOptions',
                id: 3,
                rule: 'optional',
              },
            },
          },
          MethodDescriptorProto: {
            fields: {
              name: {
                type: 'string',
                id: 1,
                rule: 'optional',
              },
              inputType: {
                type: 'string',
                id: 2,
                rule: 'optional',
              },
              outputType: {
                type: 'string',
                id: 3,
                rule: 'optional',
              },
              options: {
                type: 'google.protobuf.MethodOptions',
                id: 4,
                rule: 'optional',
              },
              clientStreaming: {
                type: 'bool',
                id: 5,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              serverStreaming: {
                type: 'bool',
                id: 6,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
            },
          },
          FileOptions: {
            fields: {
              javaPackage: {
                type: 'string',
                id: 1,
                rule: 'optional',
              },
              javaOuterClassname: {
                type: 'string',
                id: 8,
                rule: 'optional',
              },
              javaMultipleFiles: {
                type: 'bool',
                id: 10,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              javaGenerateEqualsAndHash: {
                type: 'bool',
                id: 20,
                rule: 'optional',
              },
              javaStringCheckUtf8: {
                type: 'bool',
                id: 27,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              optimizeFor: {
                type: 'google.protobuf.FileOptions.OptimizeMode',
                id: 9,
                rule: 'optional',
                options: {
                  default: 'SPEED',
                },
              },
              goPackage: {
                type: 'string',
                id: 11,
                rule: 'optional',
              },
              ccGenericServices: {
                type: 'bool',
                id: 16,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              javaGenericServices: {
                type: 'bool',
                id: 17,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              pyGenericServices: {
                type: 'bool',
                id: 18,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              phpGenericServices: {
                type: 'bool',
                id: 42,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              deprecated: {
                type: 'bool',
                id: 23,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              ccEnableArenas: {
                type: 'bool',
                id: 31,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              objcClassPrefix: {
                type: 'string',
                id: 36,
                rule: 'optional',
              },
              csharpNamespace: {
                type: 'string',
                id: 37,
                rule: 'optional',
              },
              swiftPrefix: {
                type: 'string',
                id: 39,
                rule: 'optional',
              },
              phpClassPrefix: {
                type: 'string',
                id: 40,
                rule: 'optional',
              },
              phpNamespace: {
                type: 'string',
                id: 41,
                rule: 'optional',
              },
              uninterpretedOption: {
                type: 'google.protobuf.UninterpretedOption',
                id: 999,
                rule: 'repeated',
              },
              nanopbFileopt: {
                type: 'nanopb.NanoPBOptions',
                id: 1010,
                rule: 'optional',
              },
              openapiv2Swagger: {
                type: 'grpc.gateway.protoc_gen_openapiv2.options.Swagger',
                id: 1042,
              },
              wirePackage: {
                type: 'string',
                id: 1087,
                rule: 'optional',
              },
              classPrefix: {
                type: 'string',
                id: 12450,
                rule: 'optional',
                options: {
                  default: '',
                },
              },
              projectIncludePrefix: {
                type: 'string',
                id: 12452,
                rule: 'optional',
              },
              filePrefix: {
                type: 'string',
                id: 6584,
                rule: 'optional',
              },
              defaultRules: {
                type: 'squareup.agenda.ForcedUpgradeRules',
                id: 33000,
                rule: 'optional',
              },
              fileStatus: {
                type: 'squareup.connect.v2.common.ReleaseStatus',
                id: 60000,
                rule: 'optional',
              },
              fileVersion: {
                type: 'string',
                id: 60001,
                rule: 'optional',
              },
              fileNamespace: {
                type: 'string',
                id: 60002,
                rule: 'optional',
              },
              fileOwnedBy: {
                type: 'string',
                id: 60003,
                rule: 'optional',
              },
              fileSkipLintChecks: {
                type: 'string',
                id: 60004,
                rule: 'repeated',
              },
              appVersions: {
                type: 'squareup.client.AppVersionRanges',
                id: 25000,
                rule: 'optional',
              },
            },
            nested: {
              OptimizeMode: {
                values: {
                  SPEED: 1,
                  CODE_SIZE: 2,
                  LITE_RUNTIME: 3,
                },
              },
            },
          },
          MessageOptions: {
            fields: {
              messageSetWireFormat: {
                type: 'bool',
                id: 1,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              noStandardDescriptorAccessor: {
                type: 'bool',
                id: 2,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              deprecated: {
                type: 'bool',
                id: 3,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              mapEntry: {
                type: 'bool',
                id: 7,
                rule: 'optional',
              },
              uninterpretedOption: {
                type: 'google.protobuf.UninterpretedOption',
                id: 999,
                rule: 'repeated',
              },
              nanopbMsgopt: {
                type: 'nanopb.NanoPBOptions',
                id: 1010,
                rule: 'optional',
              },
              type: {
                type: 'string',
                id: 50005,
                rule: 'optional',
              },
              openapiv2Schema: {
                type: 'grpc.gateway.protoc_gen_openapiv2.options.Schema',
                id: 1042,
              },
              eventName: {
                type: 'string',
                id: 81001,
                rule: 'optional',
              },
              tableName: {
                type: 'string',
                id: 11000,
                rule: 'optional',
              },
              joinTable: {
                type: 'bool',
                id: 12451,
                rule: 'optional',
              },
              type: {
                type: 'squareup.api.sync.ObjectType',
                id: 12000,
                rule: 'optional',
              },
              tableName: {
                type: 'string',
                id: 24700,
                rule: 'optional',
              },
              messageTokenType: {
                type: 'squareup.privacyvault.model.Entity.TokenType',
                id: 22205,
                rule: 'optional',
              },
              messagePrivacyScopes: {
                type: 'squareup.privacyvault.model.PrivacyScope',
                id: 22211,
                rule: 'repeated',
              },
              messageExportEnabled: {
                type: 'bool',
                id: 22213,
                rule: 'optional',
              },
              orderExtensionsPoint: {
                type: 'bool',
                id: 27800,
                rule: 'optional',
              },
              gnsJwsSigning: {
                type: 'bool',
                id: 51652,
                rule: 'optional',
              },
              tableName: {
                type: 'string',
                id: 55002,
                rule: 'optional',
              },
              messageValidators: {
                type: 'string',
                id: 22300,
                rule: 'optional',
              },
              dataSafetyLevel: {
                type: 'int32',
                id: 23200,
                rule: 'optional',
              },
              domain: {
                type: 'squareup.governance.GovernanceDomain',
                id: 23201,
                rule: 'optional',
              },
              messageStatus: {
                type: 'squareup.connect.v2.common.ReleaseStatus',
                id: 60000,
                rule: 'optional',
              },
              jsonExamplePath: {
                type: 'string',
                id: 60001,
                rule: 'optional',
              },
              jsonExampleType: {
                type: 'string',
                id: 60003,
                rule: 'optional',
              },
              sdkSampleDirectory: {
                type: 'string',
                id: 60002,
                rule: 'optional',
              },
              messageVersion: {
                type: 'string',
                id: 60004,
                rule: 'optional',
              },
              messageNamespace: {
                type: 'string',
                id: 60005,
                rule: 'optional',
              },
              messageOwnedBy: {
                type: 'string',
                id: 60007,
                rule: 'optional',
              },
              messageSkipLintChecks: {
                type: 'string',
                id: 60008,
                rule: 'repeated',
              },
              featureFlag: {
                type: 'string',
                id: 60009,
                rule: 'optional',
              },
              entityName: {
                type: 'string',
                id: 60010,
                rule: 'optional',
              },
              mapLikeListElement: {
                type: 'bool',
                id: 60012,
                rule: 'optional',
              },
              containsPii: {
                type: 'bool',
                id: 70003,
                rule: 'optional',
              },
              event: {
                type: 'string',
                id: 60900,
                rule: 'optional',
              },
              messageVersions: {
                type: 'squareup.client.AppVersionRanges',
                id: 25000,
                rule: 'optional',
              },
              owner: {
                type: 'string',
                id: 55682,
              },
              readList: {
                type: 'string',
                id: 55683,
              },
              writeList: {
                type: 'string',
                id: 55684,
              },
              fromKeyField: {
                type: 'string',
                id: 55689,
              },
              toKeyField: {
                type: 'string',
                id: 55690,
              },
              owner: {
                type: 'string',
                id: 51234,
                rule: 'optional',
              },
              readList: {
                type: 'string',
                id: 51235,
                rule: 'optional',
              },
              writeList: {
                type: 'string',
                id: 51236,
                rule: 'optional',
              },
              keyAlias: {
                type: 'string',
                id: 50601,
                rule: 'optional',
              },
              message: {
                type: 'bool',
                id: 67700,
                rule: 'optional',
              },
            },
          },
          FieldOptions: {
            fields: {
              packed: {
                type: 'bool',
                id: 2,
                rule: 'optional',
              },
              lazy: {
                type: 'bool',
                id: 5,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              deprecated: {
                type: 'bool',
                id: 3,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              weak: {
                type: 'bool',
                id: 10,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              uninterpretedOption: {
                type: 'google.protobuf.UninterpretedOption',
                id: 999,
                rule: 'repeated',
              },
              nanopb: {
                type: 'nanopb.NanoPBOptions',
                id: 1010,
                rule: 'optional',
              },
              id: {
                type: 'bool',
                id: 50006,
                rule: 'optional',
              },
              searchable: {
                type: 'bool',
                id: 50009,
                rule: 'optional',
              },
              openapiv2Field: {
                type: 'grpc.gateway.protoc_gen_openapiv2.options.JSONSchema',
                id: 1042,
              },
              since: {
                type: 'string',
                id: 1076,
                rule: 'optional',
              },
              until: {
                type: 'string',
                id: 1077,
                rule: 'optional',
              },
              derived: {
                type: 'bool',
                id: 32500,
                rule: 'optional',
              },
              immutable: {
                type: 'bool',
                id: 32501,
                rule: 'optional',
              },
              decimalPrecision: {
                type: 'uint32',
                id: 32502,
                rule: 'optional',
              },
              rfc3339Date: {
                type: 'bool',
                id: 32503,
                rule: 'optional',
              },
              rfc3339Datetime: {
                type: 'bool',
                id: 32504,
                rule: 'optional',
              },
              requiredOnCreation: {
                type: 'bool',
                id: 32505,
                rule: 'optional',
              },
              skipProtoValidation: {
                type: 'bool',
                id: 55001,
                rule: 'optional',
              },
              mysqlNullHax: {
                type: 'bool',
                id: 11004,
                rule: 'optional',
              },
              ignoreDefaultValue: {
                type: 'bool',
                id: 11008,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              notImplemented: {
                type: 'bool',
                id: 12354,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              attribute: {
                type: 'string',
                id: 23723,
                rule: 'optional',
              },
              relationship: {
                type: 'string',
                id: 23724,
                rule: 'optional',
              },
              ignore: {
                type: 'bool',
                id: 23725,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              leftSide: {
                type: 'bool',
                id: 23727,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              extensionJsonName: {
                type: 'string',
                id: 24001,
                rule: 'optional',
              },
              mapsTo: {
                type: 'squareup.api.sync.ObjectWrapper',
                id: 123301,
                rule: 'optional',
              },
              isPaymentToken: {
                type: 'bool',
                id: 25100,
                rule: 'optional',
              },
              eventCreationTime: {
                type: 'bool',
                id: 25101,
                rule: 'optional',
              },
              source: {
                type: 'string',
                id: 29400,
                rule: 'repeated',
              },
              riskIdentifier: {
                type: 'bool',
                id: 29700,
                rule: 'optional',
              },
              decimal: {
                type: 'bool',
                id: 24700,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              tokenType: {
                type: 'squareup.privacyvault.model.Entity.TokenType',
                id: 22207,
                rule: 'optional',
              },
              replacement: {
                type: 'string',
                id: 22209,
                rule: 'optional',
              },
              fideliusCategory: {
                type: 'string',
                id: 22210,
                rule: 'optional',
              },
              privacyScopes: {
                type: 'squareup.privacyvault.model.PrivacyScope',
                id: 22212,
                rule: 'repeated',
              },
              fieldExportEnabled: {
                type: 'bool',
                id: 22214,
                rule: 'optional',
              },
              acceptableReplacementValueRegex: {
                type: 'string',
                id: 22215,
                rule: 'optional',
              },
              readOnlyExcept: {
                type: 'squareup.connect.v2.ReadOnlyExcept',
                id: 34568,
                rule: 'optional',
              },
              logOnUse: {
                type: 'bool',
                id: 29100,
                rule: 'optional',
              },
              fuzzySearchable: {
                type: 'bool',
                id: 29104,
                rule: 'optional',
              },
              paymentStatus: {
                type: 'bool',
                id: 29105,
                rule: 'optional',
              },
              minDate: {
                type: 'string',
                id: 29108,
                rule: 'optional',
              },
              regexpSubstringSearchable: {
                type: 'bool',
                id: 29109,
                rule: 'optional',
              },
              enableHighlighting: {
                type: 'bool',
                id: 29110,
                rule: 'optional',
              },
              phoneNumber: {
                type: 'bool',
                id: 29111,
                rule: 'optional',
              },
              searchSubField: {
                type: 'string',
                id: 29112,
                rule: 'optional',
              },
              existsQuery: {
                type: 'bool',
                id: 29113,
                rule: 'optional',
              },
              notQuery: {
                type: 'bool',
                id: 29114,
                rule: 'optional',
              },
              matchNoneWhenEmpty: {
                type: 'bool',
                id: 29117,
                rule: 'optional',
              },
              immutable: {
                type: 'bool',
                id: 27102,
                rule: 'optional',
              },
              immutableOnceSet: {
                type: 'bool',
                id: 27107,
                rule: 'optional',
              },
              immutableAfterFulfillment: {
                type: 'bool',
                id: 27113,
                rule: 'optional',
              },
              logOnUse: {
                type: 'bool',
                id: 27106,
                rule: 'optional',
              },
              ordersFeatures: {
                type: 'squareup.omg.opt.OrdersFeatures',
                id: 27105,
                rule: 'optional',
              },
              skipReadOnlyValidation: {
                type: 'bool',
                id: 27108,
                rule: 'optional',
              },
              nonCloneableField: {
                type: 'bool',
                id: 27109,
                rule: 'optional',
              },
              internalField: {
                type: 'bool',
                id: 27110,
                rule: 'optional',
              },
              enforceInternallyField: {
                type: 'bool',
                id: 27111,
                rule: 'optional',
              },
              requiredForPrecalculatedOrder: {
                type: 'bool',
                id: 27112,
                rule: 'optional',
              },
              allowlistedClientOus: {
                type: 'string',
                id: 27114,
                rule: 'repeated',
              },
              externalizedModelExtension: {
                type: 'string',
                id: 27115,
                rule: 'optional',
              },
              externalizableModelExtensionContainer: {
                type: 'bool',
                id: 27116,
                rule: 'optional',
              },
              nonCloneableWhenForking: {
                type: 'bool',
                id: 27117,
                rule: 'optional',
              },
              requiredForPrecalculatedReturn: {
                type: 'bool',
                id: 27118,
                rule: 'optional',
              },
              maxSerializedSize: {
                type: 'int32',
                id: 27700,
                rule: 'optional',
              },
              warnSerializedSize: {
                type: 'int32',
                id: 27701,
                rule: 'optional',
              },
              billServerToken: {
                type: 'bool',
                id: 250001,
                rule: 'optional',
              },
              tenderServerToken: {
                type: 'bool',
                id: 250002,
                rule: 'optional',
              },
              merchantToken: {
                type: 'bool',
                id: 250003,
                rule: 'optional',
              },
              moneyTransferClientToken: {
                type: 'bool',
                id: 350000,
                rule: 'optional',
              },
              moneyTransferServerToken: {
                type: 'bool',
                id: 350001,
                rule: 'optional',
              },
              moneyTransferTenderClientToken: {
                type: 'bool',
                id: 350002,
                rule: 'optional',
              },
              moneyTransferTenderTokenPair: {
                type: 'bool',
                id: 350003,
                rule: 'optional',
              },
              moneyTransferTokenPair: {
                type: 'bool',
                id: 350004,
                rule: 'optional',
              },
              mdcField: {
                type: 'string',
                id: 78991,
                rule: 'optional',
              },
              row: {
                type: 'bool',
                id: 73101,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              columnName: {
                type: 'string',
                id: 77001,
                rule: 'optional',
              },
              effectiveDate: {
                type: 'bool',
                id: 77002,
                rule: 'optional',
              },
              whenNullPersistAs: {
                type: 'string',
                id: 77003,
                rule: 'optional',
              },
              primaryKey: {
                type: 'bool',
                id: 37001,
                rule: 'optional',
              },
              rosterOnlyDeviceProfile: {
                type: 'bool',
                id: 50000,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              fieldValidators: {
                type: 'string',
                id: 22300,
                rule: 'optional',
              },
              range: {
                type: 'squareup.validation.Range',
                id: 22301,
                rule: 'optional',
              },
              required: {
                type: 'bool',
                id: 22302,
                rule: 'optional',
              },
              required2: {
                type: 'bool',
                id: 22308,
                rule: 'optional',
              },
              notEmpty: {
                type: 'bool',
                id: 22303,
                rule: 'optional',
              },
              matchesPattern: {
                type: 'string',
                id: 22304,
                rule: 'optional',
              },
              length: {
                type: 'squareup.validation.Range',
                id: 22305,
                rule: 'optional',
              },
              collectionSize: {
                type: 'squareup.validation.Range',
                id: 22306,
                rule: 'optional',
              },
              nonNegativeMoney: {
                type: 'bool',
                id: 22307,
                rule: 'optional',
              },
              mapMaxSize: {
                type: 'uint32',
                id: 22309,
                rule: 'optional',
              },
              mapKeyLength: {
                type: 'squareup.validation.Range',
                id: 22310,
                rule: 'optional',
              },
              redacted: {
                type: 'bool',
                id: 22200,
                rule: 'optional',
              },
              tokenizable: {
                type: 'bool',
                id: 22201,
                rule: 'optional',
              },
              length: {
                type: 'int32',
                id: 50001,
                rule: 'optional',
              },
              decimalPlaces: {
                type: 'int32',
                id: 50002,
                rule: 'optional',
              },
              unitToken: {
                type: 'bool',
                id: 36000,
                rule: 'optional',
              },
              reservedGovernanceField12002: {
                type: 'string',
                id: 12002,
                rule: 'optional',
              },
              reservedGovernanceField12003: {
                type: 'string',
                id: 12003,
                rule: 'optional',
              },
              reservedGovernanceField12004: {
                type: 'string',
                id: 12004,
                rule: 'optional',
              },
              reservedGovernanceField12005: {
                type: 'string',
                id: 12005,
                rule: 'optional',
              },
              reservedGovernanceField12006: {
                type: 'string',
                id: 12006,
                rule: 'optional',
              },
              reservedGovernanceField12007: {
                type: 'string',
                id: 12007,
                rule: 'optional',
              },
              reservedGovernanceField12008: {
                type: 'string',
                id: 12008,
                rule: 'optional',
              },
              reservedGovernanceField12009: {
                type: 'string',
                id: 12009,
                rule: 'optional',
              },
              reservedGovernanceField12010: {
                type: 'string',
                id: 12010,
                rule: 'optional',
              },
              signed: {
                type: 'string',
                id: 22203,
                rule: 'optional',
              },
              nonSparseMessage: {
                type: 'bool',
                id: 27300,
                rule: 'optional',
              },
              required: {
                type: 'bool',
                id: 22500,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              maxLength: {
                type: 'int32',
                id: 22502,
                rule: 'optional',
              },
              minValue: {
                type: 'int64',
                id: 22503,
                rule: 'optional',
              },
              currentUnit: {
                type: 'bool',
                id: 59123,
                rule: 'optional',
              },
              required: {
                type: 'bool',
                id: 22600,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              maxLength: {
                type: 'int32',
                id: 22602,
                rule: 'optional',
              },
              maxSize: {
                type: 'int32',
                id: 22603,
                rule: 'optional',
              },
              isUid: {
                type: 'bool',
                id: 70000,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              fieldStatus: {
                type: 'squareup.connect.v2.common.ReleaseStatus',
                id: 60000,
                rule: 'optional',
              },
              pathParam: {
                type: 'bool',
                id: 60001,
                rule: 'optional',
              },
              queryParam: {
                type: 'bool',
                id: 60003,
                rule: 'optional',
              },
              catalogAttributeExternalName: {
                type: 'string',
                id: 60002,
                rule: 'optional',
              },
              jsonIgnore: {
                type: 'bool',
                id: 60004,
                rule: 'optional',
              },
              headerParam: {
                type: 'string',
                id: 60005,
                rule: 'optional',
              },
              fieldVersion: {
                type: 'string',
                id: 60006,
                rule: 'optional',
              },
              fieldNamespace: {
                type: 'string',
                id: 60007,
                rule: 'optional',
              },
              sparseUpdatePayload: {
                type: 'bool',
                id: 60008,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              readOnly: {
                type: 'bool',
                id: 60009,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              features: {
                type: 'squareup.connect.v2.common.Features',
                id: 60010,
                rule: 'optional',
              },
              fieldSkipLintChecks: {
                type: 'string',
                id: 60011,
                rule: 'repeated',
              },
              rfc3339DateTime: {
                type: 'bool',
                id: 60012,
                rule: 'optional',
              },
              rfc3339Duration: {
                type: 'bool',
                id: 60013,
                rule: 'optional',
              },
              ext2: {
                type: 'int64',
                id: 70004,
                rule: 'optional',
              },
              ext1: {
                type: 'int64',
                id: 70002,
                rule: 'optional',
              },
              instrumented: {
                type: 'bool',
                id: 50700,
                rule: 'optional',
              },
              derived: {
                type: 'bool',
                id: 26000,
                rule: 'optional',
              },
              fieldVersions: {
                type: 'squareup.client.AppVersionRanges',
                id: 25000,
                rule: 'optional',
              },
              derived: {
                type: 'bool',
                id: 25002,
                rule: 'optional',
              },
              translatedFieldName: {
                type: 'string',
                id: 53100,
                rule: 'optional',
              },
              redacted: {
                type: 'bool',
                id: 26600,
                rule: 'optional',
              },
              buyerReadOnly: {
                type: 'bool',
                id: 11100,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              merchantOnly: {
                type: 'bool',
                id: 11101,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              immutableForBuyers: {
                type: 'bool',
                id: 11102,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              immutable: {
                type: 'bool',
                id: 11103,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
            },
          },
          OneofOptions: {
            fields: {
              uninterpretedOption: {
                type: 'google.protobuf.UninterpretedOption',
                id: 999,
                rule: 'repeated',
              },
            },
          },
          EnumOptions: {
            fields: {
              allowAlias: {
                type: 'bool',
                id: 2,
                rule: 'optional',
              },
              deprecated: {
                type: 'bool',
                id: 3,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              uninterpretedOption: {
                type: 'google.protobuf.UninterpretedOption',
                id: 999,
                rule: 'repeated',
              },
              nanopbEnumopt: {
                type: 'nanopb.NanoPBOptions',
                id: 1010,
                rule: 'optional',
              },
              lowercase: {
                type: 'bool',
                id: 11000,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              wtfMappedToStringifiedInt: {
                type: 'bool',
                id: 11001,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              mappedToInteger: {
                type: 'bool',
                id: 11002,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              enumName: {
                type: 'string',
                id: 6584,
                rule: 'optional',
              },
              persistAsInt: {
                type: 'bool',
                id: 66001,
                rule: 'optional',
              },
              enumStatus: {
                type: 'squareup.connect.v2.common.ReleaseStatus',
                id: 60000,
                rule: 'optional',
              },
              enumVersion: {
                type: 'string',
                id: 60001,
                rule: 'optional',
              },
              enumNamespace: {
                type: 'string',
                id: 60002,
                rule: 'optional',
              },
              enumOwnedBy: {
                type: 'string',
                id: 60004,
                rule: 'optional',
              },
              enumSkipLintChecks: {
                type: 'string',
                id: 60005,
                rule: 'repeated',
              },
              enumVersions: {
                type: 'squareup.client.AppVersionRanges',
                id: 25000,
                rule: 'optional',
              },
              enum: {
                type: 'bool',
                id: 67700,
                rule: 'optional',
              },
            },
          },
          EnumValueOptions: {
            fields: {
              deprecated: {
                type: 'bool',
                id: 1,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              uninterpretedOption: {
                type: 'google.protobuf.UninterpretedOption',
                id: 999,
                rule: 'repeated',
              },
              constantSince: {
                type: 'string',
                id: 1076,
                rule: 'optional',
              },
              constantUntil: {
                type: 'string',
                id: 1077,
                rule: 'optional',
              },
              name: {
                type: 'string',
                id: 51234,
                rule: 'optional',
              },
              stringName: {
                type: 'string',
                id: 123456789,
                rule: 'optional',
              },
              displayName: {
                type: 'string',
                id: 167572983,
                rule: 'optional',
              },
              publishOnlyCurrentValue: {
                type: 'bool',
                id: 31400,
                rule: 'optional',
              },
              featureFlag: {
                type: 'string',
                id: 29200,
                rule: 'optional',
              },
              featureFlag: {
                type: 'string',
                id: 27200,
                rule: 'optional',
              },
              mime: {
                type: 'string',
                id: 73000,
                rule: 'optional',
              },
              sourceIdPrefix: {
                type: 'string',
                id: 32700,
                rule: 'optional',
              },
              persistAsString: {
                type: 'string',
                id: 66101,
                rule: 'optional',
              },
              cashRounding: {
                type: 'int32',
                id: 54000,
                rule: 'optional',
              },
              denominations: {
                type: 'squareup.common.Denominations',
                id: 54001,
                rule: 'optional',
              },
              appIdentifierPrefix: {
                type: 'string',
                id: 28000,
                rule: 'optional',
              },
              maxStoredLength: {
                type: 'int32',
                id: 80800,
                rule: 'optional',
              },
              appIdentifier: {
                type: 'string',
                id: 33000,
                rule: 'optional',
              },
              cloneable: {
                type: 'bool',
                id: 22800,
                rule: 'optional',
              },
              unitOnly: {
                type: 'bool',
                id: 22801,
                rule: 'optional',
              },
              errorCategory: {
                type: 'squareup.connect.v2.resources.Error.Category',
                id: 60000,
                rule: 'optional',
              },
              errorCodeGeneric: {
                type: 'bool',
                id: 60004,
                rule: 'optional',
              },
              enumValueStatus: {
                type: 'squareup.connect.v2.common.ReleaseStatus',
                id: 60001,
                rule: 'optional',
              },
              enumValueVersion: {
                type: 'string',
                id: 60002,
                rule: 'optional',
              },
              enumValueNamespace: {
                type: 'string',
                id: 60003,
                rule: 'optional',
              },
              enumValueSkipLintChecks: {
                type: 'string',
                id: 60005,
                rule: 'repeated',
              },
              idPrefix: {
                type: 'string',
                id: 23000,
                rule: 'optional',
              },
              shippoCarrierName: {
                type: 'string',
                id: 70000,
                rule: 'optional',
              },
              serviceLevelName: {
                type: 'string',
                id: 70001,
                rule: 'optional',
              },
              shippoServiceLevelName: {
                type: 'string',
                id: 70002,
                rule: 'optional',
              },
              upsServiceLevelName: {
                type: 'string',
                id: 70004,
                rule: 'optional',
              },
              enumValueVersions: {
                type: 'squareup.client.AppVersionRanges',
                id: 25000,
                rule: 'optional',
              },
              investmentOrderValidationRejectionReason: {
                type: 'bool',
                id: 55300,
                rule: 'optional',
              },
            },
          },
          ServiceOptions: {
            fields: {
              deprecated: {
                type: 'bool',
                id: 33,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              uninterpretedOption: {
                type: 'google.protobuf.UninterpretedOption',
                id: 999,
                rule: 'repeated',
              },
              openapiv2Tag: {
                type: 'grpc.gateway.protoc_gen_openapiv2.options.Tag',
                id: 1042,
              },
              doNotUseSakeError: {
                type: 'bool',
                id: 55001,
                rule: 'optional',
              },
              serviceOptions: {
                type: 'squareup.objc.ServiceOptions',
                id: 6684,
                rule: 'optional',
              },
              serviceOptions: {
                type: 'squareup.httprpc.HttpServiceOptions',
                id: 78000,
                rule: 'optional',
              },
              httpService: {
                type: 'squareup.tabuloid.HttpServiceOptions',
                id: 73000,
                rule: 'optional',
              },
              serviceDomain: {
                type: 'squareup.governance.GovernanceDomain',
                id: 26200,
                rule: 'optional',
              },
              defaultLatencyMillis: {
                type: 'int64',
                id: 26100,
                rule: 'optional',
              },
              objective: {
                type: 'float',
                id: 26101,
                rule: 'optional',
              },
              jobqueue: {
                type: 'bool',
                id: 50021,
                rule: 'optional',
              },
              serviceOwnedBy: {
                type: 'string',
                id: 60000,
                rule: 'optional',
              },
              serviceSkipLintChecks: {
                type: 'string',
                id: 60001,
                rule: 'repeated',
              },
              serviceVersions: {
                type: 'squareup.client.AppVersionRanges',
                id: 25000,
                rule: 'optional',
              },
              service: {
                type: 'bool',
                id: 67700,
                rule: 'optional',
              },
            },
          },
          MethodOptions: {
            fields: {
              deprecated: {
                type: 'bool',
                id: 33,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              uninterpretedOption: {
                type: 'google.protobuf.UninterpretedOption',
                id: 999,
                rule: 'repeated',
              },
              writableIdempotent: {
                type: 'bool',
                id: 24000,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              timeout: {
                type: 'double',
                id: 22000,
                rule: 'optional',
              },
              idempotent: {
                type: 'bool',
                id: 22001,
                rule: 'optional',
              },
              retryTimeoutMillis: {
                type: 'int64',
                id: 22002,
                rule: 'optional',
              },
              maximumRequestSize: {
                type: 'int32',
                id: 22003,
                rule: 'optional',
              },
              maximumResponseSize: {
                type: 'int32',
                id: 22004,
                rule: 'optional',
              },
              minimumDeadlineMillis: {
                type: 'int64',
                id: 22005,
                rule: 'optional',
              },
              timeoutMillis: {
                type: 'int64',
                id: 22006,
                rule: 'optional',
              },
              authenticationRequired: {
                type: 'bool',
                id: 29000,
                rule: 'optional',
              },
              authenticationRequired: {
                type: 'bool',
                id: 27000,
                rule: 'optional',
              },
              credentials: {
                type: 'squareup.multipass.CredentialState',
                id: 23000,
                rule: 'optional',
              },
              twoFactorAgeMinutes: {
                type: 'int32',
                id: 23002,
                rule: 'optional',
              },
              twoFactorTrustedDeviceAgeMinutes: {
                type: 'int32',
                id: 23003,
                rule: 'optional',
              },
              supportsOptionalApps: {
                type: 'bool',
                id: 23005,
                rule: 'optional',
              },
              skipDefault2faEnforcement: {
                type: 'bool',
                id: 23006,
                rule: 'optional',
              },
              ignoreUnitView: {
                type: 'bool',
                id: 23004,
                rule: 'optional',
              },
              noUnitsLoggedInUser: {
                type: 'bool',
                id: 23008,
                rule: 'optional',
              },
              sharingAuthorization: {
                type: 'string',
                id: 23251,
                rule: 'optional',
              },
              latencyMillis: {
                type: 'int64',
                id: 26100,
                rule: 'optional',
              },
              objective: {
                type: 'float',
                id: 26101,
                rule: 'optional',
              },
              httpPath: {
                type: 'string',
                id: 22701,
                rule: 'optional',
              },
              methodStatus: {
                type: 'squareup.connect.v2.common.ReleaseStatus',
                id: 60000,
                rule: 'optional',
              },
              entity: {
                type: 'string',
                id: 60001,
                rule: 'optional',
              },
              path: {
                type: 'string',
                id: 60002,
                rule: 'optional',
              },
              oauthScopeRequired: {
                type: 'bool',
                id: 60006,
                rule: 'optional',
                options: {
                  default: true,
                },
              },
              methodVersion: {
                type: 'string',
                id: 60009,
                rule: 'optional',
              },
              methodNamespace: {
                type: 'string',
                id: 60010,
                rule: 'optional',
              },
              timeoutSeconds: {
                type: 'double',
                id: 60011,
                rule: 'optional',
              },
              isSparseUpdate: {
                type: 'bool',
                id: 60012,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              xClearNullDefault: {
                type: 'bool',
                id: 60015,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              rejectUnrecognizedFieldsStartingOn: {
                type: 'string',
                id: 60017,
                rule: 'optional',
              },
              methodOwnedBy: {
                type: 'string',
                id: 60018,
                rule: 'optional',
              },
              methodSkipLintChecks: {
                type: 'string',
                id: 60019,
                rule: 'repeated',
              },
              exampleScenariosPath: {
                type: 'string',
                id: 60020,
                rule: 'optional',
              },
              notSupportedInSandbox: {
                type: 'bool',
                id: 60022,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              isSparseUpdateRequestLevel: {
                type: 'bool',
                id: 60023,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              isSparseUpdateBulkRequestLevel: {
                type: 'bool',
                id: 60025,
                rule: 'optional',
                options: {
                  default: false,
                },
              },
              deprecationDate: {
                type: 'string',
                id: 60800,
                rule: 'optional',
              },
              retirementDate: {
                type: 'string',
                id: 60801,
                rule: 'optional',
              },
              replacedBy: {
                type: 'string',
                id: 60802,
                rule: 'optional',
              },
              guideUrl: {
                type: 'string',
                id: 60803,
                rule: 'optional',
              },
              requiresAllHeaders: {
                type: 'bool',
                id: 26000,
                rule: 'optional',
              },
              methodVersions: {
                type: 'squareup.client.AppVersionRanges',
                id: 25000,
                rule: 'optional',
              },
              displayName: {
                type: 'string',
                id: 50001,
                rule: 'optional',
              },
              dynamicPath: {
                type: 'bool',
                id: 32003,
                rule: 'optional',
              },
              readTimeout: {
                type: 'int32',
                id: 32004,
                rule: 'optional',
              },
              paymentType: {
                type: 'bool',
                id: 32005,
                rule: 'optional',
              },
              sdkVersion: {
                type: 'bool',
                id: 32006,
                rule: 'optional',
              },
              contentEncoding: {
                type: 'string',
                id: 32007,
                rule: 'optional',
              },
              method: {
                type: 'bool',
                id: 67700,
                rule: 'optional',
              },
            },
          },
          UninterpretedOption: {
            fields: {
              name: {
                type: 'google.protobuf.UninterpretedOption.NamePart',
                id: 2,
                rule: 'repeated',
              },
              identifierValue: {
                type: 'string',
                id: 3,
                rule: 'optional',
              },
              positiveIntValue: {
                type: 'uint64',
                id: 4,
                rule: 'optional',
              },
              negativeIntValue: {
                type: 'int64',
                id: 5,
                rule: 'optional',
              },
              doubleValue: {
                type: 'double',
                id: 6,
                rule: 'optional',
              },
              stringValue: {
                type: 'bytes',
                id: 7,
                rule: 'optional',
              },
              aggregateValue: {
                type: 'string',
                id: 8,
                rule: 'optional',
              },
            },
            nested: {
              NamePart: {
                fields: {
                  namePart: {
                    type: 'string',
                    id: 1,
                    rule: 'required',
                  },
                  isExtension: {
                    type: 'bool',
                    id: 2,
                    rule: 'required',
                  },
                },
              },
            },
          },
          SourceCodeInfo: {
            fields: {
              location: {
                type: 'google.protobuf.SourceCodeInfo.Location',
                id: 1,
                rule: 'repeated',
              },
            },
            nested: {
              Location: {
                fields: {
                  path: {
                    type: 'int32',
                    id: 1,
                    rule: 'repeated',
                  },
                  span: {
                    type: 'int32',
                    id: 2,
                    rule: 'repeated',
                  },
                  leadingComments: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  trailingComments: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  leadingDetachedComments: {
                    type: 'string',
                    id: 6,
                    rule: 'repeated',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.google?.nested?.protobuf?.nested?.FileDescriptorSet &&
  !$root.nested?.google?.nested?.protobuf?.nested?.FileDescriptorProto &&
  !$root.nested?.google?.nested?.protobuf?.nested?.DescriptorProto &&
  !$root.nested?.google?.nested?.protobuf?.nested?.DescriptorProto?.nested
    ?.ExtensionRange &&
  !$root.nested?.google?.nested?.protobuf?.nested?.DescriptorProto?.nested
    ?.ReservedRange &&
  !$root.nested?.google?.nested?.protobuf?.nested?.ExtensionRangeOptions &&
  !$root.nested?.google?.nested?.protobuf?.nested?.FieldDescriptorProto &&
  !$root.nested?.google?.nested?.protobuf?.nested?.FieldDescriptorProto?.nested
    ?.Type &&
  !$root.nested?.google?.nested?.protobuf?.nested?.FieldDescriptorProto?.nested
    ?.Label &&
  !$root.nested?.google?.nested?.protobuf?.nested?.OneofDescriptorProto &&
  !$root.nested?.google?.nested?.protobuf?.nested?.EnumDescriptorProto &&
  !$root.nested?.google?.nested?.protobuf?.nested?.EnumDescriptorProto?.nested
    ?.EnumReservedRange &&
  !$root.nested?.google?.nested?.protobuf?.nested?.EnumValueDescriptorProto &&
  !$root.nested?.google?.nested?.protobuf?.nested?.ServiceDescriptorProto &&
  !$root.nested?.google?.nested?.protobuf?.nested?.MethodDescriptorProto &&
  !$root.nested?.google?.nested?.protobuf?.nested?.FileOptions &&
  !$root.nested?.google?.nested?.protobuf?.nested?.FileOptions?.nested
    ?.OptimizeMode &&
  !$root.nested?.google?.nested?.protobuf?.nested?.MessageOptions &&
  !$root.nested?.google?.nested?.protobuf?.nested?.FieldOptions &&
  !$root.nested?.google?.nested?.protobuf?.nested?.OneofOptions &&
  !$root.nested?.google?.nested?.protobuf?.nested?.EnumOptions &&
  !$root.nested?.google?.nested?.protobuf?.nested?.EnumValueOptions &&
  !$root.nested?.google?.nested?.protobuf?.nested?.ServiceOptions &&
  !$root.nested?.google?.nested?.protobuf?.nested?.MethodOptions &&
  !$root.nested?.google?.nested?.protobuf?.nested?.UninterpretedOption &&
  !$root.nested?.google?.nested?.protobuf?.nested?.UninterpretedOption?.nested
    ?.NamePart &&
  !$root.nested?.google?.nested?.protobuf?.nested?.SourceCodeInfo &&
  !$root.nested?.google?.nested?.protobuf?.nested?.SourceCodeInfo?.nested
    ?.Location
) {
  $root.addJSON(jsonData);
}

/**
 * The protocol compiler can output a FileDescriptorSet containing the .proto
 * files it parses.
 *
 * SOURCE FileDescriptorSet @ google/protobuf/descriptor.proto at 56:1
 */
export const FileDescriptorSet = $root.lookupType(
  'google.protobuf.FileDescriptorSet'
);
fixType(FileDescriptorSet);
Builder.createAndAttachToType(FileDescriptorSet);
/**
 * Describes a complete .proto file.
 *
 * SOURCE FileDescriptorProto @ google/protobuf/descriptor.proto at 61:1
 */
export const FileDescriptorProto = $root.lookupType(
  'google.protobuf.FileDescriptorProto'
);
fixType(FileDescriptorProto);
Builder.createAndAttachToType(FileDescriptorProto);
/**
 * Describes a message type.
 *
 * SOURCE DescriptorProto @ google/protobuf/descriptor.proto at 93:1
 */
export const DescriptorProto = $root.lookupType(
  'google.protobuf.DescriptorProto'
);
fixType(DescriptorProto);
Builder.createAndAttachToType(DescriptorProto);
/**
 * SOURCE ExtensionRange @ google/protobuf/descriptor.proto at 102:3
 */
DescriptorProto.ExtensionRange = $root.lookupType(
  'google.protobuf.DescriptorProto.ExtensionRange'
);
fixType(DescriptorProto.ExtensionRange);
Builder.createAndAttachToType(DescriptorProto.ExtensionRange);
/**
 * Range of reserved tag numbers. Reserved tag numbers may not be used by
 * fields or extension ranges in the same message. Reserved ranges may
 * not overlap.
 *
 * SOURCE ReservedRange @ google/protobuf/descriptor.proto at 117:3
 */
DescriptorProto.ReservedRange = $root.lookupType(
  'google.protobuf.DescriptorProto.ReservedRange'
);
fixType(DescriptorProto.ReservedRange);
Builder.createAndAttachToType(DescriptorProto.ReservedRange);
/**
 * SOURCE ExtensionRangeOptions @ google/protobuf/descriptor.proto at 127:1
 */
export const ExtensionRangeOptions = $root.lookupType(
  'google.protobuf.ExtensionRangeOptions'
);
fixType(ExtensionRangeOptions);
Builder.createAndAttachToType(ExtensionRangeOptions);
/**
 * Describes a field within a message.
 *
 * SOURCE FieldDescriptorProto @ google/protobuf/descriptor.proto at 136:1
 */
export const FieldDescriptorProto = $root.lookupType(
  'google.protobuf.FieldDescriptorProto'
);
fixType(FieldDescriptorProto);
Builder.createAndAttachToType(FieldDescriptorProto);
/**
 * SOURCE Type @ google/protobuf/descriptor.proto at 137:3
 */
FieldDescriptorProto.Type = $root.lookupEnum(
  'google.protobuf.FieldDescriptorProto.Type'
).values;
/**
 * SOURCE Label @ google/protobuf/descriptor.proto at 170:3
 */
FieldDescriptorProto.Label = $root.lookupEnum(
  'google.protobuf.FieldDescriptorProto.Label'
).values;
/**
 * Describes a oneof.
 *
 * SOURCE OneofDescriptorProto @ google/protobuf/descriptor.proto at 217:1
 */
export const OneofDescriptorProto = $root.lookupType(
  'google.protobuf.OneofDescriptorProto'
);
fixType(OneofDescriptorProto);
Builder.createAndAttachToType(OneofDescriptorProto);
/**
 * Describes an enum type.
 *
 * SOURCE EnumDescriptorProto @ google/protobuf/descriptor.proto at 223:1
 */
export const EnumDescriptorProto = $root.lookupType(
  'google.protobuf.EnumDescriptorProto'
);
fixType(EnumDescriptorProto);
Builder.createAndAttachToType(EnumDescriptorProto);
/**
 * Range of reserved numeric values. Reserved values may not be used by
 * entries in the same enum. Reserved ranges may not overlap.
 *
 * Note that this is distinct from DescriptorProto.ReservedRange in that it
 * is inclusive such that it can appropriately represent the entire int32
 * domain.
 *
 * SOURCE EnumReservedRange @ google/protobuf/descriptor.proto at 236:3
 */
EnumDescriptorProto.EnumReservedRange = $root.lookupType(
  'google.protobuf.EnumDescriptorProto.EnumReservedRange'
);
fixType(EnumDescriptorProto.EnumReservedRange);
Builder.createAndAttachToType(EnumDescriptorProto.EnumReservedRange);
/**
 * Describes a value within an enum.
 *
 * SOURCE EnumValueDescriptorProto @ google/protobuf/descriptor.proto at 252:1
 */
export const EnumValueDescriptorProto = $root.lookupType(
  'google.protobuf.EnumValueDescriptorProto'
);
fixType(EnumValueDescriptorProto);
Builder.createAndAttachToType(EnumValueDescriptorProto);
/**
 * Describes a service.
 *
 * SOURCE ServiceDescriptorProto @ google/protobuf/descriptor.proto at 260:1
 */
export const ServiceDescriptorProto = $root.lookupType(
  'google.protobuf.ServiceDescriptorProto'
);
fixType(ServiceDescriptorProto);
Builder.createAndAttachToType(ServiceDescriptorProto);
/**
 * Describes a method of a service.
 *
 * SOURCE MethodDescriptorProto @ google/protobuf/descriptor.proto at 268:1
 */
export const MethodDescriptorProto = $root.lookupType(
  'google.protobuf.MethodDescriptorProto'
);
fixType(MethodDescriptorProto);
Builder.createAndAttachToType(MethodDescriptorProto);
/**
 * ===================================================================
 * Options
 * Each of the definitions above may have "options" attached.  These are
 * just annotations which may cause code to be generated slightly differently
 * or may contain hints for code that manipulates protocol messages.
 *
 * Clients may define custom options as extensions of the *Options messages.
 * These extensions may not yet be known at parsing time, so the parser cannot
 * store the values in them.  Instead it stores them in a field in the *Options
 * message called uninterpreted_option. This field must have the same name
 * across all *Options messages. We then use this field to populate the
 * extensions when we build a descriptor, at which point all protos have been
 * parsed and so all extensions are known.
 *
 * Extension numbers for custom options may be chosen as follows:
 * * For options which will only be used within a single application or
 *   organization, or for experimental options, use field numbers 50000
 *   through 99999.  It is up to you to ensure that you do not use the
 *   same number for multiple options.
 * * For options which will be published and used publicly by multiple
 *   independent entities, e-mail protobuf-global-extension-registry@google.com
 *   to reserve extension numbers. Simply provide your project name (e.g.
 *   Objective-C plugin) and your project website (if available) -- there's no
 *   need to explain how you intend to use them. Usually you only need one
 *   extension number. You can declare multiple options with only one extension
 *   number by putting them in a sub-message. See the Custom Options section of
 *   the docs for examples:
 *   https://developers.google.com/protocol-buffers/docs/proto#options
 *   If this turns out to be popular, a web service will be set up
 *   to automatically assign option numbers.
 *
 * SOURCE FileOptions @ google/protobuf/descriptor.proto at 318:1
 */
export const FileOptions = $root.lookupType('google.protobuf.FileOptions');
fixType(FileOptions);
Builder.createAndAttachToType(FileOptions);
/**
 * Generated classes can be optimized for speed or code size.
 *
 * SOURCE OptimizeMode @ google/protobuf/descriptor.proto at 355:3
 */
FileOptions.OptimizeMode = $root.lookupEnum(
  'google.protobuf.FileOptions.OptimizeMode'
).values;
/**
 * SOURCE MessageOptions @ google/protobuf/descriptor.proto at 431:1
 */
export const MessageOptions = $root.lookupType(
  'google.protobuf.MessageOptions'
);
fixType(MessageOptions);
Builder.createAndAttachToType(MessageOptions);
/**
 * SOURCE FieldOptions @ google/protobuf/descriptor.proto at 496:1
 */
export const FieldOptions = $root.lookupType('google.protobuf.FieldOptions');
fixType(FieldOptions);
Builder.createAndAttachToType(FieldOptions);
/**
 * SOURCE OneofOptions @ google/protobuf/descriptor.proto at 589:1
 */
export const OneofOptions = $root.lookupType('google.protobuf.OneofOptions');
fixType(OneofOptions);
Builder.createAndAttachToType(OneofOptions);
/**
 * SOURCE EnumOptions @ google/protobuf/descriptor.proto at 597:1
 */
export const EnumOptions = $root.lookupType('google.protobuf.EnumOptions');
fixType(EnumOptions);
Builder.createAndAttachToType(EnumOptions);
/**
 * SOURCE EnumValueOptions @ google/protobuf/descriptor.proto at 618:1
 */
export const EnumValueOptions = $root.lookupType(
  'google.protobuf.EnumValueOptions'
);
fixType(EnumValueOptions);
Builder.createAndAttachToType(EnumValueOptions);
/**
 * SOURCE ServiceOptions @ google/protobuf/descriptor.proto at 632:1
 */
export const ServiceOptions = $root.lookupType(
  'google.protobuf.ServiceOptions'
);
fixType(ServiceOptions);
Builder.createAndAttachToType(ServiceOptions);
/**
 * SOURCE MethodOptions @ google/protobuf/descriptor.proto at 652:1
 */
export const MethodOptions = $root.lookupType('google.protobuf.MethodOptions');
fixType(MethodOptions);
Builder.createAndAttachToType(MethodOptions);
/**
 * A message representing a option the parser does not recognize. This only
 * appears in options protos created by the compiler::Parser class.
 * DescriptorPool resolves these when building Descriptor objects. Therefore,
 * options protos in descriptor objects (e.g. returned by Descriptor::options(),
 * or produced by Descriptor::CopyTo()) will never have UninterpretedOptions
 * in them.
 *
 * SOURCE UninterpretedOption @ google/protobuf/descriptor.proto at 690:1
 */
export const UninterpretedOption = $root.lookupType(
  'google.protobuf.UninterpretedOption'
);
fixType(UninterpretedOption);
Builder.createAndAttachToType(UninterpretedOption);
/**
 * The name of the uninterpreted option.  Each string represents a segment in
 * a dot-separated name.  is_extension is true iff a segment represents an
 * extension (denoted with parentheses in options specs in .proto files).
 * E.g.,{ ["foo", false], ["bar.baz", true], ["qux", false] } represents
 * "foo.(bar.baz).qux".
 *
 * SOURCE NamePart @ google/protobuf/descriptor.proto at 696:3
 */
UninterpretedOption.NamePart = $root.lookupType(
  'google.protobuf.UninterpretedOption.NamePart'
);
fixType(UninterpretedOption.NamePart);
Builder.createAndAttachToType(UninterpretedOption.NamePart);
/**
 * ===================================================================
 * Optional source code info
 * Encapsulates information about the original source file from which a
 * FileDescriptorProto was generated.
 *
 * SOURCE SourceCodeInfo @ google/protobuf/descriptor.proto at 717:1
 */
export const SourceCodeInfo = $root.lookupType(
  'google.protobuf.SourceCodeInfo'
);
fixType(SourceCodeInfo);
Builder.createAndAttachToType(SourceCodeInfo);
/**
 * SOURCE Location @ google/protobuf/descriptor.proto at 762:3
 */
SourceCodeInfo.Location = $root.lookupType(
  'google.protobuf.SourceCodeInfo.Location'
);
fixType(SourceCodeInfo.Location);
Builder.createAndAttachToType(SourceCodeInfo.Location);
