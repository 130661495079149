import React from 'react';
import { MarketLink, MarketList, MarketRow } from '@market/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BoolFlag } from 'routes/profile/models/Flags';
import { faqClickArticleActionEvent } from 'services/tracking/events';
import { selectBoolFlag } from 'store/featureSlice';
import Es2Tracker from 'services/tracking/tracker';
import { getDashboardDomain } from 'config/config';
import { AppState } from 'store';

const getQuestions = (useAppSubdomain: boolean) => [
  {
    isAutoReceiptRelated: true,
    key: 'preference.components.notYourReceiptFaq.question.unsubscribeAutomaticReceipts',
    url: `https://${getDashboardDomain(
      useAppSubdomain
    )}/help/article/5212#how-can-i-unsubscribe-from-automatic-receipts`,
  },
  {
    isAutoReceiptRelated: false,
    key: 'preference.components.notYourReceiptFaq.question.incorrectReceipt',
    url: `https://${getDashboardDomain(useAppSubdomain)}/help/article/4989`,
  },
  {
    isAutoReceiptRelated: true,
    key: 'preference.components.notYourReceiptFaq.question.resubscribe',
    url: `https://${getDashboardDomain(
      useAppSubdomain
    )}/help/article/5212#resubscribe`,
  },
  {
    isAutoReceiptRelated: false,
    key: 'preference.components.notYourReceiptFaq.question.sharedCard',
    url: `https://${getDashboardDomain(
      useAppSubdomain
    )}/help/article/5213-shared-cards-with-automatic-receipts`,
  },
];

type ReceiptFAQProps = {
  shouldFilterAutoReceiptFaqs?: boolean;
};

const ReceiptFAQ: React.FC<ReceiptFAQProps> = ({
  shouldFilterAutoReceiptFaqs,
}) => {
  const { t } = useTranslation();

  const useAppSubdomain = useSelector((state: AppState) =>
    selectBoolFlag(state, BoolFlag.useAppSubdomain)
  );

  const questions = getQuestions(useAppSubdomain);
  const filteredQuestions = shouldFilterAutoReceiptFaqs
    ? questions.filter((question) => !question.isAutoReceiptRelated)
    : questions;

  return (
    <>
      <h2 className={'heading-20 mb-[5px]'}>
        {t('preference.components.notYourReceiptFaq.title')}
      </h2>

      <MarketList>
        {filteredQuestions.map((question, index) => (
          <MarketRow key={`question-${index}`}>
            <MarketLink
              href={question.url}
              target={'_blank'}
              rel={'noreferrer'}
              highlight={'underline'}
              onClick={() =>
                Es2Tracker.track(faqClickArticleActionEvent(question.url))
              }
            >
              <span className={'color-blue font-semibold text-base'}>
                {t(question.key)}
              </span>
            </MarketLink>
          </MarketRow>
        ))}
      </MarketList>
    </>
  );
};

export default ReceiptFAQ;
