import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MarketButton } from '@market/react';
import Es2Tracker from 'services/tracking/tracker';
import BackButton from 'components/BackButton';
import {
  ContactInformationIdentifierType,
  IdentifierType,
} from 'routes/profile/models/Identifier';
import IdentifierValueInput from '../../../components/IdentifierValueInput';
import { isValidEmail } from 'utils/identifiers';
import { createAccountlessPrivacyRequest } from 'services/buyerportal';
import PrivacyRequestType from '../enums/PrivacyRequestType';
import ErrorToastSlot from 'components/ErrorToastSlot';
import {
  privacyRequestsRequestExportActionEvent,
  privacyRequestsRequestExportInvalidActionEvent,
  privacyRequestsRequestExportViewEvent,
} from 'services/tracking/events/privacyRequests';
import PhoneInput from 'routes/native-sign-in/components/PhoneInput';

type RequestExportDataProps = {
  onClickBack: () => void;
  onClickNext: (
    identifierType: ContactInformationIdentifierType,
    identifierValue: string
  ) => void;
};

const RequestExportData: React.FC<RequestExportDataProps> = (props) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  const [identifierType, setIdentifierType] =
    useState<ContactInformationIdentifierType>(IdentifierType.Email);
  const [identifierValue, setIdentifierValue] = useState<string>('');

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isUnexpectedError, setIsUnexpectedError] = useState<boolean>(false);
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(() => {
    if (identifierType === IdentifierType.Email) {
      return isValidEmail(identifierValue);
    }
    return false; // Empty => invalid
  });
  const [
    shouldDisplayInvalidSubmissionError,
    setShouldDisplayInvalidSubmissionError,
  ] = useState<boolean>(false);

  const isValueValid = () => {
    if (identifierType === IdentifierType.Phone) {
      return isPhoneValid;
    }

    return isEmailValid;
  };

  useEffect(() => {
    Es2Tracker.track(privacyRequestsRequestExportViewEvent());
  }, []);

  const onSetIdentifierType = (
    identifierType: ContactInformationIdentifierType
  ) => {
    setIdentifierType(identifierType);
    setIdentifierValue('');
    setIsPhoneValid(false);
    setIsEmailValid(false);
    setShouldDisplayInvalidSubmissionError(false);
  };

  const triggerCodeVerification = async () => {
    Es2Tracker.track(privacyRequestsRequestExportActionEvent(identifierType));

    if (!isValueValid()) {
      Es2Tracker.track(
        privacyRequestsRequestExportInvalidActionEvent(identifierType)
      );
      setShouldDisplayInvalidSubmissionError(true);
      return;
    }

    try {
      setIsProcessing(true);

      await createAccountlessPrivacyRequest(
        identifierType,
        identifierValue,
        PrivacyRequestType.Export
      );

      props.onClickNext(identifierType, identifierValue);
    } catch {
      setIsUnexpectedError(true);
    } finally {
      setIsProcessing(false);
    }
  };

  let inputComponent;
  if (identifierType === IdentifierType.Email) {
    inputComponent = (
      <IdentifierValueInput
        identifierType={identifierType}
        identifierValue={identifierValue}
        onIdentifierTypeChanged={onSetIdentifierType}
        onIdentifierValueChanged={(value) => {
          setIdentifierValue(value);
          setIsEmailValid(isValidEmail(value));
          setShouldDisplayInvalidSubmissionError(false);
        }}
        isInvalid={shouldDisplayInvalidSubmissionError}
        phoneInputDataTestId={'phone-input'}
        emailInputDataTestId={'email-input'}
        onEnterPressed={triggerCodeVerification}
      />
    );
  } else {
    inputComponent = (
      <PhoneInput
        onChange={(isValid: boolean, number: string) => {
          setIdentifierValue(number);
          setIsPhoneValid(isValid);
          setShouldDisplayInvalidSubmissionError(false);
        }}
        onEnterPressed={triggerCodeVerification}
        shouldShowValidationError={shouldDisplayInvalidSubmissionError}
        value={identifierValue}
      />
    );
  }

  return (
    <>
      <BackButton onClick={props.onClickBack} />
      <h1 className={'mb-4 heading-30'}>
        {t('privacyManagement.requestExportData.title')}
      </h1>
      <p className={'mt-0 mb-6 paragraph-30'}>
        {t('privacyManagement.requestExportData.subtitle')}
      </p>

      {inputComponent}

      <MarketButton
        onClick={triggerCodeVerification}
        disabled={!isValueValid() || isProcessing}
        rank={'primary'}
      >
        {t('common.next')}
      </MarketButton>

      <MarketButton
        rank={'tertiary'}
        disabled={isProcessing}
        onClick={() =>
          onSetIdentifierType(
            identifierType === IdentifierType.Email
              ? IdentifierType.Phone
              : IdentifierType.Email
          )
        }
      >
        {identifierType === IdentifierType.Email
          ? t('common.usePhoneNumber')
          : t('common.useEmailAddress')}
      </MarketButton>

      <ErrorToastSlot
        errored={isUnexpectedError}
        clearError={() => setIsUnexpectedError(false)}
      />
    </>
  );
};

export default RequestExportData;
