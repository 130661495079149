import { MarketButton } from '@market/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPrimaryId } from 'store/buyerSlice';
import BuyerCard from 'routes/profile/models/BuyerCard';
import { IdentifierType } from 'routes/profile/models/Identifier';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import { actionSetPaymentCardAsDefaultProperties } from 'services/tracking/cdp/events/paymentMethods';
import { AppState } from 'store';
import { useUpdatePrimaryIdentifierIdMutation } from 'store/query';
import { openToast, ToastVariant } from 'store/toastSlice';
import { useTranslation } from 'react-i18next';

type PreferencesProps = {
  paymentCard: BuyerCard;
};

const Preferences: React.FC<PreferencesProps> = ({ paymentCard }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const primaryCardId = useSelector((state: AppState) =>
    selectPrimaryId(state, IdentifierType.Card)
  );
  const [updatePrimaryIdentifierId, updatePrimaryIdentifierIdResult] =
    useUpdatePrimaryIdentifierIdMutation();

  const isDefault = primaryCardId === paymentCard.cardsApiId;

  const setAsPrimary = async () => {
    await updatePrimaryIdentifierId({
      id: paymentCard.cardsApiId,
      identifierType: IdentifierType.Card,
    })
      .unwrap()
      .then(() => {
        dispatch(
          openToast({
            message: t('common.changeSuccess'),
            variant: ToastVariant.Success,
          })
        );

        buyerportalCdpClient.track(
          'Set as default',
          actionSetPaymentCardAsDefaultProperties(paymentCard, isDefault)
        );
      })
      .catch(() => {
        dispatch(
          openToast({
            message: t('common.somethingWentWrong.retryable.direct'),
            variant: ToastVariant.Critical,
          })
        );
      });
  };

  return (
    <div className={'pb-8'}>
      <h2 className={'heading-20 mt-0'}>
        {t(
          'profile.paymentMethods.paymentCards.details.preferences.preferences'
        )}
      </h2>
      <div className={'flex justify-between py-4'}>
        <div className={'pr-4'}>
          <label className={'font-medium'}>
            {t('profile.paymentMethods.paymentCards.details.preferences.title')}
          </label>
          <div className={'paragraph-30 text-grey'}>
            {isDefault
              ? t(
                  'profile.paymentMethods.paymentCards.details.preferences.defaultSubtitle'
                )
              : t(
                  'profile.paymentMethods.paymentCards.details.preferences.notDefaultSubtitle'
                )}
          </div>
        </div>
        {!isDefault && (
          <MarketButton
            className={'flex w-52'}
            onClick={setAsPrimary}
            {...(updatePrimaryIdentifierIdResult.isLoading && {
              disabled: true,
            })}
          >
            {t('common.setAsDefault')}
          </MarketButton>
        )}
      </div>
    </div>
  );
};

export default Preferences;
