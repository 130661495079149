import React, { useState } from 'react';
import {
  MarketButton,
  MarketFooter,
  MarketDialog,
  MarketButtonGroup,
} from '@market/react';
import * as request from 'rpc/model/squareup/customers/request';
import {
  UnlinkOnFileBankAccountRequest,
  UnlinkOnFileBankAccountResponse,
} from 'rpc/model/squareup/buyerportal/onfile/data';
import type { PaymentMethod } from './PaymentMethodOnFile';
import buyerPortalClient from 'rpc/client';
import {
  onFileBuyerDashboardUnlinkEvent,
  onFileBuyerDashboardErrorEvent,
  EVENT_SOURCE_BOF,
  UNLINK_FAILURE_ERROR,
} from 'services/tracking/events/onFileManagementEvents';
import Es2Tracker from 'services/tracking/tracker';
import { getUtmMediumQueryParam } from '../utils';

type RemovePaymentMethodModalProps = {
  selectedPaymentMethod: PaymentMethod;
  onDismissed: () => void;
  baToken: string;
  onError: (errors: request.IRequestError[]) => void;
  onSuccess: () => void;
};

const RemovePaymentMethodModal = ({
  selectedPaymentMethod,
  onDismissed,
  baToken,
  onError,
  onSuccess,
}: RemovePaymentMethodModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const unlinkOnFileBankAccount = async () => {
    try {
      setIsLoading(true);
      const response: UnlinkOnFileBankAccountResponse =
        await buyerPortalClient.unlinkOnFileBankAccount(
          UnlinkOnFileBankAccountRequest.create({
            bankAccountId: selectedPaymentMethod.bankId,
            buyerAccessToken: baToken.toString(),
          })
        );
      if (response?.errors.length > 0) {
        Es2Tracker.track(
          onFileBuyerDashboardErrorEvent({
            errorDetail: UNLINK_FAILURE_ERROR,
            eventSource: EVENT_SOURCE_BOF,
            utmMedium: getUtmMediumQueryParam(),
          })
        );
        onError([...response.errors]);

        return false;
      } else {
        // TODO: when we start supporting CardOnFile, conditionally use
        // different values for the eventSource parameter
        Es2Tracker.track(
          onFileBuyerDashboardUnlinkEvent({
            eventSource: EVENT_SOURCE_BOF,
            utmMedium: getUtmMediumQueryParam(),
          })
        );
        onSuccess();

        return true;
      }
    } catch (error) {
      onError([error as request.IRequestError]);
      setIsLoading(false);

      return true;
    }
  };

  return (
    <MarketDialog onMarketDialogDismissed={onDismissed}>
      <main>
        <h2>
          Remove{' '}
          {`${selectedPaymentMethod.bankName} **** ${selectedPaymentMethod.accountNumberSuffix}`}
        </h2>
        <p>
          By continuing, you will remove your {selectedPaymentMethod.bankName}{' '}
          ending in {selectedPaymentMethod.accountNumberSuffix} from future
          purchases.
        </p>
      </main>
      <MarketFooter>
        <MarketButtonGroup alignment="fill">
          <MarketButton
            rank="primary"
            variant="destructive"
            onClick={async () => {
              if (await unlinkOnFileBankAccount()) {
                onDismissed();
              }
            }}
            disabled={isLoading}
          >
            {isLoading ? 'Removing...' : 'Remove'}
          </MarketButton>
          <MarketButton
            rank="secondary"
            onClick={onDismissed}
            disabled={isLoading}
          >
            Cancel
          </MarketButton>
        </MarketButtonGroup>
      </MarketFooter>
    </MarketDialog>
  );
};

export default RemovePaymentMethodModal;
