import React, { useRef, useState } from 'react';
import { LogoIcon } from 'svgs';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import type { TokenResult } from '@square/web-sdk';
import {
  MarketBanner,
  MarketButton,
  MarketField,
  MarketInputText,
} from '@market/react';
import { PaymentRecord } from 'rpc/model/squareup/payments/model/payment_record';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';
import * as Sentry from '@sentry/react';
import config from 'config/config';
import DateInput from './DateInput';
import { useSearchReceiptsMutation } from 'store/query/receiptLookup';
import { environment } from 'utils/environment';
import Es2Tracker from 'services/tracking/tracker';
import { searchReceipts } from 'services/tracking/events/receiptLookupEvents';
import Footer from './SquareInformational';

type Props = {
  paymentRecords?: PaymentRecord[];
  setPaymentRecords: (tokens: PaymentRecord[]) => void;
};

const recaptchaPublicKey =
  environment().name === 'development'
    ? config.recaptcha.devKey
    : config.recaptcha.publicKey;

const ReceiptLookupForm: React.FC<Props> = ({
  setPaymentRecords,
  paymentRecords,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState<Date | null>(new Date());
  const [amount, setAmount] = useState<string>();
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>();
  const [formKey, setFormKey] = useState<number>(0); // Form key will allow us to remount PaymentForm when a request fails

  const [search, { isLoading: isSearching }] = useSearchReceiptsMutation({});

  const handleVerify = (token: string | null) => {
    setRecaptchaToken(token);
  };

  const handleCardNonceReceived = async (token: TokenResult) => {
    const response = await search({
      amount,
      date: date || new Date(),
      nonce: token.token ?? '',
      recaptchaToken: recaptchaToken ?? '',
    });
    if ('data' in response) {
      setPaymentRecords(response.data.payments ?? []);
      if (response.data.payments?.length === 0) {
        setFormKey((prevKey) => prevKey + 1); // Increment form key to force new session in PaymentForm request
      }
      Es2Tracker.trackReceiptsCatalog(
        searchReceipts(
          response.data.payments?.map((payment) => payment?.paymentToken) ?? []
        )
      );
    } else if ('error' in response) {
      Sentry.captureException(response.error);
      setPaymentRecords([]);
      setFormKey((prevKey) => prevKey + 1); // Increment form key to force new session in PaymentForm request
    }
    setRecaptchaToken(null);
    recaptchaRef.current?.reset();
  };

  const showEmptyBanner = !isSearching && paymentRecords?.length === 0;

  return (
    <div className={'flex justify-center mt-2 p-6'}>
      <div className={'flex flex-col max-w-[440px]'}>
        <LogoIcon className={'text-[32px] mb-2'} />
        <h1 className="heading-30">{t('receiptLookup.title')}</h1>
        <p className="paragraph-30">{t('receiptLookup.description')}</p>
        {showEmptyBanner && (
          <MarketBanner
            className="mt-2 mb-8"
            variant="critical"
            data-testid="empty-state-banner"
          >
            {t('receiptLookup.list.empty')}
          </MarketBanner>
        )}

        <PaymentForm
          key={formKey}
          applicationId={config.squareDeveloper.applicationId}
          cardTokenizeResponseReceived={handleCardNonceReceived}
          locationId={config.squareDeveloper.locationId}
          overrides={{
            scriptSrc: config.squareDeveloper.webPaymentsSdkSrc,
          }}
        >
          <CreditCard
            render={(Button) => (
              <div>
                <DateInput
                  date={date}
                  onDateChanged={setDate}
                  isDateDisabled={(date) => date > new Date()}
                  className="mb-4"
                />
                <MarketField className="mb-4">
                  <MarketInputText
                    data-testid="amount-input"
                    type="number"
                    value={amount}
                    onMarketInputValueChange={({ detail }) =>
                      setAmount(detail.value)
                    }
                    id="receipt-lookup-amount-input"
                  >
                    <label htmlFor="receipt-lookup-amount-input">
                      {t('receiptLookup.label.amount')}
                    </label>
                  </MarketInputText>
                </MarketField>
                <p className="paragraph-10 text-footerText">
                  {t('receiptLookup.confirmation')}
                </p>
                <div className="my-5">
                  <ReCAPTCHA
                    sitekey={recaptchaPublicKey}
                    onChange={handleVerify}
                    ref={recaptchaRef}
                  />
                </div>
                {recaptchaToken && !isSearching && date ? (
                  <Button isLoading={isSearching} className="semibold-30 mt-3">
                    {t('receiptLookup.action.search')}
                  </Button>
                ) : (
                  <MarketButton
                    disabled
                    isLoading={isSearching}
                    className="semibold-30 mt-3 w-full"
                    rank="primary"
                    role="button"
                  >
                    {t('receiptLookup.action.search')}
                  </MarketButton>
                )}
              </div>
            )}
          />
        </PaymentForm>
        <Footer />
      </div>
    </div>
  );
};

export default ReceiptLookupForm;
