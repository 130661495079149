import { isRelativeUrl, isValidSquareProfileUrl } from 'utils/url';
import {
  GenericOnboardingQueryParam,
  LOYALTY_DATA_KEYS,
  OnboardingVariant,
  PREFILLED_PHONE_DATA_KEYS,
  VARIANT_QUERY_PARAM_KEY,
} from './constants';
import {
  LoyaltyData,
  PrefilledPhoneData,
  OnboardingVariantValue,
  SignInVariantDataPayload,
} from '../types';
import { isValueInEnum } from 'utils/enums';

export interface GenericParameters {
  returnTo: string;
  stepOverride: string | null;
}

// Parses the generic query parameters from the URL
export const parseGenericParameters = (
  params: URLSearchParams
): GenericParameters => {
  let returnTo = params.get(GenericOnboardingQueryParam.ReturnTo);
  if (
    returnTo !== null &&
    !isRelativeUrl(returnTo) &&
    !isValidSquareProfileUrl(returnTo)
  ) {
    returnTo = null;
  }

  const stepOverride = params.get(GenericOnboardingQueryParam.StepDebug);

  return {
    returnTo: returnTo || window.location.hostname,
    stepOverride,
  };
};

// Given certain variants of the onboarding flow need to be handled differently,
// this function parses the variant from the URL query parameters
export const parseOnboardingVariantFromQueryParams = (
  params: URLSearchParams
): OnboardingVariantValue | null => {
  const maybeVariant = params.get(VARIANT_QUERY_PARAM_KEY);
  return maybeVariant && isValueInEnum(maybeVariant, OnboardingVariant)
    ? (maybeVariant as OnboardingVariantValue)
    : null;
};

/* eslint-disable @typescript-eslint/no-explicit-any */

// type guard to determine if the `data` object embedded in the query params conforms to the
// PrefilledPhoneData type
export const isValidPrefilledPhoneVariantData = (
  variantDataObj: any = {} // Guard against null or undefined
): variantDataObj is PrefilledPhoneData => {
  const { obfuscatedValue, fingerprint } = variantDataObj;
  const requiredKeys = ['obfuscatedValue', 'fingerprint'];
  // verify that the required keys are present
  if (!requiredKeys.every((key) => key in variantDataObj)) {
    return false;
  }

  // verify that the values are the correct type
  if (typeof obfuscatedValue !== 'string' || typeof fingerprint !== 'string') {
    return false;
  }

  return true;
};

// Evaluates the variant data object to determine if it is a valid PrefilledPhoneData object
// and returns the data if it is, otherwise return null
const parsePrefilledPhoneParameters = (
  variantData: any
): PrefilledPhoneData | null => {
  return isValidPrefilledPhoneVariantData(variantData) ? variantData : null;
};

// type guard for the LoyaltyData type similar to the one for PrefilledPhoneData
export const isValidLoyaltyVariantData = (
  variantDataObj: any
): variantDataObj is LoyaltyData => {
  const { claimablePointsToken, isTosConsentOptional, merchantId, programId } =
    variantDataObj;
  const requiredKeys = ['merchantId', 'programId'];
  // verify that the required keys are present
  if (!requiredKeys.every((key) => key in variantDataObj)) {
    return false;
  }

  // verify that the values are the correct type
  if (
    typeof merchantId !== 'string' ||
    typeof programId !== 'string' ||
    (isTosConsentOptional !== undefined &&
      typeof isTosConsentOptional !== 'boolean') ||
    (claimablePointsToken !== undefined &&
      typeof claimablePointsToken !== 'string')
  ) {
    return false;
  }

  return true;
};

// Evaluates the variant data object to determine if it is a valid LoyaltyData object
// and returns the data if it is, otherwise return null
const parseLoyaltyParameters = (variantData: any): LoyaltyData | null => {
  return isValidLoyaltyVariantData(variantData) ? variantData : null;
};

const filterData = <T>(data: any, keys: Array<keyof T>): T => {
  const result = {} as T;

  keys.forEach((key) => {
    if (key in data) {
      result[key] = data[key];
    }
  });

  return result;
};

export const parseVariantDataFromHistoryState = (
  variant: OnboardingVariantValue | null,
  historyState: any = {}
): SignInVariantDataPayload | null => {
  switch (variant) {
    case OnboardingVariant.PrefilledPhone: {
      return isValidPrefilledPhoneVariantData(historyState)
        ? filterData<PrefilledPhoneData>(
            historyState,
            PREFILLED_PHONE_DATA_KEYS
          )
        : null;
    }
    case OnboardingVariant.Loyalty: {
      return isValidLoyaltyVariantData(historyState)
        ? filterData<LoyaltyData>(historyState, LOYALTY_DATA_KEYS)
        : null;
    }
    default: {
      return null;
    }
  }
};

/* eslint-enable @typescript-eslint/no-explicit-any */

// Given the variant and the query parameters, this function parses the variant data
// from the query parameters. If the variant is not recognized or the data is invalid,
// it returns null
export const parseVariantDataFromQueryParams = (
  variant: OnboardingVariantValue | null,
  params: URLSearchParams
): SignInVariantDataPayload | null => {
  if (!params.has('data')) {
    return null;
  }

  // Values of URLSearchParams objects have already been uri decoded, so need to do it manually
  const stringifiedVariantData = params.get('data');
  let parsedVariantData;
  try {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    parsedVariantData = JSON.parse(stringifiedVariantData!); // Non-null assertion: We explicitly checked with `params.has` at the top
  } catch {
    return null; // indicates that the data is invalid
  }
  // apply the correct parsing function based on the variant
  switch (variant) {
    case OnboardingVariant.PrefilledPhone: {
      return parsePrefilledPhoneParameters(parsedVariantData);
    }
    case OnboardingVariant.Loyalty: {
      return parseLoyaltyParameters(parsedVariantData);
    }
    default: {
      return null;
    }
  }
};
