// eslint-disable-next-line filenames/match-exported
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import RequestPhoneVerification from 'routes/cash-linking/RequestPhoneVerification';
import VerifyPhone from 'routes/cash-linking/VerifyPhone';
import CashRedirect from 'routes/cash-linking/CashRedirect';
import CashAppSell from 'routes/cash-linking/CashAppSell';
import { CashLinkInfo } from 'rpc/model/squareup/buyerportal/cashlink/data';
import { retrieveCashLinkInfo } from 'services/buyerportal';
import { Redirect, useParams } from 'react-router-dom';
import SqLoading from 'components/SqLoading';
import InvalidLink from 'routes/cash-linking/components/error/InvalidLink';
import NotOnMobile from 'routes/cash-linking/components/error/NotOnMobile';
import RequestEmail from 'routes/cash-linking/RequestEmail';
import { Identifier } from 'rpc/model/squareup/buyerportal/profile/common';
import { getIdentifier } from 'routes/cash-linking/utils/CashLinkInfoHelper';
import { MarketToast } from '@market/react';
import { useTranslation } from 'react-i18next';

enum Step {
  RequestPhoneVerification = 0, // sends request to backend
  VerifyPhone = 1, // sends request to backend
  RequestEmail = 2, // sets email
  RedirectToCashApp = 3, // redirect using backend response
  CashAppSell = 4, // sell after redirect timeout to cash app
  Errored = 5, // errored state
}

export type CashLinkingProcessRouteProps = {
  cashLinkToken: string;
};

const CashLinkingProcess: React.FC = () => {
  const { t } = useTranslation();

  const [step, setStep] = useState<Step>(Step.RequestPhoneVerification);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUnexpectedError, setIsUnexpectedError] = useState<boolean>(false);

  const [cashLinkInfo, setCashLinkInfo] = useState<CashLinkInfo | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [cashAppRedirect, setCashAppRedirect] = useState<string | null>(null);

  const { cashLinkToken } = useParams<CashLinkingProcessRouteProps>();

  useEffect(() => {
    if (!cashLinkToken || !isMobile) {
      return;
    }

    const loadCashLinkInfo = async (): Promise<CashLinkInfo | undefined> => {
      const cashLinkInfo = await retrieveCashLinkInfo(cashLinkToken);
      setCashLinkInfo(cashLinkInfo!);

      return cashLinkInfo;
    };

    /*
     * Redirect the user to cash app if they're already linked. Otherwise,
     * send them to the linking flow.
     */
    const determineNextStep = (cashLinkInfo?: CashLinkInfo) => {
      if (!cashLinkInfo) {
        setStep(Step.Errored);
        return;
      }

      let nextStep: Step;

      if (
        cashLinkInfo.deliveryIdentifier?.type === Identifier.Type.TYPE_PHONE
      ) {
        // Are we coming from the SMS flow? Request email.
        nextStep = Step.RequestEmail;
      } else if (
        cashLinkInfo.deliveryIdentifier?.type === Identifier.Type.TYPE_EMAIL
      ) {
        // Are we coming from the email flow? Request phone.
        nextStep = Step.RequestPhoneVerification;
      } else {
        nextStep = Step.Errored;
      }

      setStep(nextStep);
    };

    const setNextStep = async () => {
      setIsLoading(true);
      try {
        const cashLinkInfo = await loadCashLinkInfo();
        determineNextStep(cashLinkInfo);
      } catch {
        setStep(Step.Errored);
      } finally {
        setIsLoading(false);
      }
    };

    setNextStep();
  }, [cashLinkToken]);

  if (!isMobile) {
    return <NotOnMobile />;
  }

  if (isLoading) {
    return (
      <div
        className={
          'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
        }
      >
        <SqLoading />
      </div>
    );
  }

  if (!cashLinkInfo) {
    return <InvalidLink />;
  }

  const phone = getIdentifier(cashLinkInfo!, Identifier.Type.TYPE_PHONE);

  /*
   * Renders the component for the current step.
   */
  const renderStep = () => {
    switch (step) {
      case Step.RequestPhoneVerification: {
        return (
          <RequestPhoneVerification
            cashLinkInfo={cashLinkInfo!}
            nextStep={(collectedPhone) => {
              setPhoneNumber(collectedPhone);
              setStep(Step.VerifyPhone);
            }}
          />
        );
      }
      case Step.VerifyPhone: {
        return (
          <VerifyPhone
            cashLinkInfo={cashLinkInfo!}
            phoneNumber={phoneNumber}
            phoneId={phone?.id || null}
            backStep={() => setStep(Step.RequestPhoneVerification)}
            nextStep={(url) => {
              setCashAppRedirect(url);
              setStep(Step.RedirectToCashApp);
            }}
          />
        );
      }
      case Step.RequestEmail: {
        return (
          <RequestEmail
            cashLinkInfo={cashLinkInfo!}
            nextStep={(url) => {
              setCashAppRedirect(url);
              setStep(Step.RedirectToCashApp);
            }}
          />
        );
      }
      case Step.RedirectToCashApp: {
        return (
          <CashRedirect
            cashLinkInfo={cashLinkInfo!}
            url={cashAppRedirect!}
            onRedirectTimeout={() => setStep(Step.CashAppSell)}
          />
        );
      }
      case Step.CashAppSell: {
        return <CashAppSell />;
      }
      case Step.Errored: {
        return <InvalidLink />;
      }
      default: {
        return <Redirect to={'/'} />;
      }
    }
  };

  return (
    <div className={'flex flex-col items-center px-6 py-12'}>
      <main>{renderStep()}</main>
      {isUnexpectedError && (
        <div className={'absolute bottom-[28px] p-6 m-auto'}>
          <MarketToast
            variant={'critical'}
            onMarketToastManuallyDismissed={() => setIsUnexpectedError(false)}
            onMarketToastAutoDismissed={() => setIsUnexpectedError(false)}
          >
            {t('common.somethingWentWrong.retryable.apologetic')}
          </MarketToast>
        </div>
      )}
    </div>
  );
};

export default CashLinkingProcess;
