import { MarketRow, MarketTooltip } from '@market/react';
import React from 'react';
import BuyerCard from 'routes/profile/models/BuyerCard';
import { useTranslation } from 'react-i18next';

type CardInfoProps = {
  paymentCard: BuyerCard;
};

const CardInfo: React.FC<CardInfoProps> = ({ paymentCard }) => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className={'heading-20'}>
        {t('profile.paymentMethods.paymentCards.details.cardInfo.cardInfo')}
      </h2>
      <MarketRow>
        <label slot={'label'}>
          {t('profile.paymentMethods.paymentCards.details.cardInfo.expDate')}
        </label>
        <p slot={'subtext'}>
          {paymentCard.expMonth}/{paymentCard.expYear}
        </p>
      </MarketRow>
      {paymentCard.billingZip && (
        <MarketRow>
          <label slot={'label'}>
            {t('profile.paymentMethods.paymentCards.details.cardInfo.zipCode')}
          </label>
          <p slot={'subtext'}>{paymentCard.billingZip}</p>
        </MarketRow>
      )}
      <MarketRow>
        <label slot={'label'}>
          {t('profile.paymentMethods.paymentCards.details.cardInfo.status')}
        </label>
        <p slot={'subtext'}>
          {t('profile.paymentMethods.paymentCards.details.cardInfo.verified')}
        </p>
        <MarketTooltip slot={'trailing-accessory'}>
          <p slot={'content'}>
            {t('profile.paymentMethods.paymentCards.details.cardInfo.explain')}
          </p>
        </MarketTooltip>
      </MarketRow>
    </>
  );
};

export default CardInfo;
