import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  MarketButton,
  MarketField,
  MarketInputText,
  MarketLink,
} from '@market/react';
import Es2Tracker from 'services/tracking/tracker';
import {
  signInVerifyAddIdentifierViewEvent,
  signInVerifyAddIdentifierActionEvent,
} from 'services/tracking/events/signIn';
import { MAX_CODE_LENGTH, THROTTLE_TIMEOUT } from './util/constants';
import BackButton from 'components/BackButton';
import buyerPortalClient from 'rpc/client';
import { v4 as uuidv4 } from 'uuid';
import {
  CreateLoginOrSignupVerificationRequest,
  ICreateLoginOrSignupVerificationRequest,
} from 'rpc/model/squareup/buyerportal/onboarding/data';
import { createBuyerFromDraft } from 'services/buyerportal';
import { IdentifierType } from 'routes/profile/models/Identifier';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import {
  AddProfileIdentifierRequest,
  IAddProfileIdentifierRequest,
  IVerifyAddProfileIdentifierRequest,
  VerifyAddProfileIdentifierRequest,
} from 'rpc/model/squareup/buyerportal/profile/verified';
import { Identifier as RpcIdentifier } from 'rpc/model/squareup/buyerportal/profile/common';
import { Name, SignInIdentifier, SignInIdentifierType } from './types';
import { IdentifierClaimedError, RequestErrorCode } from 'models/Error';
import useThrottle from '../../utils/custom-react-hooks/useThrottle';
import { BoolFlag } from 'routes/profile/models/Flags';
import { AppState } from 'store';
import { selectBoolFlag } from 'store/featureSlice';
import SquareBuyerPrivacyPolicy from './components/SquarePrivacyPolicy';

interface AddIdentifierTOTPProps {
  identifier: SignInIdentifier;
  isVerifyingAssociatedPhone: boolean;
  newBuyerName?: Name;
  onBack: () => void;
  onForward: (claimedIdentifierType: SignInIdentifierType | undefined) => void;
  onUnexpectedError: (isCatastrophic?: boolean) => void;
}

const AddIdentifierTotpTranslationHelpers = {
  primaryButtonTextKey: (
    isNewBuyer: boolean,
    identifierType: SignInIdentifierType,
    isVerifyingAssociatedPhone: boolean
  ): string => {
    // Unverified email -> associated phone case
    if (isVerifyingAssociatedPhone) {
      return 'common.next';
    }
    if (isNewBuyer) {
      return 'onboarding.common.buttonText.createProfile';
    }
    // Completing existing profile
    return `onboarding.addIdentifierTotp.buttonText.existingUser.${
      identifierType === IdentifierType.Email ? 'email' : 'phone'
    }`;
  },
};

const AddIdentifierTOTP: React.FC<AddIdentifierTOTPProps> = (props) => {
  const { t } = useTranslation();

  const [code, setCode] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isInvalidCode, setIsInvalidCode] = useState<boolean>(false);

  const isCodeSubmittable = useMemo(
    () => code.length === MAX_CODE_LENGTH && !isProcessing,
    [code, isProcessing]
  );

  const isUsingOnboardingRPCs = useMemo(
    () => Boolean(props.newBuyerName) || props.isVerifyingAssociatedPhone,
    [props.newBuyerName, props.isVerifyingAssociatedPhone]
  );

  const useAppSubdomain = useSelector((state: AppState) =>
    selectBoolFlag(state, BoolFlag.useAppSubdomain)
  );

  useEffect(() => {
    Es2Tracker.track(signInVerifyAddIdentifierViewEvent(props.identifier.type));
  }, [props.identifier.type]);

  const verifyCodeForExistingBuyer = async () => {
    setIsProcessing(true);

    const parameters: IVerifyAddProfileIdentifierRequest = {
      identifierRawValue: props.identifier.value,
      identifierType:
        props.identifier.type === IdentifierType.Email
          ? RpcIdentifier.Type.TYPE_EMAIL
          : RpcIdentifier.Type.TYPE_PHONE,
      verificationCode: code,
    };

    try {
      Es2Tracker.track(
        signInVerifyAddIdentifierActionEvent(props.identifier.type)
      );

      const response = await buyerPortalClient.verifyAddProfileIdentifier(
        VerifyAddProfileIdentifierRequest.create(parameters)
      );
      switch (response.status) {
        case RequestStatus.STATUS_SUCCESS: {
          props.onForward(undefined);
          break;
        }
        case RequestStatus.STATUS_FORBIDDEN: {
          // TODO: Update buyerportal to use STATUS_CONFLICT in an effort
          // to make the "identifier claimed" response status consistent.
          props.onForward(props.identifier.type);
          break;
        }
        default: {
          const code = response?.errors?.[0]?.code;
          throw new Error(code);
        }
      }
    } catch (e) {
      if (e?.message === RequestErrorCode.CodeInvalidError) {
        setIsInvalidCode(true);
      } else {
        props.onUnexpectedError();
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const verifyCodeForNewBuyer = async () => {
    setIsProcessing(true);
    try {
      Es2Tracker.track(
        signInVerifyAddIdentifierActionEvent(props.identifier.type)
      );
      await createBuyerFromDraft(
        props.identifier,
        props.isVerifyingAssociatedPhone,
        code,
        props.newBuyerName!
      );
      props.onForward(undefined);
    } catch (e) {
      if (e instanceof IdentifierClaimedError) {
        props.onForward(e.claimedType);
      } else if (e?.message === 'CODE_INVALID') {
        setIsInvalidCode(true);
      } else {
        props.onUnexpectedError();
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const [
    requestNewCodeForExistingBuyer,
    isRequestNewCodeForExistingBuyerThrottled,
  ] = useThrottle(async () => {
    setIsProcessing(true);

    const parameters: IAddProfileIdentifierRequest = {
      idempotencyKey: uuidv4(),
      identifierType:
        props.identifier.type === IdentifierType.Email
          ? RpcIdentifier.Type.TYPE_EMAIL
          : RpcIdentifier.Type.TYPE_PHONE,
      identifierRawValue: props.identifier.value,
    };

    try {
      const response = await buyerPortalClient.requestAddProfileIdentifier(
        AddProfileIdentifierRequest.create(parameters)
      );
      if (response.status !== RequestStatus.STATUS_SUCCESS) {
        throw new Error();
      }
    } catch {
      props.onUnexpectedError();
    } finally {
      setIsProcessing(false);
    }
  }, THROTTLE_TIMEOUT);

  const [requestNewCodeForNewBuyer, isRequestNewCodeForNewBuyerThrottled] =
    useThrottle(async () => {
      setIsProcessing(true);

      const getVerificationCredentialKeyName = (
        identifierType: SignInIdentifierType,
        usingIdentifierId: boolean
      ) => {
        const TYPE_TO_BASE_KEY = {
          [IdentifierType.Email]: 'email',
          [IdentifierType.Phone]: 'phoneNumber',
        };

        return `${TYPE_TO_BASE_KEY[identifierType]}${
          usingIdentifierId ? 'Id' : ''
        }`;
      };
      const verificationCredentialKey = getVerificationCredentialKeyName(
        props.identifier.type,
        props.isVerifyingAssociatedPhone
      );

      const parameters: ICreateLoginOrSignupVerificationRequest = {
        idempotencyKey: uuidv4(),
        verificationCredential: {
          [verificationCredentialKey]: props.isVerifyingAssociatedPhone
            ? props.identifier.id
            : props.identifier.value,
        },
      };

      try {
        const response =
          await buyerPortalClient.createLoginOrSignupVerification(
            CreateLoginOrSignupVerificationRequest.create(parameters)
          );
        if (response.status !== RequestStatus.STATUS_SUCCESS) {
          throw new Error();
        }
      } catch {
        props.onUnexpectedError();
      } finally {
        setIsProcessing(false);
      }
    }, THROTTLE_TIMEOUT);

  const actionHandler = () => {
    if (isUsingOnboardingRPCs) {
      verifyCodeForNewBuyer();
    } else {
      verifyCodeForExistingBuyer();
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter' || !isCodeSubmittable) {
      return;
    }
    actionHandler();
  };

  return (
    <>
      <BackButton onClick={props.onBack} />
      <h1 className={'mt-7 mb-4 overflow-hidden text-ellipsis heading-30'}>
        {t('common.verifyIdentifier.title', {
          identifierValue: props.identifier.value,
        })}
      </h1>
      <p className={'m-0 mb-6 w-full paragraph-30'}>
        {t('common.verifyIdentifier.subtitle')}{' '}
        <span
          onClick={
            isUsingOnboardingRPCs
              ? requestNewCodeForNewBuyer
              : requestNewCodeForExistingBuyer
          }
        >
          {/* hrefs are optional according to storybook */}
          <MarketLink
            {...((isRequestNewCodeForNewBuyerThrottled ||
              isRequestNewCodeForExistingBuyerThrottled ||
              isProcessing) && { disabled: true })}
          >
            {t('common.requestNewCode')}
          </MarketLink>
        </span>
      </p>

      <MarketField invalid={isInvalidCode} className={'mb-6 w-full'}>
        <MarketInputText>
          <label>{t('common.code')}</label>
          <input
            slot={'input'}
            data-testid={'code'}
            autoComplete={'one-time-code'}
            autoCorrect={'off'}
            autoCapitalize={'off'}
            inputMode={'numeric'}
            maxLength={MAX_CODE_LENGTH}
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
              setIsInvalidCode(false);
            }}
            onKeyUp={handleKeyUp}
          />
        </MarketInputText>
        <small slot={'error'}>{t('common.code.invalid')}</small>
      </MarketField>
      <MarketButton
        rank={'primary'}
        className={'mb-2'}
        /* Fun workaround to get disabled working: https://github.com/squareup/market/issues/1570 */
        {...(!isCodeSubmittable && { disabled: true })}
        onClick={actionHandler}
      >
        {t(
          AddIdentifierTotpTranslationHelpers.primaryButtonTextKey(
            Boolean(props.newBuyerName),
            props.identifier.type,
            props.isVerifyingAssociatedPhone
          )
        )}
      </MarketButton>
      {Boolean(props.newBuyerName) && (
        <p className={'text-[#71767b] paragraph-30'}>
          <SquareBuyerPrivacyPolicy useAppSubdomain={useAppSubdomain} />
        </p>
      )}
    </>
  );
};

export default AddIdentifierTOTP;
