// eslint-disable-next-line filenames/match-exported
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import DashboardHeader from './components/DashboardHeader';
import type { PaymentMethod } from './components/PaymentMethodOnFile';
import PaymentMethodOnFile from './components/PaymentMethodOnFile';
import RemovePaymentMethodModal from './components/RemovePaymentMethodModal';
import Footer from 'components/SqPrivacyFooter';
import { useDispatch } from 'react-redux';
import {
  onFileBuyerDashboardViewEvent,
  SURFACE_DASHBOARD,
} from 'services/tracking/events/onFileManagementEvents';
import Es2Tracker from 'services/tracking/tracker';
import {
  ListOnFileBankAccountsRequest,
  OnFileBankAccount,
  RetrieveOnFileMerchantRequest,
  RetrieveOnFileMerchantResponse,
} from 'rpc/model/squareup/buyerportal/onfile/data';
import buyerPortalClient from 'rpc/client';
import PageNotFound from './components/PageNotFound';
import { openToast, ToastVariant } from 'store/toastSlice';
import GlobalToast from 'components/GlobalToast';
import { getUtmMediumQueryParam } from './utils';
import { BankAccountStatus } from 'rpc/model/squareup/payments/external/bank_accounts';
import SqLoading from 'components/SqLoading';
import { MarketDivider } from '@market/react';

/**
 * Main component for the On File Buyer Dashboard
 * https://docs.google.com/document/d/1OpQJel-zQJDmC3sOJlRmZwhx-PnqG7ZBKV2EztdJ8l4/edit#
 */
const OnFileBuyerDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const { baToken } = useParams();

  // Start with "loading" state for bank accounts
  const [paymentMethodOnFileFetchState, setPaymentMethodOnFileFetchState] =
    useState<RequestStatus | 'loading'>('loading');
  const [isRemovePaymentMethodModalOpen, setIsRemovePaymentMethodModalOpen] =
    useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod>();
  const [merchantData, setMerchantData] =
    useState<RetrieveOnFileMerchantResponse | null>(null);
  const [paymentMethods, setPaymentMethods] = useState<
    readonly OnFileBankAccount[]
  >([]);

  const onSelectPaymentMethod = (paymentMethod: PaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
    setIsRemovePaymentMethodModalOpen(true);
  };

  // Fetch merchant details
  useEffect(() => {
    const getMerchantDetails = async () => {
      try {
        const response = await buyerPortalClient.retrieveOnFileMerchant(
          RetrieveOnFileMerchantRequest.create({
            buyerAccessToken: baToken.toString(),
          })
        );
        setMerchantData(response);
      } catch {
        // Similar to below, this catch block is for frontend errors, but this status will ensure we show the error state.
        setMerchantData({
          status: RequestStatus.STATUS_ERROR,
        } as RetrieveOnFileMerchantResponse);
      }
    };

    if (!merchantData) {
      getMerchantDetails();
    }
  }, [merchantData, baToken]);

  // Track dashboard view event
  useEffect(() => {
    Es2Tracker.track(
      onFileBuyerDashboardViewEvent({
        surface: SURFACE_DASHBOARD,
        utmMedium: getUtmMediumQueryParam(),
      })
    );
  }, []);

  // Fetch linked bank accounts and update state accordingly.
  useEffect(() => {
    const getLinkedBankAccountsOnFile = async () => {
      setPaymentMethodOnFileFetchState('loading');
      try {
        const response = await buyerPortalClient.listOnFileBankAccounts(
          ListOnFileBankAccountsRequest.create({
            buyerAccessToken: baToken?.toString(),
          })
        );
        setPaymentMethodOnFileFetchState(response?.status);
        if (response?.bankAccounts.length > 0) {
          const validPaymentMethods = response.bankAccounts.filter(
            (paymentMethod) =>
              paymentMethod.accountStatus === BankAccountStatus.VERIFIED
          );
          setPaymentMethods(validPaymentMethods);
        }
      } catch {
        // Technically this catch applies to a frontend error, but this status will ensure we show the error state.
        setPaymentMethodOnFileFetchState(RequestStatus.STATUS_INTERNAL_ERROR);
      }
    };
    getLinkedBankAccountsOnFile();
  }, [baToken]);

  const onRemoveBankAccountMethodSuccess = () => {
    if (selectedPaymentMethod) {
      const newPaymentMethods = paymentMethods.filter(
        (paymentMethod) => paymentMethod.bankId !== selectedPaymentMethod.bankId
      );
      setPaymentMethods(newPaymentMethods);
      setSelectedPaymentMethod(undefined);

      dispatch(
        openToast({
          message: 'Payment method on file removed.',
          variant: ToastVariant.Success,
        })
      );
    }
  };

  // Render a loading indicator if either piece of data is still being fetched.
  if (merchantData === null || paymentMethodOnFileFetchState === 'loading') {
    return (
      <div className="flex justify-center items-center h-screen">
        <SqLoading />
      </div>
    );
  }

  // If either fetch resulted in an error, show a not found page.
  if (
    merchantData.status !== RequestStatus.STATUS_SUCCESS ||
    paymentMethodOnFileFetchState !== RequestStatus.STATUS_SUCCESS
  ) {
    return (
      <div>
        <PageNotFound />
      </div>
    );
  }

  // Otherwise, render the full dashboard.
  return (
    <div>
      <GlobalToast />
      <div className={'flex flex-col h-screen'}>
        <DashboardHeader merchantData={merchantData} />
        <main className={'my-0 mx-auto max-w-[600px] p-4 h-screen'}>
          <MarketDivider margin="medium" style={{ borderRadius: '5px' }} />
          <PaymentMethodOnFile
            onSelectPaymentMethod={onSelectPaymentMethod}
            paymentMethods={paymentMethods}
          />
        </main>
        {selectedPaymentMethod && isRemovePaymentMethodModalOpen && (
          <RemovePaymentMethodModal
            selectedPaymentMethod={selectedPaymentMethod}
            onDismissed={() => {
              setIsRemovePaymentMethodModalOpen(false);
            }}
            baToken={baToken}
            onError={() => {
              dispatch(
                openToast({
                  message: "Payment method on file couldn't be removed.",
                  variant: ToastVariant.Critical,
                })
              );
            }}
            onSuccess={onRemoveBankAccountMethodSuccess}
          />
        )}
        <Footer />
      </div>
    </div>
  );
};

export default OnFileBuyerDashboard;
