import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Store from 'svgs/market-icons/Store';
import { theme } from 'tailwind.config';
import normalizeHexColor from '../utils/normalizeHexColor';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export type MerchantLogoProps = {
  imgUrl?: string;
  sellerBrandColor?: string;
  shouldLinkToOverview?: boolean;
  merchantName?: string; // used to inform aria-label
};

const MerchantLogo: React.FC<MerchantLogoProps> = ({
  imgUrl,
  sellerBrandColor,
  shouldLinkToOverview = true,
  merchantName = 'merchant',
}) => {
  const { t } = useTranslation();
  const { merchantId } = useParams<{ merchantId: string }>();

  const alt = shouldLinkToOverview
    ? t('merchantPortal.merchantHeader.logo.clickableAltText', {
        merchantName,
      })
    : t('merchantPortal.merchantHeader.logo.nonClickableAltText');

  const renderImg = () => {
    return <img className="h-20" src={imgUrl} alt={alt} />;
  };

  const renderDefaultMerchantLogo = () => (
    <div
      aria-hidden="true"
      style={{
        backgroundColor: normalizeHexColor(
          sellerBrandColor || theme.extend.colors.bluegrey
        ),
      }}
      className={'p-3 rounded-lg flex items-center justify-center'}
    >
      <Store fill="#FFFFFF" />
    </div>
  );

  const logoContainerClasses = classNames(
    'merchant-logo-container flex justify-center',
    {
      'cursor-pointer': shouldLinkToOverview,
    }
  );

  const renderLogo = () => (imgUrl ? renderImg() : renderDefaultMerchantLogo());

  const renderLinkWrappedLogo = () => (
    <Link aria-label={alt} to={`/merchantPortal/${merchantId}`}>
      {renderLogo()}
    </Link>
  );

  return (
    <div className={logoContainerClasses}>
      {shouldLinkToOverview ? renderLinkWrappedLogo() : renderLogo()}
    </div>
  );
};

export default MerchantLogo;
