import React from 'react';
import BaseModal from 'components/modals/BaseModal';
import { MarketHeader } from '@market/react';
import { useTranslation } from 'react-i18next';
import TierProgramDetails from 'routes/merchant-scoped-portal/integrations/loyalty/components/TierProgramDetails';
import { LoyaltyProgram } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-program';
import { MembershipProgram } from 'rpc/model/squareup/memberships/model/membership-program';
import TierProgress from 'routes/merchant-scoped-portal/integrations/loyalty/components/TierProgress';
import { MemberStatus } from 'routes/merchant-scoped-portal/integrations/loyalty/models/MemberStatus';
import { DEFAULT_POINTS_TERMINOLOGY } from 'routes/merchant-scoped-portal/integrations/loyalty/utils/program';
import { DateFormat, formatDate } from 'utils/date';

export interface TierProgramDetailsModalProps {
  loyaltyProgram: LoyaltyProgram;
  membershipProgram: MembershipProgram;
  memberStatus?: MemberStatus;
}

const TierProgramDetailsModal: React.FC<TierProgramDetailsModalProps> = ({
  loyaltyProgram,
  membershipProgram,
  memberStatus,
}) => {
  const { t } = useTranslation();

  return (
    <BaseModal title={t('loyalty.tiers.label')}>
      <MarketHeader data-testid="loyalty__tier-program-details-modal__header">
        <h2>{t('loyalty.tiers.label')}</h2>
      </MarketHeader>
      <p data-testid="loyalty__tier-program-details-modal__description">
        {t('loyalty.tiers.modal.description')}
      </p>
      {memberStatus?.pointsTowardNextTier && (
        <TierProgress
          memberStatus={memberStatus}
          terminology={loyaltyProgram.terminology ?? DEFAULT_POINTS_TERMINOLOGY}
        />
      )}
      {memberStatus?.currentTierExpiration && (
        <p
          data-testid="loyalty__tier-progress__expiration"
          className="text-gray-500 mt-2 mb-8"
        >
          {t('loyalty.tiers.progress.expiration', {
            expirationDate: formatDate(
              memberStatus.currentTierExpiration,
              DateFormat.ll
            ),
          })}
        </p>
      )}
      <TierProgramDetails
        loyaltyProgram={loyaltyProgram}
        membershipProgram={membershipProgram}
        memberStatus={memberStatus}
        isCollapsible={true}
      />
    </BaseModal>
  );
};

export default TierProgramDetailsModal;
