import React from 'react';
import { MarketSvgProps } from '.';
import { useTranslation } from 'react-i18next';

const GiftCard: React.FC<MarketSvgProps> = (props) => {
  const { t } = useTranslation();
  const svgTitle = props.title || t('common.svgs.giftCardIconTitle');
  const width = `${props.width || '20'}`;
  const height = `${props.height || '18'}`;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={'0 0 20 18'}
      fill="none"
    >
      <title>{svgTitle}</title>
      <path
        d="M17 4H14.82C14.93 3.69 15 3.35 15 3C15 1.35 13.65 0 12 0C11.23 0 10.53 0.3 10 0.78C9.47 0.3 8.77 0 8 0C6.35 0 5 1.35 5 3C5 3.35 5.07 3.69 5.18 4H3C1.34 4 0 5.34 0 7V15C0 16.66 1.34 18 3 18H9H11H17C18.66 18 20 16.66 20 15V7C20 5.34 18.66 4 17 4ZM18 7V10H11V7.41L12.29 8.7L13.7 7.29L12.41 6H17C17.55 6 18 6.45 18 7ZM12 2C12.55 2 13 2.45 13 3C13 3.55 12.55 4 12 4H11V3C11 2.45 11.45 2 12 2ZM7 3C7 2.45 7.45 2 8 2C8.55 2 9 2.45 9 3V4H8C7.45 4 7 3.55 7 3ZM3 6H7.59L6.3 7.29L7.71 8.7L9 7.41V10H2V7C2 6.45 2.45 6 3 6ZM3 16C2.45 16 2 15.55 2 15V12H9V16H3ZM17 16H11V12H18V15C18 15.55 17.55 16 17 16Z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
};

export default GiftCard;
