import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Es2Tracker from 'services/tracking/tracker';
import { LogoIcon } from 'svgs';
import { MarketButton } from '@market/react';
import { privacyRequestsSuccessExportActionEvent } from 'services/tracking/events/privacyRequests';
import { IdentifierType } from 'routes/profile/models/Identifier';

const EXPORT_MAX_WAIT_DAYS = 45;

type SuccessExportDataProps = {
  identifierType: IdentifierType;
};

const SuccessExportData: React.FC<SuccessExportDataProps> = (props) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const history = useHistory();

  useEffect(() => {
    Es2Tracker.track(
      privacyRequestsSuccessExportActionEvent(props.identifierType)
    );
  }, [props.identifierType]);

  return (
    <>
      <LogoIcon className={'text-[32px]'} />
      <h1 className={'mb-4 heading-30'}>
        {t('privacyManagement.successExportData.title')}
      </h1>
      <p className={'mt-0 mb-6 paragraph-30'}>
        {t('privacyManagement.successExportData.subtitle', {
          numDays: `${EXPORT_MAX_WAIT_DAYS}`,
        })}
      </p>
      <MarketButton
        rank={'secondary'}
        onClick={() => {
          history.push('/');
        }}
      >
        {t('common.done')}
      </MarketButton>
    </>
  );
};

export default SuccessExportData;
