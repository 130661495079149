import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { COOKIE_GROUP_KEYS, hasConsent } from './userConsent';

export function getOrSetSavt(): string {
  if (!hasConsent(COOKIE_GROUP_KEYS.performanceAndAnalytics)) {
    return 'anonymous';
  }

  const existingSavt = Cookies.get('_savt');
  if (existingSavt) {
    return existingSavt;
  }

  const newSavt = uuidv4();
  Cookies.set('_savt', newSavt);
  return newSavt;
}
