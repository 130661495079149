/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../../Fixes';
import Builder from '../../../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      connect: {
        nested: {
          v2: {
            nested: {
              merchant_catalog: {
                nested: {
                  resources: {
                    nested: {
                      CatalogItemVariationWeightUnit: {
                        values: {
                          INVALID_WEIGHT: 0,
                          IMPERIAL_POUND: 1,
                          METRIC_KILOGRAM: 2,
                        },
                      },
                      ServiceCostType: {
                        values: {
                          FLAT_PRICING: 0,
                          PERCENT_PRICING: 1,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.merchant_catalog
    ?.nested?.resources?.nested?.CatalogItemVariationWeightUnit &&
  !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.merchant_catalog
    ?.nested?.resources?.nested?.ServiceCostType
) {
  $root.addJSON(jsonData);
}

/**
 * --
 * @desc Unit of weight used to measure a quantity.
 * --
 *
 * SOURCE CatalogItemVariationWeightUnit @ squareup/connect/v2/catalog/resources/enums.proto at 1129:1
 */
export const CatalogItemVariationWeightUnit = $root.lookupEnum(
  'squareup.connect.v2.merchant_catalog.resources.CatalogItemVariationWeightUnit'
).values;
/**
 * --
 * @desc Basis for cost of materials required to perform a service
 * --
 *
 * SOURCE ServiceCostType @ squareup/connect/v2/catalog/resources/enums.proto at 1220:1
 */
export const ServiceCostType = $root.lookupEnum(
  'squareup.connect.v2.merchant_catalog.resources.ServiceCostType'
).values;
