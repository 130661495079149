import React, { useCallback, useContext } from 'react';
import { AccountInfo } from 'rpc/model/squareup/buyerportal/merchantportal/loyalty/data';
import { LoyaltyProgram } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-program';
import { MemberStatus } from '../models/MemberStatus';
import { useTranslation } from 'react-i18next';
import {
  MarketActivityIndicatorBar,
  MarketPill,
  MarketRow,
} from '@market/react';
import { useHistory } from 'react-router-dom';
import { MerchantPortalContext } from 'routes/merchant-scoped-portal';

interface LoyaltyAccountInfoProps {
  accountInfo: AccountInfo;
  program: LoyaltyProgram;
}

const LoyaltyAccountInfo: React.FC<LoyaltyAccountInfoProps> = ({
  accountInfo,
  program,
}) => {
  const { t } = useTranslation();
  const points = accountInfo.loyaltyAccount?.balance ?? 0;
  const pointsTerminology =
    points === 1 ? program.terminology?.one : program.terminology?.other;
  const memberStatus =
    accountInfo.member && MemberStatus.fromMemberProto(accountInfo.member);
  const tierName = memberStatus?.currentTierName;
  const pointsUntilNextTier = memberStatus?.pointsTowardNextTier;
  const numRewardsAvailable = program.rewardTiers.filter(
    (rewardTier) => rewardTier.points <= points
  );
  const showTierProgress = memberStatus && !memberStatus.isLastTier;
  const history = useHistory();
  const mpContext = useContext(MerchantPortalContext);
  const merchantId =
    mpContext.merchantPortalBaseData?.merchantOverview.merchantId;
  const navigateToLaltPage = useCallback(() => {
    if (!merchantId || !accountInfo.loyaltyAccount?.loyaltyAccountLookupToken) {
      return;
    }
    history.push(
      `/merchantportal/${merchantId}/loyalty/lalt/${accountInfo.loyaltyAccount?.loyaltyAccountLookupToken}`
    );
  }, [
    history,
    merchantId,
    accountInfo.loyaltyAccount?.loyaltyAccountLookupToken,
  ]);

  return (
    <MarketRow
      data-testid="loyalty-account-info"
      onClick={navigateToLaltPage}
      transient
      variant="drill"
    >
      <label slot="label">
        <h2
          data-testid="loyalty-account-info__points"
          className="mt-0 mb-[12px]"
        >
          {t('loyalty.common.points', {
            count: points,
            pointsTerminology,
          })}
        </h2>
      </label>
      {memberStatus && (
        <MarketPill data-testid="loyalty-account-info__tier" slot="side-label">
          {tierName}
        </MarketPill>
      )}
      {showTierProgress && (
        <div slot="subtext">
          <MarketActivityIndicatorBar
            data-testid="loyalty-account-info__next-tier-progress-percentage"
            value={memberStatus.nextTierProgressPercentage}
            aria-label={t('loyalty.tiers.progress.barLabel')}
          />
          <p
            data-testid="loyalty-account-info__points-until-next-tier"
            className="text-fill-10"
          >
            {t('loyalty.common.pointsUntilNextTier', {
              nextTierName: memberStatus.nextTierName,
              points: pointsUntilNextTier,
              pointsTerminology,
            })}
          </p>
        </div>
      )}
      {!showTierProgress && (
        <p data-testid="loyalty-account-info__rewards-available" slot="subtext">
          {t('loyalty.common.numRewardsAvailable', {
            count: numRewardsAvailable.length,
          })}
        </p>
      )}
    </MarketRow>
  );
};

export default LoyaltyAccountInfo;
