import {
  MarketBanner,
  MarketButton,
  MarketEmptyState,
  MarketLink,
  MarketList,
} from '@market/react';
import React from 'react';
import BuyerCard from 'routes/profile/models/BuyerCard';
import { useDispatch, useSelector } from 'react-redux';
import { selectPrimaryId } from 'store/buyerSlice';
import { AppState } from 'store';
import { Identifier, IdentifierType } from 'routes/profile/models/Identifier';
import { ModalType, openModal } from 'store/modalSlice';
import PaymentCardRow from './PaymentCardRow';
import UnverifiedPaymentCardRow from './UnverifiedPaymentCardRow';
import { useTranslation } from 'react-i18next';

type PaymentCardsProps = {
  paymentCards: BuyerCard[];
  unverifiedCards: Identifier[];
};

const PaymentCards: React.FC<PaymentCardsProps> = ({
  paymentCards,
  unverifiedCards,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const primaryCardId = useSelector((state: AppState) =>
    selectPrimaryId(state, IdentifierType.Card)
  );

  const addPaymentCard = () => {
    dispatch(
      openModal({
        type: ModalType.AddPaymentCard,
      })
    );
  };

  return (
    <>
      <div className={'flex grow items-center justify-between mb-4'}>
        <h3 className={'heading-20 my-0'}>
          {t('profile.paymentMethods.paymentCards.title')}
        </h3>
        <MarketButton rank={'tertiary'} size={'small'} onClick={addPaymentCard}>
          {t('common.add')}
        </MarketButton>
      </div>
      <div>
        <p className={'paragraph-30'}>
          {t('profile.paymentMethods.paymentCards.explain')}
          <MarketLink
            className={'block'}
            target={'_blank'}
            href={
              'https://squareup.com/help/article/6945-save-payment-information-with-square-pay'
            }
          >
            {t('common.learnMore')}
          </MarketLink>
        </p>
        {paymentCards.length === 0 && unverifiedCards.length === 0 && (
          <MarketEmptyState className={'my-8'}>
            <h4 slot={'primary-text'} className={'medium-20'}>
              {t('profile.paymentMethods.paymentCards.noPaymentCards')}
            </h4>
          </MarketEmptyState>
        )}
        {unverifiedCards.length > 0 && (
          <MarketBanner className={'mb-4'}>
            {t('profile.paymentMethods.paymentCards.verify')}
          </MarketBanner>
        )}
        {paymentCards.length + unverifiedCards.length > 0 && (
          <MarketList
            aria-label={t('profile.paymentMethods.cards.ariaLabel')}
            interactive
            role={'list'}
          >
            {paymentCards.map((paymentCard) => {
              return (
                <PaymentCardRow
                  key={paymentCard.cardsApiId}
                  paymentCard={paymentCard}
                  isDefault={paymentCard.cardsApiId === primaryCardId}
                  hasMultipleCards={paymentCards.length > 1}
                />
              );
            })}
            {unverifiedCards.map((unverifiedCard) => {
              return (
                <UnverifiedPaymentCardRow
                  key={unverifiedCard.token}
                  unverifiedCard={unverifiedCard}
                />
              );
            })}
          </MarketList>
        )}
      </div>
    </>
  );
};

export default PaymentCards;
