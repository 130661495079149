/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      roster: {
        nested: {
          gateway: {
            nested: {
              Gateway: {
                values: {
                  NONE: 0,
                  SMCC: 1,
                  AMEX_CHASE: 2,
                  AMEX_CHASE_CE: 3,
                  AMEX_WELLS: 4,
                  AMEX_WELLS_CE: 5,
                  JCB_TID: 6,
                  JCB_MERCHANT_NUMBER: 7,
                  EVERLINK_TERMINAL_ID: 8,
                  AMEX_SE: 9,
                },
              },
              GatewayMerchantData: {
                fields: {
                  gateway: {
                    type: 'squareup.roster.gateway.Gateway',
                    id: 1,
                    rule: 'optional',
                  },
                  merchantToken: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  disabledAt: {
                    type: 'int64',
                    id: 3,
                    rule: 'optional',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.roster?.nested?.gateway?.nested?.Gateway &&
  !$root.nested?.squareup?.nested?.roster?.nested?.gateway?.nested
    ?.GatewayMerchantData
) {
  $root.addJSON(jsonData);
}

/**
 * Must be kept in sync with gateway_merchant_data.gateway in roster's DB.
 *
 * SOURCE Gateway @ squareup/roster/gateway.proto at 15:1
 */
export const Gateway = $root.lookupEnum(
  'squareup.roster.gateway.Gateway'
).values;
/**
 * SOURCE GatewayMerchantData @ squareup/roster/gateway.proto at 30:1
 */
export const GatewayMerchantData = $root.lookupType(
  'squareup.roster.gateway.GatewayMerchantData'
);
fixType(GatewayMerchantData);
Builder.createAndAttachToType(GatewayMerchantData);
