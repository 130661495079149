import React from 'react';

interface MerchantContactInfoLineProps {
  text: string | null;
  testId: string;
  phoneNumber?: string;
}

const MerchantContactInfoLine: React.FC<MerchantContactInfoLineProps> = ({
  text,
  testId,
  phoneNumber,
}) => {
  return (
    <p
      className="paragraph-20 text-black/[.55] m-0 whitespace-pre-wrap"
      data-testid={testId}
    >
      {text}{' '}
      {phoneNumber && (
        <a href={`tel:${phoneNumber}`} className="text-black">
          {phoneNumber}
        </a>
      )}
    </p>
  );
};

export default MerchantContactInfoLine;
