/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../../Fixes';
import Builder from '../../../../../Builder';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      feature: {
        nested: {
          relay: {
            nested: {
              flags: {
                nested: {
                  message: {
                    nested: {
                      FlagConfig: {
                        fields: {
                          includeValueExpirationOnStaleness: {
                            type: 'bool',
                            id: 1,
                            rule: 'optional',
                          },
                          includeValueCooldownOnCrash: {
                            type: 'bool',
                            id: 2,
                            rule: 'optional',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags?.nested
    ?.message?.nested?.FlagConfig
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE FlagConfig @ squareup/feature/relay/flags/message/flag_config.proto at 10:1
 */
export const FlagConfig = $root.lookupType(
  'squareup.feature.relay.flags.message.FlagConfig'
);
fixType(FlagConfig, {
  includeValueExpirationOnStaleness: { required: true },
  includeValueCooldownOnCrash: { required: true },
});
Builder.createAndAttachToType(FlagConfig);
