import React from 'react';
import { useTranslation } from 'react-i18next';
import AlertBase from './components/alerts/AlertBase';
import { MarketButton, MarketDivider } from '@market/react';
import TopNav from './layout-components/TopNav';
import PrivacyFooter from 'components/SqPrivacyFooter';

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <>
      <TopNav shouldOnlyRenderSquareProfileLink />
      <div className="flex grow items-center">
        <div className="grow box-border mx-auto px-6 sm:px-0 pt-8 pb-12 max-w-desktopContent">
          <AlertBase
            title={t('merchantPortal.alerts.pageNotFound.title')}
            subtitle={t('merchantPortal.alerts.pageNotFound.subtitle')}
            type="page"
          />
          <MarketDivider margin="large" />

          <h2 className="heading-20">
            {t('merchantPortal.pageNotFound.title')}
          </h2>
          <p className="pb-6">{t('merchantPortal.pageNotFound.subtitle')}</p>
          <MarketButton
            className="w-full md:w-auto"
            rank="secondary"
            href="/signin"
          >
            {t('merchantPortal.pageNotFound.button.signIn')}
          </MarketButton>
        </div>
      </div>

      <PrivacyFooter isMerchantPortal />
    </>
  );
};

export default PageNotFound;
