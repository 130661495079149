import React from 'react';
import GiftCardIcon from 'svgs/market-icons/GiftCard';
import LockOnIcon from 'svgs/market-icons/LockOn';
import { MarketAccessory, MarketContentCard, MarketRow } from '@market/react';
import { LoyaltyProgramRewardTier } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-program';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { selectLoyaltyProgram } from 'routes/merchant-scoped-portal/integrations/loyalty/loyaltySlice';

interface RewardTierProps {
  isLocked: boolean;
  rewardTier: LoyaltyProgramRewardTier;
}

const RewardTier: React.FC<RewardTierProps> = ({
  isLocked = true,
  rewardTier,
}) => {
  const { t } = useTranslation();

  const loyaltyProgram = useSelector((state: AppState) =>
    selectLoyaltyProgram(state.loyaltyMerchantPortal)
  );
  const pointsTerminology =
    rewardTier.points === 1
      ? loyaltyProgram?.terminology?.one
      : loyaltyProgram?.terminology?.other;

  return (
    <MarketContentCard role="option" className="py-0 mb-4 not:last-child:mb-0">
      <MarketRow>
        <label slot="label" className={isLocked ? 'text-gray-500' : ''}>
          {t('loyalty.common.points', {
            count: rewardTier.points,
            pointsTerminology,
          })}
        </label>
        <p slot="subtext" className={isLocked ? 'text-gray-500' : ''}>
          {rewardTier.name}
        </p>
        <MarketAccessory
          className={`${isLocked ? 'bg-black/[.03]' : 'bg-blue'} rounded`}
          data-testid={
            isLocked
              ? 'loyalty__reward-tier__lock-on-icon'
              : 'loyalty__reward-tier__gift-card-icon'
          }
          size="image"
          slot="leading-accessory"
        >
          {isLocked ? (
            <LockOnIcon fill="rgba(173, 173, 173, 1)" />
          ) : (
            <GiftCardIcon fill="white" />
          )}
        </MarketAccessory>
      </MarketRow>
    </MarketContentCard>
  );
};

export default RewardTier;
