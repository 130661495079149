import React from 'react';
import SearchableLoadMoreList, {
  RowItemProps,
} from 'components/profile/SearchableLoadMoreList';
import {
  EmailPreference,
  MerchantEmailPreference,
  Product,
  Value,
} from 'routes/profile/models/EmailPreference';
import { openToast, ToastVariant } from 'store/toastSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useUpdateEmailPreferenceMutation } from 'store/query/api-extensions/receiptAndMarketingPreferences';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import { actionToggleAutoReceipts } from 'services/tracking/cdp/events/receiptSettings';

type MerchantReceiptPrefsProps = {
  emailId: string;
  // Currently selected card id, to inform the update pref callback
  // Optional because the list of cards may still be loading
  cardId: string;
  merchantPrefs: MerchantEmailPreference[];
  isMerchantPrefsLoading: boolean;
};

const MerchantReceiptPrefs: React.FC<MerchantReceiptPrefsProps> = ({
  cardId,
  emailId,
  merchantPrefs,
  isMerchantPrefsLoading,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [updateEmailPreference] = useUpdateEmailPreferenceMutation();
  const data: RowItemProps[] = merchantPrefs.map((mPref) => {
    const { imageUrl, displayName, id } = mPref.merchantInfo;

    return {
      ariaLabelItem: displayName,
      displayName,
      enabled:
        mPref.cardReceiptPreferences.find(
          (cardPref) => cardPref.cardId === cardId
        )?.receiptPreference.value === Value.OptIn,
      id,
      // TODO: Find the logic to generate default image based on first char of displayName
      imageUrl,
    };
  });

  return (
    <SearchableLoadMoreList
      ariaLabel={t('profile.receiptSettings.merchantPreferences.ariaLabel')}
      ariaLabelItemFunc={(businessName: string) =>
        t('profile.receiptSettings.merchantPreference.ariaLabel', {
          businessName,
        })
      }
      rowModels={data}
      isLoading={isMerchantPrefsLoading}
      onRowToggle={(isEnabled: boolean, id: string) =>
        updateEmailPreference({
          cardId, // Passed as prop from enclosing context
          emailId: emailId!,
          merchantId: id,
          preference: new EmailPreference({
            product: Product.Receipt,
            value: isEnabled ? Value.OptIn : Value.OptOut,
          }),
        })
          .unwrap()
          .then(() => {
            buyerportalCdpClient.track(
              'Toggle Auto Receipts',
              actionToggleAutoReceipts(isEnabled, id)
            );
          })
          .catch(() => {
            dispatch(
              openToast({
                message: t('common.somethingWentWrong.retryable.direct'),
                variant: ToastVariant.Critical,
              })
            );
          })
      }
    />
  );
};

export default MerchantReceiptPrefs;
