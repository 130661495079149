import React, { useContext } from 'react';
import BaseModal from 'components/modals/BaseModal';
import { MarketHeader, MarketRow } from '@market/react';
import { useTranslation } from 'react-i18next';

import { LoyaltyPromotion } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-promotion';
import { parseICalString } from 'routes/merchant-scoped-portal/integrations/loyalty/utils/parseIcal';
import {
  formatDateRange,
  formatTimePeriod,
  getDetailsText,
  getEarningRuleDescription,
  getQualifyingCategoriesText,
  getQualifyingItemsText,
} from 'routes/merchant-scoped-portal/integrations/loyalty/utils/promotion';
import { LoyaltyProgram } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-program';
import { useRetrievePromotionDetailQuery } from 'store/query/api-extensions/loyalty';
import ModuleLoading from 'routes/profile/common/loading/ModuleLoading';
import { MerchantPortalContext } from 'routes/merchant-scoped-portal';

export interface LoyaltyPromotionDetailModalProps {
  loyaltyPromotion: LoyaltyPromotion;
  program: LoyaltyProgram;
}

const LoyaltyPromotionDetailModal: React.FC<
  LoyaltyPromotionDetailModalProps
> = ({ loyaltyPromotion, program }) => {
  const { t } = useTranslation();

  const mpContext = useContext(MerchantPortalContext);

  const merchantId =
    mpContext.merchantPortalBaseData?.merchantOverview.merchantId;

  const {
    isLoading,
    isUninitialized,
    data: loyaltyPromotionDetail,
  } = useRetrievePromotionDetailQuery({
    merchantId: merchantId ?? '',
    programId: program.id,
    promotionId: loyaltyPromotion.id,
  });

  if (isLoading || isUninitialized) {
    return (
      <BaseModal>
        <ModuleLoading embedded />
      </BaseModal>
    );
  }

  let parsedICalStrings;
  try {
    parsedICalStrings = loyaltyPromotion.availableTime?.timePeriods
      ? loyaltyPromotion.availableTime?.timePeriods.map((timePeriod) =>
          parseICalString(timePeriod)
        )
      : null;
  } catch {
    // no-op
  }

  const showDetails = Boolean(
    loyaltyPromotion.minimumSpendAmountMoney || loyaltyPromotion.triggerLimit
  );
  const showLocations = Boolean(
    loyaltyPromotionDetail?.availableLocationNames?.length
  );
  const { qualifyingCategoryNames, qualifyingItemNames } =
    loyaltyPromotionDetail ?? {};

  return (
    <BaseModal title={loyaltyPromotion.name}>
      <MarketHeader data-testid="loyalty__promotion-details-modal__header">
        <h2>{loyaltyPromotion.name}</h2>
      </MarketHeader>

      <p data-testid="loyalty__promotion-details-modal__description">
        {getEarningRuleDescription(loyaltyPromotion, program.terminology)}
      </p>

      <MarketRow data-testid="loyalty__promotion-details-modal__period">
        <label slot="label">
          {t('loyalty.loyaltyPromotionDetailsModal.promotionPeriod')}
        </label>
        <p slot="subtext">{formatDateRange(loyaltyPromotion)}</p>
      </MarketRow>

      {showLocations && (
        <MarketRow data-testid="loyalty__promotion-details-modal__locations">
          <label slot="label">
            {t('loyalty.loyaltyPromotionDetailsModal.locations')}
          </label>
          <p slot="subtext">
            {loyaltyPromotionDetail?.availableLocationNames?.map(
              (locationName, index) => {
                return (
                  <span className="block" key={`${index}-${locationName}`}>
                    {locationName}
                  </span>
                );
              }
            )}
          </p>
        </MarketRow>
      )}

      <MarketRow data-testid="loyalty__promotion-details-modal__times">
        <label slot="label">
          {t('loyalty.loyaltyPromotionDetailsModal.times')}
        </label>
        <p slot="subtext">
          {parsedICalStrings?.map((parsedICalString) => {
            return parsedICalString.map((parsedTimePeriod) => {
              return (
                <span
                  className="block"
                  key={`${parsedTimePeriod.weekday}-${parsedTimePeriod.hours.startTime}`}
                >
                  {formatTimePeriod(parsedTimePeriod)}
                </span>
              );
            });
          })}
        </p>
      </MarketRow>

      {showDetails && (
        <MarketRow data-testid="loyalty__promotion-details-modal__details">
          <label slot="label">
            {t('loyalty.loyaltyPromotionDetailsModal.details')}
          </label>
          <p slot="subtext">
            {getDetailsText(
              loyaltyPromotion.minimumSpendAmountMoney,
              loyaltyPromotion.triggerLimit
            )}
          </p>
        </MarketRow>
      )}

      {Boolean(qualifyingItemNames?.length) && (
        <MarketRow data-testid="loyalty__promotion-details-modal__qualifying-items">
          <label slot="label">
            {t('loyalty.loyaltyPromotionDetailsModal.qualifyingItems.title')}
          </label>
          <p slot="subtext">{getQualifyingItemsText(qualifyingItemNames)}</p>
        </MarketRow>
      )}

      {Boolean(qualifyingCategoryNames?.length) && (
        <MarketRow data-testid="loyalty__promotion-details-modal__qualifying-categories">
          <label slot="label">
            {t(
              'loyalty.loyaltyPromotionDetailsModal.qualifyingCategories.title'
            )}
          </label>
          <p slot="subtext">
            {getQualifyingCategoriesText(qualifyingCategoryNames)}
          </p>
        </MarketRow>
      )}
    </BaseModal>
  );
};

export default LoyaltyPromotionDetailModal;
