import { MarketButton } from '@market/react';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AlertI from 'svgs/market-icons/AlertI';

export type AlertBaseProps = {
  title: string;
  subtitle?: string;
  onTryAgain?: () => void; // if provided, will render the “Try again” button wired to this callback
  type: 'section' | 'page'; // Type dictates the size of the error message
};

// Content for the "something went wrong" error message.
// Can be slotted into any container
const AlertBase: React.FC<AlertBaseProps> = ({
  title,
  subtitle,
  onTryAgain,
  type,
}) => {
  const { t } = useTranslation();
  const containerClasses = classNames(
    'flex flex-col gap-y-8 items-stretch md:items-center',
    {
      'py-4 px-4': type === 'section',
    }
  );
  return (
    <div className={containerClasses}>
      <div className="flex flex-col items-center gap-y-2 text-center">
        <AlertI width={24} height={24} />
        {type === 'section' ? (
          <p className="medium-30 my-0">{title}</p>
        ) : (
          <h1 className="my-0">{title}</h1>
        )}
        {subtitle && <p className="paragraph-20 my-0">{subtitle}</p>}
      </div>
      {onTryAgain && (
        <MarketButton
          rank="secondary"
          onClick={onTryAgain}
          className="md:w-[320px]"
        >
          {t('common.tryAgain')}
        </MarketButton>
      )}
    </div>
  );
};

export default AlertBase;
