/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';
import '../../../card/balance/loyalty_api/model/loyalty-account';
import '../../profile/common';
import '../../../memberships/model/member';
import '../../../customers/request';
import '../../../card/balance/loyalty_api/model/loyalty-program';
import '../../../memberships/model/membership-program';
import '../../common/data';
import '../../../card/balance/loyalty_api/model/loyalty-event-group';
import '../../../card/balance/loyalty_api/model/loyalty-reward';
import '../../../card/balance/loyalty_api/model/email-collection-incentive-terms-and-status';
import '../../../card/balance/loyalty_api/model/loyalty-promotion';
import '../../../card/balance/loyalty_api/model/claimable-points';
import '../../../card/balance/model/loyalty-terms-of-service';
import '../../../passes/service/url-service';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      buyerportal: {
        nested: {
          merchantportal: {
            nested: {
              loyalty: {
                nested: {
                  AccountInfo: {
                    fields: {
                      loyaltyAccount: {
                        type: 'squareup.card.balance.loyalty_api.model.LoyaltyAccount',
                        id: 1,
                        rule: 'optional',
                      },
                      phoneIdentifier: {
                        type: 'squareup.buyerportal.profile.Identifier',
                        id: 2,
                        rule: 'optional',
                      },
                      member: {
                        type: 'squareup.memberships.model.Member',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                  },
                  RetrieveLoyaltyAccountInfoForBuyerRequest: {
                    fields: {
                      merchantId: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                    },
                  },
                  RetrieveLoyaltyAccountInfoForBuyerResponse: {
                    fields: {
                      status: {
                        type: 'squareup.customers.request.RequestStatus',
                        id: 1,
                        rule: 'optional',
                      },
                      errors: {
                        type: 'squareup.customers.request.RequestError',
                        id: 2,
                        rule: 'repeated',
                      },
                      accounts: {
                        type: 'squareup.buyerportal.merchantportal.loyalty.AccountInfo',
                        id: 3,
                        rule: 'repeated',
                      },
                    },
                  },
                  RetrieveLoyaltyProgramInfoRequest: {
                    fields: {
                      merchantId: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                    },
                  },
                  ProgramInfo: {
                    fields: {
                      loyaltyProgram: {
                        type: 'squareup.card.balance.loyalty_api.model.LoyaltyProgram',
                        id: 1,
                        rule: 'optional',
                      },
                      membershipProgram: {
                        type: 'squareup.memberships.model.MembershipProgram',
                        id: 2,
                        rule: 'optional',
                      },
                    },
                  },
                  RetrieveLoyaltyProgramInfoResponse: {
                    fields: {
                      status: {
                        type: 'squareup.customers.request.RequestStatus',
                        id: 1,
                        rule: 'optional',
                      },
                      errors: {
                        type: 'squareup.customers.request.RequestError',
                        id: 2,
                        rule: 'repeated',
                      },
                      programInfo: {
                        type: 'squareup.buyerportal.merchantportal.loyalty.ProgramInfo',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                  },
                  CreateLoyaltyAccountForBuyerRequest: {
                    fields: {
                      idempotencyKey: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      merchantId: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      loyaltyProgramId: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                      verificationCredential: {
                        type: 'squareup.buyerportal.common.VerificationCredential',
                        id: 4,
                        rule: 'optional',
                      },
                      claimablePointsToken: {
                        type: 'string',
                        id: 5,
                        rule: 'optional',
                      },
                    },
                  },
                  CreateLoyaltyAccountForBuyerResponse: {
                    fields: {
                      status: {
                        type: 'squareup.customers.request.RequestStatus',
                        id: 1,
                        rule: 'optional',
                      },
                      errors: {
                        type: 'squareup.customers.request.RequestError',
                        id: 2,
                        rule: 'repeated',
                      },
                      loyaltyAccount: {
                        type: 'squareup.card.balance.loyalty_api.model.LoyaltyAccount',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                  },
                  RetrieveLoyaltyAccountInfoRequest: {
                    fields: {
                      loyaltyAccountLookupToken: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                    },
                  },
                  RetrieveLoyaltyAccountInfoResponse: {
                    fields: {
                      status: {
                        type: 'squareup.customers.request.RequestStatus',
                        id: 1,
                        rule: 'optional',
                      },
                      errors: {
                        type: 'squareup.customers.request.RequestError',
                        id: 2,
                        rule: 'repeated',
                      },
                      info: {
                        type: 'squareup.buyerportal.merchantportal.loyalty.RetrieveLoyaltyAccountInfoResponse.Info',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      Info: {
                        fields: {
                          account: {
                            type: 'squareup.buyerportal.merchantportal.loyalty.AccountInfo',
                            id: 1,
                            rule: 'optional',
                          },
                          merchantId: {
                            type: 'string',
                            id: 2,
                            rule: 'optional',
                          },
                        },
                      },
                    },
                  },
                  ListLoyaltyEventDetailsRequest: {
                    fields: {
                      loyaltyAccountLookupToken: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      limit: {
                        type: 'uint32',
                        id: 2,
                        rule: 'optional',
                        options: {
                          default: 30,
                        },
                      },
                      cursor: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                  },
                  LoyaltyEventDetails: {
                    fields: {
                      eventGroups: {
                        type: 'squareup.card.balance.loyalty_api.model.LoyaltyEventGroup',
                        id: 1,
                        rule: 'repeated',
                      },
                      cursor: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      relatedLocationNames: {
                        type: 'string',
                        id: 3,
                        keyType: 'string',
                      },
                      relatedPromotionNames: {
                        type: 'string',
                        id: 4,
                        keyType: 'string',
                      },
                      relatedRewards: {
                        type: 'squareup.card.balance.loyalty_api.model.LoyaltyReward',
                        id: 5,
                        keyType: 'string',
                      },
                    },
                  },
                  ListLoyaltyEventDetailsResponse: {
                    fields: {
                      status: {
                        type: 'squareup.customers.request.RequestStatus',
                        id: 1,
                        rule: 'optional',
                      },
                      errors: {
                        type: 'squareup.customers.request.RequestError',
                        id: 2,
                        rule: 'repeated',
                      },
                      loyaltyEventDetails: {
                        type: 'squareup.buyerportal.merchantportal.loyalty.LoyaltyEventDetails',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                  },
                  ListPendingRewardsRequest: {
                    fields: {
                      loyaltyAccountLookupToken: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                    },
                  },
                  ListPendingRewardsResponse: {
                    fields: {
                      status: {
                        type: 'squareup.customers.request.RequestStatus',
                        id: 1,
                        rule: 'optional',
                      },
                      errors: {
                        type: 'squareup.customers.request.RequestError',
                        id: 2,
                        rule: 'repeated',
                      },
                      pendingRewards: {
                        type: 'squareup.card.balance.loyalty_api.model.LoyaltyReward',
                        id: 3,
                        rule: 'repeated',
                      },
                      hasOutstandingCouponBackedRewards: {
                        type: 'bool',
                        id: 4,
                        rule: 'optional',
                      },
                    },
                  },
                  RetrieveEmailCollectionIncentiveTermsAndStatusRequest: {
                    fields: {
                      loyaltyAccountLookupToken: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                    },
                  },
                  RetrieveEmailCollectionIncentiveTermsAndStatusResponse: {
                    fields: {
                      status: {
                        type: 'squareup.customers.request.RequestStatus',
                        id: 1,
                        rule: 'optional',
                      },
                      errors: {
                        type: 'squareup.customers.request.RequestError',
                        id: 2,
                        rule: 'repeated',
                      },
                      emailCollectionIncentiveInfo: {
                        type: 'squareup.card.balance.loyalty_api.model.EmailCollectionIncentiveTermsAndStatus',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                  },
                  CollectEmailForIncentiveRequest: {
                    fields: {
                      loyaltyAccountLookupToken: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      emailAddress: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      givenName: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                      surname: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      agreedToCopyTokens: {
                        type: 'string',
                        id: 5,
                        rule: 'repeated',
                      },
                      softwareReleaseNumber: {
                        type: 'string',
                        id: 6,
                        rule: 'optional',
                      },
                    },
                  },
                  CollectEmailForIncentiveResponse: {
                    fields: {
                      status: {
                        type: 'squareup.customers.request.RequestStatus',
                        id: 1,
                        rule: 'optional',
                      },
                      errors: {
                        type: 'squareup.customers.request.RequestError',
                        id: 2,
                        rule: 'repeated',
                      },
                      adjustment: {
                        type: 'uint64',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                  },
                  SearchLoyaltyPromotionsRequest: {
                    fields: {
                      merchantId: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      loyaltyProgramId: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      limit: {
                        type: 'uint32',
                        id: 3,
                        rule: 'optional',
                        options: {
                          default: 30,
                        },
                      },
                      cursor: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                    },
                  },
                  SearchLoyaltyPromotionsResponse: {
                    fields: {
                      status: {
                        type: 'squareup.customers.request.RequestStatus',
                        id: 1,
                        rule: 'optional',
                      },
                      errors: {
                        type: 'squareup.customers.request.RequestError',
                        id: 2,
                        rule: 'repeated',
                      },
                      loyaltyPromotions: {
                        type: 'squareup.card.balance.loyalty_api.model.LoyaltyPromotion',
                        id: 3,
                        rule: 'repeated',
                      },
                      cursor: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                    },
                  },
                  RetrieveLoyaltyPromotionDetailRequest: {
                    fields: {
                      merchantId: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      loyaltyProgramId: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      loyaltyPromotionId: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                  },
                  LoyaltyPromotionDetail: {
                    fields: {
                      loyaltyPromotion: {
                        type: 'squareup.card.balance.loyalty_api.model.LoyaltyPromotion',
                        id: 1,
                        rule: 'optional',
                      },
                      availableLocationNames: {
                        type: 'string',
                        id: 2,
                        rule: 'repeated',
                      },
                      qualifyingItemNames: {
                        type: 'string',
                        id: 3,
                        rule: 'repeated',
                      },
                      qualifyingCategoryNames: {
                        type: 'string',
                        id: 4,
                        rule: 'repeated',
                      },
                    },
                  },
                  RetrieveLoyaltyPromotionDetailResponse: {
                    fields: {
                      status: {
                        type: 'squareup.customers.request.RequestStatus',
                        id: 1,
                        rule: 'optional',
                      },
                      errors: {
                        type: 'squareup.customers.request.RequestError',
                        id: 2,
                        rule: 'repeated',
                      },
                      promotionDetail: {
                        type: 'squareup.buyerportal.merchantportal.loyalty.LoyaltyPromotionDetail',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                  },
                  RetrieveClaimablePointsRequest: {
                    fields: {
                      claimablePointsToken: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                    },
                  },
                  RetrieveClaimablePointsResponse: {
                    fields: {
                      status: {
                        type: 'squareup.customers.request.RequestStatus',
                        id: 1,
                        rule: 'optional',
                      },
                      errors: {
                        type: 'squareup.customers.request.RequestError',
                        id: 2,
                        rule: 'repeated',
                      },
                      claimablePoints: {
                        type: 'squareup.card.balance.loyalty_api.model.ClaimablePoints',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                  },
                  GetTermsOfServiceRequest: {
                    fields: {
                      merchantId: {
                        type: 'string',
                        id: 1,
                      },
                      loyaltyAccountLookupToken: {
                        type: 'string',
                        id: 2,
                      },
                    },
                    nested: {
                      terms_of_service_identifier: {
                        oneof: ['merchantId', 'loyaltyAccountLookupToken'],
                      },
                    },
                  },
                  GetTermsOfServiceResponse: {
                    fields: {
                      status: {
                        type: 'squareup.customers.request.RequestStatus',
                        id: 1,
                        rule: 'optional',
                      },
                      errors: {
                        type: 'squareup.customers.request.RequestError',
                        id: 2,
                        rule: 'repeated',
                      },
                      availableNewTos: {
                        type: 'squareup.card.balance.model.LoyaltyTermsOfService',
                        id: 3,
                        rule: 'optional',
                      },
                      latestAcceptedTos: {
                        type: 'squareup.card.balance.model.LoyaltyTermsOfService',
                        id: 4,
                        rule: 'optional',
                      },
                    },
                  },
                  UpdateTermsOfServiceConsentRequest: {
                    fields: {
                      loyaltyAccountLookupToken: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                      newlyAcceptedTos: {
                        type: 'squareup.card.balance.model.LoyaltyTermsOfService',
                        id: 2,
                        rule: 'optional',
                      },
                    },
                  },
                  UpdateTermsOfServiceConsentResponse: {
                    fields: {
                      status: {
                        type: 'squareup.customers.request.RequestStatus',
                        id: 1,
                        rule: 'optional',
                      },
                      errors: {
                        type: 'squareup.customers.request.RequestError',
                        id: 2,
                        rule: 'repeated',
                      },
                    },
                  },
                  RetrievePersonalizedPassDownloadUrlRequest: {
                    fields: {
                      loyaltyAccountId: {
                        type: 'string',
                        id: 1,
                        rule: 'optional',
                      },
                    },
                  },
                  RetrievePersonalizedPassDownloadUrlResponse: {
                    fields: {
                      status: {
                        type: 'squareup.customers.request.RequestStatus',
                        id: 1,
                        rule: 'optional',
                      },
                      errors: {
                        type: 'squareup.customers.request.RequestError',
                        id: 2,
                        rule: 'repeated',
                      },
                      personalizedPassDownloadData: {
                        type: 'squareup.passes.service.CreatePersonalizedLoyaltyPassUrlResponse.PersonalizedPassDownloadData',
                        id: 3,
                        rule: 'optional',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.AccountInfo &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.RetrieveLoyaltyAccountInfoForBuyerRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.RetrieveLoyaltyAccountInfoForBuyerResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.RetrieveLoyaltyProgramInfoRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.ProgramInfo &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.RetrieveLoyaltyProgramInfoResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.CreateLoyaltyAccountForBuyerRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.CreateLoyaltyAccountForBuyerResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.RetrieveLoyaltyAccountInfoRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.RetrieveLoyaltyAccountInfoResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.RetrieveLoyaltyAccountInfoResponse?.nested?.Info &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.ListLoyaltyEventDetailsRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.LoyaltyEventDetails &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.ListLoyaltyEventDetailsResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.ListPendingRewardsRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.ListPendingRewardsResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.RetrieveEmailCollectionIncentiveTermsAndStatusRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.RetrieveEmailCollectionIncentiveTermsAndStatusResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.CollectEmailForIncentiveRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.CollectEmailForIncentiveResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.SearchLoyaltyPromotionsRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.SearchLoyaltyPromotionsResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.RetrieveLoyaltyPromotionDetailRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.LoyaltyPromotionDetail &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.RetrieveLoyaltyPromotionDetailResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.RetrieveClaimablePointsRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.RetrieveClaimablePointsResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.GetTermsOfServiceRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.GetTermsOfServiceResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.UpdateTermsOfServiceConsentRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.UpdateTermsOfServiceConsentResponse &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.RetrievePersonalizedPassDownloadUrlRequest &&
  !$root.nested?.squareup?.nested?.buyerportal?.nested?.merchantportal?.nested
    ?.loyalty?.nested?.RetrievePersonalizedPassDownloadUrlResponse
) {
  $root.addJSON(jsonData);
}

/**
 * Begin shared *
 * Information about a loyalty account owned by the Buyer.
 *
 * SOURCE AccountInfo @ squareup/buyerportal/merchantportal/loyalty/data.proto at 30:1
 */
export const AccountInfo = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.AccountInfo'
);
fixType(AccountInfo, {
  loyaltyAccount: { required: true },
});
Builder.createAndAttachToType(AccountInfo);
/**
 * End shared *
 * Begin RetrieveLoyaltyAccountInfoForBuyer *
 * Request to retrieve info related to a Buyer's loyalty accounts with a Merchant, if any.
 *
 * SOURCE RetrieveLoyaltyAccountInfoForBuyerRequest @ squareup/buyerportal/merchantportal/loyalty/data.proto at 54:1
 */
export const RetrieveLoyaltyAccountInfoForBuyerRequest = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.RetrieveLoyaltyAccountInfoForBuyerRequest'
);
fixType(RetrieveLoyaltyAccountInfoForBuyerRequest, {
  merchantId: { notEmpty: true },
});
Builder.createAndAttachToType(RetrieveLoyaltyAccountInfoForBuyerRequest);
/**
 * Response to retrieve info related to a Buyer's loyalty accounts with a Merchant, if any.
 *
 * SOURCE RetrieveLoyaltyAccountInfoForBuyerResponse @ squareup/buyerportal/merchantportal/loyalty/data.proto at 62:1
 */
export const RetrieveLoyaltyAccountInfoForBuyerResponse = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.RetrieveLoyaltyAccountInfoForBuyerResponse'
);
fixType(RetrieveLoyaltyAccountInfoForBuyerResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(RetrieveLoyaltyAccountInfoForBuyerResponse);
/**
 * End RetrieveLoyaltyAccountInfoForBuyer *
 * Begin RetrieveLoyaltyProgramInfo *
 * Request to retrieve info related to a loyalty program for a given merchant.
 *
 * SOURCE RetrieveLoyaltyProgramInfoRequest @ squareup/buyerportal/merchantportal/loyalty/data.proto at 81:1
 */
export const RetrieveLoyaltyProgramInfoRequest = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.RetrieveLoyaltyProgramInfoRequest'
);
fixType(RetrieveLoyaltyProgramInfoRequest, {
  merchantId: { notEmpty: true },
});
Builder.createAndAttachToType(RetrieveLoyaltyProgramInfoRequest);
/**
 * Info related to a loyalty program.
 *
 * SOURCE ProgramInfo @ squareup/buyerportal/merchantportal/loyalty/data.proto at 89:1
 */
export const ProgramInfo = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.ProgramInfo'
);
fixType(ProgramInfo, {
  loyaltyProgram: { required: true },
});
Builder.createAndAttachToType(ProgramInfo);
/**
 * Response to retrieve info related to a loyalty program for a given merchant.
 *
 * SOURCE RetrieveLoyaltyProgramInfoResponse @ squareup/buyerportal/merchantportal/loyalty/data.proto at 100:1
 */
export const RetrieveLoyaltyProgramInfoResponse = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.RetrieveLoyaltyProgramInfoResponse'
);
fixType(RetrieveLoyaltyProgramInfoResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(RetrieveLoyaltyProgramInfoResponse);
/**
 * End RetrieveLoyaltyProgramInfo *
 * Begin CreateLoyaltyAccountForBuyer *
 * Request to create a loyalty account for a buyer.
 *
 * SOURCE CreateLoyaltyAccountForBuyerRequest @ squareup/buyerportal/merchantportal/loyalty/data.proto at 122:1
 */
export const CreateLoyaltyAccountForBuyerRequest = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.CreateLoyaltyAccountForBuyerRequest'
);
fixType(CreateLoyaltyAccountForBuyerRequest, {
  idempotencyKey: { notEmpty: true },
  merchantId: { notEmpty: true },
  loyaltyProgramId: { notEmpty: true },
  verificationCredential: { required: true },
});
Builder.createAndAttachToType(CreateLoyaltyAccountForBuyerRequest);
/**
 * Response to create a loyalty account for a buyer.
 *
 * SOURCE CreateLoyaltyAccountForBuyerResponse @ squareup/buyerportal/merchantportal/loyalty/data.proto at 154:1
 */
export const CreateLoyaltyAccountForBuyerResponse = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.CreateLoyaltyAccountForBuyerResponse'
);
fixType(CreateLoyaltyAccountForBuyerResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(CreateLoyaltyAccountForBuyerResponse);
/**
 * End CreateLoyaltyAccountForBuyer *
 * Begin RetrieveLoyaltyAccountInfo *
 * Request to retrieve info related to a loyalty account.
 *
 * SOURCE RetrieveLoyaltyAccountInfoRequest @ squareup/buyerportal/merchantportal/loyalty/data.proto at 173:1
 */
export const RetrieveLoyaltyAccountInfoRequest = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.RetrieveLoyaltyAccountInfoRequest'
);
fixType(RetrieveLoyaltyAccountInfoRequest, {
  loyaltyAccountLookupToken: { notEmpty: true },
});
Builder.createAndAttachToType(RetrieveLoyaltyAccountInfoRequest);
/**
 * Response to retrieve info related to a loyalty account.
 *
 * SOURCE RetrieveLoyaltyAccountInfoResponse @ squareup/buyerportal/merchantportal/loyalty/data.proto at 181:1
 */
export const RetrieveLoyaltyAccountInfoResponse = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.RetrieveLoyaltyAccountInfoResponse'
);
fixType(RetrieveLoyaltyAccountInfoResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(RetrieveLoyaltyAccountInfoResponse);
/**
 * SOURCE Info @ squareup/buyerportal/merchantportal/loyalty/data.proto at 182:3
 */
RetrieveLoyaltyAccountInfoResponse.Info = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.RetrieveLoyaltyAccountInfoResponse.Info'
);
fixType(RetrieveLoyaltyAccountInfoResponse.Info, {
  account: { required: true },
  merchantId: { required: true },
});
Builder.createAndAttachToType(RetrieveLoyaltyAccountInfoResponse.Info);
/**
 * End RetrieveLoyaltyAccountInfo *
 * Begin ListLoyaltyEventDetails *
 * Request to retrieve detailed loyalty events for the loyalty account.
 *
 * SOURCE ListLoyaltyEventDetailsRequest @ squareup/buyerportal/merchantportal/loyalty/data.proto at 206:1
 */
export const ListLoyaltyEventDetailsRequest = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.ListLoyaltyEventDetailsRequest'
);
fixType(ListLoyaltyEventDetailsRequest, {
  loyaltyAccountLookupToken: { notEmpty: true },
});
Builder.createAndAttachToType(ListLoyaltyEventDetailsRequest);
/**
 * SOURCE LoyaltyEventDetails @ squareup/buyerportal/merchantportal/loyalty/data.proto at 228:1
 */
export const LoyaltyEventDetails = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.LoyaltyEventDetails'
);
fixType(LoyaltyEventDetails);
Builder.createAndAttachToType(LoyaltyEventDetails);
/**
 * Response with detailed loyalty events for the loyalty account, if any.
 *
 * SOURCE ListLoyaltyEventDetailsResponse @ squareup/buyerportal/merchantportal/loyalty/data.proto at 255:1
 */
export const ListLoyaltyEventDetailsResponse = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.ListLoyaltyEventDetailsResponse'
);
fixType(ListLoyaltyEventDetailsResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(ListLoyaltyEventDetailsResponse);
/**
 * End ListLoyaltyEventDetails *
 * Begin ListPendingRewards *
 * Request to retrieve pending rewards on a loyalty account.
 *
 * SOURCE ListPendingRewardsRequest @ squareup/buyerportal/merchantportal/loyalty/data.proto at 273:1
 */
export const ListPendingRewardsRequest = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.ListPendingRewardsRequest'
);
fixType(ListPendingRewardsRequest, {
  loyaltyAccountLookupToken: { notEmpty: true },
});
Builder.createAndAttachToType(ListPendingRewardsRequest);
/**
 * Response with pending rewards on a loyalty account.
 *
 * SOURCE ListPendingRewardsResponse @ squareup/buyerportal/merchantportal/loyalty/data.proto at 281:1
 */
export const ListPendingRewardsResponse = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.ListPendingRewardsResponse'
);
fixType(ListPendingRewardsResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(ListPendingRewardsResponse);
/**
 * End ListPendingRewards *
 * Begin RetrieveEmailCollectionIncentiveTermsAndStatus *
 * Request to retrieve the terms and loyalty account status of the email collection incentive
 * offered by the merchant, if an incentive exists.
 *
 * SOURCE RetrieveEmailCollectionIncentiveTermsAndStatusRequest @ squareup/buyerportal/merchantportal/loyalty/data.proto at 302:1
 */
export const RetrieveEmailCollectionIncentiveTermsAndStatusRequest =
  $root.lookupType(
    'squareup.buyerportal.merchantportal.loyalty.RetrieveEmailCollectionIncentiveTermsAndStatusRequest'
  );
fixType(RetrieveEmailCollectionIncentiveTermsAndStatusRequest, {
  loyaltyAccountLookupToken: { notEmpty: true },
});
Builder.createAndAttachToType(
  RetrieveEmailCollectionIncentiveTermsAndStatusRequest
);
/**
 * Response to retrieve the terms and loyalty account status of the email collection incentive
 * offered by the merchant, if an incentive exists.
 *
 * SOURCE RetrieveEmailCollectionIncentiveTermsAndStatusResponse @ squareup/buyerportal/merchantportal/loyalty/data.proto at 311:1
 */
export const RetrieveEmailCollectionIncentiveTermsAndStatusResponse =
  $root.lookupType(
    'squareup.buyerportal.merchantportal.loyalty.RetrieveEmailCollectionIncentiveTermsAndStatusResponse'
  );
fixType(RetrieveEmailCollectionIncentiveTermsAndStatusResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(
  RetrieveEmailCollectionIncentiveTermsAndStatusResponse
);
/**
 * End RetrieveEmailCollectionIncentiveTermsAndStatus *
 * Begin CollectEmailForIncentive *
 * Request to sign up for a merchant's email mailing list in exchange for a point incentive.
 *
 * SOURCE CollectEmailForIncentiveRequest @ squareup/buyerportal/merchantportal/loyalty/data.proto at 329:1
 */
export const CollectEmailForIncentiveRequest = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.CollectEmailForIncentiveRequest'
);
fixType(CollectEmailForIncentiveRequest, {
  loyaltyAccountLookupToken: { notEmpty: true },
  emailAddress: { notEmpty: true },
});
Builder.createAndAttachToType(CollectEmailForIncentiveRequest);
/**
 * Response to sign up for a merchant's email mailing list in exchange for a point incentive.
 *
 * SOURCE CollectEmailForIncentiveResponse @ squareup/buyerportal/merchantportal/loyalty/data.proto at 355:1
 */
export const CollectEmailForIncentiveResponse = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.CollectEmailForIncentiveResponse'
);
fixType(CollectEmailForIncentiveResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(CollectEmailForIncentiveResponse);
/**
 * End CollectEmailForIncentive *
 * Begin SearchLoyaltyPromotions *
 * Request to search for a paginated list of active or scheduled loyalty promotions.
 *
 * SOURCE SearchLoyaltyPromotionsRequest @ squareup/buyerportal/merchantportal/loyalty/data.proto at 373:1
 */
export const SearchLoyaltyPromotionsRequest = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.SearchLoyaltyPromotionsRequest'
);
fixType(SearchLoyaltyPromotionsRequest, {
  merchantId: { notEmpty: true },
  loyaltyProgramId: { notEmpty: true },
});
Builder.createAndAttachToType(SearchLoyaltyPromotionsRequest);
/**
 * Response to search for a paginated list of active or scheduled loyalty promotions.
 *
 * SOURCE SearchLoyaltyPromotionsResponse @ squareup/buyerportal/merchantportal/loyalty/data.proto at 398:1
 */
export const SearchLoyaltyPromotionsResponse = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.SearchLoyaltyPromotionsResponse'
);
fixType(SearchLoyaltyPromotionsResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(SearchLoyaltyPromotionsResponse);
/**
 * End SearchLoyaltyPromotions *
 * Begin RetrieveLoyaltyPromotionDetail *
 * Request to retrieve details about a loyalty promotion, including the names of locations where
 * the promotion is eligible, qualifying items and categories.
 *
 * SOURCE RetrieveLoyaltyPromotionDetailRequest @ squareup/buyerportal/merchantportal/loyalty/data.proto at 423:1
 */
export const RetrieveLoyaltyPromotionDetailRequest = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.RetrieveLoyaltyPromotionDetailRequest'
);
fixType(RetrieveLoyaltyPromotionDetailRequest, {
  merchantId: { notEmpty: true },
  loyaltyProgramId: { notEmpty: true },
  loyaltyPromotionId: { notEmpty: true },
});
Builder.createAndAttachToType(RetrieveLoyaltyPromotionDetailRequest);
/**
 * SOURCE LoyaltyPromotionDetail @ squareup/buyerportal/merchantportal/loyalty/data.proto at 434:1
 */
export const LoyaltyPromotionDetail = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.LoyaltyPromotionDetail'
);
fixType(LoyaltyPromotionDetail, {
  loyaltyPromotion: { required: true },
});
Builder.createAndAttachToType(LoyaltyPromotionDetail);
/**
 * Response to retrieve details about a loyalty promotion, including the names of locations where
 * the promotion is eligible, qualifying items and categories.
 *
 * SOURCE RetrieveLoyaltyPromotionDetailResponse @ squareup/buyerportal/merchantportal/loyalty/data.proto at 445:1
 */
export const RetrieveLoyaltyPromotionDetailResponse = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.RetrieveLoyaltyPromotionDetailResponse'
);
fixType(RetrieveLoyaltyPromotionDetailResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(RetrieveLoyaltyPromotionDetailResponse);
/**
 * End RetrieveLoyaltyPromotionDetail *
 * Begin RetrieveClaimablePoints *
 *
 * SOURCE RetrieveClaimablePointsRequest @ squareup/buyerportal/merchantportal/loyalty/data.proto at 460:1
 */
export const RetrieveClaimablePointsRequest = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.RetrieveClaimablePointsRequest'
);
fixType(RetrieveClaimablePointsRequest, {
  claimablePointsToken: { notEmpty: true },
});
Builder.createAndAttachToType(RetrieveClaimablePointsRequest);
/**
 * Response to retrieve information about a set of claimable points.
 *
 * SOURCE RetrieveClaimablePointsResponse @ squareup/buyerportal/merchantportal/loyalty/data.proto at 468:1
 */
export const RetrieveClaimablePointsResponse = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.RetrieveClaimablePointsResponse'
);
fixType(RetrieveClaimablePointsResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(RetrieveClaimablePointsResponse);
/**
 * End RetrieveClaimablePoints *
 * Begin GetTermsOfService *
 * Request to retrieve loyalty ToS details for a merchant, and optionally ToS status for a loyalty account.
 *
 * SOURCE GetTermsOfServiceRequest @ squareup/buyerportal/merchantportal/loyalty/data.proto at 486:1
 */
export const GetTermsOfServiceRequest = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.GetTermsOfServiceRequest'
);
fixType(GetTermsOfServiceRequest);
Builder.createAndAttachToType(GetTermsOfServiceRequest);
/**
 * Response to retrieve loyalty ToS details for a merchant, and optionally ToS status for a loyalty account.
 *
 * SOURCE GetTermsOfServiceResponse @ squareup/buyerportal/merchantportal/loyalty/data.proto at 499:1
 */
export const GetTermsOfServiceResponse = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.GetTermsOfServiceResponse'
);
fixType(GetTermsOfServiceResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(GetTermsOfServiceResponse);
/**
 * End GetTermsOfService *
 * Begin UpdateTermsOfServiceConsent *
 * Request to update the terms of service consent for a loyalty account.
 *
 * SOURCE UpdateTermsOfServiceConsentRequest @ squareup/buyerportal/merchantportal/loyalty/data.proto at 526:1
 */
export const UpdateTermsOfServiceConsentRequest = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.UpdateTermsOfServiceConsentRequest'
);
fixType(UpdateTermsOfServiceConsentRequest, {
  loyaltyAccountLookupToken: { notEmpty: true },
  newlyAcceptedTos: { required: true },
});
Builder.createAndAttachToType(UpdateTermsOfServiceConsentRequest);
/**
 * Response to update the terms of service consent for a loyalty account.
 *
 * SOURCE UpdateTermsOfServiceConsentResponse @ squareup/buyerportal/merchantportal/loyalty/data.proto at 540:1
 */
export const UpdateTermsOfServiceConsentResponse = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.UpdateTermsOfServiceConsentResponse'
);
fixType(UpdateTermsOfServiceConsentResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(UpdateTermsOfServiceConsentResponse);
/**
 * End UpdateTermsOfServiceConsent *
 * Begin RetrievePersonalizedPassDownloadUrlRequest *
 * Request to retrieve URL to download a personalized Loyalty Pass, and the installation status of that pass.
 *
 * SOURCE RetrievePersonalizedPassDownloadUrlRequest @ squareup/buyerportal/merchantportal/loyalty/data.proto at 555:1
 */
export const RetrievePersonalizedPassDownloadUrlRequest = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.RetrievePersonalizedPassDownloadUrlRequest'
);
fixType(RetrievePersonalizedPassDownloadUrlRequest, {
  loyaltyAccountId: { notEmpty: true },
});
Builder.createAndAttachToType(RetrievePersonalizedPassDownloadUrlRequest);
/**
 * Response to retrieve URL to download a personalized Loyalty Pass, and the installation status of that pass.
 *
 * SOURCE RetrievePersonalizedPassDownloadUrlResponse @ squareup/buyerportal/merchantportal/loyalty/data.proto at 564:1
 */
export const RetrievePersonalizedPassDownloadUrlResponse = $root.lookupType(
  'squareup.buyerportal.merchantportal.loyalty.RetrievePersonalizedPassDownloadUrlResponse'
);
fixType(RetrievePersonalizedPassDownloadUrlResponse, {
  status: { required: true },
});
Builder.createAndAttachToType(RetrievePersonalizedPassDownloadUrlResponse);
