import React from 'react';
import { MarketAccessory, MarketActionCard, MarketRow } from '@market/react';
import { ModalType, openModal } from 'store/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { LoyaltyPromotion } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-promotion';
import { getEarningRuleDescription } from 'routes/merchant-scoped-portal/integrations/loyalty/utils/promotion';
import { AppState } from 'store';
import { selectLoyaltyProgram } from '../loyaltySlice';
import CelebrateIcon from 'svgs/market-icons/Celebrate';

interface LoyaltyPromotionCardProps {
  loyaltyPromotion: LoyaltyPromotion;
}

const LoyaltyPromotionCard: React.FC<LoyaltyPromotionCardProps> = ({
  loyaltyPromotion,
}: LoyaltyPromotionCardProps) => {
  const dispatch = useDispatch();

  const program = useSelector((state: AppState) =>
    selectLoyaltyProgram(state.loyaltyMerchantPortal)
  );

  function openLoyaltyPromotionDetailModal() {
    dispatch(
      openModal({
        props: {
          loyaltyPromotion,
          program,
        },
        type: ModalType.LoyaltyPromotionDetailModal,
      })
    );
  }

  return (
    <MarketActionCard onClick={openLoyaltyPromotionDetailModal}>
      <MarketRow variant="drill" transient>
        <MarketAccessory slot="leading-accessory" size="icon">
          <CelebrateIcon />
        </MarketAccessory>
        <label slot="label">{loyaltyPromotion.name}</label>
        <p slot="subtext">
          {getEarningRuleDescription(loyaltyPromotion, program?.terminology)}
        </p>
      </MarketRow>
    </MarketActionCard>
  );
};

export default LoyaltyPromotionCard;
