import React from 'react';

const BankIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <title>Bank</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 6V11H19V16H21V22H3V16H5V11H3V6L12 2L21 6ZM12 4.19L5 7.3V9H19V7.3L12 4.19ZM11 11V16H13V11H11ZM9 11H7V16H9V11ZM5 18V20H19V18H5ZM15 16H17V11H15V16ZM13 7C13 7.55228 12.5523 8 12 8C11.4477 8 11 7.55228 11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7Z"
        fill="black"
      />
    </svg>
  );
};

export default BankIcon;
