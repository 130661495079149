import React from 'react';
import { MarketContentCard } from '@market/react';
import { useTranslation } from 'react-i18next';
import AddToAppleWalletButton from 'components/AddToAppleWalletButton';

interface AddToAppleWalletSectionProps {
  loyaltyPassDownloadUrl: string;
}

const AddToAppleWalletSection: React.FC<AddToAppleWalletSectionProps> = ({
  loyaltyPassDownloadUrl,
}) => {
  const { t } = useTranslation();

  return (
    <section className={'mb-3'}>
      <MarketContentCard
        className={'flex justify-between items-center bg-black text-white py-1'}
      >
        <p>{t('loyalty.addToAppleWalletSection.description')}</p>
        <AddToAppleWalletButton downloadUrl={loyaltyPassDownloadUrl} />
      </MarketContentCard>
    </section>
  );
};

export default AddToAppleWalletSection;
