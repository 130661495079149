import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LogoIcon } from 'svgs';
import { MarketButton, MarketCheckbox, MarketRow } from '@market/react';
import { v4 as uuidv4 } from 'uuid';
import client from 'rpc/client';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import { LoyaltyData } from './types';
import { CreateLoginOrSignupVerificationRequest } from 'rpc/model/squareup/buyerportal/onboarding/data';
import PhoneInput from 'routes/native-sign-in/components/PhoneInput';
import { useGetTermsOfServiceQuery } from 'store/query/api-extensions/loyalty';
import { chooseConsentCopy } from 'routes/merchant-scoped-portal/integrations/loyalty/utils/consentCopy';
import i18n from 'i18n';
import ModuleLoading from 'routes/profile/common/loading/ModuleLoading';
import * as Sentry from '@sentry/react';
import { LoyaltyTermsOfService } from 'rpc/model/squareup/card/balance/model/loyalty-terms-of-service';
import { useTrackLoyaltyEvent } from 'utils/custom-react-hooks/loyalty/useTrackLoyaltyEvent';
import { EventName, FeatureFormat } from 'services/tracking/cdp/events/types';
import { Phone } from 'rpc/model/squareup/buyerportal/buyer/data';

interface LoyaltyVariantSignInProps {
  phone?: Phone;
  loyaltyData: LoyaltyData;
  onForward: (
    phoneNumber: string,
    newlyAcceptedTos?: LoyaltyTermsOfService
  ) => void;
  onUnexpectedError: (isCatastrophic?: boolean) => void;
}

const LoyaltyVariantSignIn: React.FC<LoyaltyVariantSignInProps> = ({
  phone,
  loyaltyData,
  onForward,
  onUnexpectedError,
}) => {
  const { t } = useTranslation();

  const [phoneValue, setPhoneValue] = useState<string>(
    phone?.displayValue ?? ''
  );
  const [nationalNumber, setNationalNumber] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false);
  const [agreeToTos, setAgreeToTos] = useState<boolean>(
    !loyaltyData.isTosConsentOptional
  );

  const [
    shouldDisplayInvalidSubmissionError,
    setShouldDisplayInvalidSubmissionError,
  ] = useState<boolean>(false);

  const {
    data: availableNewTos,
    isLoading,
    error,
  } = useGetTermsOfServiceQuery({
    merchantId: loyaltyData.merchantId,
  });

  const trackLoyaltyEvent = useTrackLoyaltyEvent();
  useEffect(() => {
    trackLoyaltyEvent(EventName.VIEW_FEATURE, {
      additional_parameters: {
        buyer_authenticated: Boolean(loyaltyData.buyer),
        merchant_id: loyaltyData.merchantId,
      },
      event_description: 'View enter your phone number page',
      feature_format: FeatureFormat.PAGE,
    });
  }, [loyaltyData.buyer, loyaltyData.merchantId, trackLoyaltyEvent]);

  if (isLoading) {
    return <ModuleLoading embedded />;
  }

  let tosCopy: LoyaltyTermsOfService.Copy | undefined;
  if (error || !availableNewTos) {
    Sentry.captureException('Error fetching Loyalty TOS', {
      extra: {
        availableNewTos,
        error,
      },
    });
  } else {
    tosCopy = chooseConsentCopy(i18n.language, availableNewTos.copies);
    if (!tosCopy) {
      Sentry.captureException('Consent copy not found for Loyalty TOS', {
        extra: {
          availableNewTos,
        },
      });
    }
  }

  const sendCode = async () => {
    if (!isPhoneValid) {
      setShouldDisplayInvalidSubmissionError(true);
      return;
    }

    try {
      setIsProcessing(true);
      const response = await client.createLoginOrSignupVerification(
        CreateLoginOrSignupVerificationRequest.create({
          idempotencyKey: uuidv4(),
          verificationCredential: { phoneNumber: phoneValue },
        })
      );
      if (response.status === RequestStatus.STATUS_SUCCESS) {
        if (agreeToTos && availableNewTos) {
          onForward(phoneValue, availableNewTos);
        } else {
          onForward(phoneValue);
        }
      } else {
        onUnexpectedError();
      }
    } catch {
      onUnexpectedError();
    } finally {
      setIsProcessing(false);
    }
  };

  const renderTos = () => {
    if (!tosCopy) {
      return null;
    }

    if (loyaltyData.isTosConsentOptional) {
      return (
        <MarketRow
          controlPosition="leading"
          className="bg-white"
          onMarketRowSelected={() => {
            setAgreeToTos(true);
          }}
          onMarketRowDeselected={() => {
            setAgreeToTos(false);
          }}
        >
          <MarketCheckbox slot="control" />
          <p>{tosCopy.content}</p>
        </MarketRow>
      );
    }
    return <p>{tosCopy.content}</p>;
  };

  return (
    <>
      <LogoIcon className={'text-[32px]'} />
      <h1 className={'my-7 heading-30'}>
        {t('loyalty.onboarding.phoneInputStep.title')}
      </h1>
      <PhoneInput
        onChange={(
          isValid: boolean,
          number: string,
          nationalNumber: string
        ) => {
          setPhoneValue(number);
          setIsPhoneValid(isValid);
          setShouldDisplayInvalidSubmissionError(false);
          setNationalNumber(nationalNumber);
        }}
        onEnterPressed={sendCode}
        shouldShowValidationError={shouldDisplayInvalidSubmissionError}
        value={phoneValue}
      />
      <MarketButton
        disabled={shouldDisplayInvalidSubmissionError || !nationalNumber}
        isLoading={isProcessing}
        rank="primary"
        onClick={sendCode}
      >
        {t('common.sendCode')}
      </MarketButton>
      {renderTos()}
    </>
  );
};

export default LoyaltyVariantSignIn;
