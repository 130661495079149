import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as Sentry from '@sentry/react';

export const URL_FETCH_OBFUSCATED_RECEIPT_EMAIL =
  '/services/squareup.receipts.SubscriptionService/subscriptions/receipt_info/';
export const URL_OPT_OUT_AUTOMATIC_RECEIPT_FROM_MERCHANT =
  '/services/squareup.receipts.SubscriptionService/subscriptions/automatic_receipt_opt_out/merchant/';

export type ReceiptDeliveryType = 'sms' | 'email';

export interface FetchReceiptInfoReturnValue {
  merchant: { name: string; countryCode: string };
  obfuscatedEmail: string | undefined;
  obfuscatedCard: string;
  deliveryType: ReceiptDeliveryType;
  isLinkedToDeliveryType: boolean;
}

export interface OptOutAutomaticReceiptFromMerchantReturnValue {
  success?: string;
}

export const receiptSubscriptionsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: '/services/squareup.receipts.SubscriptionService/subscriptions/',
  }),
  endpoints: (builder) => ({
    automaticReceiptOptInGlobal: builder.mutation<
      OptOutAutomaticReceiptFromMerchantReturnValue,
      string
    >({
      query: (receiptToken) => ({
        method: 'POST',
        url: `automatic_receipt_opt_in/global/${receiptToken}`,
      }),
    }),
    automaticReceiptOptInMerchant: builder.mutation<
      OptOutAutomaticReceiptFromMerchantReturnValue,
      string
    >({
      query: (receiptToken) => ({
        method: 'POST',
        url: `automatic_receipt_opt_in/merchant/${receiptToken}`,
      }),
    }),
    automaticReceiptOptOutGlobal: builder.mutation<
      OptOutAutomaticReceiptFromMerchantReturnValue,
      string
    >({
      query: (receiptToken) => ({
        method: 'POST',
        url: `automatic_receipt_opt_out/global/${receiptToken}`,
      }),
    }),
    automaticReceiptOptOutMerchant: builder.mutation<
      OptOutAutomaticReceiptFromMerchantReturnValue,
      string
    >({
      query: (receiptToken) => ({
        method: 'POST',
        url: `automatic_receipt_opt_out/merchant/${receiptToken}`,
      }),
    }),
    fetchReceiptInfo: builder.query<FetchReceiptInfoReturnValue, string>({
      query: (receiptToken) => ({
        url: `receipt_info/${receiptToken}`,
        validateStatus: (response, result) => {
          // Just check if we have a receipt object at all
          return Boolean(result?.receipt);
        },
      }),
      transformResponse: (response) => {
        // check if we're missing any of the required data to handle rendering the page/unlinking
        const allGood =
          response?.receipt?.payment_card?.human_brand &&
          response?.receipt?.payment_card?.pan_suffix &&
          response?.receipt?.user?.merchant_name &&
          response?.receipt?.user?.country_code &&
          response?.receipt?.delivery_type;

        if (!allGood) {
          const missingFields = [];
          if (!response?.receipt?.payment_card?.human_brand) {
            missingFields.push('human_brand');
          }
          if (!response?.receipt?.payment_card?.pan_suffix) {
            missingFields.push('pan_suffix');
          }
          if (!response?.receipt?.user?.merchant_name) {
            missingFields.push('merchant_name');
          }
          if (!response?.receipt?.user?.country_code) {
            missingFields.push('country_code');
          }
          if (!response?.receipt?.delivery_type) {
            missingFields.push('delivery_type');
          }

          Sentry.captureMessage(
            `Unexpected receipt_info response: missing required fields - ${missingFields.join(
              ', '
            )}`
          );
          throw new Error(
            `Unexpected receipt_info response: missing required fields - ${missingFields.join(
              ', '
            )}`
          );
        }
        const paymentCard = response.receipt.payment_card;
        const { human_brand: cardBrand, pan_suffix } = paymentCard;
        const obfuscatedCard = [cardBrand, pan_suffix]
          .filter(Boolean)
          .join(' ');

        const deliveryType = response.receipt.delivery_type;
        const {
          linked_email_id,
          linked_phone_id,
          obfuscated_email: obfuscatedEmail,
        } = paymentCard;
        const isLinkedToDeliveryType = Boolean(
          deliveryType === 'sms' ? linked_phone_id : linked_email_id
        );

        return {
          deliveryType,
          isLinkedToDeliveryType,
          merchant: {
            countryCode: response.receipt.user.country_code,
            name: response.receipt.user.merchant_name,
          },
          obfuscatedCard,
          // may or may not be used depending on delivery type
          // also, may or may not be present even if linked
          obfuscatedEmail,
        };
      },
    }),
    unlinkCardFromDeliveryIdentifier: builder.mutation<
      OptOutAutomaticReceiptFromMerchantReturnValue,
      string
    >({
      query: (receiptToken) => ({
        method: 'POST',
        url: `not_your_receipt/${receiptToken}`,
      }),
    }),
  }),
  reducerPath: 'receiptSubscriptionsApi',
});

export const {
  useFetchReceiptInfoQuery,
  useAutomaticReceiptOptInMerchantMutation,
  useAutomaticReceiptOptOutMerchantMutation,
  useAutomaticReceiptOptInGlobalMutation,
  useAutomaticReceiptOptOutGlobalMutation,
  useUnlinkCardFromDeliveryIdentifierMutation,
} = receiptSubscriptionsApi;
