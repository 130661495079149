/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import './egift_theme_category';
import '../../common/time';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      giftcard: {
        nested: {
          model: {
            nested: {
              EGiftTheme: {
                fields: {
                  imageUrl: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  backgroundColor: {
                    type: 'string',
                    id: 2,
                    rule: 'optional',
                  },
                  readOnlyToken: {
                    type: 'string',
                    id: 3,
                    rule: 'optional',
                  },
                  description: {
                    type: 'string',
                    id: 4,
                    rule: 'optional',
                  },
                  availableDateRange: {
                    type: 'squareup.giftcard.model.EGiftTheme.AvailableDateRange',
                    id: 5,
                    rule: 'optional',
                  },
                  category: {
                    type: 'squareup.giftcard.model.EGiftThemeCategory',
                    id: 6,
                    rule: 'optional',
                  },
                  isBranded: {
                    type: 'bool',
                    id: 7,
                    rule: 'optional',
                  },
                  brandDetails: {
                    type: 'squareup.giftcard.model.EGiftTheme.BrandDetails',
                    id: 8,
                    rule: 'optional',
                  },
                },
                nested: {
                  AvailableDateRange: {
                    fields: {
                      startDate: {
                        type: 'squareup.common.time.YearMonthDay',
                        id: 1,
                        rule: 'optional',
                      },
                      endDate: {
                        type: 'squareup.common.time.YearMonthDay',
                        id: 2,
                        rule: 'optional',
                      },
                    },
                  },
                  BrandDetails: {
                    fields: {
                      type: {
                        type: 'squareup.giftcard.model.EGiftTheme.BrandDetails.Type',
                        id: 1,
                        rule: 'optional',
                      },
                      businessName: {
                        type: 'string',
                        id: 2,
                        rule: 'optional',
                      },
                      backgroundColorHex: {
                        type: 'string',
                        id: 4,
                        rule: 'optional',
                      },
                      textColorHex: {
                        type: 'string',
                        id: 5,
                        rule: 'optional',
                      },
                      logoAssetUrl: {
                        type: 'string',
                        id: 6,
                        rule: 'optional',
                      },
                      logoAssetId: {
                        type: 'string',
                        id: 7,
                        rule: 'optional',
                      },
                    },
                    nested: {
                      Type: {
                        values: {
                          LOGO_SMALL: 1,
                          LOGO_LARGE: 2,
                          BUSINESS_NAME_PRIMARY_COLOR_BG: 3,
                          BUSINESS_NAME_BLACK_BG: 4,
                          BUSINESS_NAME_GIFT_WRAP_BG: 5,
                          BUSINESS_NAME_WHITE_BG: 6,
                          LOGO_LARGE_OPTION_2: 7,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.giftcard?.nested?.model?.nested
    ?.EGiftTheme &&
  !$root.nested?.squareup?.nested?.giftcard?.nested?.model?.nested?.EGiftTheme
    ?.nested?.AvailableDateRange &&
  !$root.nested?.squareup?.nested?.giftcard?.nested?.model?.nested?.EGiftTheme
    ?.nested?.BrandDetails &&
  !$root.nested?.squareup?.nested?.giftcard?.nested?.model?.nested?.EGiftTheme
    ?.nested?.BrandDetails?.nested?.Type
) {
  $root.addJSON(jsonData);
}

/**
 * SOURCE EGiftTheme @ squareup/giftcard/model/egift_theme.proto at 11:1
 */
export const EGiftTheme = $root.lookupType(
  'squareup.giftcard.model.EGiftTheme'
);
fixType(EGiftTheme);
Builder.createAndAttachToType(EGiftTheme);
/**
 * SOURCE AvailableDateRange @ squareup/giftcard/model/egift_theme.proto at 12:3
 */
EGiftTheme.AvailableDateRange = $root.lookupType(
  'squareup.giftcard.model.EGiftTheme.AvailableDateRange'
);
fixType(EGiftTheme.AvailableDateRange, {
  startDate: { required: true },
  endDate: { required: true },
});
Builder.createAndAttachToType(EGiftTheme.AvailableDateRange);
/**
 * SOURCE BrandDetails @ squareup/giftcard/model/egift_theme.proto at 20:3
 */
EGiftTheme.BrandDetails = $root.lookupType(
  'squareup.giftcard.model.EGiftTheme.BrandDetails'
);
fixType(EGiftTheme.BrandDetails);
Builder.createAndAttachToType(EGiftTheme.BrandDetails);
/**
 * The type corresponds to one of the cards possible here: https://docs.google.com/document/d/1zdbYPuq58sRFMS4vmbQwMe0pVcEBXe1NWwaicj_b4XI/edit#bookmark=id.s5ya5f1jv6n1
 * To see how the client should construct the the branded themes, see https://docs.google.com/document/d/1zdbYPuq58sRFMS4vmbQwMe0pVcEBXe1NWwaicj_b4XI/edit#bookmark=kix.6wytqu6i3fmf
 *
 * SOURCE Type @ squareup/giftcard/model/egift_theme.proto at 24:5
 */
EGiftTheme.BrandDetails.Type = $root.lookupEnum(
  'squareup.giftcard.model.EGiftTheme.BrandDetails.Type'
).values;
