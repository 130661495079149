import React from 'react';
import Profile from './ProfileDetails';
import { MarketDivider } from '@market/react';
import ContactInfo from './ContactInfo';
import AuthorizedApplications from './AuthorizedApplications';
import DownloadData from './DownloadData';
import DeleteAccount from './DeleteAccount';
import { useSelector } from 'react-redux';
import ShippingAddresses from './ShippingAddresses';
import { selectAddresses } from 'store/buyerSlice';
import Page from '../layout/Page';
import { viewProfileProperties } from 'services/tracking/cdp/events/profile';
import useCdpPageTracking from 'utils/custom-react-hooks/useCdpPageTracking';

const Account: React.FC = () => {
  const addresses = useSelector(selectAddresses);

  useCdpPageTracking({
    didLoadingFail: false,
    isLoading: false,
    properties: viewProfileProperties(),
  });

  return (
    <Page>
      <Profile />
      <MarketDivider className={'my-8'} />
      <ContactInfo />
      <MarketDivider className={'my-8'} />
      {addresses.length > 0 && (
        <>
          <ShippingAddresses />
          <MarketDivider className={'my-8'} />
        </>
      )}
      <AuthorizedApplications />
      <MarketDivider className={'my-8'} />
      <DownloadData />
      <MarketDivider className={'my-8'} />
      <DeleteAccount />
    </Page>
  );
};

export default Account;
