import React, { useEffect, useMemo, useState } from 'react';
import BaseModal from 'components/modals/BaseModal';
import {
  MarketAccessory,
  MarketButton,
  MarketField,
  MarketHeader,
  MarketInputText,
  MarketList,
  MarketRow,
  MarketSelect,
} from '@market/react';
import { useDispatch } from 'react-redux';
import { GiftCardDetail } from 'routes/profile/models/GiftCard';
import { useTransferGiftCardBalanceMutation } from 'store/query';
import {
  format as formatMoney,
  getMoneyFromFormattedString,
} from 'utils/currency';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { openToast, ToastVariant } from 'store/toastSlice';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import { useTranslation } from 'react-i18next';
import { closeModal } from 'store/modalSlice';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import useValidation from 'utils/custom-react-hooks/useValidation';
import { actionTransferGiftCardBalanceProperties } from 'services/tracking/cdp/events/paymentMethods';
import GiftCard from 'svgs/market-icons/GiftCard';

export type TransferGiftCardBalanceModalProps = {
  originalFromGiftCard: GiftCardDetail;
  allGiftCardDetailsForBuyerAtMerchant: GiftCardDetail[]; // includes the from gift card
};

const TransferGiftCardBalanceModal: React.FC<
  TransferGiftCardBalanceModalProps
> = ({ originalFromGiftCard, allGiftCardDetailsForBuyerAtMerchant }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [toGiftCardToken, setToGiftCardToken] = useState<string>('');
  const [fromGiftCardToken, setFromGiftCardToken] = useState<string>(
    originalFromGiftCard.token
  );
  const [transferAmount, setTransferAmount] = useState<string>();

  const [isInvalidToGiftCard, resetIsToGiftCardValid, validateToGiftCard] =
    useValidation(Boolean);
  const [
    isInvalidTransferAmount,
    resetIsInvalidTransferAmount,
    validateTransferAmount,
  ] = useValidation((transferAmount: string) => {
    if (!transferAmount) {
      return false;
    }
    const transferMoney = getMoneyFromFormattedString(
      transferAmount,
      originalFromGiftCard.balance.currencyCode
    );
    if (
      transferMoney.amount === 0 ||
      transferMoney.amount > originalFromGiftCard.balance.amount
    ) {
      return false;
    }
    return true;
  });

  const [transferGiftCardBalance, transferGiftCardBalanceResult] =
    useTransferGiftCardBalanceMutation();

  const selectedToGiftCardDetail = useMemo(() => {
    return allGiftCardDetailsForBuyerAtMerchant.find(
      (card) => card.token === toGiftCardToken
    );
  }, [allGiftCardDetailsForBuyerAtMerchant, toGiftCardToken]);

  const selectedFromGiftCardDetail = useMemo(() => {
    return allGiftCardDetailsForBuyerAtMerchant.find(
      (card) => card.token === fromGiftCardToken
    );
  }, [allGiftCardDetailsForBuyerAtMerchant, fromGiftCardToken]);

  const availableToGiftCards = useMemo(() => {
    return (
      allGiftCardDetailsForBuyerAtMerchant.filter(
        (card) => card.token !== fromGiftCardToken
      ) || []
    );
  }, [allGiftCardDetailsForBuyerAtMerchant, fromGiftCardToken]);

  const availableFromGiftCards = useMemo(() => {
    return (
      allGiftCardDetailsForBuyerAtMerchant.filter(
        (card) => card.token !== toGiftCardToken
      ) || []
    );
  }, [allGiftCardDetailsForBuyerAtMerchant, toGiftCardToken]);

  useEffect(() => {
    if (selectedFromGiftCardDetail) {
      setTransferAmount(selectedFromGiftCardDetail.formattedBalance());
    }
  }, [selectedFromGiftCardDetail]);

  const confirmTransferGiftCardBalance = () => {
    if (
      !validateTransferAmount(transferAmount) ||
      !validateToGiftCard(toGiftCardToken)
    ) {
      return;
    }

    const validTransferAmount = getMoneyFromFormattedString(
      transferAmount!,
      originalFromGiftCard.balance.currencyCode
    );

    transferGiftCardBalance({
      fromGiftCardToken,
      toGiftCardToken,
      amount: validTransferAmount,
    })
      .unwrap()
      .catch((ex) => {
        if ('data' in ex) {
          const apiError = ex as FetchBaseQueryError;
          if (apiError.status === RequestStatus.STATUS_BAD_REQUEST) {
            dispatch(
              openToast({
                variant: ToastVariant.Critical,
                message: t(
                  'profile.paymentMethods.giftCards.transferGiftCardBalanceModal.invalidAmount'
                ),
              })
            );
          }
        } else {
          dispatch(
            openToast({
              variant: ToastVariant.Critical,
              message: t('common.somethingWentWrong.retryable.direct'),
            })
          );
        }
      })
      .then(() => {
        dispatch(
          openToast({
            variant: ToastVariant.Success,
            message: t(
              'profile.paymentMethods.giftCards.transferGiftCardBalanceModal.success'
            ),
          })
        );

        buyerportalCdpClient.track(
          'Transfer Gift Card Funds',
          actionTransferGiftCardBalanceProperties(
            fromGiftCardToken,
            toGiftCardToken,
            formatMoney(validTransferAmount),
            originalFromGiftCard.merchantUnitToken
          )
        );

        dispatch(closeModal());
      });
  };

  const renderEGiftThemeAccessory = (imageUrl?: string) => {
    return (
      <MarketAccessory slot={'leading-accessory'}>
        {imageUrl ? (
          // width and height inform the aspect ratio. final size constrained by parent container
          <img
            width={640}
            height={400}
            src={imageUrl}
            alt={'Gift card'}
            style={{ width: 40 }}
          />
        ) : (
          <GiftCard />
        )}
      </MarketAccessory>
    );
  };

  const renderOptions = (giftCardOptions: GiftCardDetail[]) => {
    return (
      <MarketList slot={'list'}>
        {giftCardOptions.map((card) => {
          return (
            <MarketRow key={card.token} value={card.token}>
              {renderEGiftThemeAccessory(card.eGiftTheme?.imageUrl)}
              <label slot={'label'}>
                •••• {card.panSuffix} ({card.formattedBalance()})
              </label>
            </MarketRow>
          );
        })}
      </MarketList>
    );
  };

  return (
    <BaseModal>
      <MarketHeader className={'mb-8'}>
        <h2>
          {t(
            'profile.paymentMethods.giftCards.transferGiftCardBalanceModal.title'
          )}
        </h2>
        <MarketButton
          rank={'primary'}
          slot={'actions'}
          onClick={confirmTransferGiftCardBalance}
          {...(transferGiftCardBalanceResult.isLoading && { disabled: true })}
        >
          {t(
            'profile.paymentMethods.giftCards.transferGiftCardBalanceModal.transferBalance'
          )}
        </MarketButton>
      </MarketHeader>

      {/* Transfer balance from */}
      <MarketSelect
        key={'transfer-from'}
        className={'mb-4'}
        value={fromGiftCardToken}
        onMarketSelectValueDidChange={(e) => {
          // Market bug: this event is fired twice. First one is without a value
          // Strangely, this only affects the 'from' select, not the 'to' select
          if (e.detail.value) {
            setFromGiftCardToken(e.detail.value as string);
          }
        }}
        data-testid={'from'}
      >
        <label>{t('profile.transferGiftCardBalanceModal.from')}</label>
        {renderOptions(availableFromGiftCards)}
        {renderEGiftThemeAccessory(
          selectedFromGiftCardDetail?.eGiftTheme?.imageUrl
        )}
      </MarketSelect>

      {/* Transfer balance to */}
      <MarketField
        {...(isInvalidToGiftCard && {
          invalid: true,
        })}
        className="mb-4"
      >
        <MarketSelect
          key={'transfer-to'}
          value={toGiftCardToken}
          onMarketSelectValueDidChange={(e) => {
            resetIsToGiftCardValid();
            setToGiftCardToken(e.detail.value as string);
          }}
          data-testid={'to'}
        >
          <label>{t('profile.transferGiftCardBalanceModal.to')}</label>
          {renderOptions(availableToGiftCards)}
          {renderEGiftThemeAccessory(
            selectedToGiftCardDetail?.eGiftTheme?.imageUrl
          )}
        </MarketSelect>
        <small slot={'error'}>
          {t(
            'profile.paymentMethods.giftCards.transferGiftCardBalanceModal.selectCard'
          )}
        </small>
      </MarketField>

      {/* Transfer amount */}
      <MarketField
        {...(isInvalidTransferAmount && {
          invalid: true,
        })}
      >
        <MarketInputText
          value={transferAmount}
          onMarketInputValueChange={(e) => {
            resetIsInvalidTransferAmount();
            setTransferAmount(e.detail.value as string);
          }}
          onBlur={(e) => {
            // TODO pass in locale for internationalization
            const money = getMoneyFromFormattedString(
              e.target.value,
              originalFromGiftCard.balance.currencyCode!
            );
            setTransferAmount(formatMoney(money));
          }}
        >
          <label>
            {t(
              'profile.paymentMethods.giftCards.transferGiftCardBalanceModal.amount'
            )}
          </label>
        </MarketInputText>
        <small slot={'error'}>
          {t('profile.transferGiftCardBalanceModal.amountError')}
        </small>
      </MarketField>
    </BaseModal>
  );
};

export default TransferGiftCardBalanceModal;
