/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../connect/v2/common/money';

const $root =
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root());

const jsonData = {
  squareup: {
    nested: {
      omg: {
        nested: {
          splits: {
            nested: {
              SplitLineItemExtension: {
                fields: {
                  splitId: {
                    type: 'string',
                    id: 1,
                    rule: 'optional',
                  },
                  splitPartNumber: {
                    type: 'int32',
                    id: 2,
                    rule: 'optional',
                  },
                  splitTotalParts: {
                    type: 'int32',
                    id: 3,
                    rule: 'optional',
                  },
                  originalLineItemPricing: {
                    type: 'squareup.omg.splits.SplitLineItemExtension.LineItemPricing',
                    id: 4,
                    rule: 'optional',
                  },
                },
                nested: {
                  LineItemPricing: {
                    fields: {
                      basePriceMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 1,
                        rule: 'optional',
                      },
                      grossSalesMoney: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 2,
                        rule: 'optional',
                      },
                      originalQuantity: {
                        type: 'string',
                        id: 3,
                        rule: 'optional',
                      },
                      originalModifierBasePrices: {
                        type: 'squareup.connect.v2.common.Money',
                        id: 4,
                        keyType: 'string',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

// Do not initialize these protos if they have already been defined
if (
  !$root.nested?.squareup?.nested?.omg?.nested?.splits?.nested
    ?.SplitLineItemExtension &&
  !$root.nested?.squareup?.nested?.omg?.nested?.splits?.nested
    ?.SplitLineItemExtension?.nested?.LineItemPricing
) {
  $root.addJSON(jsonData);
}

/**
 * Extensions for item-splitting-specific features
 *
 * SOURCE SplitLineItemExtension @ squareup/omg/splits/extensions.proto at 13:1
 */
export const SplitLineItemExtension = $root.lookupType(
  'squareup.omg.splits.SplitLineItemExtension'
);
fixType(SplitLineItemExtension);
Builder.createAndAttachToType(SplitLineItemExtension);
/**
 * Contains a subset of fields (necessary to display a split item) from the original LineItem
 *
 * SOURCE LineItemPricing @ squareup/omg/splits/extensions.proto at 24:3
 */
SplitLineItemExtension.LineItemPricing = $root.lookupType(
  'squareup.omg.splits.SplitLineItemExtension.LineItemPricing'
);
fixType(SplitLineItemExtension.LineItemPricing);
Builder.createAndAttachToType(SplitLineItemExtension.LineItemPricing);
