import React from 'react';
import MerchantLocation from './MerchantLocation';
import {
  MarketInputSearch,
  MarketList,
  MarketRadio,
  MarketRow,
} from '@market/react';
import { useTranslation } from 'react-i18next';
import { FormattedMerchantLocation } from './view-models/FormattedMerchantLocation';

export interface LocationListProps {
  merchantLocations: FormattedMerchantLocation[];
  shouldShowContactInfo: boolean;
  onSelectLocation?: (locationId: string) => void;
  onFilterTextChange?: () => void;
}

const LocationList: React.FC<LocationListProps> = ({
  merchantLocations,
  shouldShowContactInfo,
  onSelectLocation,
  onFilterTextChange,
}) => {
  const { t } = useTranslation();

  return (
    <MarketList
      interactive={Boolean(onSelectLocation)}
      onMarketListSelectionsDidChange={(e) => {
        if (!e.detail.newSelectionValue) {
          return;
        }
        onSelectLocation?.(e.detail.newSelectionValue);
      }}
    >
      <MarketInputSearch
        data-testid="location-search-input"
        className="my-6"
        placeholder={t('merchantPortal.merchantLocation.modal.search')}
        slot="search"
        role="listitem"
        onMarketInputSearchValueChange={onFilterTextChange}
      />
      {merchantLocations.map((merchantLocation) => (
        <MarketRow
          key={merchantLocation.locationId!}
          value={merchantLocation.locationId!}
          data-testid={`location-row-${merchantLocation.locationId}`}
        >
          <MerchantLocation
            merchantLocationFormatted={merchantLocation}
            shouldShowContactInfo={shouldShowContactInfo}
          ></MerchantLocation>
          {Boolean(onSelectLocation) && <MarketRadio slot="control" />}
        </MarketRow>
      ))}
    </MarketList>
  );
};

export default LocationList;
