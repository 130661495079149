// eslint-disable-next-line filenames/match-exported
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import requestStatusMiddleware from './middleware/requestStatus';
import { composeWithDevTools } from 'redux-devtools-extension';
import modalReducer from './modalSlice';
import featureReducer from './featureSlice';
import { notificationReducer } from './notifications/reducers';
import buyerReducer from 'store/buyerSlice';
import authorizedAppsReducer from '../routes/profile/account/AuthorizedApplications/authorizedAppsSlice';
import toastReducer from './toastSlice';
import requestStatusReducer from './requestStatusSlice';
import { buyerportalApi } from './query';
import { receiptLookupApi } from './query/receiptLookup';
import { receiptSubscriptionsApi } from './query/receipts';
import { postofficeApi } from './query/postoffice';
import loyaltyMerchantPortalReducer from 'routes/merchant-scoped-portal/integrations/loyalty/loyaltySlice';
import merchantPortalReducer from 'routes/merchant-scoped-portal/merchantPortalSlice';

// Setup guide: https://redux.js.org/recipes/usage-with-typescript
const rootReducer = combineReducers({
  authorizedApps: authorizedAppsReducer,
  feature: featureReducer,
  loyaltyMerchantPortal: loyaltyMerchantPortalReducer,
  merchantPortal: merchantPortalReducer,
  modal: modalReducer,
  notification: notificationReducer,
  profile: buyerReducer,
  requestStatus: requestStatusReducer,
  toast: toastReducer,
  [buyerportalApi.reducerPath]: buyerportalApi.reducer,
  [receiptLookupApi.reducerPath]: receiptLookupApi.reducer,
  [receiptSubscriptionsApi.reducerPath]: receiptSubscriptionsApi.reducer,
  [postofficeApi.reducerPath]: postofficeApi.reducer,
});

export type AppState = ReturnType<typeof rootReducer>;

function configureMiddleware() {
  const middlewares = [
    thunkMiddleware,
    requestStatusMiddleware,
    buyerportalApi.middleware,
    receiptLookupApi.middleware,
    receiptSubscriptionsApi.middleware,
    postofficeApi.middleware,
  ];
  // @ts-ignore Typescript has issues with multiple RTKQ api middlewares...not sure why yet
  const middleWareEnhancer = applyMiddleware(...middlewares);
  return middleWareEnhancer;
}

export default function configureStore() {
  const composeEnhancers = composeWithDevTools({
    actionsBlacklist: ['requestStatus/setPendingState'],
  });

  const store = createStore(
    rootReducer,
    composeEnhancers(configureMiddleware())
  );

  return store;
}

export function configureStoreWithState(preloadedState: Partial<AppState>) {
  const store = createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(configureMiddleware())
  );

  return store;
}

export type StoreType = ReturnType<typeof configureStore>;
