import React, { useEffect, useRef, useState } from 'react';
import {
  MarketField,
  MarketDropdown,
  MarketInputText,
  MarketPopover,
  MarketDatePicker,
} from '@market/react';
import { useTranslation } from 'react-i18next';
import { formatLocalDate, parseUserDate } from 'utils/date';
import { isValid as dateIsValid } from 'date-fns';

type Props = {
  date: Date | null;
  onDateChanged?: (newDate: Date | null) => void;
  className?: string;
  isDateDisabled?: (date: Date) => boolean;
};

const DateInput: React.FC<Props> = ({
  date,
  onDateChanged,
  className,
  isDateDisabled,
}) => {
  const { t } = useTranslation();
  const dropdownRef = useRef<HTMLMarketDropdownElement>(null);

  const [displayDate, setDisplayDate] = useState<string>(
    date ? formatLocalDate(date) : ''
  );
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (date) {
      const formatted = formatLocalDate(date);
      if (formatted !== displayDate) {
        setDisplayDate(formatted);
      }
    }
  }, [date]);

  function handleTextInputChange({ detail }: { detail: { value: string } }) {
    setDisplayDate(detail.value);
    setIsValid(true);
  }

  function handleOnBlur() {
    let parsedDate;
    try {
      parsedDate = parseUserDate(displayDate);
    } catch {
      parsedDate = null;
    }

    const isValid =
      parsedDate && dateIsValid(parsedDate) && !isDateDisabled?.(parsedDate);

    onDateChanged?.(isValid ? parsedDate : null);
    setIsValid(Boolean(isValid));
  }

  return (
    <MarketField className={className} invalid={!isValid}>
      <span slot="error">{t('receiptLookup.date.error')}</span>
      <MarketDropdown
        data-testid="date-input"
        className="w-full"
        interaction="persistent"
        popover-placement="bottom-start"
        ref={dropdownRef}
      >
        <MarketInputText
          id="receipt-lookup-date-input"
          data-testid="date-input-text"
          slot="trigger"
          value={displayDate}
          onMarketInputValueChange={handleTextInputChange}
          onBlur={handleOnBlur}
        >
          <label htmlFor="receipt-lookup-date-input">
            {t('receiptLookup.label.date')}*
          </label>
        </MarketInputText>
        <MarketPopover slot="popover">
          <MarketDatePicker
            selectedStartDate={date?.toString()}
            locale={navigator.language}
            isDateDisabled={isDateDisabled}
            displayedDate={date?.toString()}
            onMarketDateRangeChanged={({ detail }) => {
              setIsValid(true);
              onDateChanged?.(new Date(detail.startDate));
              setDisplayDate(detail.startDate);
              dropdownRef.current?.closeDropdown();
            }}
          ></MarketDatePicker>
        </MarketPopover>
      </MarketDropdown>
    </MarketField>
  );
};

export default DateInput;
